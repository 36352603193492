$dialog-reassign-assessment-size: $size-dialog-small-max-width;
$dialog-reassign-assessment-text-color: $color-label;
$dialog-reassign-assessment-user-data-separator: $main-separator-components;

.dialog-reassign-assessment {
  .MuiPaper-root {
    max-width: $dialog-reassign-assessment-size;
  }
}

.dialog-reassign-assessment__text {
  @extend .text-p;

  color: $dialog-reassign-assessment-text-color;
}

.dialog-reassign-assessment__user-data {
  margin-top: $dialog-reassign-assessment-user-data-separator;
  margin-bottom: $dialog-reassign-assessment-user-data-separator;
}
