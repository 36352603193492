$unoi-lesson-info-background-color: #f1f3f5;

.unoi-lesson-view {
  .layout-header__children {
    padding: 0 !important;
  }
  .layout-header__content {
    background-color: $unoi-lesson-info-background-color;
  }
}
