$no-items-mode-edit-background: $color-gray-07;
$no-items-mode-edit-background-edit-mode: transparent;
$no-items-mode-edit-text-color: $color-gray-03;

.no-items-mode-edit {
  @extend .card-default-big;
  @extend .title-h6;

  background: $no-items-mode-edit-background;
  color: $no-items-mode-edit-text-color;
  text-align: center;
}

.no-items-mode-edit--edit-mode {
  @extend .border-actions;

  background: $no-items-mode-edit-background-edit-mode;
}

.no-items-mode-edit__add-button {
  @extend .text-link;
  @extend .hover-basic;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: $main-separator-components;
}

.no-items-mode-edit__add-button-text {
  margin-left: $separator-s;
}
