$item-complex-list-left-width: 54px;

.item-complex-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-complex-list__left {
  display: flex;
  align-items: center;
}

.item-complex-list__left-children {
  width: $item-complex-list-left-width;
  flex: 0 0 auto;
}

.item-complex-list__right {
  flex: 0 0 auto;
}
