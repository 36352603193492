$list-complex-dropdown-hover-background: $color-hover-dropdowns-items-hover;
$list-complex-dropdown-padding: $padding-dropdown;
$list-complex-dropdown-padding-li: $separator-s $separator-xs;
$list-complex-dropdown-border-radius-li: $border-radius-list-complex-li;
$list-complex-dropdown-transition-duration: $transition-duration-01;

.list-complex-dropdown {
  padding: $list-complex-dropdown-padding;

  .item-complex-list {
    transition-duration: $list-complex-dropdown-transition-duration;

    border-radius: $list-complex-dropdown-border-radius-li;
    padding: $list-complex-dropdown-padding-li;
    &:hover {
      cursor: pointer;
      background: $list-complex-dropdown-hover-background;
    }
  }
}
