.learning-objective-item {
  display: flex;
  padding: $separator-m;
  border-radius: $border-size-active;
  background-color: $color-white;
}

.learning-objective-item__left {
  display: flex;
  align-items: center;
  width: 60%;
}

.learning-objective-item__center {
  width: 20%;
}

.learning-objective-item__right {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: space-between;
}

.learning-objective-item__info {
  margin-left: $separator-m;
}

.learning-objective-item__results {
  width: 85px;

  .progress-bar__bar {
    background: #40d14f;
  }
}

.learning-objective-item__info-parent {
  @extend .text-small-bold;

  margin-bottom: $separator-xs;
}

.learning-objective-item__activities {
  @extend .text-small;

  color: $color-gray-03;
}

.learning-objective-item__info-name {
  @extend .text-small;
  @extend .ellipsis-complex;

  margin-right: $separator-xxl;
}

.learning-objective-item__results-see {
  @extend .hover-basic;
  margin-right: $separator-m;
}

.learning-objective-item--green {
  border-left: 4px solid #5fcb93;
}

.learning-objective-item--yellow {
  border-left: 4px solid #f2aa3e;
}

.learning-objective-item--red {
  border-left: 4px solid #f86871;
}

.learning-objective-item--grey {
  border-left: 4px solid #d8d8d8;
}
