$font-first: var(--font-first);
$font-first-semi: var(--font-first-semi);
$font-second: var(--font-second);
$font-second-bold: var(--font-second-bold);
$font-first-kids: 'Concert Regular';
$font-second-kids: 'Circular Regular';
$font-second-bold-kids: 'Circular Bold';

$font-size-01: 48px;
$font-size-02: 36px;
$font-size-03: 32px;
$font-size-04: 28px;
$font-size-05: 22px;
$font-size-06: 18px;
$font-size-07: 15px;
$font-size-08: 14px;
$font-size-09: 15px;
$font-size-10: 12px;
$font-size-11: 10px;
