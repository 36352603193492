$bubble-border-radius: 100%;
$bubble-text-border-radius: $border-radius-03;

$bubble-size: $size-bubble;
$bubble-size-medium: $size-bubble-medium;
$bubble-background: $gradient;
$bubble-background-blue: $color-second-blue-02;
$bubble-font-size: 9px;
$bubble-font-line-height: 11px;
$bubble-text-color: $color-white;
$bubble-text-padding: $separator-xs;

.bubble {
  background: $bubble-background;
  border-radius: $bubble-border-radius;
  display: inline-block;
  height: $bubble-size;
  min-width: $bubble-size;
}

.bubble--color-blue {
  background: $bubble-background-blue;
}

.bubble--text {
  border-radius: $bubble-text-border-radius;
  height: $bubble-size-medium;
  line-height: 9px;
  min-width: $bubble-size-medium;
  font-weight: bold;
  text-align: center;
}

.bubble-text {
  @extend .text-smallest-bold;

  color: $bubble-text-color;
  font-size: $bubble-font-size !important;
  line-height: $bubble-size-medium !important;

  position: relative;
  padding: 0 $bubble-text-padding;
}
