$border-radius-01: var(--border-radius-01) !default;
$border-radius-02: 4px !default;
$border-radius-03: 24px !default;
$border-radius-04: 12px !default;
$border-radius-05: 16px !default;
$border-radius-06: 36px !default;
$border-radius-07: 20px !default;
$border-radius-08: 40px !default;

$border-size-01: 1px !default;
$border-size-02: 2px !default;
$border-size-03: 3px !default;
$border-size-active: 8px !default;
