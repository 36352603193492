$layout-calendar-left-background: $color-background;
$layout-calendar-padding: $main-padding-page;
$layout-calendar-footer-background: $color-layout-progress;
$layout-calendar-footer-box-shadow: $box-shadow-03;
$layout-calendar-footer-height: $size-layout-progress-footer-height;
$layout-calendar-column1-width: $size-layout-progress-aside-width;
$layout-calendar-height-with-footer: calc(100vh - #{$layout-calendar-footer-height});
$layout-calendar-height-with-header: calc(100vh - #{$size-height-header});
$layout-calendar-height-with-header-footer: calc(100vh - #{$size-height-header} - #{$layout-calendar-footer-height});
$layout-calendar-responsive-aside-width: 378px;

.layout-calendar {
  min-height: 100vh;
}

.layout-calendar__header {
  position: fixed;
  top: 0;

  width: 100%;
  z-index: $z-index-header;
}

.layout-calendar__columns {
  display: flex;

  min-height: 100vh;
}

.layout-calendar__column-1 {
  @extend .scroll-bar-horizontal;
}

.layout-calendar--with-footer {
  position: relative;

  .layout-calendar__columns {
    min-height: $layout-calendar-height-with-footer;
    padding-bottom: $layout-calendar-footer-height;
  }

  .layout-calendar__column-1 {
    height: $layout-calendar-height-with-footer;
  }

  .layout-calendar__column-2 {
    min-height: $layout-calendar-height-with-footer;
  }
}

.layout-calendar--with-header {
  min-height: $layout-calendar-height-with-header;

  .layout-calendar__columns {
    min-height: $layout-calendar-height-with-header;
    margin-top: $size-height-header;
  }

  .layout-calendar__column-1 {
    height: $layout-calendar-height-with-header;
  }

  .layout-calendar__column-2 {
    min-height: $layout-calendar-height-with-header;
  }
}

.layout-calendar--with-header.layout-calendar--with-footer {
  min-height: $layout-calendar-height-with-header-footer;

  .layout-calendar__columns {
    min-height: $layout-calendar-height-with-header-footer;
  }

  .layout-calendar__column-1 {
    height: $layout-calendar-height-with-header-footer;
  }

  .layout-calendar__column-2 {
    min-height: $layout-calendar-height-with-header-footer;
  }
}

.layout-calendar__column-1 {
  @extend .padding-default;

  background: $layout-calendar-left-background;
  // padding-left: $layout-calendar-padding;
  padding-top: $layout-calendar-padding;
  padding-bottom: $layout-calendar-padding;

  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;

  position: fixed;
  left: 0;

  width: $layout-calendar-column1-width;
}

.layout-calendar__column-2 {
  @extend .padding-default-rigth;

  padding-top: $layout-calendar-padding;
  // padding-right: $layout-calendar-padding;
  padding-left: calc(#{$layout-calendar-column1-width} + #{$layout-calendar-padding});
  padding-bottom: $layout-calendar-padding;
  width: 100%;
}

.layout-calendar__column-2-children {
  @extend .grid;

  height: 100%;
  width: 100% !important;
}

.layout-calendar__footer {
  background: $layout-calendar-footer-background;
  box-shadow: $layout-calendar-footer-box-shadow;
  height: $layout-calendar-footer-height;
  bottom: 0;

  position: fixed;
  bottom: 0;

  width: 100%;
  z-index: $z-index-header;
}

// @media #{$breakpoints-large} {
//   .layout-calendar__column-1 {
//     @include column--without-gutter(4);

//     display: block;
//   }

//   .layout-calendar__column-2 {
//     @include column--without-gutter(8);
//   }
// }

// @media #{$breakpoints-xxlarge} {
//   .layout-calendar__column-1 {
//     @include column--without-gutter(3);
//   }

//   .layout-calendar__column-2 {
//     @include column--without-gutter(9);
//   }
// }

// responsive
.layout-calendar--responsive {
  &.layout-calendar--responsive-open {
    .layout-calendar__column-1 {
      right: 0;
      transition: right 0.3s ease-in-out;
    }

    .layout-calendar__mobile-menu-backdrop {
      display: block;
      opacity: 0.25;
    }
  }

  .layout-calendar__column-1 {
    padding-top: $separator-xl;
    padding-right: 0;

    left: unset;
    right: -#{$layout-calendar-responsive-aside-width};
    top: 0;

    transition: right 0.3s ease-in-out;
    width: $layout-calendar-responsive-aside-width;
    z-index: $z-index-aside;

    .layout-calendar_responsive-close-button {
      text-align: right;
      padding-right: $separator-xl;

      .icon {
        cursor: pointer;
      }
    }
  }

  .layout-calendar__column-2 {
    padding-left: 28px;
    margin-top: 64px;
  }

  .layout-calendar__mobile-menu-backdrop {
    position: fixed;
    display: none;
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0);
    top: 0;
    right: 0;
    opacity: 0;
    z-index: $z-index-aside-overlay;
  }
}

@media #{$breakpoints-xlarge} {
  .layout-calendar--responsive {
    .layout-calendar__column-1 {
      background: $layout-calendar-left-background;
      padding-top: $separator-xl;
      padding-bottom: $separator-xl;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100vh;
      position: fixed;
      left: 0;
      top: unset;
      width: $layout-calendar-column1-width;

      .layout-calendar_responsive-close-button {
        display: none;
      }
    }

    .layout-calendar__column-2 {
      padding-top: $separator-xl;
      padding-left: calc(#{$layout-calendar-column1-width} + #{$separator-xl});
      padding-bottom: $separator-xl;
      width: 100%;
      margin-top: 0;
    }

    .layout-calendar__mobile-menu-backdrop {
      display: none;
    }

    .header-responsive {
      display: none;
    }
  }
}
