@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.slide-in-blurred-top {
  -webkit-animation: slide-in-blurred-top 1.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-top 1.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.loading-robot {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;

  svg {
    width: 15rem;
    height: auto;
  }
  @keyframes floatingShadow {
    0% {
      transform: scale(1);
    }
    45% {
      transform: scale(0.7);
    }
    55% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
  .robot {
    animation: floating 3s ease-in-out infinite;
  }
  .shadow {
    transform-origin: bottom;
    animation: floatingShadow 3s ease-in-out infinite;
  }
  .color {
    animation: color 2s ease-in-out infinite;
  }

  .color2 {
    animation: color 1s ease-in-out infinite;
    animation-direction: reverse;
  }
  .eyes {
    transform-origin: bottom;
    animation: eye 3s ease-in-out infinite;
    animation-direction: alternate;
  }

  @keyframes floating {
    0% {
      transform: translate3d(0, 0, 0);
    }
    45% {
      transform: translate3d(0, -10%, 0);
    }
    55% {
      transform: translate3d(0, -10%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes eye {
    0% {
      transform: translate3d(0, 0, 0);
    }
    5% {
      transform: translate3d(-70%, 90%, 0);
    }
    40% {
      transform: translate3d(-70%, 90%, 0);
    }
    50% {
      transform: translate3d(-50%, -40%, 0);
    }
    93% {
      transform: translate3d(-50%, -40%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes color {
    0% {
      fill: #ff5322;
    }
    55% {
      fill: #f91b6b;
    }
    100% {
      fill: #ffad31;
    }
  }

  .loading__text {
    margin-top: $separator-m;
    font-family: $font-first;
    font-weight: 500;
    color: $color-gray-03;
  }
}
