$new-test-form-buttons-separator: $main-separator-components;
$new-test-form-items-separator: $form-spacing;
$new-test-form-options-separator-icon: $main-separator-items;
$new-test-form-dropdown-students-width: 300px;
$new-test-form-dropdown-students-placeholder-color: $color-title;
$new-test-form-dropdown-radio-padding: $padding-card-default;
$new-test-form-dropdown-radio-border-radius: $border-radius-01;
$new-test-form-dropdown-radio-selected-name-color: $color-title;
$new-test-form-dropdown-radio-selected-background: $color-gray-07;
$new-test-form-dropdown-radio-separator: $main-separator-components;

$new-test-form-section-selector-list-padding: $separator-s;
$new-test-form-section-selector-option-padding: $separator-s $separator-xs;
$new-test-form-section-selector-text-color: $color-gray-02;
$new-test-form-section-selector-hover-color: $color-gray-07;

.new-test-form {
  .error-text {
    @extend .text-smallest;
  }
  .section-select__wrapper {
    .select-customizable {
    }
    .select-customizable__menu-list {
      padding: $new-test-form-section-selector-list-padding;
    }
    .select-customizable__option {
      border-radius: 8px;
      padding: $new-test-form-section-selector-list-padding;
      color: $new-test-form-section-selector-text-color !important;

      &:hover {
        background: $new-test-form-section-selector-hover-color !important;
      }
    }
  }
}

.new-test-form__form {
  @extend .grid-column;

  .textarea__input {
    height: 84px;
    resize: none;
  }
}

.new-test-form__big {
  @include column(12);

  margin-bottom: $new-test-form-items-separator;
}

.new-test-form__six-parts {
  @include column(6);

  margin-bottom: $new-test-form-items-separator;
}

.new-test-form__big--error-max-length {
  .input-text__input {
    border: 2px dashed $color-first !important;
  }
}

.new-test-form__big--task-type {
  display: flex;
  justify-content: space-between;
}

.new-test-form__big--task-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-test-form__big--task-type-radio {
  border-radius: $new-test-form-dropdown-radio-border-radius;
  // margin-right: $new-test-form-dropdown-radio-separator;
  padding: $new-test-form-dropdown-radio-padding;

  .input-radio__label {
    @extend .text-small;
  }
  .input-radio__description {
    @extend .text-smallest;
  }
}

.new-test-form__big--task-type-radio--selected {
  background: $new-test-form-dropdown-radio-selected-background;

  .input-radio__label {
    color: $new-test-form-dropdown-radio-selected-name-color;
  }
}

.new-test-form__buttons {
  margin-top: $new-test-form-buttons-separator;
}

.new-test-form__options {
  display: flex;
  align-items: center;
}

.new-test-form__text {
  margin-left: $new-test-form-options-separator-icon;
}

.new-test-form__six-parts--students {
  .select-checkboxs__picky--with-value {
    .select-checkboxs__placeholder-all-text,
    .picky__input > span {
      color: $new-test-form-dropdown-students-placeholder-color !important;
    }
  }
}
