$filter-course-aside-margin-top: $main-separator-components;

.filter-course-aside__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $separator-l $padding-aside;
}

.filter-course-aside__disciplines {
  margin-top: $filter-course-aside-margin-top;
}
