.lesson-mint-view {
  background: $color-white;

  .lesson-header__menu,
  .lesson-header__unit-hidden {
    display: none;
  }

  .mint-posts-editor {
    background-color: $color-white !important;
  }

  .lemo-controls-right {
    color: $color-first;
  }

  .lemonade-exercises .lemo-base-button--text .lemo-icon {
    fill: $color-first;
  }

  .layout-header__children {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.mint-view-media-editor-video {
  position: relative;
}

button.brand--background,
.lemo-base-button--primary {
  background-color: $color-first !important;

  &:disabled {
    background-color: $color-gray-05 !important;
  }
}

.lesson-view__content--active {
  transition-duration: 0.9s;
  background-color: $color-first-alpha;
}
