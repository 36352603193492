$my-classrooms-view-top-height: 50px;
$my-classrooms-view-image-height: 150px;
$my-classrooms-view-image-width: 150px;

.my-classrooms-view--tab-library {
  .text-link-component {
    display: none;
  }

  .myclassrooms-view-library__program-group {
    background: $color-white;
    border-radius: $border-radius-01;
  }

  .my-classrooms-view__top {
    height: $my-classrooms-view-top-height;
  }

  .program-card {
    padding: 0;
  }

  .program-card__picture-container,
  .program-card__picture {
    border-radius: $border-radius-01;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
    height: $my-classrooms-view-image-height;
    width: $my-classrooms-view-image-width;
  }

  .program-card__info {
    padding-top: $main-separator-items;
    padding-bottom: $main-separator-items;
  }
}
