$group-lessons-progress-card-top-height: 392px;
$group-lessons-progress-card-bottom-height: 185px;
$group-lessons-progress-card-bottom-background-color: $color-white;
$group-lessons-progress-card-border-radius: 16px;
$group-lessons-progress-card-image-lesson-size: 150px;
$group-lessons-progress-card-image-container-lesson-size: 210px;
$group-lessons-progress-card-progress-height: 8px;

// .group-lessons-progress-card {
//   border-radius: $group-lessons-progress-card-border-radius $group-lessons-progress-card-border-radius 0px 0px;;
// }

.group-lessons-progress-card__lesson-item {
  margin: 0 $gutter;
}

.group-lessons-progress-card__lesson-item--type-done,
.group-lessons-progress-card__lesson-item--type-current {
  .group-lessons-progress-card__lesson-image {
    @extend .border-primary;

    border-width: $border-radius-02;
  }
}

.group-lessons-progress-card__lesson-item--type-current {
  .group-lessons-progress-card__lesson-image {
    animation: pulse3 2s infinite;
  }
}

.group-lessons-progress-card__image {
  height: $group-lessons-progress-card-top-height;
  background-size: cover;
}

.group-lessons-progress-card__name {
  @extend .title-h3;

  color: $color-white;
  padding: $separator-xxl;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px $color-gray-03;
}

.group-lessons-progress-card__bottom {
  @extend .border-light;

  background: $group-lessons-progress-card-bottom-background-color;
  //height: $group-lessons-progress-card-bottom-height;
  border-top: 0;
}

.group-lessons-progress-card__lessons-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.group-lessons-progress-card__lesson-item {
  position: relative;
  top: -108px;
}

.group-lessons-progress-card__lesson-image-container {
  height: $group-lessons-progress-card-image-container-lesson-size;
  width: $group-lessons-progress-card-image-container-lesson-size;

  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.group-lessons-progress-card__lesson-image {
  height: $group-lessons-progress-card-image-lesson-size;
  width: $group-lessons-progress-card-image-lesson-size;

  background-size: 100% 100%;
  background-position: center center;
  border-radius: 100%;

  box-sizing: content-box;
  position: relative;

  transition-duration: 0.3s;
  &:hover {
    @extend .hover-basic;

    opacity: 1;
    box-shadow: 0 0 0 17px $color-first-alpha;
    background-size: 120% 120%;
  }
}

.group-lessons-progress-card__lesson-name {
  @extend .title-h5;
  // @extend .ellipsis;
  color: $color-gray-03;
  text-transform: uppercase;
  text-align: center;
  margin: $separator-xs 0 $separator-s 0;
}

.group-lessons-progress-card__lesson-done-icon {
  position: absolute;
  right: -8px;
  bottom: 25px;
  background: $color-white;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.group-lessons-progress-card__lesson-done-text {
  @extend .title-h6;

  color: $color-first;
  display: block;

  text-align: center;
}

.group-lessons-progress-card__lesson-info-container {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .group-lessons-progress-card__lesson-progress {
    margin-bottom: $separator-s;
    min-height: 20px;
  }

  .progress-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: $group-lessons-progress-card-image-lesson-size;
    margin: auto;
  }

  .progress-bar__bar {
    width: 100%;
    height: $group-lessons-progress-card-progress-height;
  }

  .progress-bar__label {
    display: flex;
    flex: 0 0 auto;
    margin-left: $main-separator-items;
  }
  .group-lessons-progress-card__lesson-date {
    font-family: $font-first;
    color: $color-gray-04;
    padding-top: $separator-xs;

    &--today {
      color: $color-first;
    }
  }
}

@-webkit-keyframes pulse3 {
  0% {
    -webkit-box-shadow: 0 0 0 0 #ff53224d;
  }

  50% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse3 {
  0% {
    -moz-box-shadow: 0 0 0 0 #ff53224d;
    box-shadow: 0 0 0 0 #ff53224d;
  }

  50% {
    -moz-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
