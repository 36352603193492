.course-form-schedule__times-unit {
  display: block;
  .input-radio {
    margin-right: $main-separator-components;
    width: auto;
  }
}

.course-form-schedule__title {
  @extend .title-h5;
}

.course-form-schedule__forms-units {
  margin-top: $main-separator-components;

  .MuiAccordion-root {
    @extend .card-default-big;

    margin-bottom: $main-separator-components;
  }

  .accordion__tab {
    padding: 0;
  }

  .accordion__title-text {
    margin-left: 0;
  }
}

@media #{$breakpoints-tablet} {
  .course-form-schedule__times-unit {
    display: flex;
    justify-content: flex-start;
  }
}
