.remote-mint-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 12px;
  box-shadow: 0 1px 2px 0 rgba(112, 107, 101, 0.15);
  border: $border-size-01 solid $color-gray-05;

}

.remote-mint-item__left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remote-mint-item__left-custom {
  margin-right: $main-separator-items;
}

.remote-mint-item__left-text {
  @include ellipsis-line(1);

  padding-right: $main-separator-components;
  line-height: 1.2;
} 
