$dropdown-event-calendar-width: $size-dropdown-event-calendar-width;
$dropdown-event-calendar-date-header-margin: $separator-m;
$dropdown-event-calendar-date-margin: $separator-m;
$dropdown-event-calendar-name-margin: $separator-l;
$dropdown-event-calendar-task-info-margin: $separator-xl;
$dropdown-event-calendar-task-questions-margin: $separator-xs;
$dropdown-event-calendar-name-buttom: $main-separator-components;
$dropdown-event-calendar-name-separator: $main-separator-items;
$dropdown-event-calendar-date-header-color: $color-gray-02;
$dropdown-event-calendar-course-name-color: $color-gray-02;
$dropdown-event-calendar-date: $color-label;
$dropdown-event-calendar-name: $color-gray-03;
$dropdown-event-calendar-task-name: $color-gray-01;

.dropdown-event-calendar__lib {
  .MuiPaper-root {
    width: $dropdown-event-calendar-width;
  }
  .card-generic {
    border: 0;
  }
}

.dropdown-event-calendar__close {
  @extend .hover-basic;

  position: absolute;
  right: 10px;
}

.dropdown-event-calendar__course {
  display: flex;
  align-items: center;
}

.dropdown-event-calendar__course-name {
  @extend .text-small-bold;

  margin-left: $dropdown-event-calendar-name-margin;
  color: $dropdown-event-calendar-course-name-color;
  display: block;
}

.dropdown-event-calendar__date-header {
  @extend .text-small-bold;

  margin-bottom: $dropdown-event-calendar-date-header-margin;
  color: $dropdown-event-calendar-date-header-color;
}

.dropdown-event-calendar__task-course {
  @extend .text-small;

  display: flex;
  align-items: center;
  color: $dropdown-event-calendar-name;
}

.dropdown-event-calendar__task-unit {
  margin-left: $dropdown-event-calendar-task-questions-margin;
}

.dropdown-event-calendar__task-info {
  display: flex;
  align-items: center;
  margin: $dropdown-event-calendar-name-margin 0;
}

.dropdown-event-calendar__task-info-right {
  margin-left: $dropdown-event-calendar-task-info-margin;
}

.dropdown-event-calendar__task-info-questions {
  @extend .text-smallest;

  margin-bottom: $dropdown-event-calendar-task-questions-margin;
  color: $dropdown-event-calendar-name;
}

.dropdown-event-calendar__task-name {
  @extend .text-p-bold;

  color: $dropdown-event-calendar-task-name;
}

.dropdown-event-calendar__date-text {
  margin-left: $dropdown-event-calendar-date-margin;
}

.dropdown-event-calendar__date {
  @extend .text-small;

  display: flex;
  align-items: space-between;
  justify-content: space-between;
  margin-top: $dropdown-event-calendar-name-margin;
  color: $dropdown-event-calendar-date;

  .switch {
    display: flex;
    align-items: center;
  }
}

.dropdown-event-meet {
  padding: $separator-m 0;
  margin: $separator-m 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-gray-06;
  border-bottom: 1px solid $color-gray-06;
}

.dropdown-event-meet__icons {
  display: flex;
}

.dropdown-event-meet__icon {
  margin-right: $separator-m;
  cursor: pointer;
  svg {
    fill: $color-gray-04 !important;
  }
  &--disabled {
    cursor: not-allowed;
    svg {
      fill: $color-gray-05 !important;
    }
  }
}

.dropdown-event-meet__icon:hover {
  svg {
    fill: $color-first !important;
  }
}

.dropdown-event-meet__icon--disabled:hover {
  svg {
    fill: $color-gray-05 !important;
  }
}

.dropdown-event-meet__info {
  margin-right: $separator-xl;
}

.dropdown-event-calendar__date-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-event-calendar__name {
  @extend .text-p-bold;

  color: $dropdown-event-calendar-name;
  display: block;
  margin: $dropdown-event-calendar-name-separator 0 $dropdown-event-calendar-name-buttom 0;
}

.dropdown-event-calendar__footer {
  display: flex;
  justify-content: flex-end;
}
