$dialog-select-avatar: $size-dialog-s-max-width;
$dialog-select-avatar-margin: $form-spacing;
$dialog-select-avatar-selected-color: $color-first;

.dialog-select-avatar {
  .MuiPaper-root {
    max-width: $dialog-select-avatar;
  }
}

.dialog-select-avatar__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -$dialog-select-avatar-margin;
}

.dialog-select-avatar__avatar {
  @extend .hover-basic;

  margin: $dialog-select-avatar-margin;
}

.dialog-select-avatar__avatar--default {
  border-radius: 100%;
  border: 2px solid transparent;
}

.dialog-select-avatar__avatar--selected {
  border-radius: 100%;
  border: 2px solid $dialog-select-avatar-selected-color;
}
