$footer-banner-background: $color-gray-01;
$footer-banner-color: $color-white;

.footer-banner {
  @extend .text-small;

  z-index: $z-index-minor-elements;
  color: $footer-banner-color;
  background: $footer-banner-background;
  padding: $card-default-big-padding;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
