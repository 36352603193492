$aside-notifications-width: 550px;
$aside-notifications-list-separator: $main-separator-components;
$aside-notifications-actions-separator: $main-separator-components;
$aside-notifications-mask-as-read-color: $color-label2;
$aside-notifications-z-indez: $z-index-aside;

.aside-notifications__aside {
  z-index: $aside-notifications-z-indez !important;

  .menu-aside {
    width: $aside-notifications-width !important;
  }
  .header-tabs {
    .header-tabs__tab {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .list-complex-dropdown {
    padding-left: 0;
  }

  .notifications-list {
    height: calc(100vh - 30px);
  }
}

.aside-notifications__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aside-notifications__list {
  margin-top: $aside-notifications-list-separator;

  .item-complex-list__left {
    align-items: flex-start;
  }
}

.aside-notifications__mark-as-read {
  @extend .text-small;
  @extend .hover-basic;

  color: $aside-notifications-mask-as-read-color;
  text-decoration: underline;
}

.aside-notifications__actions {
  margin-right: $aside-notifications-actions-separator;
}
