$calendar-view-separators: $main-separator-components;
$calendar-view-title-color: $color-title;

.icon-title {
  display: flex;
  align-items: center;
}

.icon-title__title {
  @extend .title-h4;

  color: $calendar-view-title-color;
  margin-left: $calendar-view-separators;
}
