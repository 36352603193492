$classrooms-simple-item-date-spaces: $separator-m;
$classrooms-simple-item-info-margin: $separator-l;
$classrooms-simple-item-user-color: $color-title;
$classrooms-simple-item-date-color: $color-label;
$classrooms-simple-item-text-color: $color-title;

.classrooms-simple-item {
  @extend .card-default;

  display: flex;
  flex-direction: column;
}

.classrooms-simple-item__info {
  display: flex;
  align-items: center;
}

.classrooms-simple-item__info-main {
  @extend .ellipsis-complex;

  display: flex;
  flex-direction: column;
  margin-left: $classrooms-simple-item-info-margin;
}

.classrooms-simple-item__name {
  @extend .text-small-bold;

  color: $classrooms-simple-item-user-color;
}

.classrooms-simple-item__level {
  @extend .text-smallest;

  color: $classrooms-simple-item-date-color;
}

.classrooms-simple-item__text {
  @extend .text-small;

  color: $classrooms-simple-item-text-color;
}

.classrooms-simple-item__options {
  @extend .hover-basic;
}
