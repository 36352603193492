$profile-form__separator: $form-spacing-big;
$profile-form__button-separator: $main-separator-components;
$profile-form__role-separator: $main-separator-items;
$profile-form__image-separator: $main-separator-components;

.profile-form__image {
  @extend .text-link;
  @extend .title-h6;

  display: flex;
  align-items: center;
}

.profile-form__text-change-image {
  margin-left: $profile-form__image-separator;
}

.profile-form__item {
  margin-bottom: $profile-form__separator;
}

.profile-form__buttom {
  margin-top: $profile-form__button-separator;
}

.profile-form__label {
  @extend .label;
}

.profile-form__role {
  @extend .text-small;

  display: block;
  margin-top: $profile-form__role-separator;
}

.profile-form__body {
  width: 90%;
}
