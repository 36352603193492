$toolbar-separator: $separator-calendar-toolbar;
$toolbar-left: 72px; //anchura de los que ocupan las horas
$toolbar-separator-items: $separator-xl;
$toolbar-today-color: $color-gray-04;
$toolbar-month-color: $color-title;

$toolbar-actions-border-radius: $border-radius-calendar-toolbar-actions;
$toolbar-action-height: $size-calendar-big-actions-height;
$toolbar-action-today-padding: 0 $separator-s;
$toolbar-select-separator: $separator-s;
$toolbar-select-min-width: 167px;
$toolbar-z-index: $z-index-toolbar;

$toolbar-select-hover-background-color: $color-gray-07;
$toolbar-select-hover-text-color: $color-gray-02;

.toolbar {
  display: block;
  align-items: center;
  justify-content: space-between;

  margin-bottom: $toolbar-separator;
  z-index: $toolbar-z-index;
}

.toolbar__left,
.toolbar__right {
  display: flex;
  align-items: center;
}

.toolbar__left {
  flex-direction: row;
}

.toolbar__right {
  flex-direction: row;
}

.toolbar__settings {
  cursor: pointer;
}

.toolbar__actions-container {
  display: flex;
  align-items: center;
}

.toolbar__month-title {
  @extend .title-h5;

  color: $toolbar-month-color;
  margin-left: $toolbar-separator-items;
}

.toolbar__action {
  @extend .hover-basic;
  @extend .border-light;

  border-radius: $toolbar-actions-border-radius;
  height: $toolbar-action-height;
  margin-right: $toolbar-select-separator;
  display: flex;
  align-items: center;
}

.toolbar__action--today {
  @extend .text-small-bold;

  color: $toolbar-today-color;
  height: $toolbar-action-height;
  padding: $toolbar-action-today-padding;
}

.toolbar__add-event-container {
  display: flex;
  align-items: center;
}

.toolbar__add-event-text {
  @extend .title-h6;
  @extend .text-link;
}

.toolbar__views {
  margin-left: $toolbar-separator-items;
  min-width: $toolbar-select-min-width;

  .select-customizable__option:hover {
    background-color: $toolbar-select-hover-background-color;
    color: $toolbar-select-hover-text-color;
  }
}

@media #{$breakpoints-xlarge} {
  .toolbar__left,
  .toolbar__right {
    flex-direction: row;
  }
}

@media #{$breakpoints-tablet} {
  .toolbar {
    display: flex;
    margin-left: $toolbar-left;
  }
}
