$skeleton-kanban-entry-height: 300px;

.skeleton-kanban {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
}

.skeleton-kanban-column {
  display: flex;
  height: $skeleton-kanban-entry-height;
}
