// $pagintation-wigget-height: 119px;
$pagintation-wigget-height: 119px;
$pagintation-wigget-width: 300px;
$pagintation-wigget-background-color: #ffffff;
$pagination-widget-open-button-width: 161px;
$pagination-widget-open-button-height: 31px;
$pagination-widget-go-button-width: 66px;
$pagination-widget-font-size: 14px;
$pagination-widget-button-font-size: 14px;
$pagination-widget-input-width: 62px;
$pagination-widget-input-height: 32px;

@keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-bottom {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
}

.pagintation-wigget {
  display: flex;
  position: absolute;
  bottom: 76px;
  margin-left: -90px;
  background-color: $pagintation-wigget-background-color;
  width: $pagintation-wigget-width;
  height: $pagintation-wigget-height;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: $pagination-widget-font-size;
  box-shadow: 0px 6px 12px 0px #a39b9333;
  border: 1px solid #dbd5ce;
}

.pagintation-widget__slide-top {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.pagination-widget__slide-bottom {
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.pagination-widget__top {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .input-text__input {
    max-width: $pagination-widget-input-width;
    max-height: $pagination-widget-input-height;
  }

  .input-text__input:focus {
    box-shadow: 0px 0px 0px 2px #ff5a0a33;
    border: 1px solid #ed7011;
  }

  .button {
    padding: 0;
    font-size: $pagination-widget-button-font-size;
    max-width: $pagination-widget-go-button-width;
    max-height: $pagination-widget-input-height;

    .icon {
      right: 8px;
    }
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: $pagintation-wigget-background-color;
      }
    }
  }
}

.pagination-widget__bottom {
  .button {
    width: $pagination-widget-open-button-width;
    height: $pagination-widget-open-button-height;
    font-size: $pagination-widget-button-font-size;
  }
}
