$learning-objetives-tag-color: $color-label;
$learning-objetives-tag-separator: $main-separator-items;

.learning-objetives-tag-list {
}

.learning-objetives-tag-list__title {
  @extend .title-h6;
  @extend .ellipsis;

  display: block;
  color: $learning-objetives-tag-color !important;
  margin-bottom: $learning-objetives-tag-separator;
}
