$tag-border-radius: $border-radius-tag;
$tag-background: $color-background-tag;
$tag-background-primary: rgba(255, 83, 34, 0.1);
$tag-padding: 0 $separator-s;
$tag-padding-big: $separator-s $separator-l;
$tag-padding-medium: $separator-xs $separator-s;
$tag-color: $color-tag;
$tag-color-primary: $color-tag-primary;
$tag-color-tooltip-background: $color-second-yellow-01;
$tag-icon-separator: $main-separator-items;

.tag {
  @extend .border-light;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: $tag-background;
  border-radius: $tag-border-radius;
  padding: $tag-padding;
}

.tag__text {
  @extend .text-extra;

  color: $color-tag;
}

.tag--color-primary {
  background: $tag-background-primary;
  border: 0;

  .tag__text {
    color: $tag-color-primary !important;
  }
}

.tag--size-big {
  border: 0;
  padding: $tag-padding-big;
  border-radius: $border-radius-03;
  .tag__text {
    @extend .title-h6;
  }
}

.tag--size-medium {
  border: 0;
  padding: $tag-padding-medium;
  border-radius: $border-radius-03;
}

.tag-tooltip {
  background-color: $tag-color-tooltip-background !important;
  width: 394px;

  border-radius: 4px;
  border: solid 1px #ebdfb6;
}

.tag__icon {
  margin-left: $tag-icon-separator;
}

.tag--clickable {
  cursor: pointer;
}
