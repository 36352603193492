$course-form-spaces: $form-spacing;
$course-form-spaces-title: $main-separator-components;
$course-form-margin-bottom: $main-separator-components;
$course-form-selects-width: 400px;
$course-form-shedule-separator: $main-separator-items;
$course-form-select-hover-background-color: $color-gray-07;
$course-form-select-hover-text-color: $color-gray-02;

.course-form {
  @extend .grid-column;

  .select-bubble {
    max-width: $course-form-selects-width;
  }
}
.course-form__form {
  @extend .grid-column;

  margin-left: 0;
  align-items: baseline;
}

.course-form__form-title {
  @extend .title-h5;

  display: block;
  margin-bottom: $course-form-spaces-title;
  margin-top: $course-form-spaces-title;
}

.course-form__form-title--large {
  @include column(12);
}

.course-form__form-title--first {
  margin-top: 0;
}

.course-form__item {
  margin-bottom: $course-form-spaces;
}

.course-form__item--all-width {
  @include column(12);
}

.course-form__item--middle-width {
  @include column(6);
}

.course-form__item--big {
  @include column(12);
}

.course-form__item--small {
  @include column(12);
}

.course-form__item--selects {
  margin-bottom: $course-form-margin-bottom;
}

.course-form {
  .select-customizable__option:hover {
    background-color: $course-form-select-hover-background-color;
    color: $course-form-select-hover-text-color;
  }
}

.course-form__schedule {
  @extend .grid-column;

  margin-bottom: $course-form-shedule-separator;

  .course-form__item {
    margin-bottom: $main-separator-components;
  }

  .DayPickerInput-Overlay {
    left: 0;
    right: auto;
  }
}

.course-form__item-day {
  @extend .grid-column;
}

.course-form__item-link {
  @extend .text-link;
  @extend .text-small-bold;
}

.course-form__shedule-title {
  @extend .title-h6;
}

@media #{$breakpoints-medium} {
  .course-form__item--big {
    @include column(8);
  }

  .course-form__item--small {
    @include column(4);
  }

  .course-form__schedule {
    .DayPickerInput-Overlay {
      right: 0;
      left: auto;
    }
  }
}
