.accordion-templates {
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    margin: $separator-s auto !important;
  }

  .accordion-templates__parent,
  .accordion-templates__children {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: $separator-s auto;
  }

  .accordion-templates__children {
    padding-left: $separator-l;
  }

  .accordion-templates__parent-text,
  .accordion-templates__children-text {
    margin-left: $separator-s;
    font-size: 14px;
    font-weight: 700;
    line-height: 23px;
    color: $color-gray-03;

    &--selected {
      color: $color-gray-02;
    }
  }
}
