.rubrics-dialog-add-list-item {
  &__name {
    color: var(--gray-color-gray-02, $color-gray-02);
    font-family: Dosis;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__criterial {
    color: var(--gray-color-gray-03, $color-gray-03);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  &__box {
    margin: 5px;
    width: 290px;
  }
}
