$scorm-results-overview-separator: $main-separator-components;

.scorm-results-overview__title {
  @extend .grades__title;
}

.scorm-results-overview__counters {
  margin-top: $scorm-results-overview-separator;
  display: flex;
  flex-wrap: nowrap;

  >div {
    margin-right: $scorm-results-overview-separator
  }
}

.scorm-results-overview__graph {
  margin-top: $scorm-results-overview-separator;
}
