.chat-button {
  position: fixed;
  bottom: $gutter;
  left: $gutter;
  z-index: 5000;
}

.chat-button__messages-wrapper {
  border-radius: $border-radius-01;
  background-color: $color-white;
  box-shadow: $box-shadow-02;
}

.chat-button__messages-header {
  display: flex;
  justify-content: flex-end;
}

.chat-button__messages-close {
  @extend .hover-basic;

  margin: 10px;
}

.chat-button__button {
  @extend .hover-basic;
}

.chat-button__bubble {
  position: fixed;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: red;
  left: 66px;
  bottom: 66px;
}
