$dialog-search-questions-new-max-width: $size-dialog-xxl-max-width;
$dialog-search-questions-new-color-founds: $color-label;
$dialog-search-questions-new-search-width: 270px;
$dialog-search-questions-new-width-card: 201px;
$dialog-search-questions-new-margin-card: $separator-xxl;
$dialog-search-questions-new-selects-width: 200px;
$dialog-search-questions-new-menu-selects-width: 200px;
$dialog-search-questions-new-header-components-margin: $separator-xxl;

.dialog-search-questions-new {
  .header-custom__center {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
  }
  .layout-calendar--responsive .layout-calendar__column-1 {
    padding-left: 0;
  }

  .layout-calendar--responsive.layout-calendar--responsive-open .layout-calendar__column-1 {
    height: 100vh;
  }
}

.dialog-search-questions-new__header-left-founds {
  color: $color-gray-02;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-family: $font-first;
}
.dialog-search-questions-new__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dialog-search-questions-new__header-left {
  display: flex;
  align-items: center;
}
.dialog-search-questions-new__header-right {
  margin-top: $separator-m;
  margin-bottom: $separator-m;
  display: flex;
  min-width: 50%;
}

.dialog-search-questions-new__search {
  width: 100%;

  .input-text {
    width: 100%;
  }
}

.dialog-search-questions-new__content {
  width: 100%;
}

.dialog-search-questions-new__right {
  padding-bottom: 80px;
}

.dialog-search-questions-new__footer {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  min-height: 80px;
  height: auto;
  background-color: $color-white;
  bottom: 0;
}

.dialog-search-questions-new__left-section-header {
  padding: $separator-s $separator-l;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebe0d7;
}

.dialog-search-questions-new__left-subsection {
  width: 100%;
}

.dialog-search-questions-new__left-subsection-accordion,
.dialog-search-questions-new__left-subsection-courses {
  background-color: transparent !important;
  box-shadow: none !important;
  margin: auto $separator-m !important;
}

.dialog-search-questions-new__left-subsection-accordion {
  border-bottom: 1px solid #ebe0d7 !important;
  padding: $separator-m $separator-s !important;
}

.dialog-search-questions-new__left-subsection-header {
  padding: $separator-m $separator-l;
  display: flex;
  justify-content: space-between;
}

.dialog-search-questions-new__left-subsection-content {
  padding: $separator-m $separator-l;
}

.dialog-search-questions-new__left-subsection-option {
  margin-bottom: $separator-m;
}

.dialog-search-questions-new__left-section-header-left {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  color: $color-gray-03;
}

.dialog-search-questions-new__left-section-header-right {
  .text-link-component {
    font-family: $font-first;
    font-size: 16px;
  }
}

.dialog-search-questions-new__select-container {
  .select-customizable .select-customizable__control {
    height: auto;
    min-height: 48px;
  }
  .css-1rhbuit-multiValue {
    background-color: #faf7f5;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #ebe0d7;
    overflow: hidden;

    svg {
      width: 20px;
      height: 20px;
      fill: #828282;
    }
  }

  .select-customizable__indicator {
    display: none;
  }
}

.dialog-search-questions-new__left-subsection-courses {
  margin: $separator-m $separator-m !important;
}

.dialog-search-questions-new__courses-header {
  margin: $separator-xl auto;
  .header-tabs__tab {
    background-color: $color-white;
  }
}

.dialog-search-questions-new__course-title {
  margin: $separator-m;
}

.dialog-search-questions-new__left-courses-items {
  text-transform: none;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 31px;
  padding: $separator-m;
  color: #828282;
  font-size: 14px;
  font-weight: 700;

  .input-checkbox {
    width: 12%;
  }
  .text-html {
    width: 88%;
  }
}

.dialog-search-questions-new__left-courses-subitems {
  text-transform: none;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 31px;
  padding: $separator-s;
  color: #828282;
  font-size: 14px;
  font-weight: 700;

  .input-checkbox {
    width: 12%;
  }
  .text-html {
    width: 88%;
  }
}

.dialog-search-questions-new__left-courses-items--selected {
  color: #4e4e4e;
}

.dialog-search-questions-new__left-courses-children {
  padding-left: $separator-xxl;
}

.dialog-search-questions__no-results {
  margin-top: $separator-xxxl;
}

.dialog-search-questions-new__header-right-founds {
  @extend .text-small;

  color: $dialog-search-questions-new-color-founds;
}

@media #{$breakpoints-tablet} {
  .dialog-search-questions-new__footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media #{$breakpoints-xlarge} {
  .dialog-search-questions-new__footer {
    width: calc(100% - 500px);
  }
}
