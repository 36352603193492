.image-content {
  max-width: 100%;
  max-width: 100%;

  text-align: center;
}

.image-content__img {
  max-width: 100%;
  max-width: 100%;
}
