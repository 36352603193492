$book-card-border-radius: $border-radius-book;
$book-card-border-radius-big: $border-radius-big-size;
$book-card-image-height: $size-book-card-image-height;
$book-card-image-selectable-width: $size-book-card-image-selectable-width;
$book-card-responsive-color: $color-label;
$book-card-separator: $separator-xs;
$book-card-width-progress: $size-book-card-width-progress;
$book-card-height-view-selectable: $size-book-card-height-view-selectable;
$book-card-button-separator: $separator-s;
$book-card-title-color: $color-gray-01;
$book-card-language-color: $color-gray-02;

.book-card {
  @extend .border-light;

  border-radius: $book-card-border-radius;
  position: relative;
}

.book-card--border-radius-big {
  .book-card__background-image {
    border-top-left-radius: $book-card-border-radius-big;
    border-top-right-radius: $book-card-border-radius-big;
  }

  .book-card__info {
    border-bottom-right-radius: $book-card-border-radius-big;
    border-bottom-left-radius: $book-card-border-radius-big;
  }
}

.book-card__image {
  height: $book-card-image-height;

  .program-image__picture-container,
  .program-image__picture {
    border-radius: 0px;
    height: 100%;
    width: 100%;
  }
}

.book-card__background-image {
  background-size: cover;
  background-position: top;

  border-top-left-radius: $book-card-border-radius;
  border-top-right-radius: $book-card-border-radius;

  height: 100%;
  width: 100%;
}

.book-card__info {
  @extend .card-default;

  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.book-card__responsive {
  @extend .text-extra;
  @extend .ellipsis-complex;

  color: $book-card-responsive-color;
  margin-bottom: $book-card-separator;
}

.book-card__language {
  @extend .text-extra;

  color: $book-card-language-color;
  margin-bottom: $book-card-separator;
}

.book-card__title {
  @extend .text-small-bold;
  @extend .ellipsis-complex;

  color: $book-card-title-color;
  margin-bottom: $book-card-separator;
}

.book-card__progressbar {
  width: $book-card-width-progress;
}

.book-card--selected {
  border: $border-size-02 solid $color-first;
}

.book-card--selectable,
.book-card--custom-right,
.book-card--view {
  @extend .hover-basic;

  display: flex;
  height: $book-card-height-view-selectable !important;

  .book-card__image {
    flex: 0 0 auto;
    width: $book-card-image-selectable-width;
    height: 100%;
  }

  .book-card__background-image {
    border-bottom-left-radius: $book-card-border-radius;
    border-top-right-radius: 0;
  }

  .book-card__title {
    -webkit-line-clamp: 3;
  }

  .book-card__info {
    border-top-right-radius: $book-card-border-radius;
    border-bottom-right-radius: $book-card-border-radius;
  }
}

.book-card--default {
  @extend .hover-basic;
}

.book-card__selects-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  &[animation='1'] {
    animation: wobble 0.4s;
  }
}

.book-card__coming-soon {
  @extend .title-h5;

  position: absolute;
  right: $separator-m;
  bottom: $separator-m;
  color: $color-first;
}

.book-card__button {
  position: absolute;
  right: $book-card-button-separator;
  bottom: $book-card-button-separator;
}

.disabled {
  opacity: 0.5;
}

.disabled:hover {
  opacity: 0.5;
}
