$section-resources-view-label-size: 22px;
$section-resources-view-header-height: 108px;

.section-resources-view {
  .layout-big-header__header {
    .tag {
      background: transparent;
      border-radius: 0;
    }
  }

  .button-fixed-with-hover {
    z-index: $z-index-minor-elements;
  }
}

.section-resources-view__actions-left,
.section-resources-view__actions-right {
  display: flex;
  align-items: center;
}

.section-resources-view__label {
  margin-right: $separator-s;
  width: $section-resources-view-label-size;
  height: $section-resources-view-label-size;

  svg {
    width: $section-resources-view-label-size;
    height: $section-resources-view-label-size;
  }
}

.section-resources-view__title {
  @extend .title-h6;

  text-transform: uppercase;
  color: $color-gray-01;
}

.section-resources-view__tab {
  display: flex;
  align-items: center;

  .icon {
    margin-left: $main-separator-items;
  }
}

.section-resources-view__name-group {
  @extend .title-h6;

  color: $color-gray-01;
  display: block;
  text-transform: uppercase;
}

.section-resources-view__count-resources {
  @extend .text-small;

  color: $color-gray-02;
  display: block;
  margin-top: $main-separator-items;
}

.section-resources-view__list-resources {
  margin-top: $main-separator-items;
}

.section-resources-view__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-resources-view__filter {
  display: flex;
  align-items: center;

  min-width: 280px;

  .input-checkbox {
    margin-left: $main-separator-components;
  }
}

.group-resources-view--visor {
  .wrap_media_viewer {
    background-color: $color-white !important;
  }

  .viewers_header_content_progress,
  .viewers_header_content_title {
    display: none !important;
  }

  .viewers_header_content {
    padding-right: $margin-default-mobile !important;
    padding-left: $margin-default-mobile !important;
  }
}

.section-resources-view__empty-case {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  margin-top: 10vh;
}

.section-resources-view__empty-case-text {
  @extend .title-h5;

  color: $color-gray-02;
  display: block;
  margin-top: $main-separator-components;
}

.section-resources-view__empty-case-button {
  margin-top: $separator-xxxxxl;
}

@media #{$breakpoints-xxlarge} {
  .group-resources-view--visor {
    .viewers_header_content {
      padding-right: $margin-default-xxlarge !important;
      padding-left: $margin-default-xxlarge !important;
    }
  }
}

@media #{$breakpoints-xxxlarge} {
  .group-resources-view--visor {
    .viewers_header_content {
      padding-right: $margin-default-xxxlarge !important;
      padding-left: $margin-default-xxxlarge !important;
    }
  }
}
