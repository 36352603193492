$lessons-list-item-separator: $separator-xs;
$lessons-list-item-edit-mode-duration-transition: $transition-duration-01;
$lessons-list-item-edit-mode-height-hide-item-add: 4px;
$lessons-list-item-edit-mode-height-show-item-add: 52px;

.lessons-list__item {
  // margin-bottom: $lessons-list-item-separator;
}

.lessons-list__item--done + .lessons-list__item {
  .icon-time-check:before {
    background: $icon-time-check-after-done-background;
  }
}

// edit mode

.lessons-list__add-item {
  height: $lessons-list-item-edit-mode-height-hide-item-add;
  transition-duration: $transition-duration-01;
  // margin-top: $separator-m;
  padding-left: $separator-l;

  .button {
    display: none;
  }

  &:hover {
    height: $lessons-list-item-edit-mode-height-show-item-add;

    .button {
      display: block !important;
      height: $lessons-list-item-edit-mode-height-show-item-add;
      min-height: $lessons-list-item-edit-mode-height-show-item-add;
      padding: 0;
    }
  }
}

.lessons-list-in-edit-mode .lessons-list__add-item:last-child {
  display: block !important;
  height: $lessons-list-item-edit-mode-height-show-item-add;

  .button {
    display: block !important;
    height: $lessons-list-item-edit-mode-height-show-item-add;
    min-height: $lessons-list-item-edit-mode-height-show-item-add;
    padding: 0;
  }
}
