.classroom-users-content {
  .hr {
    background: $color-gray-06;
  }
  .user-item__name {
    color: $color-gray-01;
  }
}

.classroom-users-content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classroom-users-content__button-import {
  display: none;
  .button__text {
    margin-right: $separator-s;
  }
}

.classroom-users-content__name {
  @extend .title-h4;
}

.classroom-users-content__students {
  @extend .text-smallest;

  color: $color-gray-03;
  display: block;
  margin: $separator-m 0;
}

.classroom-users-content__user-tag {
  flex: 0 0 auto;

  display: flex;
  align-items: center;

  .tag {
    padding: $separator-s $separator-m;
    margin-bottom: 0;
    border: 0;
  }

  .tag__text {
    color: $color-gray-03;
    font-size: $font-size-06;
  }
}

.classroom-users-content__user-tag-count {
  margin-left: $separator-s;
}

.classroom-users-content__student {
  // margin-bottom: $main-separator-items;
  padding-bottom: $separator-m;
  padding-top: $separator-m;
  border-bottom: $border-size-01 solid $color-gray-06;

  &:last-child {
    border-bottom: 0;
  }
}
