$dropdown-tasks-width: $size-dropdown-tasks-width;
$dropdown-tasks-remaining-color: $color-error;
$dropdown-tasks-remaining-padding-right: $separator-xs;

.dropdown-tasks__lib {
  .MuiPaper-root {
    width: $dropdown-tasks-width;
  }

  .no-items {
    padding: $padding-card-default;
  }

  .no-items__item--texts {
    margin-bottom: 0;
  }
}

.dropdown-tasks__subtype {
  @extend .text-small-bold;
}

.dropdown-tasks__name {
  @extend .text-small;
}

.dropdown-tasks__remaining {
  @extend .text-extra;

  color: $dropdown-tasks-remaining-color;
  padding-right: $dropdown-tasks-remaining-padding-right;
}
