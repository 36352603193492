.student-grades-table {
  .MuiAccordion-root.Mui-disabled {
    background-color: $color-white !important;
  }
  .student-grades-table__header {
    display: flex;
    justify-content: space-between;
    font-family: $font-second;
    font-weight: 700;
    font-size: $font-size-10;
    line-height: 20px;
    color: $color-gray-03;
    text-transform: uppercase;
    padding-left: 16px;
    padding-right: 128px;

    .student-grades-table__header-left {
    }

    .student-grades-table__header-right {
    }
  }

  .student-grades-table__unit-accordion {
    padding: 8px 16px;
    min-height: 63px;
    background: $color-white;
    border: 1px solid $color-gray-05;
    border-radius: $border-radius-01;
    margin-top: 12px;

    .student-grades-table__unit-accordion-header {
      .MuiAccordionSummary-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
    }

    .student-grades-table__unit-accordion-title {
      font-family: $font-first;
      font-size: $font-size-06;
      line-height: 23px;
      color: $color-gray-02;
    }

    .student-grades-table__unit-accordion-score {
      padding-right: 80px;

      .score {
        @extend .title-h5;
      }
    }

    .Mui-disabled {
      background-color: $color-white !important;
      color: $color-gray-02 !important;
    }
  }

  .student-grades-table__lesson-accordion {
    margin-left: 16px !important;
    border-bottom: 1px solid #c7bfb7;
    padding: 8px 0;
    min-height: 42px;

    &:last-child {
      border-bottom: none;
    }

    .student-grades-table__lesson-accordion-header {
      .MuiAccordionSummary-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .student-grades-table__lesson-accordion-detail {
      border-bottom: 1px solid #c7bfb7;
      min-height: 48px;
      padding: 8px 0;
      margin-left: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
    }

    .student-grades-table__lesson-accordion-title {
      font-family: $font-second;
      font-size: $font-size-06;
      line-height: 24px;
      color: $color-gray-02;
    }

    .student-grades-table__lesson-accordion-score {
      padding-right: 80px;

      .score {
        @extend .title-h5;
      }
    }
  }
}
