$project-remote-control-background: $color-gray-01;
$project-remote-control-transition-duration: 0.7s;
$project-remote-control-height: $size-footer-projection-height;
$project-remote-control-icon-arrow-separator: $main-separator-items;
$project-remote-control-name-projecting-color: $color-success;
$project-remote-control-name-projecting-separator: $main-separator-items;
$project-remote-control-background-tools: $color-background;
$project-remote-control-padding-tools: $padding-card-default-small;
$project-remote-control-title-tools-separator: $main-separator-items;
$project-remote-control-title-tools-separator-top: $main-separator-components;
$project-remote-control-up-height: 40px;
$project-remote-control-controls-separator: $main-separator-components;
$project-remote-control-times-color: $color-white;
$project-remote-control-times-separator: $main-separator-items;
$project-remote-control-replay-separator: $main-separator-items;
$project-remote-control-name-color: $color-white;
$project-remote-control-name-separator: $main-separator-components;
$project-remote-control-timeout-color: $color-white;
$project-remote-control-page-info-color: $color-white;
$project-remote-control-controls-pdf-padding: $separator-l $separator-s;
$project-remote-control-preview-image-max-height: 200px;
$project-remote-control-controls-fullscreen-separator: $main-separator-items;
$project-remote-control-controls-actions-separator: $separator-xl;
$project-remote-control-task-actions-background: $color-white;
$project-remote-control-task-actions-title-separator: $main-separator-items;
$project-remote-control-task-actions-switch: $main-separator-components;
$project-remote-control-task-actions-separator: $main-separator-components;

.project-remote-control {
  @extend .grid;

  background: $project-remote-control-background;
  border-top-left-radius: $border-radius-03;
  border-top-right-radius: $border-radius-03;
  max-height: $project-remote-control-height;
  overflow: hidden;
  transition: max-height $project-remote-control-transition-duration ease-out;
  z-index: $z-index-popup;

  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  max-width: 100%;
  height: 100%;

  .arrows-control {
    z-index: $z-index-popup;
  }

  .preview-content__image {
    margin: 0;
  }

  .preview-content__img {
    max-height: $project-remote-control-preview-image-max-height;
  }

  .MediaViewerVideo_content {
    height: 100%;
    width: 100%;
  }

  .wrap-MediaViewerVideo_content {
    top: 0;
    // height: auto;
  }

  .wrap_media_viewer {
    height: auto;
  }

  .preview-content__audio-bar {
    margin-left: 0;
    width: 100%;
  }

  .preview-content__description {
    display: none;
  }
}

.project-remote-control__hide {
  opacity: 1;
  transition-duration: $project-remote-control-transition-duration;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 $padding-card-default;
}

.project-remote-control__timeout-text {
  @extend .text-small;

  color: $project-remote-control-timeout-color;
}

.project-remote-control--show {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 100%;
  transition: max-height $project-remote-control-transition-duration ease-in;

  .project-remote-control__hide {
    opacity: 0;
    height: 0;
    padding: 0;
  }

  .project-remote-control__show {
    opacity: 1;
    padding: $padding-card-default;
    visibility: visible;
  }

  .project-remote-control__up {
    justify-content: space-between;
  }

  .project-remote-control__arrow {
    .icon {
      transform: rotate(180deg);
    }
  }

  .project-remote-control__control-scrolls {
    visibility: visible;

    // margin-top: $project-remote-control-controls-separator;
  }
}

.project-remote-control__control-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-remote-control__control-fullscreen {
  margin: $padding-card-default;
  position: absolute;
  right: 0;
  bottom: 0;
}

.project-remote-control__up {
  @extend .hover-basic;

  display: flex;
  justify-content: center;
  align-items: center;

  height: $project-remote-control-up-height;
  padding: 0 $padding-card-default-horizontal;
}

.project-remote-control__arrow {
  .icon {
    transition-duration: $project-remote-control-transition-duration;
  }
}

.project-remote-control__controls {
  display: flex;
  align-items: center;

  .icon {
    flex: 0 0 auto;
  }
}

.project-remote-control__name {
  @extend .text-p;
  @include ellipsis-line(1);

  color: $project-remote-control-name-projecting-color;
  margin-left: $project-remote-control-name-projecting-separator;
}

.project-remote-control__show {
  // position: absolute;
  // top: 0;
  display: flex;
  flex-direction: column;

  // margin-top: $project-remote-control-up-height;
  opacity: 0;
  transition-duration: $project-remote-control-transition-duration;
  visibility: hidden;

  width: 100%;
  height: calc(100% - #{$project-remote-control-up-height});
}

.project-remote-control__show--tools {
  // height: 100%;
  padding: 0 !important;
  .project-remote-control__show-content {
    height: 100%;
  }
}

.project-remote-control__show-content {
  height: calc(100% - #{$arrows-control-button-height} - #{$arrows-control-button-height});
  overflow-y: auto;
  z-index: $z-index-popup;
}

.project-remote-control__controls-tools {
  background: $project-remote-control-background-tools;
  height: 100%;
  padding: $project-remote-control-padding-tools;
  overflow: auto;
}

.project-remote-control__controls-tools-header {
  display: flex;
  align-items: center;
}

.project-remote-control__controls-tools-content {
  // height: 100%;
  margin-top: $project-remote-control-title-tools-separator-top;
}

.project-remote-control__controls-tools-title {
  @extend .text-p;

  margin-left: $project-remote-control-title-tools-separator;
}

.project-remote-control__projecting {
  display: flex;
  align-items: center;
}

.project-remote-control__projecting-text {
  @extend .text-small;

  color: $project-remote-control-name-projecting-color;
  margin-left: $project-remote-control-name-projecting-separator;
}

.project-remote-control__control-scrolls {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  // height: 100%;
  // padding-bottom: $main-padding-page;
  visibility: hidden;
}

.project-remote-control__controls-player-header {
  display: flex;
  justify-content: center;
  padding-top: $main-separator-components;

  button {
    margin: 0 $project-remote-control-controls-actions-separator;
  }
}

.project-remote-control__controls-player {
  height: 100%;
  text-align: center;
}

.project-remote-control__controls-visor {
  height: 100%;
  width: 100%;
}

.project-remote-control__controls-player-name {
  @extend .title-h5;
  @extend .ellipsis;

  color: $project-remote-control-name-color;
  display: block;
  margin: $project-remote-control-name-separator 0;
  text-align: center;
}

.project-remote-control__controls-player-info-times {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-remote-control__controls-player-info-time {
  @extend .text-extra;

  color: $project-remote-control-times-color;
  margin-top: $project-remote-control-times-separator;
}

.project-remote-control__controls-player-replay {
  margin-right: $project-remote-control-replay-separator;
}

.project-remote-control__controls-player-replay-invisible {
  visibility: hidden;
}

.project-remote-control__controls-pdf-pages-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $project-remote-control-controls-pdf-padding;
}

.project-remote-control__controls-pdf-info-page {
  @extend .text-p;

  color: $project-remote-control-page-info-color;
}

.project-remote-control__projecting-text-container {
  display: flex;
  align-items: center;
}

.project-remote-control__controls-pdf-pages-content--hidden {
  .bubble-content {
    visibility: hidden;
  }
}

.project-remote-control__controls-task-actions-header {
  display: flex;
  align-items: center;
}

.project-remote-control__controls-task-actions {
  @extend .card-default;

  background: $project-remote-control-task-actions-background;
  margin-bottom: $project-remote-control-task-actions-separator;

  .numeration {
    display: inline;
  }
}

.project-remote-control__controls-task-actions-numeration {
  flex: 0 0 auto;
}

.project-remote-control__controls-task-title {
  @include ellipsis-line(2);

  margin-left: $project-remote-control-task-actions-title-separator;
  line-height: 1.2;
}

.project-remote-control__controls-task-actions-switch {
  margin-top: $project-remote-control-task-actions-switch;

  .switch {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}

.project-remote-control__controls-mint-icon {
  .icon-bubble {
    background: #a39b93;
    height: 80px;
    width: 80px;
  }

  .icon {
    height: 36px;
    width: 36px;
  }
}

.project-remote-control__controls-mint-title {
  @extend .title-h4;

  color: $color-white;
  padding-left: $main-separator-items;
  padding-right: $main-separator-items;
}


.project-remote-control__controls-task-title--mint {
  font-size: $font-size-06;
  margin-left: 0;
  margin-top: $separator-l;
}
