.hover-basic {
  cursor: pointer;
}

.disabled-hover {
  cursor: default !important;
  opacity: 1 !important;;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis-complex {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ellipsis-line($lines) {
  display: -webkit-box !important;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media #{$breakpoints-tablet} {
  .hover-basic:hover {
    opacity: 0.7;
    transition-duration: 0.3s;
  }
}
