$library-content-item-name-margin-left: $separator-l;
$library-content-item-margin: $separator-m;
$library-content-item-padding: $separator-s;
$library-content-item-active-color: $color-gray-07;

.library-content-item {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  margin-bottom: $library-content-item-margin;
  border-top-left-radius: $border-size-active;
  border-bottom-left-radius: $border-size-active;
  padding: $library-content-item-padding;
}

.library-content-item__name {
  @extend .title-h6;
  @extend .ellipsis-complex;

  margin-left: $library-content-item-name-margin-left;
}

.library-content-item--active {
  background: $library-content-item-active-color;
}

.library-content-skeleton {
  height: 40px;
  margin-bottom: $library-content-item-margin;
  border-top-left-radius: $border-size-active;
  border-bottom-left-radius: $border-size-active;
}

.content-detail-skeleton__name {
  height: 20px;
}
