$tooltip-learning-objectives-title-color: $color-title;
$tooltip-learning-objectives-text-color: $color-gray-02;

.tooltip-learning-objectives__title {
  @extend .title-h6;

  color: $tooltip-learning-objectives-title-color;
}

.tooltip-learning-objectives__text {
  @extend .text-smallest;

  color: $tooltip-learning-objectives-text-color;
  display: block;
  margin-top: $main-separator-components;
}
