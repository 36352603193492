$comment-skeleton-margin: $separator-m;
$comment-skeleton-wrapper-padding: $padding-card-default-vertical;

.comments-list {
  max-height: 70vh;
  overflow: auto;
}

.comment-skeleton__wrapper {
  padding: $comment-skeleton-wrapper-padding;
}

.comment-skeleton {
  height: 45px;
  margin-bottom: $comment-skeleton-margin;
}
