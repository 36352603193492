.lesson-power-view {
  .lesson-info__title--books {
    display: none;
  }
  .books-list {
    display: none !important;
  }
}

.lesson-power-view--fullwidth {
  .layout-header {
    background-color: white;
  }
  .layout-header__children {
    max-width: 100% !important;
    width: 100% !important;
  }
}
