$attachment-card-size-color: $color-gray-03;
$attachment-card-progress-margin: $separator-xl;

.attachment-card {
  @extend .card-default;

  .item-complex-list__left,
  .item-complex-list__center {
    flex-grow: 1;
  }

  .item-complex-list__center {
    margin-right: $attachment-card-progress-margin;
  }
}

.attachment-card__title {
  @extend .text-p;
}

.attachment-card__size {
  @extend .text-smallest;

  color: $attachment-card-size-color;
}

.attachment-card__delete {
  @extend .hover-basic;
}
