.my-classrooms-view-top-right-order {
  display: flex;
  align-items: center;
}

.my-classrooms-view-top-right-order__select {
  margin-right: $main-separator-components;
  .select-customizable {
    width: 166px;
  }
}


.my-classrooms-view-top-right-order__text {
  @extend .text-small;

  color: $color-gray-02;
  margin-right: $main-separator-items;
}
