.cookie-card {
  @extend .card-default;
  width: 90%;
  max-width: 570px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cookie-card__title-text {
    @extend .text-p-bold;
    text-transform: uppercase;
    display: block;
    margin-bottom: $card-generic-title-separator;
  }

  .cookie-card__body {
    margin-top: $separator-l;
    margin-bottom: $separator-xxl;
  }
  .cookie-card__body-text {
    @extend .text-p;
    .text-link-component {
      @extend .text-p;
      @extend .hover-basic;
      display: inline;
      color: $color-first;
    }
  }
  .cookie-card__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: $separator-m;
  }
}
