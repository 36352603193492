.licenses-expired-list {
  margin-top: $separator-xl;
  margin-bottom: $separator-l;
}

.licenses-expired-list-title {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 19px;
  color: $color-gray-02;
}

.licenses-expired-list__card {
  margin: $separator-m 0;
}

.licenses-expired-list__card-title {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: $separator-s;
}

.licenses-expired-list__card-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.licenses-expired-list__card-more {
  margin-right: $separator-l;
  color: $color-gray-03;
  font-weight: 700;
  cursor: pointer;
}
