$dialog-notes-header-background: $color-white;
$dialog-notes-title-separator: $main-separator-items;
$dialog-notes-title-shadow: 0px 1px 0px rgba(128, 190, 225, 0.3);
$dialog-notes-title-color: $color-title;
$dialog-notes-padding: $padding-dialog;

.dialog-notes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $dialog-notes-header-background;
  box-shadow: $dialog-notes-title-shadow;
  padding: $dialog-notes-padding;

  position: sticky;
  top: 0;
}

.dialog-notes__header-left {
  display: flex;
  align-items: center;
}

.dialog-notes__header-title {
  @extend .title-h6;

  color: $dialog-notes-title-color;
  margin-left: $dialog-notes-title-separator;
}

.dialog-notes__content {
  padding: $dialog-notes-padding;
  padding-bottom: $size-footer-projection-height;
}
