$lesson-item-student-border-radius: $border-radius-05;
$lesson-item-student-image-height: 86px;
$lesson-item-student-date-color: $color-label;
$lesson-item-student-date-separator: $main-separator-items;
$lesson-item-student-status-separator: $main-separator-components;
$lesson-item-student-today-status-color: $color-title;
$lesson-item-student-background-color-default: $color-gray-06;

.lesson-item-student {
  @extend .hover-basic;
  // @extend .card-secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 4px 0px 4px 0px;
  border-radius: $lesson-item-student-border-radius;
  position: relative;

  width: 100%;
  border: 1px solid #ebe0d7;

  .icon--lock {
    opacity: 0.4;
  }
}

.lesson-item-student--today {
  border: 2px solid $color-first;
  box-shadow: 0px 2px 5px 0px #81665126;
}

.lesson-item-student__center {
  display: flex;
  align-items: center;
}

.lesson-item-student__image {
  svg {
    height: 32px;
    width: 32px;
  }
}

.lesson-item-student__icon-book {
  padding: $separator-xxl;
}

.lesson-item-student__name {
  // @extend .title-h6;
  @include ellipsis-line(1);
  font-size: 18px;
  line-height: 24px;
  color: #4e4e4e;
  font-weight: 600;
  font-family: $font-first;

  display: block;
}

.lesson-item-student__date {
  font-family: $font-second;
  color: #828282;
  font-weight: 500;
  font-size: 14px;
  display: block;
}

.lesson-item-student__day {
  margin-top: $separator-xs;
  display: flex;
  align-items: flex-end;
}

.lesson-item-student__today {
  @extend .text-smallest;
  line-height: 14px;
  margin-left: $separator-xs;
  text-transform: uppercase;
  color: $color-first;
}

.lesson-item-student__status {
  margin-right: $separator-m;
  display: flex;
  justify-content: flex-end;
  width: 160px;

  .progress-bar {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .progress-bar__bar {
      width: 65%;
    }
    .progress-bar__label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      font-family: $font-first;
      color: #4f4f4f;
    }
  }
}
