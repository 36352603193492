$note-card-note-total-color: $color-title;
$note-card-note-text-color: $color-label;
$note-card-needs-reinforcement: $color-error;
$note-card-empty: $color-gray-03;
$note-card-needs-ampliacion: $color-alert;
$note-card-good-progress: $color-success;

.note-card {
  @extend .card-default-big;

  text-align: center;
}

.note-card--empty {
  .note-card__note {
    color: $note-card-empty;
  }
}

.note-card--needs-reinforcement {
  .note-card__note {
    color: $note-card-needs-reinforcement;
  }
}

.note-card--needs-ampliacion {
  .note-card__note {
    color: $note-card-needs-ampliacion;
  }
}

.note-card--good-progress {
  .note-card__note {
    color: $note-card-good-progress;
  }
}

.note-card__note-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.note-card__note {
  @extend .title-h2;
}

.note-card__note-total {
  @extend .title-h5;

  color: $note-card-note-total-color;
}

.note-card__text {
  @extend .text-extra;

  color: $note-card-note-text-color;
}

.note-card__percent {
  @extend .title-h5;

  margin-left: $separator-xs;
  color: $color-gray-04;
}

.note-card__note-icon {
  path {
    fill: $color-white !important;
  }
}
