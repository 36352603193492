$event-all-day-border-radius: $border-radius-01;
$event-all-day-color-text: $color-white;

.event-all-day {
  display: flex;
  align-items: center;
  border-radius: $event-all-day-border-radius;
  padding: 0 6px;
}

.event-all-day__name {
  @extend .text-extra;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  color: $event-all-day-color-text;
  margin-left: 5px;
}

.event-all-day--passed {
  opacity: 0.6;
}
