$icon-background-background: $color-gray-07;
$icon-background-padding: $separator-s;
$icon-background-separator: $main-separator-items;
$icon-background-color-first-text-color: $color-first;

.icon-background {
  display: flex;
  align-items: center;
}

.icon-background--color-first {
  .icon-background__info-text,
  .icon-background__info-description {
    color: $icon-background-color-first-text-color;
  }
}

.icon-background__icon {
  background: $icon-background-background;
  border-radius: $border-radius-01;
  padding: $icon-background-padding;

  display: inline-block;
}

.icon-background__info {
  display: flex;
  flex-direction: column;

  margin-left: $icon-background-separator;
}

.icon-background__info-text {
  @extend .text-smallest;

  color: $color-label;
}

.icon-background__info-description {
  @extend .text-small-bold;

  color: $color-title;
}
