$header-info-course-background: $color-white;

.header-info-course {
  height: 100%;
  background: $header-info-course-background;
  border-bottom: $border-size-01 solid $color-borders;
  display: flex;
  align-items: center;
}

.header-info-course__container {
  display: flex;
  align-items: center;
}

.header-info-course__info-container {
  display: flex;
  flex-direction: column;

  margin-left: $main-separator-components;
}

.header-info-course__name {
  @extend .title-h2;
}

.header-info-course__classroomName {
  @extend .title-h6;
}

.header-info-course__level {
  @extend .text-smallest;
}
