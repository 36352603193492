$no-items-separator: $main-separator-components;
$no-items-separator-small: $main-separator-items;
$no-items-title-color: $color-title;
$no-items-label-color: $color-label;
$no-items-image-size: 248px;
$no-items-image-size-small: 160px;
$no-items-image-size-medium: 360px;

.no-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-items--size-small {
  .no-items__item-title {
    @extend .title-h6;
  }

  .no-items__item--image {
    img {
      max-height: $no-items-image-size-small;
      max-width: $no-items-image-size-small;
    }
  }
}

.no-items--size-medium {
  .no-items__item--image {
    img {
      max-height: $no-items-image-size-medium;
      max-width: $no-items-image-size-medium;
    }
  }
}

.no-items__item {
  margin-bottom: $no-items-separator;
}

.no-items__item--image {
  img {
    max-height: $no-items-image-size;
    max-width: $no-items-image-size;
  }
}

.no-items__item--texts {
  display: flex;
  flex-direction: column;

  text-align: center;
}

.no-items__item-title {
  @extend .title-h4;

  color: $no-items-title-color;
}

.no-items__item-text {
  @extend .text-small-bold;

  color: $no-items-label-color;
  margin-top: $no-items-separator-small;
}
