$dropdown-courses-width: $size-dropdown-courses-width;
$dropdown-courses-name-color: $color-label;
$dropdown-courses-name-hover: $color-title;
$dropdown-courses-name-margin-left: $separator-s;
$dropdown-corse-max-height: 430px;

$dropdown-courses-top-separator: 14px;

.dropdown-courses {
  .item-complex-list__center {
    margin-left: $dropdown-courses-name-margin-left;
  }
}

.dropdown-courses__lib {
  margin-top: $dropdown-courses-top-separator;
  .MuiPaper-root {
    width: $dropdown-courses-width;
  }

  .list-complex-dropdown {
    max-height: $dropdown-corse-max-height;
    overflow-y: auto;
  }
}

.dropdown-courses__bubbles {
  position: relative;
}

.dropdown-courses__notification {
  position: absolute;
  left: calc(#{$size-icon-bubble-medium} - 4px);
}

.dropdown-courses__name {
  @extend .text-small-bold;
}

.dropdown-courses__my-classes-text {
  @extend .dropdown-courses__name;
}

.dropdown-courses__class {
  @extend .text-small;
}

.dropdown-courses__settings {
  visibility: hidden;
}

.item-complex-list {
  &:hover {
    .dropdown-courses__name {
      color: $dropdown-courses-name-hover;
    }
    .dropdown-courses__settings {
      visibility: visible;
    }
  }
}
