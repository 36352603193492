.tree-view-selected {
  margin-top: 30px;

  .tree-view-selected__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;

    .description {
      margin-left: 28px;
      color: $color-gray-04;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
    }
  }
}
