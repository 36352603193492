$task-simple-item-border-radius-big: $border-radius-big-size;
$task-simple-item-title-color: $color-title;
$task-simple-item-date-color: $color-label2;
$task-simple-item-due-color-soon: $color-label;
$task-simple-item-due-color-late: $color-first;
$task-simple-item-questions-color: $color-title;
$task-simple-item-separator: $separator-xs;
$task-simple-item-pending: $color-label;
$task-simple-item-student-status-text-color: $color-first;
$task-simple-item-student-status-font-size: $font-size-08;
$task-simple-item-student-status-separator: $main-separator-items;
$task-simple-item-student-late-icon-separator: $main-separator-items;

.task-simple-item {
  @extend .card-default-small;
}

.task-simple-item--type-view-student {
  border-radius: $task-simple-item-border-radius-big;
}

.task-simple-item--status-soon {
  .task-simple-item__date {
    color: $task-simple-item-due-color-soon;
    font-style: italic;
  }
}

.task-simple-item--status-late {
  border-color: $task-simple-item-due-color-late;
  .task-simple-item__date {
    color: $task-simple-item-due-color-late;
    font-style: italic;
  }
}

.task-simple-item__left {
  display: flex;
  flex-direction: column;
}

.task-simple-item__title {
  @extend .text-small-bold;
  @extend .ellipsis-complex;

  color: $task-simple-item-title-color;
  margin-bottom: $task-simple-item-separator;
}

.task-simple-item__questions-length {
  @extend .text-smallest;

  color: $task-simple-item-questions-color;
  margin-bottom: $task-simple-item-separator;
}

.task-simple-item__date-late-icon {
  margin-right: $task-simple-item-student-late-icon-separator;
}

.task-simple-item__date-container {
  display: flex;
  align-items: center;
}

.task-simple-item__date {
  @extend .text-smallest;

  color: $task-simple-item-date-color;
}

.task-simple-item__no-date {
  @extend .text-smallest;

  color: $color-gray-04;
}

.task-simple-item__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.task-simple-item__right--student {
  display: flex;
  align-items: center;
}

.task-simple-item__student-status-icon {
}

.task-simple-item__student-status-text {
  @extend .title-h3;

  color: $task-simple-item-student-status-text-color;
  font-size: $task-simple-item-student-status-font-size;
  margin-right: $task-simple-item-student-status-separator;
  text-transform: uppercase;
}

.task-simple-item__pending {
  @extend .title-h6;

  flex: 0 0 auto;
  margin-bottom: $task-simple-item-separator;
}

.task-simple-item__pending-text {
  @extend .text-smallest;
}

.task-simple-item__pending,
.task-simple-item__pending-text {
  color: $task-simple-item-pending;
}
