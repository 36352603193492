$event-week-name-color: $color-gray-03;
$event-week-name-separator: $separator-xs;
$event-week-events-border-radius: $border-radius-01;
$event-week-date-font-size: 10px;
$event-week-padding-to-border: 2px;
$event-week-padding: $separator-xs;
$event-week-background: $color-white;
$event-week-unit-color: $color-gray-02;

.event-week {
  border-radius: $event-week-events-border-radius;
  padding: $event-week-padding-to-border;

  height: 97%;
  width: 97%;
  margin: 2px;
  box-shadow: $box-shadow-02;
}

.event-week__container {
  background: $event-week-background;
  border-radius: $event-week-events-border-radius;
  padding: $event-week-padding;

  height: 100%;
  width: 100%;
}

.event-week__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
}

.event-week__date-text {
  @extend .text-extra-bold;

  font-size: $event-week-date-font-size;
  color: $color-black;
}

.event-week__name {
  @extend .text-extra-bold;

  color: $event-week-unit-color;
  margin-top: $event-week-name-separator;
}

.event-week__unit {
  @include ellipsis-line(2);
  color: $event-week-name-color;
  margin-top: $event-week-name-separator;
}

.calendar-big--view-week {
  .rbc-event.rbc-selected,
  .rbc-event {
    background-color: transparent !important;
  }
  .rbc-event-label {
    display: none;
  }

  .rbc-day-slot .rbc-event {
    padding: 0;
    border: 0;
  }

  .rbc-event {
    border-radius: 0;
  }
}

.event-week--passed {
  opacity: 0.6;
}

.event-week--holiday {
  background-color: #eee4dc;
}
