$dialog-crop-image-max-width: $size-dialog-xs-max-width;
$dialog-crop-image-croppper-margin-top: $main-separator-components;
$dialog-crop-image-meta-color: $color-label;

.dialog-crop-image {
  .MuiPaper-root {
    max-width: $dialog-crop-image-max-width;
  }
}

.dialog-crop-image__description {
  @extend .text-small;
}

.dialog-crop-image__croper {
  margin-top: $dialog-crop-image-croppper-margin-top;
}

.dialog-crop-image__meta-info {
  @extend .text-extra;

  color: $dialog-crop-image-meta-color;
}
