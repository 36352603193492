$lesson-info-title-separator: $main-separator-items;
$lesson-info-components-separator: $main-separator-components;
$lesson-info-book-card-width: 90%;
$lesson-info-book-card-max-width: 160px;
$lesson-info-book-card-width-mobile: 30%;
$lesson-info-book-card-width-medium: 48%;
$lesson-info-book-card-separator: $main-separator-items;
$lesson-info-book-card-title-separator: $main-separator-items;
$lesson-info-book-card-border-radius-student: $border-radius-05;
$lesson-info-book-card-shadow-student: $box-shadow-04;
$lesson-info-title-color: $color-label;

.lesson-info {
  @extend .grid-column;

  .item-complex-list__left-children,
  .lesson-element-item__count-text,
  .progress-bar__bar,
  .progress-bar__text {
    display: none;
  }
}

.lesson-info__left {
  @include column(12);

  margin-bottom: $lesson-info-components-separator;
}

.lesson-info__book {
  margin-bottom: $lesson-info-components-separator;

  .learning-objetives-tag-list {
    margin-bottom: $lesson-info-title-separator;
  }
}

.lesson-info__right {
  @include column(12);
}

.lesson-info__title--books {
  @extend .title-h6;
  @extend .ellipsis;

  display: block;
  color: $lesson-info-title-color !important;
  margin-bottom: $lesson-info-title-separator;
}

.lesson-info__list-books {
  display: block;

  .books-list {
    padding: $separator-s !important;
  }

  .book-card {
    width: $lesson-info-book-card-width;
    max-width: $lesson-info-book-card-max-width;
    margin-right: $lesson-info-book-card-separator;
    margin-bottom: $separator-l;
  }
}

.books-list {
  display: block;
}

@media #{$breakpoints-small} {
  .lesson-info__list-books {
    .books-list {
      display: flex;
    }
    .book-card {
      width: $lesson-info-book-card-width-medium;
      margin-right: calc(50% - #{$lesson-info-book-card-width-medium});
      margin-bottom: $lesson-info-book-card-separator;
    }
  }
}

@media #{$breakpoints-medium} {
  .lesson-info {
    .item-complex-list__left-children {
      display: flex;
    }

    .lesson-element-item__count-text,
    .progress-bar__bar,
    .progress-bar__text {
      display: block;
    }
  }

  .lesson-info__list-books {
    .book-card {
      width: $lesson-info-book-card-width-mobile;
      margin-right: $lesson-info-book-card-separator;
      margin-bottom: $lesson-info-book-card-separator;
    }
  }
}

@media #{$breakpoints-large} {
  .lesson-info__left {
    @include column(3);

    margin-bottom: 0;
  }

  .lesson-info__right {
    @include column(9);
  }

  .lesson-info__list-books {
    .books-list {
      display: block;
    }
    .book-card {
      width: $lesson-info-book-card-width;
      margin-right: 10px !important;
      margin-bottom: $lesson-info-book-card-separator;
    }
  }
}

@media #{$breakpoints-xlarge} {
  .lesson-info__left {
    @include column(2);
  }

  .lesson-info__right {
    @include column(10);
  }
}

.lesson-info--student {
  .book-card {
    border-radius: $lesson-info-book-card-border-radius-student;
    box-shadow: $lesson-info-book-card-shadow-student;
  }
  .book-card__background-image {
    border-top-left-radius: $lesson-info-book-card-border-radius-student;
    border-top-right-radius: $lesson-info-book-card-border-radius-student;
  }
  .book-card__info {
    border-bottom-left-radius: $lesson-info-book-card-border-radius-student;
    border-bottom-right-radius: $lesson-info-book-card-border-radius-student;
  }
}
