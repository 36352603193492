.remote-view--mint {
  .layout-header__children {
    padding: 0
  }

  .remote-mint-item {
    margin-bottom: $separator-l;
  }

  .bubble-content {
    border: 0;
    flex: 0 0 auto;
  }

  .bubble-content__content {
    color: $color-white;
  }

  .remote__tools-options {
    margin-top: $main-separator-components;
  }

  &.remote-view--tab-tools {
    .layout-header {
      background: $color-gray-07 !important;
    }
  }

  .turn-to-speak-view__top {
    border: 0 !important;
    background: transparent  !important;
    box-shadow: none  !important;
  }

  .turn-to-speak-view__random {
    margin-top: $main-separator-components;
    .button {
      @extend .button;
      background: $color-first !important;
    }

    .icon {
      path {
        fill: $color-white;
      }
    }
  }

  .project-remote-control__controls-tools {
    padding-left: $separator-l;
    padding-right: $separator-l;
  }
}

.remote-mint-view__title {
  @extend .text-small-bold;

  color: $color-gray-03;
  display: block;
  text-transform: uppercase;
  margin-bottom: $main-separator-items;
}

.remote-mint-view__unit {
  padding-top: $main-separator-components;
}
  