$aside-lesson-width: 430px;
$aside-lesson-item-height: 48px;
$aside-lesson-kanban-height: 56px;
$aside-lesson-add-height: 64px;
$aside-lesson-item-padding: $separator-xl;
$aside-lesson-border-color: $color-gray-05;
$aside-lesson-list-padding: 100px;

.aside-lessons__aside {
  .menu-aside {
    width: $aside-lesson-width !important;
    max-width: 100vw;
  }

  .menu-aside__children {
    padding-top: $separator-xl;
    padding-left: 0;
    padding-right: 0;
  }
}

.aside-lessons__list--teacher {
  padding-bottom: $aside-lesson-list-padding;
}

.asside-lessons__kanban {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  height: $aside-lesson-kanban-height;
  padding-left: $aside-lesson-item-padding;
  border-bottom: 1px solid $aside-lesson-border-color;
  border-left: 4px solid transparent;

  display: none;
}

.asside-lesson__kanban-title {
  @extend .text-button;

  margin-left: $separator-m;
  color: $color-first;
}

.aside-lessons__bottom-container {
  @extend .hover-basic;

  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: $aside-lesson-item-padding;
  border-top: 1px solid $aside-lesson-border-color;
  height: $aside-lesson-add-height;
  width: $aside-lesson-width;

  background-color: $color-white;
}

.asside-lessons__add {
  display: flex;
  align-items: center;
}

.asside-lessons__add-title {
  @extend .text-button;

  margin-left: $separator-s;
  color: $color-first;
}

.asside-lessons__personalize {
  display: flex;
  align-items: center;
}

.asside-lessons__personalize-title {
  @extend .text-small-bold;

  color: $color-gray-02;
  margin-left: $separator-s;
}

.asside-lessons__new {
  display: flex;
  align-items: center;
  margin-top: $separator-l;
  padding-left: $aside-lesson-item-padding;
  opacity: 0;
}

.asside-lesson__new-input {
  width: 95%;

  .input-text__input {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $color-first;

    &:focus {
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
      border-bottom: 1px solid $color-first !important;
    }
  }

  svg {
    path {
      fill: $color-white !important;
    }
  }
}

.asside-lesson__edit {
  padding-left: $aside-lesson-item-padding;
  padding-bottom: $separator-l;
  padding-top: $separator-s;
  border-bottom: 1px solid $aside-lesson-border-color;
}

.asside-lessons__new--show {
  opacity: 1;
}

@media #{$breakpoints-medium} {
  .asside-lessons__kanban {
    display: flex;
  }
}
