.dialog-new-poll__option-input {
  display: flex;
  margin-top: $separator-l;
  align-items: center;
}

.dialog-new-poll__option-input__input {
  flex: 1;
  margin-right: $separator-m;
}

.dialog-new-poll__option {
  @extend .text-small-bold;

  margin-top: $separator-m;
}
