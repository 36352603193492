.gamified-achievements-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  border-radius: 12px;
  border: 1px solid #eee4dc;
  height: 145px;
  padding: 12px 8px;
  margin-bottom: 12px;

  &__text {
    color: $color-gray-02;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__sub {
    border-radius: 50px;
    background: #f03737;
    width: 34px;
    height: 34px;
    padding: 8px 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-family: Dosis;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
  }

  &__sub-box {
    height: 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
    bottom: 3px;
    left: 3px;
  }
}
