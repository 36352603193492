$mosaic-list-item-margin-bottom: $separator-l;

.mosaic-list {
  @extend .grid-column;

  align-items: stretch;
}

.mosaic-list__item {
  @include column(12);

  margin-bottom: $mosaic-list-item-margin-bottom;

  > div {
    height: 100%;
  }
}

@media #{$breakpoints-medium} {
  .mosaic-list--col-4 {
    .mosaic-list__item {
      @include column(6);
    }
  }

  .mosaic-list--col-3 {
    .mosaic-list__item {
      @include column(6);
    }
  }

  .mosaic-list--col-2 {
    .mosaic-list__item {
      @include column(6);
    }
  }
}

@media #{$breakpoints-large} {
  .mosaic-list--col-4 {
    .mosaic-list__item {
      @include column(4);
    }
  }
}

@media #{$breakpoints-xlarge} {
  .mosaic-list--col-4 {
    .mosaic-list__item {
      @include column(3);
    }
  }

  .mosaic-list--col-3 {
    .mosaic-list__item {
      @include column(4);
    }
  }
}
