$toast-border-radius: $border-radius-toasts;
$toast-error-background: $color-white;
$toast-success-background: $color-toast-success-background;
$toast-success-border: $color-toast-success-border-color;
$toast-success-color: $color-toast-success-color-color;
$toast-error-color: $color-error;
$toast-color: $color-white;
$toast-min-width: 230px;

.react-toast-notifications__container {
  z-index: $z-index-notifications !important;
}

.react-toast-notifications__toast {
  width: auto !important;
}

.react-toast-notifications__toast__icon-wrapper {
  display: none;
}

.toast {
  @extend .text-small-bold;

  border-radius: $toast-border-radius;
  color: $toast-color;
  min-width: $toast-min-width;
  padding: $separator-s;

  display: flex;
  align-items: center;

  svg {
    margin-right: $main-separator-items;
  }
}

.toast--success {
  color: $toast-success-color;
}

.toast--error {
  color: $toast-error-color;
}

.react-toast-notifications__toast--error {
  background-color: $toast-success-background !important;
  @extend .border-errors;
}

.react-toast-notifications__toast--success {
  background-color: $toast-success-background !important;
  border: 1px solid $toast-success-border;
}

.react-toast-notifications__toast--info {
  background-color: $color-info !important;
  border: 1px solid $color-info;
}

.react-toast-notifications__toast__dismiss-button {
  display: none;
}
