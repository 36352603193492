$device-item-title-color: $color-title;
$device-item-type-color: $color-label;
$device-item-separator: $separator-xs;
$device-item-day-color: $color-title;
$device-item-month-color: $color-label;

.device-item {
  @extend .card-default-small;
  @extend .border-light;
}

.device-item__left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-item__day {
  @extend .text-p;

  color: $device-item-day-color;
}

.device-item__month {
  @extend .text-extra;

  color: $device-item-month-color;
  text-transform: uppercase;
}

.device-item__center {
  display: flex;
  flex-direction: column;
}

.device-item__title {
  @extend .text-small-bold;
  @extend .ellipsis-complex;

  color: $device-item-title-color;
  margin-bottom: $device-item-separator;
}

.device-item__type {
  @extend .text-extra;

  color: $device-item-type-color;
}

.device-item__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.device-item__view {
  @extend .text-small-bold;
  @extend .text-link;
}
