$assesment-grade-tag-background: $color-background-tag;
$assesment-grade-tag-border-radius: $border-radius-03;
$assesment-grade-tag-padding: $separator-s $separator-xl;

$assesment-grade-tag-total-color: $color-gray-04;
$assesment-grade-tag-text-color: $color-label;

$assesment-grade-tag-needs-reinforcement-bg-color: rgba(247, 104, 104, 0.1);
$assesment-grade-tag-needs-reinforcement-text-color: $color-error;

$assesment-grade-tag-needs-ampliacion-bg-color: rgba(242, 170, 62, 0.1);
$assesment-grade-tag-needs-ampliacion-text-color: $color-second-orange-01;

$assesment-grade-tag-good-progress-bg-color: rgba(65, 209, 88, 0.1);
$assesment-grade-tag-good-progress-text-color: $color-success;

$assesment-grade-tag-timed-out-bg-color: $color-gray-03;
$assesment-grade-tag-timed-out-text-color: $color-white;

.assessment-grade-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: $assesment-grade-tag-background;
  border-radius: $assesment-grade-tag-border-radius;
  padding: $assesment-grade-tag-padding;
  min-height: 44px;
  width: max-content;

  .assessment-grade-tag__icon .icon {
    height: 24px;
    width: 24px;
  }
}

.assessment-grade-tag__grade-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: $separator-m;
}

.assessment-grade-tag__grade {
  @extend .title-h5;
}

.assessment-grade-tag__grade-total {
  @extend .title-h5;

  color: $assesment-grade-tag-total-color;
}

.assessment-grade-tag--needs-reinforcement {
  background: $assesment-grade-tag-needs-reinforcement-bg-color;

  .assessment-grade-tag__grade {
    color: $assesment-grade-tag-needs-reinforcement-text-color;
  }
}

.assessment-grade-tag--needs-ampliacion {
  background: $assesment-grade-tag-needs-ampliacion-bg-color;

  .assessment-grade-tag__grade {
    color: $assesment-grade-tag-needs-ampliacion-text-color;
  }
}

.assessment-grade-tag--good-progress {
  background: $assesment-grade-tag-good-progress-bg-color;

  .assessment-grade-tag__grade {
    color: $assesment-grade-tag-good-progress-text-color;
  }
}

.assessment-grade-tag--timed-out {
  background: $assesment-grade-tag-timed-out-bg-color;

  .assessment-grade-tag__grade {
    color: $assesment-grade-tag-timed-out-text-color;
  }
}
