// $font-size-01: 48px;
// $font-size-02: 36px;
// $font-size-03: 32px;
// $font-size-04: 28px;
// $font-size-05: 22px;
// $font-size-06: 18px;
// $font-size-07: 15px;
// $font-size-08: 14px;
// $font-size-09: 15px;
// $font-size-10: 12px;
// $font-size-11: 10px;

.title-h1 {
  font-family: $font-first;
  font-size: $font-size-01;
  line-height: 61px;
}

.title-h2 {
  font-family: $font-first;
  font-size: $font-size-02;
  line-height: 46px;
}

.title-h3 {
  font-family: $font-first-semi;
  font-size: $font-size-03;
  line-height: 40px;
}

.title-h4 {
  font-family: $font-first-semi;
  font-size: $font-size-04;
  line-height: 35px;
}

.title-h5 {
  font-family: $font-first-semi;
  font-size: $font-size-05;
  line-height: 28px;
}

.text-link {
  @extend .hover-basic;

  color: $color-first !important;
}

.title-h6 {
  font-family: $font-first-semi;
  font-size: $font-size-06;
  line-height: 23px;
}

.title-h7 {
  font-family: $font-first-semi;
  font-size: $font-size-07;
  line-height: 23px;
}

.title-h8 {
  font-family: $font-first-semi;
  font-size: $font-size-08;
  line-height: 23px;
}

.text-p {
  font-family: $font-second;
  font-size: $font-size-06;
  line-height: 27px;
}

.text-p-bold {
  @extend .text-p;

  font-family: $font-second-bold;
}

.text-small {
  font-family: $font-second;
  font-size: $font-size-07;
  line-height: 20px;
}

.text-small-bold,
.label {
  font-family: $font-second-bold;
  font-size: $font-size-07;
  line-height: 24px;
}

.text-smallest-bold {
  font-family: $font-second-bold;
  font-size: $font-size-08;
  line-height: 24px;
}

.label {
  @extend .text-small;

  color: $color-label;
}

.text-s {
  font-family: $font-second;
  font-size: $font-size-09;
  line-height: 25px;
}

.text-smallest {
  font-family: $font-second;
  font-size: $font-size-08;
  line-height: 18px;
}

.text-extra {
  font-family: $font-second;
  font-size: $font-size-10;
  line-height: 18px;
}

.text-extra-bold {
  @extend .text-extra;

  font-family: $font-second-bold;
}

.text-xs {
  font-family: $font-second;
  font-size: $font-size-11;
  line-height: 14px;
}

.text-button {
  font-family: $font-first;
  font-size: $font-size-06;
  line-height: 23px;
}

.placeholder {
  @extend .text-small;

  color: $color-placeholder;
}
