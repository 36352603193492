$text-link-disabled-color: $color-disabled;

.text-link-component {
  @extend .text-link;
  @extend .text-small-bold;

  display: flex;
  align-items: center;

  .icon {
    margin-right: $main-separator-items;
  }
}

.text-link-component--disabled {
  color: $text-link-disabled-color !important;
  cursor: not-allowed;
  opacity: 1 !important;
}
