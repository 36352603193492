.tools_title {
  @extend .title-h4;

  color: $color-gray-02;
}

.tools__tools {
  display: flex;
  margin-top: $separator-l;
}

.poll,
.colaborative-document {
  margin-left: $separator-l;
}
