$primary-2022-card-visible-text-margin: $separator-s;
$primary-2022-card-editby-color: $color-gray-02;

.only-you-see {
  display: flex;
  align-items: center;
}

.only-you-see__text {
  @extend .text-small;

  margin-left: $primary-2022-card-visible-text-margin;
  color: $primary-2022-card-editby-color;
}
