$dropdown-users-classroom-width: 493px;

.dropdown-users-classroom__lib {
  .MuiPaper-root {
    width: $dropdown-users-classroom-width;
    max-width: 94vw;
  }

  .input-text__input {
    border-radius: $border-radius-02;
    background: $color-gray-07;
  }

  .for-users {
    flex: 0 0 auto;
  }

  .for-users__all {

  }
  


  .user-item__input-checkbox {
    margin: 0;
  }

  .user-item__name {
    font-family: $font-second;
    font-size: $font-size-06;
    margin-left: $separator-m;
  }

  .user-item--checked {
    .user-item__name {
      color: $color-gray-01;
    }
  }
}

.dropdown-users-classroom__all {
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  margin-left: $separator-s;
}

.dropdown-users-classroom__all-text, 
.dropdown-users-classroom__no-students {
  @extend .text-p;
  
  color: $color-gray-03;
}

.dropdown-users-classroom__all-text {
  margin-left: $separator-l;
}

.dropdown-users-classroom__no-students {
  display: block;
  margin-bottom: $separator-m;
}

.dropdown-users-classroom__top, .dropdown-users-classroom__users, 
.dropdown-users-classroom__footer {
  padding: $padding-card-default;
}

.dropdown-users-classroom__users {
  max-height: 40vh;
  overflow-y: scroll;
}

.dropdown-users-classroom__all-classroom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: $border-size-01 solid $color-borders;
  padding-bottom: $separator-m;
  padding-right: 6px;
  margin-top: $separator-xxl;

  .input-checkbox {
    display: flex;
    justify-content: flex-end;
  }
}

.dropdown-users-classroom__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $color-gray-07;

  .input-checkbox__label {
    color: $color-gray-01;
  }
}

.dropdown-users-classroom__footer-save {
  flex: 0 0 auto;
}

.dropdown-users-classroom__all-classroom--checked {
  .for-users__all-text,
  .dropdown-users-classroom__all-text {
    color: $color-gray-01;
  }
}

.dropdown-users-classroom__no-students {

}
