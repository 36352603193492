$unoi-lesson-header-height: 56px;
$unoi-lesson-header-font-family: 'Dosis Semi Bold';
$unoi-lesson-header-font-size: 18px;
$unoi-lesson-header-font-size-project: 16px;
$unoi-lesson-header-font-weight-project: 700;
$unoi-lesson-header-border-color: #dde0e4;
$unoi-lesson-header-color-gray: #828282;

.unoi-lesson-header {
  padding: $separator-m;
  font-family: $unoi-lesson-header-font-family;
  width: 100vw;
  height: $unoi-lesson-header-height;
  border-bottom: 1px solid $unoi-lesson-header-border-color;
}

.unoi-lesson-header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.unoi-lesson-header__back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.unoi-lesson-header__title {
  font-size: $unoi-lesson-header-font-size;
  text-transform: capitalize;
  color: $unoi-lesson-header-color-gray;
  margin-left: $separator-m;
  font-family: $unoi-lesson-header-font-family;
}

.unoi-lesson-header__navigation {
  z-index: 10;
  text-transform: uppercase;
}

.unoi-lesson-header__project {
  display: flex;
  align-items: center;
  font-family: $unoi-lesson-header-font-family;
  color: $color-first;
  font-size: $unoi-lesson-header-font-size-project;
  font-weight: $unoi-lesson-header-font-weight-project;
  cursor: pointer;
}

.unoi-lesson-header__project-text {
  margin-right: $separator-m;
}
