.xapi-individual-results-progress {
  width: 182px;
  background: transparent;
  border-radius: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ebe0d7;

  .xapi-individual-results-progress__bar {
    position: relative;
    margin: 4px 0;
    height: 4px;
    flex-grow: 1;

    .xapi-individual-results-progress__fill {
      position: absolute;
      left: 0;
      background: #41d158;
      height: 4px;
      z-index: 1;
    }

    .xapi-individual-results-progress__bg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      background: $color-gray-05;
    }
  }

  .xapi-individual-results-progress__text {
    @extend .title-h6;

    color: $color-gray-02;
    margin-left: 18px;

    display: flex;
  }

  .xapi-individual-results-progress__perc {
    font-size: 14px;
    font-family: $font-second;
    margin-left: $separator-xs;
    color: $color-gray-03;
    display: block;
  }
}
