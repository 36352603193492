$dialog-add-link-max-width: $size-dialog-small-max-width;
$dialog-add-link-separator-height: $separator-xxl;

.dialog-add-link {
  .MuiPaper-root {
    max-width: $dialog-add-link-max-width;
  }

  .input-text__label {
    @extend .text-small;
  }
}

.dialog-add-link__separator {
  margin-top: $dialog-add-link-separator-height;
}
