$switch-separator-texts: $separator-s;
$switch-label-color-light: $color-gray-03;
$switch-description-color: $color-gray-04;

.switch {
  @extend .hover-basic;
  padding: $separator-m;
  display: flex;
  align-items: flex-start;
}

.switch[animation='1'] {
  .switch__icon {
    animation: wobble 0.4s;
  }
}

.switch__icon {
  flex: 0 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-top: 3px;
  }
}

.switch__texts {
  margin-left: $switch-separator-texts;
}

.switch__label {
  @extend .text-small-bold;
  @extend .ellipsis-complex;

  flex: 0 0 auto;
}

.switch--color-light {
  color: $switch-label-color-light;
}

.switch__description {
  @extend .text-smallest;
  @extend .ellipsis-complex;

  color: $switch-description-color;
}
