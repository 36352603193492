$doughnut-chart-center-background: $color-white;
$doughnut-chart-center-size: 110px;
$doughnut-chart-center-text-padding: $main-separator-items;
$doughnut-chart-center-text1-color: $color-title;
$doughnut-chart-center-text2-color: $color-label;

.doughnut-chart {
  position: relative;
  width: 100%;

  .recharts-tooltip-wrapper {
    z-index: 1;
  }

  .recharts-default-tooltip {
    @extend .text-extra;

    background: red;
  }
}

.doughnut-rechart {
  position: relative;
}

.doughnut-chart__center {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  background: $doughnut-chart-center-background;
  border-radius: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  text-align: center;

  height: $doughnut-chart-center-size;
  width: $doughnut-chart-center-size;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.doughnut-chart__text1 {
  @extend .title-h4;

  color: $doughnut-chart-center-text1-color;
  padding: 0 $doughnut-chart-center-text-padding;
}

.doughnut-chart__text2 {
  @extend .text-extra;

  color: $doughnut-chart-center-text2-color;
  padding: 0 $doughnut-chart-center-text-padding;
}
