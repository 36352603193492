$title-and-action-text-space: $main-separator-items;
$title-and-action-title-color: $color-title;
$title-and-action-text-color: $color-label;

.title-and-action {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title-and-action__text-container {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.title-and-action__text {
  @extend .title-h6;

  color: $title-and-action-text-color;
}

.title-and-action__action {
  flex: 0 0 auto;
}
