$my-classrooms-view-header-scrolltop-background: $color-gray-07;
$my-classrooms-view-header-scrolltop-content-top: 140px;
$my-classrooms-view-enter-class-widht: 400px;
$my-classrooms-view-enter-class-text-margin: $separator-xl;
$my-classrooms-view-programs-margin: $separator-xxxl;
$my-classrooms-view-programs-border-radius: $border-radius-01;
$my-classrooms-view-new-class-icon-size: 32px;
$my-classrooms-view-header-tab-height: 4px;
$my-classrooms-view-header-tab-bottom: -10px;

.my-classrooms-view {
  .header-simple__subheader {
    margin-bottom: $separator-xxxl;
  }

  .my-classrooms-view-top-right {
    width: auto;
    align-self: flex-end;
    display: none;
    .icon {
      height: $my-classrooms-view-new-class-icon-size;
      width: $my-classrooms-view-new-class-icon-size;
    }
  }
  .header-simple__subheader {
    .header-simple__logo {
      display: none;
    }
  }
  .header-tabs__tab--active {
    &:after {
      height: $my-classrooms-view-header-tab-height;
      bottom: $my-classrooms-view-header-tab-bottom;
    }
  }
}

.my-classrooms-view__top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.my-classrooms-view__header-small {
  z-index: $z-index-header;
  transition: top 0.8s ease 0s; /* only transition top property */
  top: -100px;
  position: fixed;
  width: 100%;
}

.my-classrooms-view--header-no-animation {
  .layout-header__header {
    top: 0;
    position: sticky !important;
  }

  .my-classrooms-view__header-small {
    position: initial;
  }
}

.my-classrooms-view--scrolldown {
  .my-classrooms-view__header-small {
    top: 0;
  }
}

.my-classrooms-view {
  .layout-header__header {
    position: initial;
  }

  .my-classrooms-view__header-small {
    z-index: $z-index-minor-elements;
  }
}

.my-classrooms-view__title-action {
  margin-bottom: $main-separator-components;
}

.my-classrooms-view__enter-class {
  max-width: $my-classrooms-view-enter-class-widht;
  margin: 0 auto;
  padding-bottom: $my-classrooms-view-programs-margin;
}

.my-classrooms-view__enter-class-text {
  @extend .title-h4;

  margin-bottom: $my-classrooms-view-enter-class-text-margin;
  text-align: center;
}

.my-classrooms-view__ask-teacher {
  @extend .title-h6;

  margin-top: $my-classrooms-view-enter-class-text-margin;
  text-align: center;
}

.my-classrooms-view__programs-container {
  margin-top: $my-classrooms-view-programs-margin;

  .MuiAccordion-root {
    background: $color-gray-06;
    border: 0px;
    box-shadow: none;
    margin-top: $separator-xl !important;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: $my-classrooms-view-programs-border-radius;
    border-bottom-right-radius: $my-classrooms-view-programs-border-radius;
  }

  .MuiAccordion-rounded:first-child {
    border-top-left-radius: $my-classrooms-view-programs-border-radius;
    border-top-right-radius: $my-classrooms-view-programs-border-radius;
  }

  #panel1a-header {
    div {
      @extend .title-h4;
    }
  }
}

.my-classrooms-view__programs-list {
  width: 100%;
  margin-top: $my-classrooms-view-enter-class-text-margin;
}

.my-classrooms-view__programs-accordion {
  padding: $my-classrooms-view-enter-class-text-margin;
}

.my-classrooms-view__fab {
  position: fixed;
  bottom: 0;
  right: 0;
}

.my-classrooms-view-top-right__action {
  @extend .hover-basic;
}

.my-classrooms-view-top-right__right {
  margin-top: $separator-l;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.my-classrooms-view-top-right__order {
  display: flex;
  align-items: center;
}

.my-classrooms-view-top-right-order__text {
  display: none;
}

.heads-simple__hello-container {
  display: flex;
  align-items: center;
}

.heads-simple__hello-avatar {
  margin-right: $separator-l;
}

@media #{$breakpoints-small} {
  .my-classrooms-view__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .my-classrooms-view-top-right-order__text {
    display: block;
  }

  .my-classrooms-view {
    .my-classrooms-view-top-right {
      display: block;
    }
  }
}
