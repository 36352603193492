$lesson-info-lesson-items-components-separator: $main-separator-components;
$lesson-info-lesson-items-move-icon-separator: $separator-l;
$lesson-info-lesson-items-title-separator: $main-separator-items;
$lesson-info-lesson-items-title-color: $color-label;
$lesson-info-lesson-items-section-empry-separator: $main-separator-items;
$lesson-info-lesson-items-section-background: $color-gray-06;

.lesson-info-lesson-items__list {
  margin-bottom: $lesson-info-lesson-items-components-separator;
}

.lesson-info-lesson-items__actions-move-icon {
  @extend .hover-basic;

  margin-left: $lesson-info-lesson-items-move-icon-separator;
  visibility: hidden;
}

.lesson-info-lesson-items__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: $lesson-info-lesson-items-title-separator;

  &:hover {
    .lesson-info-lesson-items__actions-move-icon {
      visibility: visible;
    }
  }
}

.lesson-info-lesson-items__title-container {
  display: flex;
  align-items: center;

  .learning-objetives-tag-list {
    margin-bottom: 0;
  }

  .icon {
    margin-right: $lesson-info-lesson-items-title-separator;
  }
}

.lesson-info-lesson-items__title {
  @extend .title-h6;
  @extend .ellipsis;

  display: block;
  color: $lesson-info-lesson-items-title-color !important;
}

.lesson-info-lesson-items__actions {
  display: flex;
}

.lesson-info-lesson-items__empty-section {
  margin-top: $lesson-info-lesson-items-section-empry-separator;

  .no-items-mode-edit {
    background: $lesson-info-lesson-items-section-background;
  }
}
