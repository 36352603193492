$layout-background: $color-background;
$layout-header-padding-basic: $main-padding-page;
$layout-header-background-image: $size-layout-header-backgroundimage-height;
$layout-header-background-text-color: $color-white;
$layout-header-background-text-separator: $main-separator-items;
$layout-header-footer-background: $color-layout-progress;
$layout-header-footer-box-shadow: $box-shadow-03;
$layout-header-footer-height: $size-layout-progress-footer-height;
$layout-header-footer-border: $border-headers;
$layout-header-height-with-header: 100vh;

.layout-header {
  background: $layout-background;
  min-height: $layout-header-height-with-header;
}

.layout-header__header {
  position: sticky;
  top: 0;

  width: 100%;
  z-index: $z-index-header;
}

.layout-header--with-footer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .layout-header__content {
    flex: 1 1 auto;
    overflow-y: auto;
  }
}

.layout-header__background-image {
  background-size: cover;
  background-position: center;
  height: $layout-header-background-image;
}

.layout-header__children {
  @extend .grid;

  padding: $layout-header-padding-basic 0;
}

.layout-header__background-text-container {
  @extend .grid;

  height: 100%;

  display: flex;
  align-items: flex-end;
}

.layout-header__background-text {
  @extend .title-h2;

  color: $layout-header-background-text-color;
  display: block;
  margin-bottom: $layout-header-background-text-separator;
}

.layout-header__footer {
  background: $layout-header-footer-background;
  border-top: $layout-header-footer-border;
  box-shadow: $layout-header-footer-box-shadow;
  height: $layout-header-footer-height;
  flex: 0 0 auto;
}
