$custom-banner-height: 128px;

.custom-banner {
  position: relative;
  border-radius: $border-radius-01;
  height: $custom-banner-height;
  padding: $separator-m $separator-l;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-banner__top,
.custom-banner__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-banner__image {
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: $border-radius-01;
  background-size: cover;
  background-position: center;
}
