$profile-view-separators: $main-separator-components;
$profile-view-list-profile-width: 265px;
$profile-view-text-separator: $main-separator-items;
$profile-view-preferences-languages-list-margin: $separator-l;
$profile-view-text-color: $color-title;

.profile-view {
  .list-simple-dropdown__li {
    padding: $separator-l $separator-xl;
  }
}

.profile-view__right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-view__item {
  margin-bottom: $profile-view-separators;
  .back-button {
    display: none;
  }
}

.profile-view__item--title {
  display: none;
  width: $profile-view-list-profile-width;
}

.profile-view__item--list-profiles {
  width: $profile-view-list-profile-width;
}

.profile-view__center-column {
  display: flex;
  //align-items: center;
  flex-direction: column;
}

.profile-view__options {
  width: 100%;
}

.profile-view__section {
  @include column(6);

  margin-left: 0;
}

.profile-view-licenses__header {
  @extend .grid-column;
}

.profile-view-licenses__header-title {
  @include column(6);
}

.profile-view-licenses__header-action {
  @include column(6);

  justify-content: flex-end;
  display: flex;
}

.profile-view__list {
  margin-top: $profile-view-separators;
}

.profile-view-devices__text {
  @extend .text-p;

  color: $profile-view-text-color;
  display: block;
  margin-top: $profile-view-separators;
}

.profile-view__school {
  width: 90%;
  margin-top: auto;
}

.profile-view-licenses__empty {
  @extend .text-p;

  color: $color-gray-02;
}

.profile-view-preferences__languages {
  margin-top: $profile-view-separators;
}

.profile-view-preferences__languages-list {
  margin-top: $profile-view-preferences-languages-list-margin;
}

.profile-view-preferences__languages-title {
  color: $color-gray-03;
}

.profile-view-licenses__expired {
  margin-top: $separator-l;
}

@media #{$breakpoints-xlarge} {
  .toolbar__left,
  .toolbar__right {
    flex-direction: row;
  }

  .profile-view__item--title {
    display: block;
  }

  .profile-view__item {
    .back-button {
      display: block;
    }
  }
}
