.border-primary {
  border: $border-size-02 solid $color-first;
}

.border-primary-thin {
  border: $border-size-01 solid $color-first;
}

.border-default {
  border: $border-size-02 solid $color-borders;
}

.border-light {
  border: $border-size-01 solid $color-borders;
}

.border-light-black {
  border: $border-size-01 solid $color-borders-black;
}

.border-errors {
  border: $border-size-01 solid $color-error;
}

.border-actions {
  border: $border-size-01 dotted $color-first;
}

.border-dashed {
  border: $border-size-02 dashed $color-borders;
}

.border-info {
  border: $border-size-02 solid $color-info;
}
