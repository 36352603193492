$table-react-min-width-th: 130px;
$table-react-min-width-th-user: 220px;
$table-react-th-background: $color-white;

.table-react > table > thead > tr > th:first-child,
.table-react > table > * > tr > td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.table-react {
  border-radius: $border-radius-01;
  overflow: auto;
  white-space: nowrap;
  left: 0;

  thead tr {
    @extend .border-default;

    border-collapse: separate;

    position: sticky;
    z-index: $z-index-header;

    top: 0px;
  }

  table {
    @extend .border-default;

    border-top: 0;
    border-left: 0;
    border-collapse: separate;
    border-spacing: 0;

    tr {
      th {
        background: $table-react-th-background;
        border-bottom: $border-size-02 solid $color-borders;
        border-right: $border-size-01 solid $color-borders;
        border-top: $border-size-02 solid $color-borders;
        padding: $separator-xs $separator-s;
        text-align: left;
        vertical-align: middle;
        min-width: $table-react-min-width-th;
        max-width: $table-react-min-width-th;

        &:first-child {
          border-right: 0;
          min-width: $table-react-min-width-th-user !important;
          max-width: $table-react-min-width-th-user !important;
          border-left: $border-size-02 solid $color-borders;
        }
        &:nth-child(2) {
          text-align: right;
        }

        &:last-child {
          border-right: 0;
        }
      }

      td {
        background: $table-react-th-background;
        border-bottom: $border-size-01 solid $color-borders;
        border-right: $border-size-01 solid $color-borders;
        padding: $padding-card-default-small;
        text-align: right;
        vertical-align: middle;

        &:first-child {
          border-right: 0;
          text-align: left;
          border-left: $border-size-02 solid $color-borders;
        }

        &:last-child {
          border-right: 0;
        }

        .text-link-component {
          justify-content: flex-end;
        }
      }

      &:first-child {
        td {
          background: $color-gray-07;
        }
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.table-react__with-arrow-container {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-react__hr--border-top-white {
  border-top-color: $color-white !important;
}

.table-react__td-click {
  @extend .hover-basic;
}
