$group-timeline-two-sides-in-images-radius: 8px;

.agroup-timeline-two-sides-in-images__item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: $timeline-two-sides-background-color;
  margin-bottom: $main-separator-items;
  border-top: $border-size-01 solid $color-borders;
  border-bottom: $border-size-01 solid $color-borders;
}

.agroup-timeline-two-sides-in-images__item-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-top-left-radius: $group-timeline-two-sides-in-images-radius;
  border-bottom-left-radius: $group-timeline-two-sides-in-images-radius;
  
  height: 100%;
  width: 100%;

  padding: $separator-l;
}

.agroup-timeline-two-sides-in-images__item-name {
  @extend .title-h3;

  color: $color-white;
}
