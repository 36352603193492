@font-face {
  font-family: 'Dosis Bold';
  src: url('../assets/fonts/Dosis-Bold.ttf');
}

@font-face {
  font-family: 'Dosis Semi Bold';
  src: url('../assets/fonts/Dosis-SemiBold.ttf');
}

@font-face {
  font-family: 'Lato Bold';
  src: url('../assets/fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato Regular';
  src: url('../assets/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('../assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans Bold';
  src: url('../assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans Regular';
  src: url('../assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Concert Regular';
  src: url('../assets/fonts/ConcertOne-Regular.ttf');
}

@font-face {
  font-family: 'Circular Regular';
  src: url('../assets/fonts/CircularStd-Medium.ttf');
}

@font-face {
  font-family: 'Circular Bold';
  src: url('../assets/fonts/CircularStd-Bold.ttf');
}

body {
  color: $color-black;
  font-family: $font-second;
}
