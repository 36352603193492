@import './RubricsDialog/RubricsDialogSubTitle.scss';
@import './RubricsDialogAdd/RubricsDialogAddList.scss';
@import './RubricsDialogAdd/RubricsDialogAddListItem.scss';
@import './RubricsTable/RubricsTable.scss';

.rubrics {
  &__icon {
    svg {
      border-radius: 4px;
      background: $color-info;
      padding: 4px;
    }
  }
}
