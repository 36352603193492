.immersive-reader {
  @extend .hover-basic;
  position: fixed;
  right: $separator-l;
  margin-top: $separator-l;

  z-index: 1000;
}

@media #{$breakpoints-medium} {
  .immersive-reader {
    right: auto;
    left: auto;
    margin-left: -$separator-xxxxl;
    margin-top: $separator-l;
  }
}

@media #{$breakpoints-large} {
  .immersive-reader {
    margin-left: 8%;
    margin-top: $separator-l;
  }
}
