.gamified-classification-dialog-list {
  &__title {
    color: #4f4f4f;
    font-family: Dosis;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  &__title-medal {
    @extend .gamified-classification-dialog-list__title;
    width: 96px;
  }

  &__title-level {
    @extend .gamified-classification-dialog-list__title;
    width: 56px;
  }

  &__title-exp {
    @extend .gamified-classification-dialog-list__title;
    width: 104px;
  }

  &__box {
    max-height: 470px;
    overflow: auto;
  }
}
