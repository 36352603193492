.book-select {
  display: flex;
  align-items: center;
}

.book-select__name {
  @extend .title-h7;

  margin-left: $separator-m;
}
