$menu-aside-padding: $padding-aside;

.MuiDrawer-root {
  .MuiBackdrop-root {
    background-color: $color-background-cover;
  }
}

.menu-aside--position-right {
  .MuiPaper-root {
    border-radius: $border-radius-01 0 0 $border-radius-01;
  }
}

.menu-aside--position-left {
  .MuiPaper-root {
    border-radius: 0 $border-radius-01 $border-radius-01 0;
  }
}

.menu-aside {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 100%;
}

.menu-aside__header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px $menu-aside-padding 0 $menu-aside-padding;
}

.menu-aside__header-title {
  @extend .title-h6;
}

.menu-aside__icon-close {
  @extend .hover-basic;
}

.menu-aside__children {
  padding: $separator-l $menu-aside-padding;
  height: 100%;
}

.menu-aside__content {
  flex: 1 1 auto;
  overflow: auto;
}

.menu-aside__footer {
  flex: 0 0 auto;
}

.menu-aside__header-right {
  display: flex;
  align-items: center;
}
