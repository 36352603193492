$dropdown-students-grades-width: $size-dropdown-students-grades;
$dropdown-students-max-height: 300px;
$user-item-poinsts-width: 38px;

.dropdown-students-grades__lib {
  .MuiPaper-root {
    width: $dropdown-students-grades-width;
  }
}

.dropdown-students-grades__dropdown {
  @extend .card-generic--default-small;

  max-height: $dropdown-students-max-height;
  overflow: auto;

  .basic-list__item {
    border: 0;
  }

  .user-item__texts {
    @extend .ellipsis;

    color: $color-label;
  }

  .user-item__main-info {
    @include ellipsis-line(1);

    display: flex;
    align-items: center;

    width: calc(100% - #{$user-item-poinsts-width});
  }
}
