.square-progress-badge {

  width: 100px;

  .square-progress-badge__progress {
    @extend .title-h3;

    color: $color-title;
    display: block;
  }

  .square-progress-badge__title {
    @extend .text-smallest;
    text-transform: uppercase;
    color: $color-label;
    font-weight: 700;
  }

  .square-progress-badge__bar {
    position: relative;
    margin: 4px 0;
    height: 4px;

    .square-progress-badge__bar-fill {
      position: absolute;
      left: 0;
      background: $color-label;
      height: 4px;
      z-index: 1;
    }

    .square-progress-badge__bar-bg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      background: $color-gray-05;
    }
  }
}
