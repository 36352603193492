$myclassrooms-view-library-grid:  12px 24px;
$class-room-card-background-color: $color-gray-07;

.myclassrooms-view-library__program-group {
  background-color: $class-room-card-background-color;
  border: none;
  margin-bottom: $main-separator-components;
  padding: 24px 24px;
  position: relative;
}

.myclassrooms-view-library__program-group-title {
  @extend .title-h5;

  color: $color-gray-01;
  display: block;
  margin-bottom: $main-separator-components;
  text-transform: uppercase;
}

@media #{$breakpoints-tablet} {
  .myclassrooms-view-library__programs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $myclassrooms-view-library-grid;
    align-self: stretch;
  }
}


