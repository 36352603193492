$assigments-main-info-questions-color: $color-label;
$assigments-main-info-date-color: $color-label;
$assigments-main-info-date-icon-separator: $main-separator-items;
$assigments-main-info-bubble-separator: $main-separator-components;
$assigments-main-info-separator-items: $main-separator-items;
$assigments-main-info-separator-components: $main-separator-components;
$assigments-main-info-separator-hr: $separator-hr;
$assigments-main-info-delivery-passed-color: $color-label;

.assigments-main-info {
  @extend .card-default;

  .comments-list-and-create__sender {
    width: calc(100% + (#{$padding-card-default-horizontal} * 2));
    left: -#{$padding-card-default-horizontal};
    position: relative;
    bottom: -#{$padding-card-default-vertical};
  }
}

.assigments-main-info__header {
  display: block;
}

.assigments-main-info__left {
  display: block;
}

.assigments-main-info__bubble {
  margin-right: $assigments-main-info-bubble-separator;
}
.assigments-main-info__visible {
  margin-right: $assigments-main-info-date-icon-separator;
}

.assigments-main-info__name-container {
  display: flex;
  align-items: center;
}

.assigments-main-info__name {
  @extend .title-h4;
}

.assigments-main-info__meta-info {
  @extend .text-smallest;

  color: $assigments-main-info-questions-color;
}

.assigments-main-info__stats-contents {
  display: flex;
  align-items: center;
}

.assigments-main-info__stats-bar {
  margin-right: $assigments-main-info-separator-components;
}

.assigments-main-info__deadline {
  display: flex;
  align-items: center;
}

.assigments-main-info__date-container {
  display: flex;
  flex-direction: column;

  margin-left: $assigments-main-info-date-icon-separator;
}

.assigments-main-info__deadline-text {
  @extend .text-small-bold;
}

.assigments-main-info__date {
  @extend .text-extra;

  color: $assigments-main-info-date-color;
}

.assigments-main-info__tag {
  margin-top: $assigments-main-info-separator-items;
}

.assigments-main-info__tag,
.assigments-main-info__name-container,
.assigments-main-info__questions {
  margin-bottom: $assigments-main-info-separator-items;
}

.assigments-main-info__more-options {
  @extend .hover-basic;
}

.assigments-main-info__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.assigments-main-info__hr {
  margin-top: $assigments-main-info-separator-hr;
  margin-bottom: $assigments-main-info-separator-hr;
}

.assigments-main-info__deadline--passed {
  .assigments-main-info__deadline-text {
    color: $assigments-main-info-delivery-passed-color;
  }
}

@media #{$breakpoints-medium} {
  .assigments-main-info__header {
    display: flex;
    justify-content: space-between;
  }

  .assigments-main-info__left {
    display: flex;
  }

  .assigments-main-info__tag {
    margin-top: 0;
  }
}
