$dialog-calendar-settings-max-width: $size-dialog-small-max-width;
$dialog-calendar-settings-separator-items: $main-separator-items;
$dialog-calendar-settings-title-color: $color-title;
$dialog-calendar-settings-text-color: $color-label;
$dialog-calendar-settings-border-radius: 12px;

.dialog-calendar-settings {
  .MuiPaper-root {
    max-width: $dialog-calendar-settings-max-width;
  }
  .MuiPaper-rounded {
    border-radius: $dialog-calendar-settings-border-radius;
  }
}

.dialog-calendar-settings__title {
  @extend .title-h6;
  display: flex;
  justify-content: space-between;
  padding-bottom: $dialog-calendar-settings-separator-items;
  color: $dialog-calendar-settings-title-color;
}

.dialog-calendar-settings__close {
  cursor: pointer;
}

.dialog-calendar-settings__separator {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f6f6f6;
}

.dialog-calendar-settings__info {
  min-height: 200px;
  width: 100%;
  height: 100%;
  display: flex;
}

.dialog-calendar-settings__info-all {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-calendar-settings__info-all-with-days {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.dialog-calendar-settings__info-days {
  width: 100%;
}

.dialog-calendar-settings__info-day {
  width: 100%;

  margin: $separator-s;
  display: flex;
  align-items: center;
}

.dialog-calendar-settings__info-day-date {
  @extend .text-s;

  width: 35%;
  margin: $separator-s;
  border-right: 1px solid #a39b93;
  color: #36393b;
}

.dialog-calendar-settings__info-day-name {
  @extend .title-h6;
  display: flex;
  justify-content: space-between;

  padding: $separator-xs;
  width: 65%;

  color: #36393b;
  font-family: $font-first-semi;
}

.dialog-calendar-settings__info-inputs {
  width: 100%;
  height: 100%;

  padding-top: $dialog-calendar-settings-separator-items;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: $dialog-calendar-settings-separator-items;
}

.dialog-calendar-settings__info-dates {
  width: 100%;
  height: 100%;
  margin-top: $dialog-calendar-settings-separator-items;
  display: flex;

  div:first-child {
    margin-right: $dialog-calendar-settings-separator-items;
  }
}

.dialog-calendar-settings__text {
  font-size: 15px;
  margin: $dialog-calendar-settings-separator-items;
  color: #828282;
}

.dialog-calendar-settings__button {
  margin: $separator-m 0;
  font-size: 18px;
}
