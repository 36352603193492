$more-icon-menu-shadow-size: 25px;

.more-icon-menu {
  @extend .hover-basic;

  margin-left: auto;
}

.more-icon-menu__shadow--vertical {
  .icon {
    transform: rotate(90deg);
  }
}

.more-icon-menu__shadow {
  height: $more-icon-menu-shadow-size;
  width: $more-icon-menu-shadow-size;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover,
  &.more-icon-menu__shadow--open {
    background-color: $color-gray-07;
    border-radius: 100%;
    visibility: visible !important;
  }
}

@media #{$breakpoints-tablet} {
  .more-icon-menu {
    margin-left: $separator-l;
  }
}
