$assessment-results-view-doughnut-height: 300px;

.mint-view {
  .item-block-visor__controls-wrapper {
    .button {
      min-width: auto;
    }
  }

  .mint-assistant-editor,
  .block-shortcut {
    display: none;
  }

  .float-button {
    .button {
      width: 60px;
      height: 60px;
      min-width: 60px;
    }
  }

  .item-block-editor__left-controls .button {
    display: none;
  }

  .mint-footer-lms__counters-number {
    @extend .title-h3;
  }

  .mint-footer-lms__counters-progress {
    height: 4px !important;
  }

  .mint-footer-lms__counters-progress-bar {
    border-radius: $border-radius-02;
  }

  .mint-footer-lms__counters-label {
    @extend .text-button;

    color: $color-gray-03;
  }

  .mint-view__head-editor {
    @extend .hover-basic;

    //position: absolute;
    position: relative;
    display: flex;
    justify-content: flex-end;
    //transform: translateX(-50%);
    //left: 50%;
    z-index: 100;
    //top: 0;

    .mint-view__head-edit {
      top: 10px;
      position: absolute;
    }

    & + .lemonade-exercises {
      top: -20px;

      .lemo-question-header {
        padding-right: 40px;
      }
    }
  }

  .mint-footer-lms__button {
    .button {
      border-radius: $border-radius-08;
      padding: 2px 25px;
    }
  }

  //SIDEBAR ADD BLOCKS
  .collections-menu,
  .create-block-sidebar {
    z-index: 4001;
  }

  .variants-menu {
    background-color: $color-gray-07;
  }

  .aside-menu-button.selected {
    background-color: $color-gray-07;
  }

  .student-custom-footer {
    .textarea {
      height: auto;
    }
  }

  //EDITOR WRAPPER
  .wrapper-post-editor {
    .lemo-question-header {
      //padding-right: 40px;
    }
  }

  //STUDY BUDDY
  .mint-epigraph {
    @extend .hover-basic;

    transition: all 0.5s ease;
    margin-bottom: $separator-m;
    * {
      background-color: transparent !important;
    }

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
  }

  .fr-view {
    // reset
    ul,
    ol {
      list-style: initial;
      padding-left: 40px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    i {
      font-style: italic;
    }

    b,
    strong {
      font-weight: bold;
    }

    sub {
      vertical-align: sub;
      font-size: smaller;
    }

    sup {
      font-size: smaller;
    }
  }

  .block-shortcut-inline__button-list {
    .icon-text-button {
      &:last-child {
        display: none;
      }
      &:nth-last-child(2) {
        display: none;
      }
    }
  }
}

.mint-view__dialog-ia {
  .MuiDialog-paper {
    min-height: 400px !important;
    border-radius: 16px !important;
  }
  .dialog__actions {
    padding: $separator-l 5%;
    .button {
      z-index: 10000;
    }
  }

  .loading-robot {
    svg {
      max-height: 200px;
    }
  }
}

.mint-view__dialog-ia-header {
  padding: $separator-l 5%;
  span {
    background-color: $color-first;
    color: $color-white;
    line-height: 20px;
    font-size: 15px;
    padding: 4px 12px;
    border-radius: 43px;
    font-weight: 700;
  }
}

.mint-view__dialog-ia-text {
  padding: 0 5%;
  font-family: $font-second;
  line-height: 25px;

  // p {
  //   margin: $separator-m auto;
  // }
}

.mint-view__view-container {
  width: 90%;
  margin: auto;
}

.mint-view__empty-post {
  text-align: center;
  margin-top: $main-separator-components;
}

.mint-view__chart {
  margin: auto;

  .doughnut-chart {
    display: flex;
    justify-content: center;
  }

  .doughnut-rechart {
    width: 100%;
  }
}

.mint-view-media-editor-image__icon {
  @extend .hover-basic;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.mint-view-media-editor-image__figure {
  max-width: 100%;
  max-height: 264px;
  margin: auto;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    max-height: 264px;
  }

  .icon--edit-post {
    height: 48px;
    width: 48px;

    path {
      fill: $color-white;
    }
  }
}

.mint-tabs-model-a {
  margin-top: 0 !important;
}

.block-edit__content {
  padding-top: 0 !important;
}

.mint-tab-content-settings-sidebar {
  margin-top: $main-separator-components;

  .label {
    color: $color-gray-01 !important;
  }
}

.settings-dropdown__label,
.settings-item-label {
  color: $color-gray-01 !important;
}

@media #{$breakpoints-xxxlarge} {
  .mint-view__yoy {
    left: 5%;

    .yoy {
      position: relative;
      left: -20%;
    }
  }
}

@media #{$breakpoints-medium} {
  .mint-view__yoy {
    display: block;
  }

  .mint-view__view-container {
    width: 80%;
  }
}

@media #{$breakpoints-large} {
  .mint-view__yoy {
    left: 5%;
  }

  .mint-view__view-container {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .mint-lesson .lesson__section .mint-item-block_component {
    .mint-block-image--text-aside,
    .mint-block-gallery,
    .mint-block-carousel {
      max-width: 1024px !important;
    }
    .mint-video {
      height: auto;
    }
  }
}
