$tags-list-separator: $separator-s;

.tags-list {
  .tag {
    margin-right: $tags-list-separator;
    margin-bottom: $tags-list-separator;
  }

  .tag:last-child {
    margin-right: 0;
  }
}
