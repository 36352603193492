.tooltip-content__header {
  display: flex;
  margin-bottom: $separator-m;
}

.tooltip-content__header-icon {
  margin-right: $separator-m;
}

.tooltip-content__header-text {
  @extend .title-h6;
  color: $color-gray-01;
  margin-left: $separator-s;
}

.tooltip-content__text {
  @extend .text-small;
  line-height: 20px;
  color: $color-gray-01;
  b {
    font-weight: bold;
  }
}
