$learning-objective-item-margin: $main-separator-components;
$learning-objective-item-checkbox-margin: $main-separator-items;

.learning-objective-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $learning-objective-item-margin;
}

.learning-objective-item__name {
  @extend .text-small;
}

.learning-objective-item__right {
  display: flex;
}

.learning-objective-item__checkbox {
  margin-left: $learning-objective-item-checkbox-margin;
}
