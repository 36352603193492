$logo-size-default: 40px;
$logo-size-big: 80px;

.logo {
  background-image: var(--logo);
  height: $logo-size-default;
  width: auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.logo--big {
  height: $logo-size-big;
}
