$kanban-dialog-add-card-background: $color-gray-07;
$kanban-dialog-add-card-radius: $border-radius-04;
$kanban-dialog-add-card-border-width: 24px;
$kanban-dialog-add-card-icon-container-height: 60px;
$kanban-dialog-add-card-icon-container-width: 94px;
$kanban-dialog-add-card-icon-container-background: $color-white;
$kanban-dialog-add-card-icon-container-radius: 40px;
$kanban-dialog-add-card-textarea-height: 135px;
$kanban-dialog-add-card-textarea-radius: 8px;
$kanban-dialog-add-card-emoji-radius: 16px;
$kanban-dialog-add-card-emoji-margin: 10px;
$kanban-dialog-add-card-emojis-top: 135px;
$kanban-dialog-add-card-emojis-left: 60px;
$kanban-dialog-add-card-color-picker-margin: $separator-l;
$kanban-dialog-add-card-color-size: 32px;
$kanban-dialog-add-card-color-active: $color-first;

.kanban-dialog-add-card {
  .dialog__title {
    @extend .title-h6;
  }

  .MuiDialog-paper {
    overflow-y: initial;
  }

  .emoji-mart {
    border-radius: $kanban-dialog-add-card-emoji-radius;
  }

  .emoji-mart-search {
    display: none;
  }

  textarea {
    resize: none;
  }
}

.kanban-dialog-add-card__card {
  padding: $padding-card-default;
  background: $kanban-dialog-add-card-background;
  border-left: $kanban-dialog-add-card-border-width solid $color-gray-01;
  border-radius: $kanban-dialog-add-card-radius;
}

.kanban-dialog-add-card__icon-container {
  @extend .hover-basic;

  background: $kanban-dialog-add-card-icon-container-background;
  border-radius: $kanban-dialog-add-card-icon-container-radius;
  height: $kanban-dialog-add-card-icon-container-height;
  width: $kanban-dialog-add-card-icon-container-width;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// .kanban-dialog-add-card__icon {
//   @extend .hover-basic;
// }

.kanban-dialog-add-card__icon-arrow {
  margin-left: $main-separator-items;
}

.kanban-dialog-add-card__textarea {
  margin-top: $main-separator-items;

  .textarea__input {
    border-radius: $kanban-dialog-add-card-textarea-radius;
    height: $kanban-dialog-add-card-textarea-height;
  }
}

.kanban-dialog-add-card__color-picker {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: $kanban-dialog-add-card-color-picker-margin;
}

.kanban-dialog-add-card__color-wrapper {
  padding: 3px;
}

.kanban-dialog-add-card__color {
  @extend .hover-basic;

  height: $kanban-dialog-add-card-color-size;
  width: $kanban-dialog-add-card-color-size;
  border-radius: $kanban-dialog-add-card-icon-container-radius;
}

.kanban-dialog-add-card__emojis-container {
  position: absolute;
  top: $kanban-dialog-add-card-emojis-top;
  left: $kanban-dialog-add-card-emojis-left;
  z-index: 100;
}

.kanban-dialog-add-card__color--active {
  border: 2px solid $kanban-dialog-add-card-color-active;
  border-radius: $kanban-dialog-add-card-icon-container-radius;
}

.kanban-dialog-add-card__textarea--disabled {
  .textarea__input {
    background: transparent;
    height: auto;
    border: none;
  }
}
