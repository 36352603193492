$banner-resources-polp-max-width: 444px;
$banner-resources-polp-height: 292px;
$banner-resources-polp-font-family: $font-second;
$banner-resources-polp-font-size: 18px;
$banner-resources-polp-font-weigth: 400;
$banner-resources-polp-line-height: 32px;
$banner-resources-polp-text-color: $color-gray-01;
$banner-resources-polp-card-shadow: 0px 1px 2px rgba(112, 107, 101, 0.15);
$banner-resources-polp-card-border-radius: 4px;
$banner-resources-polp-popcorn-image-height: 150px;
$banner-resources-polp-popcorn-image-height-half: 70px;

.banner-resources-polp {
  margin: 100px auto $separator-xxxxxl auto;
  width: $banner-resources-polp-max-width;
  max-width: 90%;
  min-height: $banner-resources-polp-height;
  font-family: $banner-resources-polp-font-family;
}

.banner-resources-polp__content {
  @extend .card-default;

  height: $banner-resources-polp-height;
  width: 100%;
  position: relative;
  box-shadow: $banner-all-resources-card-shadow;
  border: none;
  border-radius: $banner-all-resources-card-border-radius;
  padding: $separator-xxl $separator-xxxxxl;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.banner-resources-polp__image {
  position: absolute;
  top: -$banner-resources-polp-popcorn-image-height-half;
  img {
    height: $banner-resources-polp-popcorn-image-height;
    width: auto;
  }
}

.banner-resources-polp__text {
  margin-top: $banner-resources-polp-popcorn-image-height-half;
  font-size: $banner-resources-polp-font-size;
  color: $banner-resources-polp-text-color;
  font-weight: $banner-resources-polp-font-weigth;
  line-height: $banner-resources-polp-line-height;
  text-align: center;
}
