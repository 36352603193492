$header-month-day-name-color: $color-calendar-day-title;
$header-month-day-name-color-active: $color-first;
$header-month-day-name-margin-bottom: $separator-xxl;

.calendar-big {
  .rbc-date-cell {
    a {
      position: relative;
      top: 1.2px;
      right: 1px;
    }
  }
  .rbc-month-view {
    border: 0;

    .rbc-day-bg:first-child {
      border-left: 1px solid;
    }

    .rbc-day-bg:last-child {
      border-right: 1px solid;
    }

    .rbc-month-row:nth-child(2) {
      .rbc-day-bg {
        border-top: 1px solid;
      }
    }

    .rbc-month-row:last-child {
      .rbc-day-bg {
        border-bottom: 1px solid;
      }
    }
  }

  .header-month__header-month--selected {
    .header-month__header-month-day-name {
      color: $header-month-day-name-color-active;
    }
  }

  .header-month__header-month {
    margin-bottom: $header-month-day-name-margin-bottom;
  }

  .header-month__header-month-day-name {
    @extend .text-small-bold;
    @extend .ellipsis-complex;

    color: $header-month-day-name-color;
  }
}
