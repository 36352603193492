$select-customizable-color: $color-title;
$select-customizable-border-radius: $border-radius-inputs;
$select-customizable-min-width: 100px;
$select-customizable-min-heigt: $size-height-input;
$select-customizable-min-heigt-small: $size-height-input-small;
$select-customizable-option-hover-background: $color-first;
$select-customizable-option-hover-color: $color-white;
$select-customizable-error-separator: $separator-s;

.select-customizable {
  min-width: $select-customizable-min-width;

  /*[class*='Input'] {
    margin: 0;
  }*/

  &:focus,
  &:focus-within,
  &:active {
    .select-customizable__control {
      @extend .text-small;
      @extend .border-default;

      box-shadow: none;
    }
  }

  .select-customizable__control {
    @extend .transition-hover-border;
    @extend .border-default;

    border-radius: $select-customizable-border-radius;
    height: $select-customizable-min-heigt;

    span {
      @extend .ellipsis;
    }
  }

  & [class*='indicatorSeparator'] {
    display: none;
  }

  .select-customizable__menu {
    // min-width: 300px;
  }

  .select-customizable__option {
    @extend .text-small;

    background-color: transparent;
    color: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    // min-height: 40px;

    &:hover {
      cursor: pointer;

      background-color: $select-customizable-option-hover-background;
      color: $select-customizable-option-hover-color;
    }

    & * {
      @extend .text-small;
    }
  }

  .select-customizable__placeholder {
    @extend .placeholder;
  }

  .select-customizable__value-container {
    @extend .ellipsis;

    height: 100%;
    padding-left: $input-padding;
    padding-right: $input-padding;
    //flex-wrap: nowrap;
    > :last-child {
      margin: 0;
    }
  }

  .select-customizable__single-value {
    @extend .text-small;
    @extend .ellipsis;

    color: $select-customizable-color;
    position: relative;

    -webkit-transform: none;
    transform: none;
    top: 0;

    & * {
      @extend .text-small;
    }
  }

  .select-customizable__menu-notice--no-options {
    @extend .text-small;
    & * {
      @extend .text-small;
    }
  }

  .select-customizable__multi-value {
    > div {
      @extend .text-small;
    }
  }

  .select-customizable__group-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .select-customizable__group-badge {
    background-color: #ebecf0;
    border-radius: 2em;
    color: #172b4d;
    display: inline-block;
    font-size: 12;
    font-weight: normal;
    line-height: 1;
    min-width: 1;
    padding: 0.16666666666667em 0.5em;
    text-align: center;
  }

  &:focus {
    outline: none;
  }
}

.select-customizable--size-small {
  .select-customizable__control {
    min-height: $select-customizable-min-heigt-small;
  }
}

.select-customizable__indicator {
  display: flex;
  align-items: center;
  .icon {
    position: relative;
    right: 5px;
  }
}

// .select-customizable__label + .select-customizable {
//   margin-top: $label-input-separator;
// }

.select-customizable__label {
  @extend .label;
  @extend .ellipsis-complex;
}

.select-customizable__error {
  margin-top: $select-customizable-error-separator;
  display: block;
}
