$dialog-add-program-to-class-min-height: 350px;

.dialog-add-program-to-class__container {
  min-height: $dialog-add-program-to-class-min-height;
}

.dialog-add-program-to-class__subtitle {
  @extend .text-small;

  margin-bottom: $separator-m;
  color: $color-gray-02;
}

.dialog-add-program-to-class__separator {
  height: 1px;
  background-color: $color-gray-05;
}

.dialog-add-program-to-class__create-class {
  height: $dialog-add-program-to-class-min-height;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dialog-add-program-to-class__no-classes {
  @extend .title-h4;

  margin-bottom: $separator-l;
  color: $color-gray-01;
}
