.MuiAccordionSummary-root,
.MuiAccordionDetails-root {
  min-height: inherit !important;
  padding: 0 !important;
}

.MuiAccordion-root:before {
  display: none;
}

.MuiButtonBase-root {
  min-height: inherit;
  padding: 0;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiAccordionSummary-root {
  height: 28px !important;
}
