$input-text-border-radius: $border-radius-inputs;
$input-text-height: $size-height-input;
$input-text-color-placeholder: $color-placeholder;
$input-text-background: $color-input-background;
$input-min-height: 40px;

.input-date-v2 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: $input-min-height;

  & .MuiFormControl-root {
    @extend .transition-hover-border;
    @extend .text-small;
    @extend .border-default;

    font-family: $font-second;
    background: $input-text-background;
    border-radius: $input-text-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    & .MuiInputBase-root {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: $color-gray-02;

      & .MuiInputAdornment-root {
        width: auto;
        position: absolute;
        right: $separator-m;
        width: $input-date-icon-width;

        svg {
          fill: $color-gray-04;
        }
      }
    }

    & .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }

  .input-date-v2__starticon {
    position: absolute;
    left: $separator-s;
    svg {
      height: 22px;
      width: 22px;
      padding: 5px;
      border-radius: 50%;
      background-color: #f7f4f3;
    }
  }

  .input-date-v2__label {
    @extend .label;
    margin-bottom: $label-input-separator;
  }

  input {
    padding-left: $separator-xl !important;
  }
}

.input-date-v2--disabled {
  .MuiFormControl-root {
    background-color: #faf8f7;
  }
}

// Mui classes
.MuiPickersDay-root.Mui-selected,
.css-1aquho2-MuiTabs-indicator,
.css-12ha4i7,
.css-7lip4c,
.css-a1rc6s,
.css-118whkv,
.css-a1rc6s {
  background-color: $color-first !important;
}

.MuiSvgIcon-root,
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: $color-first !important;
}

.css-118whkv {
  border-color: $color-first !important;
}

.css-2ujp1m {
  border-color: $color-first !important;
}

.css-3dah0e-MuiModal-root-MuiDialog-root,
.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root,
.css-j5h6pi-MuiPopper-root,
.MuiPickersPopper-root,
.css-1mtsuo7 {
  z-index: $z-index-popup !important;
}

.MuiInputAdornment-positionStart {
  position: absolute;
  left: 2px;
  cursor: pointer;
}
