$answer-test-bar-footer-height: $size-footer-answer-test-bar;
$answer-test-bar-footer-border-radius: $border-radius-bars-test-bar-footer;
$answer-test-bar-footer-padding-horizontal: 26px;
$answer-test-bar-footer-separator-bottom: $main-separator-items;
$answer-test-bar-footer-box-shadows: $box-shadow-02;
$answer-test-bar-footer-title-separator: $main-separator-items;
$answer-test-bar-footer-completed-color: $color-icon-blue2;
$answer-test-bar-footer-completed-background: #f6fafe;
$answer-test-bar-footer-correct-color: $color-success;
$answer-test-bar-footer-correct-background: #f6fdf7;
$answer-test-bar-footer-incorrect-color: $color-error;
$answer-test-bar-footer-incorrect-background: #fff6f7;
$answer-test-bar-footer-feedback-separator: $main-separator-components;
$answer-test-bar-footer-status-bottom: 70px;
$answer-test-bar-footer-status-padding-bottom: 50px;
$answer-test-bar-footer-status-open-max-height: 60vh;
$answer-test-bar-footer-feedback-max-height: 150px;
$answer-test-bar-footer-status-max-height: 36px;
$answer-test-bar-footer-more-button-height: 32px;
$answer-test-bar-footer-more-button-width: 56px;

.answer-test-bar-footer {
  position: relative;
}

.answer-test-bar-footer__content {
  @extend .border-default;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $color-background-test-bar-footer;
  border-radius: $answer-test-bar-footer-border-radius;
  box-shadow: $answer-test-bar-footer-box-shadows;
  height: $answer-test-bar-footer-height;
  padding: 0 $answer-test-bar-footer-padding-horizontal;
  position: relative;
  z-index: 2;
}

.answer-test-bar-footer__right {
  .button {
    margin-left: $answer-test-bar-footer-separator-bottom;
  }

  .button__text {
    padding-left: 0;
  }
}
.answer-test-bar-footer__status-container {
}

.answer-test-bar-footer__status {
  border: $border-size-02 solid $answer-test-bar-footer-completed-color;
  border-top-left-radius: $border-radius-03;
  border-top-right-radius: $border-radius-03;

  position: absolute;
  width: 70%;
  bottom: $answer-test-bar-footer-status-bottom;
  left: 15%;

  padding: 0;
  opacity: 0;

  z-index: 100;
}

.answer-test-bar-footer__status-showed {
  @extend .border-default;

  opacity: 1;
  transition: opacity 1s, max-height 0.6s;

  padding: $separator-xxl;
  padding-bottom: $answer-test-bar-footer-status-padding-bottom;
}

.answer-test-bar-footer__status-showed--completed {
  background: $answer-test-bar-footer-completed-background;
  border-color: $answer-test-bar-footer-completed-color;

  .answer-test-bar-footer__status-title {
    color: $answer-test-bar-footer-completed-color;
  }

  circle {
    fill: $icon-color-blue2;
  }
}

.answer-test-bar-footer__status-showed--correct {
  background: $answer-test-bar-footer-correct-background;
  border-color: $answer-test-bar-footer-correct-color;

  .answer-test-bar-footer__status-title {
    color: $answer-test-bar-footer-correct-color;
  }
}

.answer-test-bar-footer__status-showed--incorrect {
  background: $answer-test-bar-footer-incorrect-background;
  border-color: $answer-test-bar-footer-incorrect-color;
  .answer-test-bar-footer__status-title {
    color: $answer-test-bar-footer-incorrect-color;
  }
}

.answer-test-bar-footer__status-title-container {
  display: flex;
  align-items: center;
}

.answer-test-bar-footer__status-title {
  @extend .title-h3;

  margin-left: $answer-test-bar-footer-title-separator;
}

.answer-test-bar-footer__status-feedback {
  margin-top: $answer-test-bar-footer-feedback-separator;
  max-height: $answer-test-bar-footer-status-max-height;
  overflow: hidden;
  transition: opacity 1s, max-height 0.6s;
}

.answer-test-bar-footer__status-more-button {
  @extend .hover-basic;

  height: $answer-test-bar-footer-more-button-height;
  width: $answer-test-bar-footer-more-button-width;
  background: $answer-test-bar-footer-incorrect-color;

  border-top-left-radius: $border-radius-01;
  border-top-right-radius: $border-radius-01;

  position: absolute;
  top: -18px;
  transform: translate(-50%, -50%);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-test-bar-footer__status--open-large-feedback {
  .answer-test-bar-footer__status-feedback {
    max-height: 40vh;
    overflow: auto;
  }
}

.answer-test-bar-footer__status-feedback-text {
  padding: $separator-xs;
}

@media #{$breakpoints-medium} {
  .answer-test-bar-footer__status--open-large-feedback {
    .answer-test-bar-footer__status-feedback {
      max-height: $answer-test-bar-footer-status-open-max-height;
      overflow: auto;
    }
  }
}
