// Corporate

$color-first: var(--color-first) !default;
$color-first-alpha: var(--color-first-alpha) !default;
$gradient: linear-gradient(111.12deg, var(--color-gradient-1) 0.31%, var(--color-gradient-2) 100%) !default;

// base

$color-white: #ffff !default;
$color-black: #000000 !default;

// Gray

$color-gray-01: #333333 !default;
$color-gray-02: #4e4e4e !default;
$color-gray-03: #828282 !default;
$color-gray-04: #bdbdbd !default;
$color-gray-05: #e0e0e0 !default;
$color-gray-06: #f2f2f2 !default;
$color-gray-07: #f9f9f9 !default;

// background

$color-bg-01: var(--color-bg-01) !default;
$color-bg-02: var(--color-bg-02) !default;
$color-bg-03: var(--color-bg-03) !default;
$color-bg-04: var(--color-bg-04) !default;
$color-bg-05: var(--color-bg-05) !default;

//  status

$color-error: #f66868 !default;
$color-alert: #f2aa3f !default;
$color-success: #40d158 !default;
$color-link: #4991e5 !default;
$color-info: #4991e5 !default;

// secondary

$color-second-yellow-01: #fff3c9 !default;
$color-second-red-01: #fef0ef !default;
$color-second-green-01: $color-success !default;
$color-second-green-02: #ecfaee !default;
$color-second-green-03: #70cf96 !default;
$color-second-green-04: #e9f5ee !default;
$color-second-orange-01: #ffb979 !default;
$color-second-orange-02: #fed0a5 !default;
$color-second-orange-03: #fdf2e2 !default;
$color-second-blue-01: #1e359d !default;
$color-second-blue-02: #56ccf2 !default;
$color-second-blue-03: #dbe9f0 !default;

// charts

$gradient-visual: linear-gradient(135deg, #ffad31 14.75%, #f05432 100%) !default;
$gradient-bluevisual: linear-gradient(137.34deg, #89e2ff 0%, #2f80ed 100%) !default;
$gradient-natural: linear-gradient(135deg, #86e328 15.25%, #00b775 100%) !default;

// colores que hemos ido necesitando sin aparecer en el diseño

$translucid-first: rgba(255, 83, 34, 0.85) !default;
$translucid-black: rgba(0, 0, 0, 0.3) !default;
$translucid-green: rgba(33, 150, 83, 0.3) !default;
$translucid-red: rgba(247, 104, 104, 0.3) !default;

$color-background-cover: rgba(0, 0, 0, 0.25) !default;
