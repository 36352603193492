.level-progress {
  height: 42px;
  width: 100%;
  border-top: 4px solid $color-gray-04;
  border-bottom: 4px solid $color-gray-04;
  border-right: 4px solid $color-gray-04;
  background: $color-gray-01;

  &__bar-complete {
    height: 100%;
    background: #6e30bc;
  }

  &__box-left {
    width: 66px;
    position: relative;
    right: 58px;
  }

  &__box-total {
    position: relative;
    bottom: 58px;
  }

  &__box-right {
    width: 66px;
    position: relative;
    left: 54px;
  }

  &__box-subtitle {
    height: 30px;
  }

  &__text {
    color: #fff;
    text-align: center;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    position: relative;
    bottom: 16px;
  }

  &__subtitle {
    color: $color-gray-03;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
  }

  &__height {
    height: 40px;
    margin: 0 46px 54px 46px;
  }
}
