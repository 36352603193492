$units-accordion-padding: $padding-card-default;
$units-accordion-separator: $separator-l;
$units-accordion-text-color: $color-gray-02;
$units-accordion-move-icon-separator: $separator-l;
$units-accordion-lessons-duration-transition: $transition-duration-01;
$units-accordion-lessons-edit-mode-item-show-height: 40px;
$units-accordion-height-card: 70px;
$units-accordion-card-background: $color-white;
$units-accordion-card-border-radius: $border-radius-01;
$units-accordion-color-title-student-expanded: $color-title;
$units-accordion-heighest-height: 60px;
$units-accordion-card-height: 112px;
$units-accordion-card-border-radius: 16px;

.units-accordion {
  width: 100%;

  > div {
    width: 100%;
  }

  .MuiAccordion-root {
    // @extend .card-background;

    background-color: $units-accordion-card-background;
    box-shadow: 0px 2px 5px rgb(129 102 81 / 15%);
    margin-top: $units-accordion-separator;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    // padding-right: $separator-m !important;
    min-height: $units-accordion-card-height;
    border-radius: $units-accordion-card-border-radius;

    .MuiAccordionSummary-expandIcon {
      display: block !important;
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    height: $units-accordion-card-height;
    align-items: flex-start;
  }

  .MuiAccordion-root.Mui-expanded:first-child {
    margin-top: $units-accordion-separator;
  }

  .units-accordion__children-container {
    // margin-top: $units-accordion-separator;
    width: 100%;

    .no-items-mode-edit {
      width: 100%;
      margin: auto;
      max-width: 430px;
    }
  }
  .MuiAccordionSummary-expandIcon {
    margin-right: $separator-s;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0);
    .MuiIconButton-label {
      transform: rotate(180deg);
    }
  }

  .Mui-expanded {
    .MuiAccordionSummary-root {
      border-bottom: 1px solid #ebe0d7;
    }
    .units-accordion__times-icons {
      display: none;
    }
    .lesson-item__name-cover {
      border-bottom-left-radius: 0;

      img {
        border-bottom-left-radius: 0;
      }
    }
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: $units-accordion-card-border-radius;
    border-bottom-right-radius: $units-accordion-card-border-radius;
  }

  .MuiAccordion-rounded:first-child {
    border-top-left-radius: $units-accordion-card-border-radius;
    border-top-right-radius: $units-accordion-card-border-radius;
  }

  .MuiAccordionDetails-root {
    padding: 0 !important;
  }

  .lesson-item__name-cover {
    width: 140px;
    height: $units-accordion-card-height;
    border-bottom-left-radius: $units-accordion-card-border-radius;
    border-top-left-radius: $units-accordion-card-border-radius;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-bottom-left-radius: $units-accordion-card-border-radius;
      border-top-left-radius: $units-accordion-card-border-radius;
    }
  }

  .units-accordion__name {
    text-transform: uppercase;
  }
}

.units-accordion--student {
  .MuiAccordionSummary-root {
    min-height: $units-accordion-height-card !important;
    padding: $separator-m;
    border-bottom: none !important;
    border-radius: 8px;
  }

  .MuiAccordion-root {
    background: $units-accordion-card-background !important;
    padding-top: $separator-m !important;
    padding-bottom: $separator-m !important;
    min-height: $units-accordion-height-card !important;
  }

  .units-accordion__title-container {
    padding-top: $separator-m !important;
    padding-bottom: $separator-m !important;
  }

  .MuiAccordionDetails-root {
    // padding: 0 !important;
    padding-left: $separator-s !important;
    padding-right: $separator-s !important;
  }

  .MuiAccordion-root {
    @extend .card-secondary;

    margin-bottom: $units-accordion-separator;
    margin-top: 0 !important;
    display: block;
    position: relative;
    padding: $separator-m;

    .MuiAccordionSummary-expandIcon {
      display: block !important;
    }

    .Mui-disabled {
      .MuiAccordionSummary-expandIcon {
        display: none !important;
      }
    }

    .MuiAccordionDetails-root {
      padding: $separator-m !important;
    }

    .units-accordion__times-icons {
      display: none;
    }

    .Mui-expanded {
      .units-accordion__name {
        color: $units-accordion-color-title-student-expanded;
      }

      .icon--chevron-down {
        path {
          fill: $units-accordion-color-title-student-expanded;
        }
      }
    }
  }
}

.units-accordion__done + [class*='units-accordion'] {
  .icon-time-check:before {
    background: $icon-time-check-after-done-background;
  }
  padding: $separator-l;
}

// edit mode
// .units-accordion--edit-mode {
//   margin: 0 !important;
//   > div {
//     > div {
//       margin-top: 0 !important;
//     }
//   }
// }

.units-accordion__add-item {
  border: 0;
  list-style: none;
  padding-bottom: 8px;
  width: 100%;
}

.units-accordion__actions-move {
  display: flex;
}

.units-accordion__actions-move-icon {
  @extend .hover-basic;

  margin-left: $units-accordion-move-icon-separator;
}

.MuiAccordionSummary-root.Mui-focused {
  background: transparent !important;
}

.units-accordion__name-input {
  width: 100%;

  .input-text__input {
    @extend .title-h5;

    color: $units-accordion-text-color;
    background: transparent;
    border: 0;
    width: 100%;
    padding: 0;
  }
}

.units-accordion__item-left {
  margin-top: $separator-xxxl;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.units-accordion__visibility {
  @extend .text-smallest;

  color: $color-gray-03;
  margin-right: $main-separator-items;
}

// .units-accordion__lessons-length {
//   @extend .text-smallest;

//   color: $color-gray-03;
// }

.units-accordion__container {
  width: 100%;
}

.units-accordion__title-container {
  height: 112px;
}

.units-accordion__name--edit-mode {
  border-bottom: 1px dotted;
}

.lesson-item__name-text-container {
  margin-left: $separator-m;
  margin-top: $separator-m;
}

.lesson-item__name-container {
  display: flex;
  align-items: flex-start;

  // flex-direction: column;
}

.units-accordion__visibility-container {
  display: flex;
  align-items: flex-start;
  margin-top: $main-separator-items;

  .icon {
    margin-right: $main-separator-items;
  }
}

.units-accordion__heighest {
  .MuiAccordionSummary-root {
    height: $units-accordion-heighest-height !important;
  }
}

.units-accordion__empty-lessons {
  display: flex;
  flex-direction: column;
}

.units-accordion__empty-lessons-title {
  @extend .title-h6;

  color: $color-gray-02;
}

.units-accordion__empty-lessons-text {
  @extend .text-small;

  color: $color-gray-02;
  margin-top: $main-separator-items;
}

.units-accordion__add-item {
  list-style: none;

  .button {
    padding-left: 0;
  }
}

.units-accordion__restore-dialog-message-attention {
  @extend .title-h6;

  color: $color-white;
  padding: $separator-m $separator-m;
  background-color: $color-error;
  margin-top: $separator-l;
}
