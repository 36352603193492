.tree-view {
  .MuiDialog-paper {
    height: 90%;
  }

  .input-checkbox {
    width: inherit;
  }
}

.tree-view__search {
  margin: 0 0 22px 0;
}
