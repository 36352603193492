$layout-progress-image-height: $size-layout-progress-header-height;
$layout-progress-image-height-mobile: $size-layout-progress-header-height-mobile;
$layouding: $main-padding-page;
$layout-progress-icon-right-top: 30px;
$layout-progress-icon-right-right: 30px;
$layout-progress-background: $color-background;
$layout-progress-background-white: $color-white;
$layout-progress-footer-background: $color-layout-progress;
$layout-progress-footer-box-shadow: $box-shadow-03;
$layout-progress-footer-height: $size-layout-progress-footer-height;
$layout-progress-footer-skip-margin: $separator-xxxxl;
$layout-progress-header-logo-width: $size-layout-progress-header-logo-width;
$layout-progress-steps-color: $color-white;
$layout-progress-steps-separator: $separator-xxxxxl;

.layout-progress {
  background: $layout-progress-background;
  height: 100vh;
  position: relative;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .progress-bar__bar {
    border-radius: 0;
  }
}

.layout-progress--background-white {
  background: $layout-progress-background-white;
}

.layout-progress__header-logo {
  position: absolute;
  left: $layout-progress-icon-right-right;
  top: $layout-progress-icon-right-top;
  width: $layout-progress-header-logo-width;
  z-index: $z-index-header-elements;
}

.layout-progress__image {
  height: $layout-progress-image-height;
  width: 100%;
  position: relative;
}

.layout-progress__children {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-x: hidden;
}

.layout-progress__children-container {
  position: relative;
  min-height: 100%;
  position: relative;
  min-height: 100%;
  overflow: auto;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.layout-progress__icon-right {
  @extend .hover-basic;

  position: absolute;
  right: $layout-progress-icon-right-right;
  top: $layout-progress-icon-right-top;
  z-index: $z-index-header-elements;

  display: flex;
  align-items: center;
}

.layout-progress__steps {
  @extend .title-h6;

  color: $layout-progress-steps-color;
  margin-right: $layout-progress-steps-separator;
}

.layout-progress__footer-container {
  background: $layout-progress-footer-background;
  box-shadow: $layout-progress-footer-box-shadow;
  height: $layout-progress-footer-height;
  bottom: 0;
}

.layout-progress__footer {
  @extend .grid;

  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    z-index: $z-index-header-elements;
  }
}

.layout-progress__footer__button--inline {
  display: flex;
  align-items: center;
}

.layout-progress__footer__skip-button {
  margin-right: $layout-progress-footer-skip-margin;
}
