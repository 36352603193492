$content-detail-form-input-separator: $main-separator-components;
$content-detail-form-checkbox-input-color: $color-title;

.content-detail-form__field {
  margin-top: $content-detail-form-input-separator;

  .select-checkboxs .picky__input {
    color: $content-detail-form-checkbox-input-color;
  }
}
