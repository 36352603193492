$header-lesson-separator: $main-separator-components;
$header-lesson-padding: $main-padding-page;
$header-lesson-background: $color-header-lesson;
$header-lesson-color-metadata: $color-label2;
$header-lesson-separator-main: $main-separator-components;
$header-lesson-separator-icons: $main-separator-items;
$header-lesson-separator-simple: $main-separator-items;
$header-course-border: $border-headers;
$header-lesson-student-height: 190px;
$header-lesson-student-text-color: $color-white;

.header-lesson {
  background: $header-lesson-background;
  border-bottom: $header-course-border;
  padding: $header-lesson-padding 0;

  .header-tabs__tab:first-child {
    margin-left: 0;
  }
}

.header-lesson--student {
  height: $header-lesson-student-height;

  .header-lesson__container {
    @extend .padding-default;

    display: flex;
  }
}

.header-lesson__container {
  position: relative;
  width: 100%;
}

.header-lesson__container-header {
  @extend .margin-default;

  display: flex;
  align-items: baseline;
}

.header-lesson__info {
  display: block;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;
  margin-bottom: $header-lesson-separator;
  margin-left: $header-lesson-separator;
}

.header-lesson__info-title-unit {
  display: flex;
  align-items: center;

  .icon {
    flex: 0 0 auto;
  }
}

.header-lesson__info-title-text-unit {
  @extend .title-h5;

  margin-left: $header-lesson-separator-icons;
}

.header-lesson__info-title-text-lesson {
  @extend .title-h3;
  @include ellipsis-line(1);

  // color: $header-lesson-student-text-color;
}

.header-lesson__title-container {
  @extend .grid;

  height: 100%;
  // width: 100%;

  display: flex;
  align-items: flex-end;
}

.header-lesson__title-text {
  @extend .title-h1;
  @include ellipsis-line(1);

  color: $header-lesson-student-text-color;
}

.header-lesson__subinfo {
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  margin-top: $header-lesson-separator-simple;
}

.header-lesson__actions {
  @extend .grid;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header-lesson__actions-buttons {
  display: flex;
}

.header-lesson__meta-info {
  display: flex;
  align-items: center;

  svg {
    flex: 0 0 auto;
    display: none;
  }
}

.header-lesson__meta-info-text {
  @extend .text-small;

  color: $header-lesson-color-metadata;
  margin-left: 0;
  margin-right: $header-lesson-separator-main;
  flex: 0 0 auto;
}

.header-lesson__create-link {
  @extend .hover-basic;

  display: flex;
  align-items: center;

  margin-right: $header-lesson-separator-main;
}

.header-lesson__create-link-text {
  @extend .text-small-bold;
  @extend .text-link;

  display: none;
  margin-right: $header-lesson-separator-main;
  margin-left: $header-lesson-separator-icons;
}

.header-lesson__back {
  // position: absolute;
  // left: -30px;
  // top: 0;
}

@media #{$breakpoints-large} {
  .header-lesson__create-link {
    margin-right: 0;
    .button__content {
      .icon {
        margin-right: $main-separator-items !important;
      }
    }
  }

  .header-lesson__create-link-text {
    display: block;
  }

  .header-lesson {
    .button__text {
      display: flex !important;
    }
  }

  .header-lesson__info {
    display: flex;
  }

  .header-lesson__subinfo {
    margin-top: 0;
  }

  .header-lesson__meta-info {
    svg {
      display: block;
    }
  }

  .header-lesson__meta-info-text {
    margin-left: $header-lesson-separator-icons;
  }
}

.header-lesson--student {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;

  .header-lesson__info-title-text {
    color: $header-lesson-student-text-color;
  }

  .header-lesson__info-title-text {
    margin-left: 0;
  }

  .header-lesson__title-container {
    @extend .grid;

    height: 100%;
    // width: 100%;

    display: flex;
    align-items: flex-end;
  }

  .header-lesson__title-text {
    @extend .title-h1;
    @include ellipsis-line(1);

    color: $header-lesson-student-text-color;
  }
}
