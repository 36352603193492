$dialog-onboarding__navigation-dot-size: 15px;
$dialog-onboarding__navigation-dot-color: #d9d9d9;
$dialog-onboarding__navigation-dot-color-active: $color-first;
$dialog-onboarding__container-max-width: 600px;
$dialog-onboarding__pic-width: 600px;
$dialog-onboarding__pic-height: 340px;
$dialog-onboarding__pic-radius: 24px;
$dialog-onboarding__pic-bg: #f0f0f0;
$dialog-onboarding__description-color: #4e4e4e;
$dialog-onboarding__description-size: 16px;
$dialog-onboarding__description-line-height: 25px;
$dialog-onboarding__description-font: $font-second;
$dialog-onboarding__description-weigth: 400;

.dialog-onboarding {
  .MuiPaper-rounded {
    border-radius: 8px;
  }
  .dialog-onboarding__container {
    padding: $separator-m;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: $dialog-onboarding__container-max-width;
  }
  .dialog-onboarding__navigation {
    display: flex;
    justify-content: center;
    margin-bottom: $separator-xl;
  }
  .dialog-onboarding__navigation-dot {
    height: $dialog-onboarding__navigation-dot-size;
    width: $dialog-onboarding__navigation-dot-size;
    background-color: $dialog-onboarding__navigation-dot-color;
    border-radius: 50%;
    margin-right: $separator-s;
    cursor: pointer;
    &--active {
      background-color: $color-first;
    }
  }
  .dialog-onboarding__pic {
    margin: 0 auto;
    margin-bottom: $separator-m;
    width: $dialog-onboarding__pic-width;
    height: $dialog-onboarding__pic-height;
    border-radius: $dialog-onboarding__pic-radius;
    background-color: #f0f0f0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $dialog-onboarding__pic-radius;
    }
  }

  .dialog-onboarding__title {
    @extend .title-h5;
    margin-bottom: $separator-m;
  }

  .dialog-onboarding__description {
    color: $dialog-onboarding__description-color;
    font-size: $dialog-onboarding__description-size;
    line-height: $dialog-onboarding__description-line-height;
    font-family: $dialog-onboarding__description-font;
    font-weight: $dialog-onboarding__description-weigth;
  }

  .footer-buttons {
    width: 100%;
    justify-content: space-between;
  }
}

.dialog-onboarding--last-step {
  .footer-buttons {
    width: 100%;
    justify-content: center;
  }
}
