  $horizontal-students-progress-padding: 90px $separator-xxxxl 40px;

  .horizontal-students-progress {
    @extend .card-secondary;
    @extend .border-default;

    padding: $horizontal-students-progress-padding !important;
  }

  .horizontal-students-progress__wrapper {
    width: 100%;
    position: relative;
  }

  .horizontal-students-progress__wrapper {
    width: 100%;
    position: relative;
  }

  .horizontal-students-progress__item {
    position: absolute;
    top: -45px;

    &:hover {
      .horizontal-students-progress__item-hover {
        display: inline-block;
      }
    }

    .horizontal-students-progress__item-hover {
      display: none;
      position: absolute;
      top: -35px;
      width: max-content;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      background: $color-gray-03;
      padding: 6px;
      border-radius: $border-radius-01;
      font-size: 12px;

      &:after {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $color-gray-03;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
      }
    }
  }

  .horizontal-students-progress__bar {
    border-top: 4px solid $color-gray-05;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    span {
      font-weight: 700;

      color: $color-gray-01;

      &:first-child {
        color: $color-gray-03
      }

      &:last-child {
        color: $color-gray-03
      }
    }
  }
