$template-card-border-radius: $border-radius-02;
$template-card-info-padding: $separator-l;
$template-card-image-height: $size-template-card-image-height;
$template-card-title-selected: $color-first;
$template-card-description-text-color: $color-white;
$template-card-title-text-color: $color-gray-03;

.template-card {
  cursor: pointer;
  border-radius: $template-card-border-radius;
  display: flex;
  flex-direction: column;
}

.template-card__image {
  position: relative;
  order: 1;
  height: auto;

  &:hover {
    .template-card__description {
      opacity: 1 !important;
    }
  }
}

.template-card__background-image {
  background-size: cover;
  background-position: center;

  border-top-left-radius: $template-card-border-radius;
  border-top-right-radius: $template-card-border-radius;

  height: 100%;
  width: 100%;

  display: none;
}

.template-card__description {
  @extend .text-smallest;

  border-top-left-radius: $template-card-border-radius;
  border-top-right-radius: $template-card-border-radius;

  padding: $separator-l;
  transition: 0.4s;
  width: 100%;
  position: relative;
  opacity: 1;
  background-color: transparent;
  color: inherit;
  height: auto;
  padding-top: 0;
}

.template-card__info {
  display: flex;
  justify-content: space-between;
  padding: $template-card-info-padding;
  padding-bottom: $separator-s;

  .input-radio {
    width: auto;
    opacity: 0;
  }
}

.template-card__title {
  @extend .text-smallest;

  color: $template-card-title-text-color;
}

.template-card--default {
  @extend .border-light;
}

.template-card--selected {
  @extend .border-primary-thin;

  .template-card__title {
    color: $template-card-title-selected;
  }
}

@media #{$breakpoints-medium} {
  .template-card__info {
    padding-bottom: $template-card-info-padding;
    .input-radio {
      opacity: 1;
    }
  }

  .template-card__image {
    order: 0;
    height: $template-card-image-height;
  }
  .template-card__background-image {
    display: block;
  }
  .template-card__description {
    opacity: 0;
    background-color: $translucid-first;
    color: $template-card-description-text-color;
    height: 100%;
    position: absolute;
    padding: $separator-l;
  }
}
