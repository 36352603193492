$border-radius-button: $border-radius-01 !default;
$border-radius-big-button: $border-radius-06 !default;
$border-radius-book: $border-radius-01 !default;
$border-radius-big-size: $border-radius-05 !default;
$border-radius-inputs: $border-radius-01 !default;
$border-radius-textarea: $border-radius-01 !default;
$border-radius-toasts: $border-radius-01 !default;
$border-radius-dropdown: $border-radius-01 !default;
$border-radius-list-complex-li: $border-radius-01 !default;
$border-radius-progress-bar: $border-radius-02 !default;
$border-radius-avatar: 100% !default;
$border-radius-icon-bubble: 100% !default;
$border-radius-icon-bubble-square: $border-radius-01 !default;
$border-radius-calendar-toolbar-actions: $border-radius-01 !default;
$border-radius-tag: $border-radius-04 !default;
$border-radius-select-bubble: $border-radius-04 !default;
$border-headers: $border-size-02 solid $color-borders !default;
$border-footers: $border-size-01 solid $color-borders !default;
$border-radius-bars-vertical-users-graph: $border-radius-02 !default;
$border-radius-bars-test-bar-footer: 100px !default;
