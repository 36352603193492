$dialog-feed-back: $size-dialog-xxl-max-width;
$dialog-feed-back-margin: $main-separator-items;
$dialog-feed-back-selected-color: $color-first;
$dialog-feed-back-title-color-good: $color-second-green-03;
$dialog-feed-back-title-color-bad: $color-second-orange-01;
$dialog-feed-back-title-separator-left: $main-separator-items;

.dialog-feed-back {
  .MuiPaper-root {
    max-width: $dialog-feed-back;
  }
}

.dialog-feed-back__container {
  display: flex;
  justify-content: space-between;

  .dialog-feed-back__textarea-container:first-child {
    margin-right: $dialog-feed-back-margin;
  }

  .dialog-feed-back__textarea-container:last-child {
    margin-left: $dialog-feed-back-margin;
  }
}

.dialog-feed-back__textarea-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  left: 6px;
  top: 4px;
}

.dialog-feed-back__textarea-header__left {
  display: flex;
  align-items: center;
}

.dialog-feed-back__textarea-title {
  @extend .title-h6;

  margin-left: $dialog-feed-back-title-separator-left;
}

.dialog-feed-back__textarea-container {
  width: 100%;
}

.dialog-feed-back__textarea-container--good {
  .dialog-feed-back__textarea-title {
    color: $dialog-feed-back-title-color-good;
  }

  textarea {
    border: $border-size-02 $dialog-feed-back-title-color-good solid;
    height: 200px;

    &:hover {
      border-color: $dialog-feed-back-title-color-good;
    }
  }
}

.dialog-feed-back__textarea-container--bad {
  .dialog-feed-back__textarea-title {
    color: $dialog-feed-back-title-color-bad;
  }

  textarea {
    border: $border-size-02 $dialog-feed-back-title-color-bad solid;
    height: 200px;

    &:hover {
      border-color: $dialog-feed-back-title-color-bad;
    }
  }
}
