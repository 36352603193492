$recover-password-title-margin: $separator-xxxl;
$recover-password-title-color: $color-gray-02;

.recover-password__title {
  @extend .title-h4;

  margin-bottom: $recover-password-title-margin;
  color: $recover-password-title-color;
}

.recover-password__new-password,
.recover-password__new-password-confirm {
  margin-bottom: $recover-password-title-margin;
}

.recover-password__save {
  display: flex;

  .button {
    width: auto;
    padding: 0 $recover-password-title-margin;
  }
}

.recover-password__success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password__success-text {
  margin-bottom: $separator-xxxxxxl;
}

.recover-password__login {
  margin-right: auto;
}
