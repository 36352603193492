$task-type-height: 50px;
$task-type-left-padding: $separator-l;
$task-type-right-padding: $separator-xxl;
$task-type-name-padding: $separator-m;
$task-type-active-radius: $border-radius-05;
$task-type-active-color: $color-white;
$task-type-amount-color: $color-gray-04;
$task-type-name-active-color: $color-first;

.task-type {
  @extend .hover-basic;

  height: $task-type-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-type__left {
  display: flex;
  justify-content: center;
  padding-left: $task-type-left-padding;
}

.task-type__right {
  padding-right: $task-type-right-padding;
}

.task-type__name {
  @extend .title-h6;
  padding-left: $task-type-name-padding;
}

.task-type__amount {
  @extend .text-small;

  color: $task-type-amount-color;
}

.task-type--active {
  background: $task-type-active-color;
  border-radius: $task-type-active-radius 0 0 $task-type-active-radius;

  .task-type__name {
    color: $task-type-name-active-color;
  }
}
