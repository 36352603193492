$post-card-separator-main: $main-separator-components;
$post-card-separator-secondary: $main-separator-items;
$post-card-text-color: $color-title;
$post-card-icon-text-color: $color-label;
$post-card-transition-durantion: $transition-duration-01;
$post-card-border-radius-big: $border-radius-big-size;
$post-card-content-bubble-border-radius: $border-radius-04;
$post-card-preview-max-height: 400px;

.post-card {
  @extend .card-secondary;

  .message-sender-simple {
    margin-left: -#{$separator-l - $border-size-02};
    margin-bottom: -#{$separator-l + $border-size-02};
    width: calc(100% + #{$separator-l - $border-size-02} * 2);
  }

  .preview-content__type-with-icon {
    width: 100%;
    margin: 0;
  }

  .preview-content__audio {
    width: 100%;
    margin: 0;
  }

  .icon-bubble--size-medium {
    border-radius: $post-card-content-bubble-border-radius;
  }
}

.post-card--border-radius-big {
  border-radius: $post-card-border-radius-big;
}

.post-card__header,
.post-card__texts {
  margin-bottom: $post-card-separator-secondary;
}

.post-card__header {
  display: flex;
  justify-content: space-between;
}

.post-card__header-info {
  display: flex;
}

.post-card__header-info-text {
  margin-left: $post-card-separator-secondary;
}

.post-card__header-info-text-user,
.post-card__header-info-text-item {
  @extend .text-small-bold;

  color: $post-card-text-color;
}

.post-card__header-info-text-action {
  @extend .text-small;

  color: $post-card-text-color;
}

.post-card__header-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.post-card__header-action-options {
  @extend .hover-basic;

  height: 30px;
  border-radius: 100%;
  padding: 2px;

  &:hover {
    background: $color-gray-07;
  }
}

.post-card__header-date {
  @extend .text-extra;
}

.post-card__texts {
  display: flex;
  flex-direction: column;
}

.post-card__title {
  @extend .title-h5;

  color: $post-card-text-color;
  margin-bottom: $post-card-separator-secondary;
}

.post-card__text {
  @extend .text-p;

  color: $post-card-text-color;
  margin-bottom: $post-card-separator-secondary;
}

.post-card__meta-info {
  display: flex;
  justify-content: space-between;
}

.post-card__meta-info-icons {
  display: flex;
}

.post-card__meta-info-icon {
  @extend .hover-basic;

  display: flex;
  align-items: center;
}

.post-card__meta-info-icon-text {
  @extend .text-smallest;

  color: $post-card-icon-text-color;
  margin-right: $post-card-separator-main;
  margin-left: $post-card-separator-secondary;
}

.post-card__comments {
  transition-duration: $post-card-transition-durantion;
  transform: scaleY(0);
  transform-origin: top;
}

.post-card__comments--show {
  transform: scaleY(1);
  margin-top: $post-card-separator-main;
}

.post-card__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-card__preview-content {
  width: 100%;

  .preview-content__img,
  video {
    margin: auto;
    max-height: $post-card-preview-max-height;
  }
}
