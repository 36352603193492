$sidebar-tabs-option-height: 50px;
$sidebar-tabs-option-font-size: 18px;
$sidebar-tabs-option-text-color: $color-gray-03;
$sidebar-tabs-option-active-font-weight: 700;
$sidebar-tabs-option-active-text-color: $color-first;
$sidebar-tabs-option-active-bg-color: $color-white;
$sidebar-tabs-option-border-radius: 4px 0px 0px 4px;
$sidebar-tabs-option-padding: $separator-s $separator-l;

.sidebar-tabs {
  display: flex;
  flex-direction: column;
}

.sidebar-tabs__option {
  @extend .hover-basic;

  display: flex;
  align-items: center;

  height: $sidebar-tabs-option-height;
  border-radius: $sidebar-tabs-option-border-radius;
  color: $sidebar-tabs-option-text-color;
  font-size: $sidebar-tabs-option-font-size;
  padding: $sidebar-tabs-option-padding;
  width: 100%;

  &--active {
    color: $sidebar-tabs-option-active-text-color;
    font-weight: $sidebar-tabs-option-active-font-weight;
    background-color: $sidebar-tabs-option-active-bg-color;
  }
}
