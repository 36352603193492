$header-simple-height: $size-height-header;
$header-simple-border: $border-headers;
$header-simple-text-space: $main-separator-items;
$header-simple-logo-width: 130px;
$header-simple-title-color: $color-title;
$header-simple-white: $color-white;

.header-simple {
  width: 100%;
}

.header-simple__bar {
  height: $header-simple-height;
  width: 100%;

  display: flex;
  align-items: center;
}

.header-simple__logo {
  min-width: $header-simple-logo-width;
}

.header-simple--background-white {
  background: $header-simple-white;
  border-bottom: $header-simple-border;
}

.header-simple__grid {
  @extend .padding-default;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.header-simple__left {
  display: flex;
  align-items: center;
}

.header-simple__title {
  @extend .title-h2;
  @extend .ellipsis-complex;

  color: $header-simple-title-color;
  margin-bottom: $header-simple-text-space;
  width: 70%;
}

.header-simple__custom-center {
  margin-left: $separator-xl;
}
