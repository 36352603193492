.dialog-activity-detail {
  .answer-test-view__footer-bar {
    position: relative;
    transform: none;
    left: auto;
    top: auto;
    bottom: auto;

    display: flex;
    justify-content: flex-end;
    width: 100%;

    z-index: 1;
  }

  .answer-test-bar-footer__content {
    box-shadow: none;
    border: 0;
    padding: 0;
    background: transparent;

    margin-top: $main-separator-items;
  }

  .answer-test-bar-footer__status-showed {
    display: none;
  }
}

.dialog-activity-detail__assesment {
  @extend .assessment-instructions-view__top !optional;
}

.dialog-activity-detail__share {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: $main-separator-components;
}

.dialog-activity-detail--type-audiorecorder {
  .solution-corrective-feedback__show-solution {
    display: none;
  }
}
