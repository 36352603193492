$avatars-list-count-bubble-size-small: $size-avatar-small;
$avatars-list-count-bubble-size-defaut: $size-avatar-default;
$avatars-list-count-bubble-text-color: $color-first;
$avatars-list-count-bubble-background: $color-white;
$avatars-list-count-bubble-move: 16%;
$avatars-list-score-separator: $main-separator-items;
$avatars-list-score-color: $color-label;

.avatars-list {
  @extend .hover-basic;

  .horizontal-students-result__student {
    position: relative;
    .horizontal-students-result__icon {
      margin-left: 0;
    }
  }
}

.avatars-list--align-right {
  .avatars-list__avatars-container {
    position: relative;
    left: 0;
  }
}

.avatars-list__score {
  @extend .text-xs;

  color: $avatars-list-score-color;
  margin-top: $avatars-list-score-separator;
}

.avatars-list__avatars-container {
  display: flex;
  justify-content: center;
}

.avatars-list__avatars {
  display: flex;
}

.avatars-list__avatars--2 {
  .horizontal-students-result__student:nth-child(1) {
    left: $avatars-list-count-bubble-move;
  }
  .horizontal-students-result__student:nth-child(2) {
    left: -$avatars-list-count-bubble-move;
  }
}

.avatars-list__avatars--more {
  .horizontal-students-result__student:nth-child(1) {
    left: $avatars-list-count-bubble-move;
  }
  .avatars-list__count-bubble {
    left: -$avatars-list-count-bubble-move;
  }
}

.avatars-list__count-bubble {
  @extend .border-default;

  border-radius: 100%;
  background: $avatars-list-count-bubble-background;
  height: $avatars-list-count-bubble-size-small;
  width: $avatars-list-count-bubble-size-small;
  position: relative;
}

.avatars-list--size-default {
  .avatars-list__count-bubble {
    height: $avatars-list-count-bubble-size-defaut;
    width: $avatars-list-count-bubble-size-defaut;
  }
}

.avatars-list__count-bubble-text {
  @extend .text-small;

  color: $avatars-list-count-bubble-text-color;
  white-space: nowrap;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatars-list__arrow {
  position: absolute;
  top: 90%;
}

.avatars-list--opacity {
  opacity: 0.4;
}
