$header-course-height: $size-height-header;
$header-course-background: $color-header-course;
$header-course-border: $border-headers;
$header-course-name-margin-left: $separator-m;
$header-course-exit-margin: $separator-xxxxl;
$header-course-exit-color: $color-gray-03;
$header-course-student-version: $color-gray-02;

.header-course {
  background: $header-course-background;
  border-bottom: $header-course-border;
  height: $header-course-height;

  display: flex;
  align-items: center;
  position: relative;
}

.header-course__grid {
  @extend .padding-default;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.header-left,
.header-right {
  width: 30%;
}

.header-center {
  flex: 1 1 auto;

  width: 40%;
}

.header-right {
  display: flex;
  justify-content: flex-end;
}

.header-course__back-icon {
  @extend .hover-basic;
}

.header-course__name {
  @extend .title-h6;
  @include ellipsis-line(2);
  max-width: 90px;
  margin-left: $header-course-name-margin-left;
}

.header-course__student-mode-right {
  display: flex;
  align-items: center;
}

.header-course-student-mode-exit {
  @extend .hover-basic;
  @extend .text-button;

  color: $header-course-exit-color;
  margin-right: $header-course-exit-margin;
}

.header-course__student-version {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-course__student-version-text {
  @extend .title-h6;

  margin-left: $header-course-name-margin-left;
  color: $header-course-student-version;
}

.header-course__projection-close {
  @extend .hover-basic;
}

.header-course__back-container {
  display: flex;
  align-items: center;
}

// Responsive

.header-course {
  .header-course-selector__item--bubble {
    display: none;
  }

  //   .header-course-selector__item--name,
  //   .header-course-selector__item--share,
  //   .header-button-right__item {
  //     display: none;
  //   }

  //   .header-button-right {
  //     .header-button-right__item:last-child {
  //       display: block;
  //     }
  //   }
}

@media #{$breakpoints-medium} {
  .header-course__name {
    @include ellipsis-line(2);
    max-width: 100%;
  }
}

@media #{$breakpoints-large} {
  .header-course {
    .header-course-selector__item--bubble {
      display: block;
    }

    .header-course-selector__item--name,
    .header-course-selector__item--share,
    .header-button-right__item {
      display: block;
    }
  }
}
