$input-text-border-radius: $border-radius-inputs;
$input-text-height: $size-height-input;
$input-text-padding: $input-padding;
$input-text-color-placeholder: $color-placeholder;
$input-text-color-placeholder-font-family: $font-second;
$input-text-background: $color-input-background;
$input-text-grey-background: $color-gray-07;
$input-text-error-spaces: $main-separator-items;

.input-text {
  display: flex;
  flex-direction: column;
}

.input-text--icon {
  .input-text__input-container {
    input {
      padding-right: calc(#{$size-icons-big} + #{$separator-m});
    }
  }
}

.input-text--focus {
  input {
    border: 1px solid #ed7011;
    box-shadow: 0px 3px 2px 0px #a39b9333 inset;
  }
}

.input-text--error {
  input {
    @extend .border-errors;
  }
}

.input-text__label {
  @extend .label;

  margin-bottom: $label-input-separator;
}

.input-text--background-grey {
  .input-text__input {
    background: $input-text-grey-background;
  }
}

.input-text__input-container {
  position: relative;
  width: 100%;
}

.input-text__input {
  @extend .transition-hover-border;
  @extend .text-small;
  @extend .border-default;

  background: $input-text-background;
  border-radius: $input-text-border-radius;
  height: $input-text-height;
  padding: $input-text-padding;
  width: 100%;

  &::placeholder {
    color: $input-text-color-placeholder;
  }
}

.input-text__icon {
  display: inline-flex;

  position: absolute;
  right: $separator-m;
  transform: translateY(-50%);
  top: 50%;
}

.input-text--icon-left {
  .input-text__input {
    padding-left: calc(#{$size-icons-big} + #{$separator-l});
    padding-right: $input-text-padding !important;
  }
  .input-text__icon {
    left: $input-text-padding;
    right: initial;
  }
}

.input-text--icon-click {
  @extend .hover-basic;
}

.input-text__error {
  margin-top: $input-text-error-spaces;
  color: $color-error;
}
