$header-course-class-entry-max-width: 125px;

.header-program__back-container {
  display: flex;
  align-items: center;
}

.header-course__course-picture {
  display: none;
}

.header-program__info {
  display: flex;
  align-items: center;
  margin-left: $separator-m;
}

.header-course__classes {
  display: flex;
  align-items: center;
}

.header-course__classes-list {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.header-course__class-entry {
  @extend .text-small-bold;

  max-width: $header-course-class-entry-max-width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  margin-right: $separator-xs;
  padding: $separator-s $separator-m;
  border: 1px solid $color-gray-05;
  border-radius: $border-radius-07;
  color: $color-gray-02;
}

.header-course__add-to-class {
  flex: 1 0 auto;
}

.header-course__add-to-class-icon {
  @extend .hover-basic;
}

.dialog-replace-editorial-course {
  b,
  strong {
    font-weight: bold;
  }
}
.dialog-replace-editorial-course__text {
  @extend .text-p;
}

@media #{$breakpoints-large} {
  .header-course__course-picture {
    display: block;
  }
  .header-course__add-to-class-icon {
    margin-left: $separator-l;
  }
}
