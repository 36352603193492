$input-time-ranges-min-width: 70px;
$input-time-ranges-separator: $main-separator-items;
$input-time-ranges-label-separator: $label-input-separator;

$input-time-range-separator-background: $color-gray-06;
$input-time-range-separator-height: 1px;
$input-time-range-separator-width: 12px;

.input-time-range__label {
  @extend .label;
}

.input-time-range {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1 1 auto;

  color: $color-gray-03;
  min-width: $input-time-ranges-min-width;
}

.input-time-range--error {
  .input-time-range__input {
    @extend .border-errors;
  }
}

.input-time-range__input {
  @extend .text-smallest;
  @extend .input-text__input;

  display: flex;
  min-width: $input-time-ranges-min-width;
}

// .input-time-range__label + .input-time-range {
//   margin-top: $input-time-ranges-label-separator;
// }

.input-time-range__separator {
  height: $input-time-range-separator-height;
  width: $input-time-range-separator-width;
  background: $input-time-range-separator-background;
  margin: 0 $main-separator-items 0 $main-separator-items;
}
