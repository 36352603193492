.gamified-toast {
  .react-toast-notifications__toast--success {
    border-radius: 20px;
    border: 1px solid #eee4dc;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    width: 487px !important;
    padding: 16px;
  }

  &__title {
    color: $color-gray-01;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  &__subtitle {
    color: $color-gray-03;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
}
