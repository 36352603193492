$behaviour-item-date-spaces: $separator-m;
$behaviour-item-info-margin: $separator-l;
$behaviour-item-user-color: $color-title;
$behaviour-item-date-color: $color-label;
$behaviour-item-text-color: $color-title;
$behaviour-item-comment-separator: $main-separator-items;
$behaviour-item-date-separator: $main-separator-items;

.behaviour-item {
  display: flex;
  flex-direction: column;
  padding: $padding-card-default-small;

  .item-complex-list,
  .item-complex-list__left,
  .item-complex-list__center {
    width: 100%;
  }
}

.behaviour-item__info-main {
  display: flex;
  flex-direction: column;
  margin-left: $behaviour-item-info-margin;
}

.behaviour-item__info-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.behaviour-item__name {
  @extend .text-small-bold;

  color: $behaviour-item-user-color;
}

.behaviour-item__comment {
  @extend .text-smallest;

  color: $behaviour-item-text-color;
  display: block;
  margin-top: $behaviour-item-comment-separator;
}

.behaviour-item__date {
  @extend .text-smallest;

  color: $behaviour-item-date-color;
  margin-right: $behaviour-item-date-separator;
}

.behaviour-item__options {
  display: flex;
  align-items: center;
}

.behaviour-item__delete {
  @extend .hover-basic;
}
