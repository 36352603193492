$notifications-todo-item-title-color: $color-title;
$notifications-todo-item-unit-color: $color-label;
$notifications-todo-item-detail-color: $color-label;

$notifications-todo-item-course-separator: $main-separator-items;
$notifications-todo-item-hover-background: $color-gray-07;
$notifications-todo-item-disabled-color: $color-gray-04;

$notifications-todo-item-one-day-color: $color-error;
$notifications-todo-item-teacher-action-color: $color-first;
$notifications-todo-item-student-color: $color-gray-02;
$notifications-todo-item-student-turned-color: $color-gray-03;
$notifications-todo-item-notification-consumption-tag-background: $color-gray-05;
$notifications-todo-item-notification-consumption-tag-color: $color-white;
.notifications-todo-item {
  @extend .hover-basic;

  border: 0;

  &:hover {
    background: $notifications-todo-item-hover-background;
  }

  .item-complex-list {
    .item-complex-list__left {
      .item-complex-list__left-children {
        width: auto;
      }
    }

    .item-complex-list__center {
      margin-left: $separator-s;
    }
  }
}

.notifications-todo-item__center {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: $separator-xs;
}

.notifications-todo-item__title {
  @extend .text-extra;
  @extend .ellipsis-complex;

  color: $notifications-todo-item-title-color;
}

.notifications-todo-item__unit-name {
  @extend .text-extra-bold;
  @include ellipsis-line(1);

  color: $notifications-todo-item-unit-color;
}

.notifications-todo-item__class-name {
  @extend .text-xs;
}

.notifications-todo-item__detail {
  @extend .text-extra;

  display: flex;
  align-items: center;
  margin-right: $notifications-todo-item-course-separator;
  color: $notifications-todo-item-detail-color;
}

.notifications-todo-item--status-one-day,
.notifications-todo-item--status-teacher-publish-jobs-pending {
  .notifications-todo-item__unit-name,
  .buble-and-date__day,
  .buble-and-date__month,
  .notifications-todo-item__title {
    color: $notifications-todo-item-one-day-color !important;
  }
}

.notification-item__notification:not(.notification-item__notification--not-consumption) {
  .notifications-todo-item__unit-name,
  .buble-and-date__day,
  .buble-and-date__month,
  .notifications-todo-item__title,
  .notification-todo-item__student-name,
  .notifications-todo-item__teacher-action {
    color: $notifications-todo-item-disabled-color !important;
  }

  .notification-todo-item__all-icon {
    .icon-bubble {
      background: $notifications-todo-item-disabled-color !important;
    }
  }
}

.notifications-todo-item--status-due-date-tomorrow {
  .buble-and-date__day,
  .buble-and-date__month,
  .notifications-todo-item__unit-name,
  .notifications-todo-item__title {
    color: $color-first !important;
  }
}

.notifications-todo-item__teacher-action {
  @extend .title-h6;

  color: $notifications-todo-item-teacher-action-color;
}

.notification-todo-item__student-name {
  @extend .text-extra-bold;

  color: $notifications-todo-item-student-color;

  span {
    @extend .text-extra;

    color: $notifications-todo-item-student-turned-color;
  }
}

.notification-todo-item__notification--consumption {
  .tag {
    background: $notifications-todo-item-notification-consumption-tag-background !important;
  }

  .tag__text {
    color: $notifications-todo-item-notification-consumption-tag-color !important;
  }
}
