$horizontal-user-list-entry-width: 60px;
$horizontal-user-list-entry-height: 80px;
$horizontal-user-list-entry-margin: $separator-s;
$horizontal-user-list-entry-avatar-margin: $separator-xxs;
$horizontal-user-list-entry-name-color: $color-gray-03;

.horizontal-users-list__wrapper {
  display: flex;
  justify-content: center;
}

.horizontal-users-list__entry {
  @extend .hover-basic;

  width: $horizontal-user-list-entry-width;
  height: $horizontal-user-list-entry-height;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 $horizontal-user-list-entry-margin;
}

.horizontal-users-list__entry-avatar {
  margin-bottom: $horizontal-user-list-entry-avatar-margin;
  padding: $horizontal-user-list-entry-avatar-margin;
  border: $horizontal-user-list-entry-avatar-margin solid transparent;
  border-radius: 100%;
}

.horizontal-users-list__entry-name {
  @extend .text-extra;

  font-family: $font-second-bold;
  text-align: center;
  color: $horizontal-user-list-entry-name-color;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.horizontal-users-list__entry-tooltip {
  .MuiTooltip-tooltip {
    @extend .text-smallest;

    background-color: $color-second-yellow-01;
    color: $color-gray-01;
    padding: 15px 22px;
    box-shadow: 0px 6px 12px rgba(163, 155, 147, 0.2);
    border: 1px solid #eee4dc;
    top: 20px;
  }

  .MuiTooltip-arrow {
    color: $color-second-yellow-01;
  }
}

.horizontal-users-list__entry--active {
  border-bottom: $separator-xs $color-first solid;

  .horizontal-users-list__entry-avatar {
    border-color: $color-first;
  }

  .horizontal-users-list__entry-name {
    @extend .text-small-bold;

    color: $color-gray-01;
  }
}
