$dropdown-profile-width: $size-dropdown-profile-width;
$dropdown-profile-name-color: $color-title;
$dropdown-profile-email-color: $color-label;
$dropdown-profile-options-color: $color-label;
$dropdown-profile-separator: $separator-s;
$dropdown-profile-separator-small: $separator-xs;
$dropdown-profile-card-padding: $separator-l;
$dropdown-profile__dropdown-top-separator: $separator-m;

.dropdown-profile__lib {
  .MuiPaper-root {
    width: $dropdown-profile-width;
  }

  .list-simple-dropdown {
    padding: 0;
  }

  .list-simple-dropdown__li {
    padding-left: 0;
    padding-right: 0;
  }

  .list-simple-dropdown__icon {
    margin-left: $card-default-small-padding;
  }
}

.dropdown-profile__dropdown {
  @extend .border-default;

  border-radius: $border-radius-01;
  margin-top: $dropdown-profile__dropdown-top-separator;
}

.dropdown-profile__header {
  padding: $dropdown-profile-card-padding;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: $dropdown-profile-separator;
}

.dropdown-profile__link {
  @extend .title-h6;
  @extend .text-link;
}

.dropdown-profile__info {
  padding: 0 $dropdown-profile-card-padding;

  display: flex;
  flex-direction: column;
}

.dropdown-profile__name {
  @extend .title-h6;

  color: $dropdown-profile-name-color;
  margin-bottom: $dropdown-profile-separator-small;
}

.dropdown-profile__email {
  @extend .text-small;

  color: $dropdown-profile-email-color;
}

.dropdown-profile__separator {
  margin: $dropdown-profile-separator 0 $dropdown-profile-separator 0;
}

.dropdown-profile__recorded {
  display: flex;
  align-items: center;
}

.dropdown-profile__recorded-text {
  margin-right: $dropdown-profile-separator;
}

.dropdown-profile__options .list-simple-dropdown__name {
  @extend .title-h6;
  color: $dropdown-profile-options-color;
}
