.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

// -------------------------------------------------
// align-items
// -------------------------------------------------

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

// -------------------------------------------------
// justify-content
// -------------------------------------------------

.justify-content-start {
  justify-content: start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

// -------------------------------------------------
// sm
// -------------------------------------------------
@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }
}

// -------------------------------------------------
// md
// -------------------------------------------------
@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }
}

// -------------------------------------------------
// lg
// -------------------------------------------------
@media (min-width: 992px) {
  .d-md-flex {
    display: flex !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }
}

// -------------------------------------------------
// xl
// -------------------------------------------------
@media (min-width: 1200px) {
  .d-xl-flex {
    display: flex !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }
}

// -------------------------------------------------
// xxl
// -------------------------------------------------
@media (min-width: 1400px) {
  .d-xxl-flex {
    display: flex !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }
}
