$school-card-margin: $main-separator-items;
$school-card-title-color: $color-gray-03;

.school-card {
  margin-top: auto;
}

.school-card__title,
.school-card__name {
  @extend .title-h5;

  margin-bottom: $school-card-margin;
}

.school-card__title {
  color: $school-card-title-color;
}

.school-card__location {
  @extend .text-smallest;
}
