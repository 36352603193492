$projection-card-container-padding: $separator-s;
$projection-card-title-color: $color-first;

.projection-card__container {
  @extend .hover-basic;
  @extend .card-secondary;

  padding: $projection-card-container-padding $separator-l;
}

.projection-card__top {
  display: flex;
  align-items: center;
}

.projection-card__bottom {
  margin-top: $projection-card-container-padding;
}

.projection-card__type {
  @extend .text-extra;
  @extend .ellipsis;

  margin-left: $projection-card-container-padding;
}

.projection-card__title {
  @extend .text-small;
  @extend .ellipsis;

  color: $projection-card-title-color;
}

.projection-card--active {
  border: 1px solid $projection-card-title-color;
  border-radius: $border-radius-01;
}
