.exp-progress {
  background: #1462a0;
  border-radius: 0px 8px 8px 0px;
  color: #fff;
  font-family: Dosis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px 8px 8px 0;
  width: 84px;
  height: 30px;

  &__icon {
    position: relative;
    right: 15px;

    svg {
      height: 40px;
      width: 40px;
    }
  }
}
