.table-consumptions {
  margin-top: $separator-xl;
  margin-bottom: $separator-xl;

  &--consumed {
    width: 620px;
  }

  &--not-consumed {
    margin-left: $separator-m;
    width: 220px;
  }

  .table-consumptions__header {
    height: 36px;

    th {
      text-align: left;
      padding-left: $separator-xxl;
      background-color: #f3eeea;
      vertical-align: middle;
      font-family: $font-first;
      color: #666666;
      text-transform: uppercase;
      font-size: 12px;
      min-width: 155px;
    }
    th:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    th:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .table-consumptions__body {
    .table-consumptions_row {
      height: 36px;
      font-family: $font-second;
      color: #4e4e4e;
      font-weight: 400;
      font-size: 14px;
      border-bottom: 1px solid #e0e0e0;

      td {
        padding: 0 $separator-xl;
        vertical-align: middle;

        .user-item__main-info {
          width: 100%;
        }
      }

      td:first-child {
        padding: 0 $separator-s;
      }
    }
  }
}
