.car-user {
  @extend .hover-basic;
  width: 200px;

  .car-user__box {
    min-height: 92px;
  }

  &__selected {
    border-color: $color-first;
  }
}
