.bar-chart-horizontal {
  &__custom-bar {
    rx: 4; /* border-radius X */
    ry: 4; /* border-radius Y */
  }

  &__custom-tick-x {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: $color-gray-03;
  }

  &__custom-tick-y {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    color: $color-gray-01;
  }
}
