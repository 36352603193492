$header-tab-active-color: $color-first;
$header-tab-text-color: $color-label2;
$header-tab-separator: $separator-m;
$header-tab-separator-with-icon: $separator-m;
$header-tab-transition-duration: $transition-duration-01;
$header-tab-after-height: $size-header-tabs-after-height;
$header-tab-after-width: $size-header-tabs-after-width;

$header-tab-text-color-typeview-border: $color-label;
$header-tab-width-fixed-typeview-border: 140px;
$header-tab-active-color-typeview-border: $color-white;
$header-tab-active-background-typeview-border: $color-first;

$header-tab-background-typeview-background: $color-gray-05;
$header-tab-active-background-typeview-background: $color-first;
$header-tab-text-color-typeview-background: $color-white;
$header-tab-separator-typeview-background: $separator-xs;
$header-tab-separator-typeview-height: 44px;

.header-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-tabs__tab--disabled {
  &:hover {
    cursor: not-allowed;
  }
}

.header-tabs__align-left {
  justify-content: flex-start;
}

.header-tabs__align-right {
  justify-content: flex-end;
}

.header-tabs__tab--id-grades {
  display: none;
}

.header-tabs__type-view-default {
  .header-tabs__tab {
    margin: 0 $header-tab-separator;
    position: relative;
    transition-duration: $header-tab-transition-duration;
    flex: 0 0 auto;
    a {
      text-decoration: none;
    }

    &:after {
      transition-duration: $header-tab-transition-duration;
      content: '';
      width: 0;
    }
  }

  .header-tabs__bubble {
    position: absolute;
    top: -6px;
    right: -12px;
  }

  .header-tabs__tab--active {
    .header-tabs__tab-text {
      color: $header-tab-active-color;
    }

    &:after {
      height: $header-tab-after-height;
      width: $header-tab-after-width;
      display: block;

      position: absolute;
      bottom: -20px;
      transform: translateX(-50%);
      left: 50%;

      background-color: $header-tab-active-color;
    }
  }

  .header-tabs__tab-text {
    @extend .hover-basic;
    @extend .title-h6;

    color: $header-tab-text-color;
  }

  .header-tabs__tab--with-icon {
    &:after {
      bottom: -10px;
    }
    margin: 0 $header-tab-separator-with-icon;
    .header-tabs__tab-text-container {
      text-align: center;
    }
  }
}

.header-tabs__type-view-border {
  .header-tabs__tab {
    @extend .border-light;
    @extend .hover-basic;

    border-right: 0;

    padding: $padding-card-default-small;
    text-align: center;
    width: $header-tab-width-fixed-typeview-border;

    &:first-child {
      border-bottom-left-radius: $border-radius-07;
      border-top-left-radius: $border-radius-07;
    }

    &:last-child {
      @extend .border-light;

      border-bottom-right-radius: $border-radius-07;
      border-top-right-radius: $border-radius-07;
    }
  }

  .header-tabs__tab-text {
    @extend .title-h6;
    @include ellipsis-line(1);

    color: $header-tab-text-color-typeview-border;
  }

  .header-tabs__tab--active {
    color: $header-tab-active-color-typeview-border;
    background: $header-tab-active-background-typeview-border;

    .header-tabs__tab-text {
      color: $header-tab-active-color-typeview-border;
    }
  }
}

.header-tabs__type-view-background {
  .header-tabs__tab {
    @extend .hover-basic;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $header-tab-background-typeview-background;
    height: $header-tab-separator-typeview-height;
    padding: 0 $separator-xxxl 0 $separator-xxxl;
    text-align: center;
    margin: 0 $header-tab-separator-typeview-background;

    border-bottom-left-radius: $border-radius-07;
    border-bottom-right-radius: $border-radius-07;
  }

  .header-tabs__tab-text {
    @extend .title-h6;
    @include ellipsis-line(1);

    color: $header-tab-text-color-typeview-background;
  }

  .header-tabs__tab-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-tabs__tab--active {
    background: $header-tab-active-background-typeview-background;
  }
}

@media #{$breakpoints-tablet} {
  .header-tabs__tab--id-grades {
    display: block;
  }
}

.header-tabs__type-view-background-round {
  @extend .header-tabs__type-view-background;

  .header-tabs__tab {
    border-radius: 40px;
  }
}

.header-tabs__type-view-vertical {
  @extend .header-tabs__type-view-background-round;

  flex-direction: column;

  .header-tabs__tab {
    background: transparent;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-bottom: $main-separator-items;
    padding: 0 20px 0 20px;

    justify-content: left;
  }

  .header-tabs__tab-text {
    color: $color-gray-03;
  }

  .header-tabs__tab--active {
    background: $color-first;

    .header-tabs__tab-text {
      color: $color-white;
    }
  }
}
