$header-save-height: $size-height-header;
$header-save-border: $border-headers;
$header-save-background: $color-header-save;

.header-save {
  background: $header-save-background;
  height: $header-save-height;

  display: flex;
  align-items: center;
  border-bottom: $header-save-border;
}

.header-save__grid {
  @extend .padding-default;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}
