.dialog-image {
  .MuiDialog-paper,
  .MuiDialogContent-root {
    height:auto;
    width: auto;
    padding: 0;
    text-align: center;
    padding: 0 !important;
    max-height: 100vh;
    max-width: 100vw;
  }
}

.dialog-image__image-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
}

.dialog-image__image {
  max-height: 100vh;
  max-width: 100vw;
}
