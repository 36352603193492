$size-avatar-small: 32px !default;
$size-avatar-xs: 20px !default;
$size-avatar-default: 40px !default;
$size-avatar-big: 58px !default;
$size-avatar-xl: 104px !default;
$size-avatar-xxl: 150px !default;

$size-bubble: 10px !default;
$size-bubble-medium: 14px !default;

$size-icon-bubble: 32px !default;
$size-icon-bubble-xs: 6px !default;
$size-icon-bubble-s: 36px !default;
$size-icon-bubble-small: 40px !default;
$size-icon-bubble-medium: 44px !default;
$size-icon-bubble-l: 56px !default;
$size-icon-bubble-big: 64px !default;
$size-icon-bubble-xl: 120px !default;

$size-number-bubble: 30px !default;
$size-number-bubble-m: 40px !default;
$size-number-bubble-xl: 60px !default;
$size-number-bubble-s: 30px !default;

$size-icons-xs: 12px !default;
$size-icons-s: 14px !default;
$size-icons-default: 19px !default;
$size-icons-big: 25px !default;
$size-icons-big-2: 36px !default;
$size-icons-xl: 46px !default;
$size-icons-xxl: 58px !default;

$size-input-checkbox: 22px !default;
$size-height-input-points: 36px !default;
$size-height-input-points-big: 50px !default;
$size-height-input-points-medium: 48px !default;
$size-height-input: 48px !default;
$size-height-input-small: 40px !default;

$size-book-card-width-progress: 60% !default;
$size-book-card-image-height: 150px !default;
$size-book-card-image-selectable-width: 150px !default;
$size-book-card-height-view-selectable: 150px !default;

$size-dropdown-courses-width: 314px !default;
$size-dropdown-profile-width: 298px !default;
$size-dropdown-students-grades: 273px !default;
$size-dropdown-event-calendar-width: 274px !default;
$size-dropdown-next-events-width: 312px !default;
$size-dropdown-notifications-width: 299px !default;
$size-dropdown-tasks-width: 312px !default;

$size-title-and-action-logo: 62px !default;

$size-header-tabs-after-width: 22px !default;
$size-header-tabs-after-height: 3px !default;

$size-login-form-max-width: 415px !default;

$size-button-height: 48px !default;
$size-button-height-small: 40px !default;
$size-button-height-xs: 30px !default;
$size-button-min-width: 70px !default;

$size-height-header: 64px !default;
$size-height-header-responsive: 48px !default;
$size-height-footer: 64px !default;

$size-height-footer-small: 56px !default;

$size-form-sender-textarea-focus-height: 132px !default;

$size-progressbar-height: 4px !default;
$size-progressbar-medium-height: 7px !default;

$size-layout-header-big-header-height: 203px !default;

$size-layout-progress-header-height: 166px !default;
$size-layout-progress-header-height-mobile: 130px !default;
$size-layout-progress-footer-height: 59px !default;
$size-layout-progress-aside-width: 450px !default;

$size-calendar-big-indicator-height: 4px !default;
$size-calendar-big-day-height: 90px !default;
$size-calendar-big-month-view-height: 600px !default;
$size-calendar-big-actions-height: 24px !default;

$size-program-icon-current-background-size: 38px !default;

$size-select-bubble-after-height: 6px !default;

$size-graph-bars-width: 25px !default;
$size-graph-bars-height: 276px !default;

$size-dialog-xxs-max-width: 357px !default;
$size-dialog-xs-max-width: 456px !default;
$size-dialog-s-max-width: 534px !default;
$size-dialog-small-max-width: 684px !default;
$size-dialog-xl-max-width: 904px !default;
$size-dialog-xxl-max-width: 1209px !default;

$size-classroom-course-max-width: 240px !default;

$size-layout-header-backgroundimage-height: 140px !default;

$size-template-card-image-height: 159px !default;

$size-video-max-height: 400px !default;
$size-video-small-height: 300px !default;

$size-document-icon: 80px !default;

$size-layout-content-width: 856px !default;
$size-footer-projection-height: 76px !default;
$size-footer-answer-test-bar: 72px !default;

$size-bar-menu-width: 64px !default;
$size-text-editor-max-height: 300px !default;
$size-lemonade-container-max-width: 850px !default;
$size-program-max-width: 850px !default;

$size-layout-progress-header-logo-width: 187px;

$size-avatar-picture: 100% !default;
