.add-license {
  height: 100vh;

  .signup-step-license {
    height: 100%;
  }

  .signup-step-license--with-licenses {
    .signup__column2 {
      background: $color-white !important;
    }
  }
}

.add-license__logout {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  position: absolute;
  bottom: $separator-xxxxl;
}

.add-license__logout-text {
  @extend .title-h6;

  margin-left: $separator-l;
  color: $color-first;
}
