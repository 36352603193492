$kids-color-black: #2d2d2d;
$kids-color-gray: #828282;
$kids-color-orange: #ff5322;

.lesson-item-student-kids {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 50px;
  border: 2px solid #e9e9e9;
  box-shadow: 0px 4px 0px 0px #0000001a;
}

.lesson-item-student-kids--today {
  box-shadow: 0px 4px 0px 0px #ff5322;
  border: 2px solid #ff5322;
}

.lesson-item-student-kids__left {
  display: flex;
  align-items: center;
}

.lesson-item-student-kids__icon {
  border: 4px solid #c9c9c9;
  padding: 8px;
  border-radius: 50px;
  margin-right: 16px;

  svg {
    height: 32px;
    width: 32px;
    path {
      fill: #c9c9c9;
    }
  }
}

.lesson-item-student-kids__name {
  @extend .title-h4;

  color: $kids-color-black;
}

.lesson-item-student-kids__completed {
  @extend .title-h6;

  padding: 8px 16px;
  border-radius: 80px;
  color: $color-white;
  background-color: $color-first;
}

.lesson-item-student-kids__progress {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 80px;
  background: #f9f9f9;
}

.lesson-item-student-kids__progress-bar {
  width: 100px;
}

.lesson-item-student-kids__progress-label {
  @extend .title-h6;

  margin-left: 8px;
  color: $kids-color-black;
}

.lesson-item-student-kids__date {
  font-family: $font-second-kids;
  font-weight: 500;
  color: $kids-color-gray;
  line-height: 19px;
}

.lesson-item-student-kids__lesson-day {
  font-weight: 700;
  font-family: $font-second-bold-kids;
  color: $kids-color-orange;
  text-transform: uppercase;
  font-size: 16px;
}
