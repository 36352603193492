$card-generic-title-separator: $separator-m;

.card-generic--default {
  @extend .card-default;
}

.card-generic--default-small {
  @extend .card-default-small;
}

.card-generic--default-big {
  @extend .card-default-big;
}

.card-generic--simple {
  @extend .card-simple;
}

.card-generic--secondary {
  @extend .card-secondary;
}

.card-generic__title-text {
  @extend .text-small-bold;

  display: block;
  margin-bottom: $card-generic-title-separator;
}

.card-generic--onclick {
  @extend .hover-basic;
}
