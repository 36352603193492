$dialog-list-behaviour-title-separator: $main-separator-components;
$dialog-list-behaviour-title-color: $color-title;

.dialog-list-behaviour {
  .MuiDialogContent-root {
    padding-top: 0 !important;
  }
}

.dialog-list-behaviour__title {
  display: flex;
  align-items: center;
}

.dialog-list-behaviour__title-name {
  @extend .title-h5;

  color: $dialog-list-behaviour-title-color;
  margin-left: $dialog-list-behaviour-title-separator;
}
