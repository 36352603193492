$video-lesson-font-family: $font-second;
$video-lesson-bg-white: #ffffff;

.video-lesson {
  background-color: $video-lesson-bg-white !important;

  .video-lesson-content {
    background-color: $video-lesson-bg-white !important;

    .video-lesson-content__top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: $separator-m;
    }

    .video-lesson-content__top-title {
      font-family: $font-first;
      font-weight: 600;
      font-size: 18px;
      color: $color-gray-01;
    }

    .video-lesson-content__start {
      margin: $separator-m;
      font-weight: 400;
      font-family: $font-second;
      color: $color-gray-02;
      z-index: 1;
    }

    .video-lesson-content__grade-particular {
      margin-top: $separator-l;
      margin-left: 50%;
    }

    .vl-lesson .vl-card-activity-list .vl-card-activity-list__text {
      font-family: $video-lesson-font-family !important;

      font-weight: 700;
      font-size: 15px;
      color: $color-gray-02;
    }

    .vl-lesson .vl-tool-title-url .vl-tool-title-url__toggles {
      margin-bottom: $separator-xxxl;
    }

    .vl-lesson .vl-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
    }

    .vl-lesson .vl-tool-title-url .vl-tool-title-url__inputs {
      flex-direction: column;
    }

    .vl-lesson .vl-toggle .vl-toggle__switch {
      // margin-right: $separator-s;
    }

    .vl-lesson .vl-toggle.vl-toggle--checked .vl-toggle__text,
    .vl-lesson .vl-toggle .vl-toggle__text {
      font-family: $font-second !important;
      font-weight: 700;
      color: $color-gray-01;
    }

    @extend .dialog-activity__body;
    @extend .grid;
    font-family: $video-lesson-font-family !important;

    margin-bottom: $separator-xxxl;

    .vl-lesson .vl-player .vl-player__tool {
      position: relative;
      opacity: 1;
    }
    .vl-player__tool {
      display: block;
    }
    .vl-lesson .vl-wrapper-visor-question .vl-wrapper-visor-question__visor,
    .vl-lesson .vl-wrapper-question .vl-lemonade-question__question {
      border-radius: 0 !important;
    }

    .vl-lesson .vl-mark-slider {
      border-color: $color-first;
    }

    .vl-lesson .vl-card-activity-list {
      border-radius: 0;
    }

    .vl-lesson .vl-card-activity-list--active,
    .vl-lesson .vl-card-activity-list--active:hover,
    .vl-lesson .vl-card-activity-list:hover {
      border-color: $color-first !important;
      border-radius: 0;
    }

    .vl-lesson .vl-slider .rc-slider .rc-slider-handle,
    .vl-lesson .vl-slider .rc-slider .rc-slider-track {
      background: $color-first;
    }

    .vl-lesson .vl-mark-slider.vl-mark-slider--correct.vl-mark-slider--focus {
      box-shadow: none;
    }

    .vl-lesson .vl-card-activity-list .vl-card-activity-list__time {
      background-color: $color-first;
      display: flex;
      align-items: center;
      background: rgba(237, 112, 17, 0.1);
      border-radius: 40px;
      padding: 4px 12px;
    }

    .vl-lesson .vl-button-questions .vl-button-questions__icon-and-text {
      justify-content: flex-end;
    }

    .vl-lesson .vl-button-questions .vl-button-questions__icon-and-text svg path {
      stroke: $color-first;
    }

    .vl-lesson .vl-button-questions--no-active .vl-button-questions__icon-and-text svg path {
      stroke: $color-gray-03;
    }

    .vl-lesson .vl-button-questions .vl-button--icon-active {
      color: $color-first;
      background-color: transparent;
    }

    .vl-lesson .vl-button-questions--no-active .vl-button--icon-active {
      color: $color-gray-03;
    }

    .vl-lesson .vl-horizontal-scrolling .vl-horizontal-scrolling__arrow {
      padding: 4px 4px;
      background-color: transparent;
      border: 1px solid $color-gray-05;
      color: $color-gray-05;
    }

    .vl-lesson .vl-horizontal-scrolling .vl-horizontal-scrolling__arrow svg path {
      stroke: $color-gray-05;
    }

    .vl-lesson .vl-horizontal-scrolling .vl-horizontal-scrolling__arrow:hover {
      background-color: rgba(237, 112, 17, 0.1);
    }

    .vl-lesson .vl-button--primary-full-width {
      border: none;
      @extend .button;
      @extend .button--color-primary;
      padding: 2px 12px;
    }

    .vl-lesson .vl-button--primary-without-bg {
      @extend .button;
      @extend .button--color-transparent-primary;
      display: flex;
      align-items: center;
      svg path {
        fill: $color-first;
      }
    }

    .vl-lesson .vl-button--primary-border {
      @extend .button;
      @extend .button--color-primary-outlined;
      display: flex;
      align-items: center;
      svg path {
        fill: $color-first;
      }
    }

    .vl-lesson .vl-card-activity-list .vl-card-activity-list__time .vl-card-activity-list__time-text {
      text-align: center;
      font-weight: 700;
      font-size: 14px;
      color: #ed7011;
      font-family: $font-second;
      line-height: 21px;
    }

    .vl-lesson .vl-results {
      font-family: $video-lesson-font-family !important;
      margin-bottom: $separator-l;
      border-radius: 0;
      gap: 0;
      padding: 0px;
      justify-content: space-evenly;
      align-items: center;
      grid-gap: 0;
      height: 100px;
    }

    .vl-font-heading-h2,
    .vl-lesson .vl-results-counter .vl-results-counter__number,
    .vl-lesson .vl-results-counter .vl-results-counter__text,
    .lemonade-exercises .lemo-title,
    .lemonade-exercises .lemo-stimulus,
    .vl-lesson .vl-lemonade-question .lemonade-exercises .lemo-dropdown-content-item,
    .lemonade-exercises .lemo-multiple-choice .lemo-option-content,
    .vl-lesson .vl-lemonade-question .lemonade-exercises p,
    .vl-lesson .vl-lemonade-question .lemonade-exercises span {
      font-family: $video-lesson-font-family !important;
    }

    .vl-lesson .vl-results .vl-results-separator {
      height: 100px;
    }

    .vl-lesson .vl-results-counter {
      align-items: center !important;
    }

    .vl-lesson .vl-wrapper-question .vl-lemonade-question__question {
      font-family: $font-second;
    }

    .vl-horizontal-scrolling {
      padding: $separator-m auto;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .vl-input-generic-title {
      .vl-wrapper-title-error__title {
        @extend .input-text__label;
        margin-bottom: 0 !important;
        margin-top: $separator-l;
      }
      input {
        @extend .input-text__input;
        margin-top: 0;
      }
    }

    .vl-lesson .vl-input-time input,
    .vl-lesson .vl-tool-activity .vl-tool-activity__inputs .vl-wrapper-title-error__element .vl-select,
    .vl-lesson .vl-tool-activity .vl-tool-activity__inputs .vl-wrapper-title-error__element input {
      border: 1px solid #dbd5ce !important;
      border-radius: 4px !important;
    }

    .vl-lesson .vl-wrapper-visor-question .vl-wrapper-visor-question__question {
      flex: 1 0 50%;
      z-index: 10;
    }

    .vl-lesson .vl-select__select {
      font-family: $font-second;
    }

    .vl-lesson .vl-toggle .vl-toggle__slider:before {
      background-color: $color-white;
      border: $color-gray-04 1.5px solid;
      height: 5.5px;
      width: 5.5px;
      bottom: 1.5px;
      left: 2px;
      border-radius: 100%;
    }

    .vl-lesson .vl-toggle .vl-toggle__slider {
      background-color: transparent;
      border: $color-gray-04 2px solid;
    }

    .vl-lesson .vl-toggle input:checked + .vl-toggle__slider {
      background-color: transparent;
      border: $color-first 2px solid;
    }

    .vl-lesson .vl-toggle input:checked + .vl-toggle__slider:before {
      transform: translateX(7.5px);
      border: $color-first 1.5px solid;
    }

    .lemonade-exercises .lemo-selected .lemo-checkbox-square-icon,
    .lemonade-exercises .lemo-selected .lemo-checkbox-square-icon .lemo-shape,
    .lemonade-exercises .lemo-selected .lemo-checkbox-rounded-icon .lemo-shape {
      border-color: $color-first;
    }
    .lemonade-exercises .lemo-selected .lemo-checkbox-square-icon:after,
    .lemonade-exercises .lemo-selected .lemo-checkbox-rounded-icon:after,
    .lemonade-exercises .lemo-selected .lemo-checkbox-rounded-icon .lemo-icon {
      background-color: $color-first;
    }

    .vl-lesson .vl-tool-title-url .vl-tool-title-url__buttons,
    .vl-lesson .vl-tool-cut .vl-tool-cut__buttons,
    .vl-lesson .vl-tool-activity .vl-tool-activity__buttons {
      .vl-button {
        @extend .button;
        background: none;
        width: auto;
        @extend .button--color-primary-outlined;
      }

      .vl-button--secondary,
      .vl-lesson .vl-button--secondary {
        border: none;
        color: $color-gray-02;
      }
    }

    .vl-lesson .vl-button-add-activity .vl-button-add-activity__button,
    .vl-lesson .vl-confirm-message .vl-controls {
      .vl-button {
        @extend .button;
        background: none;
        width: auto;
        border: none;
        @extend .button--color-primary;
      }
      .vl-button--secondary,
      .vl-lesson .vl-button--secondary {
        border: none;
        color: $color-gray-02;
        background: transparent !important;
      }
    }

    .vl-lesson .vl-button--icon-fill svg {
      fill: $color-gray-03 !important;
      path {
        fill: $color-gray-03 !important;
      }
    }

    .vl-lesson .vl-button--icon-stroke svg {
      stroke: $color-gray-03 !important;
    }

    .vl-lesson-visor .vl-button--icon-active.vl-button--icon-fill svg {
      border-bottom: none !important;
    }

    .vl-lesson .vl-button-questions .vl-button--icon-active.vl-button--icon-fill svg {
      fill: $color-white !important;
      stroke: $color-first;
      stroke-width: 2;
      border-bottom: 3px solid $color-first;
      path {
        fill: $color-white !important;
      }
    }

    .vl-lesson .vl-card-activity-list .vl-card-activity-list__intents {
      background-color: transparent;
      color: $color-gray-02;
      font-family: $font-first;
    }

    .vl-lesson .vl-tool .vl-tool__left .vl-tool__left-times,
    .vl-lesson .vl-button-questions .vl-button-questions__text {
      font-family: $font-second;
      font-size: 15px;
      font-weight: 400;
    }

    .vl-lesson .vl-button--icon-fill:hover svg {
      fill: $color-first !important;
    }

    .vl-lesson .vl-button-questions .vl-button--icon-active.vl-button--icon-stroke svg {
      stroke: $color-first !important;
      stroke-width: 2;
      border-bottom: 3px solid $color-first;
    }

    .vl-lesson .vl-button--icon-stroke:hover svg {
      stroke: $color-first !important;
    }

    .vl-lesson .vl-range-slider .rc-slider .rc-slider-handle {
      background-color: $color-first;
    }

    .vl-lesson .vl-range-slider .rc-slider .rc-slider-track {
      background-color: $color-first !important;
      opacity: 20% !important;
    }

    .vl-lesson .vl-range-slider .rc-slider .rc-slider-rail,
    .vl-lesson .vl-slider .rc-slider .rc-slider-rail {
      background-color: $color-gray-06 !important;
    }

    .vl-lesson .vl-position-slider .rc-slider .rc-slider-handle {
      background-color: $color-first !important;
    }
    .vl-lesson .vl-position-slider .rc-slider .rc-slider-rail {
      background-color: $color-first !important;
      opacity: 20% !important;
    }

    .vl-lesson .vl-add-activity-option:hover {
      color: $color-first;
      background-color: $color-first;
      svg {
        path {
          stroke: $color-white;
        }
      }
    }

    .vl-lesson .vl-add-activity-option {
      border: solid 1px $color-gray-06;
    }
  }
  .vl-lesson .vl-modal-main {
    max-width: 600px;
  }

  .vl-mark-slider.vl-mark-slider--correct {
    background-color: $color-success !important;
    border: solid 2px $color-success !important;
  }
  .vl-lesson .vl-lemonade-question .vl-lemonade-question__question-points .lemonade-exercises__score p {
    font-size: 16px;
    font-weight: 600;
    font-family: $font-second;
  }

  .vl-navigation-attempts {
    font-family: $video-lesson-font-family;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts {
    background: $color-gray-07;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__label {
    font-size: 15px;
    font-weight: 700;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__label,
  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__number {
    color: $color-gray-03;

    svg {
      display: none;
    }
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts {
    justify-content: flex-start;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__right,
  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__left {
    button {
      background: transparent;
    }
  }

  .vl-lesson .vl-button--arrow-attempts {
    border-radius: 50%;
    border: 1px solid $color-gray-03;
    height: 28px;
    width: 28px;
    margin: auto $separator-s;
    svg {
      // width: 8px !important;
      // height: 12px !important;
      border: none;
    }
    svg rect {
      fill: transparent;
    }
    svg path {
      fill: $color-gray-03;
    }
  }

  .vl-lesson .vl-navigation-attempts__right {
    .vl-navigation-attempts__number {
      font-size: 22px;
      color: $color-gray-02;

      span {
        font-size: 15px;
        color: $color-gray-04;
      }
    }
  }

  .vl-lesson .vl-lemonade-question .vl-lemonade-question__score-wrapper {
    .assessment-grade-tag {
      padding: $separator-m;

      .assessment-grade-tag__grade-container {
        margin-right: $separator-s;
      }
    }
  }
}

.react-tiny-popover-container {
  .vl-card-activity-options {
    width: 150px;
  }
  .vl-card-activity-list__options-menu {
    .vl-card-activity-options {
      .vl-card-activity-option .vl-card-activity-option__text {
        font-family: $font-second !important;
      }

      .vl-card-activity-option:hover {
        background: $color-first !important;
        border-radius: 10px;
        .vl-card-activity-option__icon svg path {
          stroke: $color-white;
        }
      }
    }
  }
}

@media #{$breakpoints-medium} {
  .video-lesson-content__start {
    position: absolute;
    top: 80px;
    left: 50%;
  }
}
