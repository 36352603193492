$dialog-lemonade-templates-type-max-width: 200px;
$dialog-lemonade-templates-type-padding: $separator-s;
$dialog-lemonade-templates-type-border-radius: $border-radius-02;
$dialog-lemonade-templates-type-selected-color: $color-first;

.dialog-lemonade-templates {
  min-height: 250px;

  .MuiPaper-root {
    max-width: $size-dialog-xl-max-width;
  }

  .template-card {
    border-radius: 2px;
  }

  .template-card__description,
  .template-card__background-image {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .template-card__image {
    &:hover {
      .template-card__description {
        opacity: 0.2 !important;
      }
    }
  }
}

.dialog-lemonade-templates__loading {
  min-height: 300px;
}

.dialog-lemonade-templates__ai {
  min-height: 150px;
  .textarea__input {
    min-height: 90px;
    max-height: 130px;
  }
}

.dialog-lemonade-templates__ai-error {
  margin-top: $separator-s;
  font-family: $font-first;
}

.dialog-lemonade-templates__columns {
  @extend .grid-column;

  min-height: 100px;
}

.dialog-lemonade-templates__left-column {
  @include column(4);
}

.dialog-lemonade-templates__right-column {
  @include column(8);
}

.dialog-lemonade-templates__list {
  overflow-y: auto;
  height: calc(70vh - 48px - 54px); //menos el footer menos el header
  padding-right: $dialog-lemonade-templates-type-padding;
  margin-bottom: $dialog-lemonade-templates-type-padding;
}

.dialog-lemonade-templates__type {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  max-width: $dialog-lemonade-templates-type-max-width;
  margin-bottom: $padding-card-default-vertical;
  padding: $dialog-lemonade-templates-type-padding;
  border-radius: $dialog-lemonade-templates-type-border-radius;
}

.dialog-lemonade-templates__type-icon {
  display: none;
}

.dialog-lemonade-templates__type-name {
  @extend .label;

  margin-left: $padding-card-default-vertical;
  font-size: 18px !important;
}

.dialog-lemonade-templates__type--selected {
  // background: $gradient;
  // width: 100%;

  .dialog-lemonade-templates__type-name {
    color: $dialog-lemonade-templates-type-selected-color;
  }
}

@media #{$breakpoints-medium} {
  .dialog-lemonade-templates__type-icon {
    display: block;
  }
}

/*@media #{$breakpoints-xlarge} {
  .dialog-lemonade-templates__left-column {
    @include column(3);
  }

  .dialog-lemonade-templates__right-column {
    @include column(9);
  }
}*/
