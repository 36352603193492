.videolesson-results-view {
  .video-lesson {
    margin-top: $separator-l;
    .vl-lesson .vl-wrapper-visor-question .vl-wrapper-visor-question__visor,
    .vl-lesson .vl-wrapper-question .vl-lemonade-question__question {
      border-radius: 8px !important;
      border: 1px solid var(--stroke-border-card, #ebe0d7);
    }

    .vl-card-activity-list--active,
    .vl-card-activity-list {
      border-radius: 8px !important;
    }
  }

  .video-lesson .video-lesson-content {
    width: 100% !important;
  }
  .video-lesson,
  .video-lesson-content {
    background-color: transparent !important;
  }
  .video-lesson .video-lesson-content .vl-lesson .vl-button-add-activity .vl-button-add-activity__button .vl-button,
  .video-lesson .video-lesson-content .vl-lesson .vl-confirm-message .vl-controls .vl-button {
    background-color: $color-first !important;
  }
}
