$header-assessment-height: $size-height-header;
$header-assessment-background: $color-header-course;
$header-assessment-border: $border-headers;
$header-assessment-title-separator: $main-separator-components;
$header-assessment-title-color: $color-title;

$header-assessment-text-color: $color-label;
$header-assessment-right-separator-components: $main-separator-components;
$header-assessment-right-separator-items: $main-separator-items;

.header-assessment {
  background: $header-assessment-background;
  border-bottom: $header-assessment-border;
  height: $header-assessment-height;

  display: flex;
  align-items: center;
  position: relative;

  width: 100%;
}

.header-assessment__right,
.header-assessment__left {
  width: 40%;
  flex-wrap: nowrap;
}

.header-assessment__left {
  display: flex;
  align-items: center;
}

.header-assessment__title {
  @extend .text-p;
  @include ellipsis-line(2);

  color: $header-assessment-title-color;
  font-family: $font-second-bold;
  margin-left: $header-assessment-title-separator;
}

.header-assessment__center {
  width: 20%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-assessment__points-warning {
  margin-right: $separator-s;
}

.header-assessment__points--error {
  color: $color-error !important;
}

.header-assessment__grid {
  @extend .padding-default;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.header-assessment__points {
  @extend .text-small;
  display: none;
  color: $header-assessment-text-color;
}

.header-assessment__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-assessment__saved {
  margin-right: $header-assessment-right-separator-components;

  display: flex;
  align-items: center;
}

.header-assessment__saved-text {
  @extend .text-smallest;

  color: $header-assessment-text-color;
  margin-right: $header-assessment-right-separator-items;
}

.header-assessment__saved--saving {
  font-style: italic;
}

.header-assessment__date-schedule {
  min-width: 188px;
  margin-right: $header-assessment-right-separator-items;
}

.header-assessment__button-split {
  min-width: 168px;
}

@media #{$breakpoints-large} {
  .header-assessment__points {
    display: block;
  }
}
