$dialog-actions-margin: $main-separator-items;
$dialog-separator-margin-hr: $main-separator-components;
$dialog-separator-margin-hr-with-custom-header: $separator-m;
$dialog-separator-custom-header: $separator-m;
$dialog-padding: $padding-dialog;
$dialog-text-color: $color-label;
$dialog-hr-height: 1px;
$dialog-footer-background: $color-background-dialog-footer;
$dialog-padding-left-with-icon: 46px;

.dialog {
  .header-tabs__tab--active:after {
    bottom: -14px;
  }

  z-index: $z-index-popup-overlay !important;

  .input-date-v2 {
    z-index: $z-index-popup;
  }
}

.dialog--with-icon {
  .MuiDialogContent-root {
    padding-left: $dialog-padding-left-with-icon !important;
  }
}

.dialog__title {
  @extend .title-h5;

  width: 30%;
  white-space: nowrap;
}

.MuiDialogActions-root {
  margin-top: $dialog-actions-margin;
}

.dialog__header,
.MuiDialogContent-root,
.MuiDialogActions-root {
  padding: $dialog-padding !important;
}

.dialog__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dialog__custom-header {
  margin-top: $dialog-separator-custom-header;
}

.dialog__title-tabs {
  position: absolute;
  left: 42%;
}

.dialog__close-container {
  width: 30%;
}

.dialog__close {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dialog__text {
  @extend .text-small;

  color: $dialog-text-color;
}

.MuiDialog-paperFullScreen {
  .MuiDialogContent-root {
    padding: 0 !important;
  }
}

.dialog__hr {
  margin-top: $dialog-separator-margin-hr;
  left: -#{$separator-xl};
  position: relative;
  width: calc(100% + #{$separator-xl} + #{$separator-xl});

  .hr {
    height: 1px;
  }
}

.dialog__custom-header ~ .dialog__hr {
  margin-top: $dialog-separator-margin-hr-with-custom-header;
}

.dialog--with-footer-with-background {
  .MuiDialogActions-root {
    background: $dialog-footer-background;
  }
}

.dialog__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.dialog--size-xxs {
  .MuiDialog-paper {
    max-width: $size-dialog-xxs-max-width;
  }
}

.dialog--size-xs {
  .MuiDialog-paper {
    max-width: $size-dialog-xs-max-width;
  }
}

.dialog--size-s {
  .MuiDialog-paper {
    max-width: $size-dialog-s-max-width;
  }
}

.dialog--size-small {
  .MuiDialog-paper {
    max-width: $size-dialog-small-max-width;
  }
}

.dialog--size-xl {
  .MuiDialog-paper {
    max-width: $size-dialog-xl-max-width;
  }
}

.dialog--size-xxl {
  .MuiDialog-paper {
    max-width: $size-dialog-xxl-max-width;
  }
}

.dialog--size-all-screen {
  .MuiDialog-paper {
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    margin: 0;
    border-radius: 0;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .MuiDialogContent-root {
    padding: 0 !important;
    overflow-x: hidden;
  }
}
