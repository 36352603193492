$program-card-border-radius: $border-radius-book;
$program-card-border-radius-big: $border-radius-big-size;
$program-card-image-size: 136px;
$program-card-image-selectable-width: $size-book-card-image-selectable-width;
$program-card-responsive-color: $color-label;
$program-card-separator: $separator-xs;
$program-card-width-progress: $size-book-card-width-progress;
$program-card-height-view-selectable: $size-book-card-height-view-selectable;
$program-card-button-separator: $separator-s;
$program-card-title-color: $color-gray-01;
$program-card-language-color: $color-gray-02;
$program-card-background: $color-white;
$program-card-picture-size: 136px;

.program-card {
  @extend .border-light;
  @extend .hover-basic;

  background: $program-card-background;
  border-radius: $program-card-border-radius;
  padding: $padding-card-default-small;
  position: relative;

  display: flex;
  height: 100%;

  .program-image__picture {
    height: $program-card-image-size;
    width: $program-card-image-size;
  }
}

.program-card__info {
  @extend .card-default;

  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: $program-card-border-radius;
  border-bottom-right-radius: $program-card-border-radius;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  max-width: 100%;
}

.program-card__responsive {
  @extend .text-smallest-bold;
  @extend .ellipsis;

  color: $program-card-responsive-color;
  margin-bottom: $program-card-separator;
}

.program-card__language {
  @extend .text-extra;

  color: $program-card-language-color;
  margin-bottom: $program-card-separator;
}

.program-card__title {
  @extend .title-h5;
  @extend .ellipsis-complex;

  color: $program-card-title-color;
  margin-bottom: $program-card-separator;
  -webkit-line-clamp: 2;
}

.program-card__info-bottom {
  display: flex;
  align-items: center;
}

.program-card__version-name {
  margin-left: $main-separator-items;
}

.program-card__version-name {
  @extend .text-small;

  color: $color-gray-02;
}

.program-card__responsive-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.program-card__edit {
  @extend .title-h7;

  background: $color-gray-01;
  color: $color-white;
  border-radius: 4px 0px 8px;
  padding: $padding-card-default-small;
  position: absolute;
  top: $padding-card-default-small;
  left: $padding-card-default-small;
}

.program-card__picture-container {
  border-style: solid;
  padding: 1px;
}

.program-card__picture {
  background-size: cover;

  width: $program-card-picture-size;
  height: $program-card-picture-size;
  image-rendering: -webkit-optimize-contrast;
}
