.class-to-add-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $separator-m 0;
  border-bottom: 1px solid $color-gray-04;
}

.class-to-add-item__left {
  display: flex;
}

.class-to-add-item__info {
  margin-left: $separator-s;
}

.class-to-add-item__name {
  @extend .text-button;

  margin-bottom: $separator-s;
  color: $color-gray-01;
}

.class-to-add-item__in-use {
  @extend .text-smallest;

  color: $color-gray-03;
  font-style: italic;
  margin-right: $separator-m;
}

.class-to-add-item--disabled {
  .class-to-add-item__name {
    color: $color-gray-03;
  }

  .input-checkbox {
    svg {
      path {
        fill: $color-gray-03;
      }
    }
  }
}
