$preview-content-separator: $main-separator-components;
$preview-content-separator-name: $main-separator-items;
$preview-content-color-name: $color-first;
$preview-content-card-width: 410px;
$preview-content-audio-bar-background: $color-gray-04;
$preview-content-audio-bar-height: 2px;
$preview-content-audio-bar-size-circle: 12px;
$preview-content-audio-bar-background-circle: $color-first;
$preview-content-audio-bar-left-circle: 4px;
$preview-content-audio-bar-bottom-circle: 4px;
$preview-content-descriptio-color: $color-gray-04;

.preview-content__image {
  margin: $preview-content-separator auto;
}

.preview-content__img {
  @extend .hover-basic;

  display: block;
  margin: auto;
  max-width: 100%;
}

.preview-content__type-with-icon {
  @extend .card-default-small;
  @extend .hover-basic;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: $preview-content-separator 0;

  width: $preview-content-card-width;
}

.preview-content__type-with-icon-bubble {
  flex: 0 0 auto;
}

.preview-content__name {
  @extend .label;

  color: $preview-content-color-name;
  display: block;
}

.preview-content__description {
  @extend .text-smallest;

  margin-bottom: $preview-content-separator;
  display: none;
  color: $preview-content-descriptio-color;
}

.preview-content__type-with-icon-text-container {
  margin-left: $preview-content-separator-name;
}

.preview-content__audio {
  @extend .card-default-small;
  @extend .hover-basic;

  margin: $preview-content-separator 0;
  width: $preview-content-card-width;
}

.preview-content__video {
  margin: $preview-content-separator 0;
}

.preview-content__audio-icons {
  display: flex;
  align-items: center;

  //margin-top: $padding-card-default-horizontal;
}

.preview-content__audio-bar-container {
  position: relative;
  width: 100%;
}

.preview-content__audio-bar {
  height: $preview-content-audio-bar-height;

  width: calc(100% - #{$padding-card-default-horizontal});
  margin-left: $padding-card-default-horizontal;

  background: $preview-content-audio-bar-background-circle;
  transition: all ease 0.05s;
  cursor: pointer;
}

.preview-content__audio-bar-circle {
  height: $preview-content-audio-bar-size-circle;
  width: $preview-content-audio-bar-size-circle;
  background: $preview-content-audio-bar-background-circle;
  border-radius: 100%;

  position: absolute;
  bottom: -#{$preview-content-audio-bar-bottom-circle};
  left: $preview-content-audio-bar-left-circle;
}

.preview-content__audio .preview-content__duration {
  float: right;
  margin: 18px 0 0 0;
  color: #ababab;
  box-sizing: border-box;
  padding: 0 0 0 11px;
  font-size: 13px;
}

.preview-content__duration_video {
  position: absolute;
  color: #ababab;
  box-sizing: border-box;
  padding: 0 0 0 11px;
  font-size: 13px;
  top: calc(50% - 4px);
  right: 8px;
}
