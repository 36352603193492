  .scorm-individual-results-table-item {
    display: flex;

    >span {
      @extend .text-smallest;
      color: $color-gray-02;
      min-height: 48px;
      border-bottom: 1px solid $color-gray-05;
      display: flex;
      align-items: center;

      &:nth-child(1) {
        flex-grow: 1
      }

      &:nth-child(2) {
        width: 250px;
      }

      &:nth-child(3) {
        width: 120px;
        text-align: center;
        justify-content: center;
      }

      &:nth-child(4) {
        width: 90px;
        text-align: center;
        justify-content: center;
      }
    }

    .activity-state-icon {
      margin-right: 8px;
    }

    .score {
      width: 32px;
      height: 32px;
      border-radius: 25px;
      background: #F1F3F5;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .score--needs-reinforcement {
      background: #ffe8e8;
    }

    .score--needs-ampliacion {
      background: #FEF7EC;
    }

    .score--good-progress {
      background: #ECFBEF;
    }
  }
