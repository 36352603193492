.paginator {
  display: flex;
  justify-content: center;
}

.paginator__step {
  margin: 0 $separator-l;
}

.paginator--with-arrows {
  justify-content: space-between;

  .paginator__step {
    margin: 0;
  }
}
