$unoi-course-view-program-image-size: 80px;

.unoi-course-view-program__image {
  border-radius: 100%;
  height: $unoi-course-view-program-image-size;
  width: $unoi-course-view-program-image-size;

  background-size: 100% 100%;
  background-position: center center;
  float: right;
  margin-right: $main-separator-items;

  transition-duration: 0.3s;
}

.unoi-course-view-program__texts-container {
  display: flex;
  flex-direction: column;
}

.unoi-course-view-program__texts-name {
  @extend .title-h6;

  color: $color-gray-01;
  text-transform: uppercase;
}

.unoi-course-view-program__texts-dates {
  @extend .text-extra;

  color: $color-gray-03;
}

.unoi-course-view-program__list-student-mode {
  .group-lessons-progress-card {
    margin-bottom: $main-separator-components;
  }
}
