.xapi-individual-results-table {
  font-family: $font-second;
  .xapi-individual-results-table__header {
    display: flex;

    span {
      font-family: $font-second;
      font-weight: 700;
      font-size: $font-size-10;
      line-height: 15px;
      color: $color-gray-03;
      text-transform: uppercase;
      margin-right: $separator-s;

      &:nth-child(1) {
        width: 75%;
      }

      &:nth-child(2) {
        width: 15%;
        text-align: right;
      }

      &:nth-child(3) {
        width: 10%;
        text-align: right;
      }
    }
  }

  .xapi-individual-results-table__content {
    width: 100%;
    .xapi-individual-results-table__content-title {
      font-family: $font-first;
      min-height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .xapi-individual-results-table__row {
    display: flex;
    align-items: center;
    min-height: 50px;

    font-family: $font-second;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: $color-gray-02;

    .xapi-individual-results-table__row-title {
      width: 75%;
      display: flex;
      flex-direction: column;

      .xapi-individual-results-table__row-question {
        display: flex;
        align-items: center;
        min-height: 50px;
        cursor: pointer;
        .xapi-individual-results-table__row-question-text {
          @extend .ellipsis-complex;
          max-width: 95%;
          margin-left: $separator-m;
          font-size: 16px;
        }
      }

      .xapi-individual-results-table__row-response {
        display: none;
        width: 100%;
        margin-left: $separator-xxxl;
        margin-bottom: $separator-m;

        &--open {
          display: flex;
          flex-direction: column;
        }

        .xapi-individual-results-table__row-response-title {
          font-size: 14px;
          line-height: 20px;
        }

        .xapi-individual-results-table__row-response-text {
          font-size: 16px;
          font-family: $font-second;
          font-weight: 700;
          line-height: 25px;
        }
      }
    }

    .xapi-individual-results-table__row-attempts {
      width: 15%;
      text-align: right;
      font-size: 14px;
    }

    .xapi-individual-results-table__row-score {
      font-family: $font-first;
      font-weight: 700;
      font-size: 16px;
      width: 10%;
      text-align: right;
    }

    .score--needs-reinforcement {
      color: $score-needs-reinforcement;
    }

    .score--needs-ampliacion {
      color: $score-needs-ampliacion;
    }

    .score--good-progress {
      color: $score-good-progress;
    }
  }

  .xapi-individual-results-table__accordion {
    min-height: 40px;

    .MuiAccordionDetails-root {
      flex-direction: column;
    }

    .MuiIconButton-root {
      padding: 0;
      margin-right: 14px;
    }
  }

  .xapi-individual-results-table__accordion-title {
    font-family: $font-first;
    font-weight: 700;
    font-size: $font-size-10;
    line-height: 15px;
    color: $color-gray-02;
    text-transform: uppercase;
    min-height: 40px;
    border-bottom: 1px solid $color-gray-05;
    flex-direction: row-reverse;
  }

  .xapi-individual-results-table__category-title {
    font-family: $font-first;
    font-size: 16px;
    color: $color-gray-02;
    min-height: 40px;
    border-bottom: 1px solid $color-gray-05;
    display: flex;
    align-items: center;
  }
}

.xapi-individual-results-table__row-tooltip {
  font-family: $font-second;
  height: auto;
  font-size: $font-size-10;
}
