$course-card-grandient-margin-vertical: $separator-s;
$course-card-grandient-height: 72px;
$course-card-icon-size: $size-avatar-picture;
$course-card-title-color: $color-title;
$course-card-text-color: $color-label;
$course-card-edit-icon-size: 16px;
$course-card-edit-size: 34px;

.course-card {
  transition-duration: $transition-duration-01;
  height: 100%;

  .card-generic__children {
    height: 100%;
  }

  .more-icon-menu {
    visibility: hidden;
  }

  .icon-bubble {
    border-radius: 10%;
  }

  &:hover {
    cursor: pointer;
    box-shadow: $shadow-card-secondary;
    .more-icon-menu {
      visibility: visible;
    }
  }
  .program-image__picture-container {
    height: 70px;
    width: 70px;
    border-radius: $border-radius-01;
  }

  .program-image__picture {
    border-radius: $border-radius-01;
  }
}

// image, gradient, icon
.course-card__icon-container {
  position: relative;
  height: $course-card-grandient-height;
  margin: 0 auto $course-card-grandient-margin-vertical auto;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .program-image__picture {
    height: $course-card-icon-size;
    width: $course-card-icon-size;
    object-fit: cover;
  }
}

// end image, gradient, icon

.course-card__info {
  height: calc(100% - #{$size-icon-bubble-big} - #{$padding-card-default-vertical});

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-card__edit--icon {
  background-image: $button-gradient;
  border: 2px solid $color-white;
  border-radius: 100%;
  left: calc($icon-bubble-size-big - 20px);
  top: -$separator-xs;
  padding: 0;
  width: $course-card-edit-size;
  height: $course-card-edit-size;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    height: $course-card-edit-icon-size;
    width: $course-card-edit-icon-size;
  }
}

.course-card__info-item {
}

.course-card__info-item--name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $course-card-title-color;
}

.course-card__info-item-configure {
  @extend .text-link;
  @extend .title-h6;
  @extend .hover-basic;

  margin-top: $main-separator-components;
}

.class-room-card__texts {
  display: flex;
  flex-direction: column;
}

// .course-card__info-item--level {
//   @extend .text-small-bold;
//   @extend .ellipsis;

//   color: $course-card-text-color;
// }

// .course-card__info-item--students {
//   @extend .text-smallest;

//   color: $course-card-text-color;
// }
