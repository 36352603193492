.image-gradient {
  background-size: cover;
  position: relative;
  background-position: center;

  height: 100%;
  width: 100%;
}

.image-gradient__gradient {
  position: absolute;

  background-image: $gradient;
  opacity: 0.5;

  height: 100%;
  width: 100%;
}
