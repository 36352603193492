$student-grades-grades-text-separator: $main-separator-items;
$student-grades-grades-section-separator: $main-separator-components;
$student-grades-selector-width: 183px;

.student-grades-grades {
  display: flex;
  flex-direction: column;

  .student-grades-grades__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex: 0 0 auto;
  }

  .student-grades-grades__title {
    @extend .grades__title;
  }

  .student-grades-grades__text {
    @extend .grades__text;

    margin-top: $student-grades-grades-text-separator;
  }

  .student-grades-grades__selector {
    // display: none;
    min-width: $student-grades-selector-width;
  }

  .student-grades-grades__content {
    margin-top: $student-grades-grades-section-separator;
  }

  .student-grades-grades__treasurehunt-activity {
    @extend .card-default-big;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: $main-separator-items;
  }

  .student-grades-grades__treasurehunt-name {
    @extend .title-h6;

    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
  }

  .student-grades-grades__treasurehunt-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin-top: $student-grades-grades-section-separator;
    padding-left: 16px;
    padding-right: 128px;

  }

  .student-grades-grades__treasurehunt-title {
    @extend .title-h6;

    color: $color-gray-03;
    font-size: $font-size-10;
    text-transform: uppercase;
  }
}
