$program-image-picture-size: 44px;

.program-image__bubble {
  flex: 0 0 auto;
}

.program-image__picture-container {
  border-radius: 100%;
  border-style: solid;
  padding: 1px;
  z-index: 10;
}

.program-image__picture {
  background-size: cover;
  border-radius: 100%;

  width: $program-image-picture-size;
  height: $program-image-picture-size;
  image-rendering: -webkit-optimize-contrast;
}

.program-image__edit {
  @extend .title-h7;
  transform: rotate(90deg);
  background: $color-gray-02;
  border-radius: 8px 8px 0px 0px;
  color: $color-gray-06;
  // margin: $separator-xs;
  padding: 1px $separator-xs;
  padding-bottom: 20px;
  position: absolute;
  left: 36px;
  top: 13px;
  width: 68px;
  z-index: 1;
  text-align: center;
  // box-shadow: $box-shadow-02;
}
