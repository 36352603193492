$mint-view-header-teacher-legend-bubble-size: 10px;

.mint-view-header-teacher {
  .mint-header-lms__alternative-view {
    width: 100%;
    min-height: 420px;
  }

  .assessment-instructions-view__date {
    width: 100%;
    margin-bottom: 0;
    z-index: 100;
    max-width: 300px;
  }

  .mint-header-lms__actions {
    display: flex;
    justify-content: space-between;

    .button {
      border-radius: $border-radius-08;
      padding: $button-padding;
      min-height: $button-height;
      justify-content: center;
    }

    .mint-header-lms__actions-tabs {
      align-items: flex-start;
    }

    .input-date__label {
      display: none;
    }

    .mint-header-lms__date {
      flex: 1 1 auto;
      justify-content: flex-end;
      display: flex;
      align-items: center;
    }
  }
}

.mint-view-header-teacher__legend {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mint-view-header-teacher__legend-item-bubble {
  border-radius: 100%;
  height: $mint-view-header-teacher-legend-bubble-size;
  width: $mint-view-header-teacher-legend-bubble-size;
}

.mint-view-header-teacher__legend-item {
  display: flex;
  align-items: baseline;
}

.mint-view-header-teacher__legend-item-text {
  @extend .text-smallest;

  color: $color-gray-02;
  display: block;
  margin-left: $separator-m;
}

.mint-view-header-teacher__chart {
  margin-top: $separator-l;
}
 
.mint-view-header-teacher__bars {
  .mint-view-header-teacher__legend {
    margin-top: $separator-l;
    justify-content: flex-start;
  }

  .mint-view-header-teacher__legend-item-text {
    margin-right: $separator-xl;
  }
}

.mint-view-header-teacher__actions-calendar {
  display: flex;
  align-items: center;
}

.mint-header-lms__actions-calendar-icon {
  background: $color-gray-07;
  border-radius: $border-radius-02;
  margin-right: $separator-s;
  padding: $separator-xs;
}

.mint-header-lms__actions-calendar-text {
  @extend .text-small-bold;

  color: $color-gray-02;
}
