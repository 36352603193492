$banner-all-resources-max-width: 774px;
$banner-all-resources-height: 132px;
$banner-all-resources-font-family: $font-second;
$banner-all-resources-title-font-size: 18px;
$banner-all-resources-title-font-weigth: 600;
$banner-all-resources-text-font-weigth: 400;
$banner-all-resources-text-font-size: 15px;
$banner-all-resources-text-line-height: 22.82px;
$banner-all-resources-title-line-height: 27px;
$banner-all-resources-text-color: $color-gray-01;
$banner-all-resources-card-shadow: 0px 1px 2px rgba(112, 107, 101, 0.15);
$banner-all-resources-card-border-radius: 4px;

.banner-all-resources {
  margin: $separator-m auto $separator-xxxxxl auto;
  width: $banner-all-resources-max-width;
  max-width: 90%;
  min-height: $banner-all-resources-height;
}

.banner-all-resources__card {
  @extend .card-default;

  box-shadow: $banner-all-resources-card-shadow;
  border: none;
  border-radius: $banner-all-resources-card-border-radius;
  padding: $separator-xxl;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.banner-all-resources__texts {
  color: $banner-all-resources-text-color;
  font-family: $banner-all-resources-font-family;
  margin-bottom: $separator-xxl;
  width: 100%;
}

.banner-all-resources__link {
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .button__text {
    margin-right: $separator-m;
  }
}

.banner-all-resources__texts-title {
  font-size: $banner-all-resources-title-font-size;
  font-weight: $banner-all-resources-title-font-weigth;
  margin-bottom: $separator-m;
  line-height: $banner-all-resources-title-line-height;
}

.banner-all-resources__texts-text {
  font-size: $banner-all-resources-text-font-size;
  font-weight: $banner-all-resources-text-font-weigth;
  line-height: $banner-all-resources-text-line-height;
}

@media #{$breakpoints-large} {
  .banner-all-resources__card--fanfest {
    flex-direction: row;
  }

  .banner-all-resources__link--fanfest {
    width: 40%;
    height: $banner-all-resources-height;
  }
  .banner-all-resources__texts--fanfest {
    width: 55%;
  }
}
