@for $i from 1 through 100 {
  .w-#{$i} {
    width: #{$i * 1 + '%'};
  }
}

@for $i from 1 through 100 {
  .h-#{$i} {
    height: #{$i * 1 + '%'};
  }
}

// -------------------------------------------------
// sm
// -------------------------------------------------
@media (min-width: 576px) {
  @for $i from 1 through 100 {
    .w-sm-#{$i} {
      width: #{$i * 1 + '%'};
    }
  }

  @for $i from 1 through 100 {
    .h-sm-#{$i} {
      height: #{$i * 1 + '%'};
    }
  }
}

// -------------------------------------------------
// md
// -------------------------------------------------
@media (min-width: 768px) {
  @for $i from 1 through 100 {
    .w-md-#{$i} {
      width: #{$i * 1 + '%'};
    }
  }

  @for $i from 1 through 100 {
    .h-md-#{$i} {
      height: #{$i * 1 + '%'};
    }
  }
}

// -------------------------------------------------
// lg
// -------------------------------------------------
@media (min-width: 992px) {
  @for $i from 1 through 100 {
    .w-lg-#{$i} {
      width: #{$i * 1 + '%'};
    }
  }

  @for $i from 1 through 100 {
    .h-lg-#{$i} {
      height: #{$i * 1 + '%'};
    }
  }
}

// -------------------------------------------------
// xl
// -------------------------------------------------
@media (min-width: 1200px) {
  @for $i from 1 through 100 {
    .w-xl-#{$i} {
      width: #{$i * 1 + '%'};
    }
  }

  @for $i from 1 through 100 {
    .h-xl-#{$i} {
      height: #{$i * 1 + '%'};
    }
  }
}

// -------------------------------------------------
// xxl
// -------------------------------------------------
@media (min-width: 1400px) {
  @for $i from 1 through 100 {
    .w-xxl-#{$i} {
      width: #{$i * 1 + '%'};
    }
  }

  @for $i from 1 through 100 {
    .h-xxl-#{$i} {
      height: #{$i * 1 + '%'};
    }
  }
}
