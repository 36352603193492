$score-points-label-square-width: 119px;
$score-points-label-square-height: 80px;
$score-points-label-student-font-size: 26px;
$score-points-label-total-font-size: 16px;
$score-points-label-text-font-size: 14px;
$score-points-label-student-font-color: #41d158;

.score-points-label {
  height: $score-points-label-square-height;
  width: $score-points-label-square-width;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: $border-size-01 solid $color-gray-05;
  border-radius: 4px;
}

.score-points-label__score {
  margin-bottom: $separator-s;
}

.score-points-label__score-student {
  font-family: $font-first;
  color: $score-points-label-student-font-color;
  font-size: $score-points-label-student-font-size;
}

.score-points-label__score-total {
  color: $color-gray-04;
  font-size: $score-points-label-total-font-size;
}

.score-points-label__text {
  color: $color-gray-03;
  font-size: $score-points-label-text-font-size;
  font-weight: 700;
}
