.student-courses-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: stretch;
  grid-gap: $main-separator-components;
}


@media #{$breakpoints-large} {
  .student-courses-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media #{$breakpoints-xlarge} {
  .student-courses-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media #{$breakpoints-xxxlarge} {
  .student-courses-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
