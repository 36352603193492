$dropdown-notifications-width: $size-dropdown-notifications-width;
$dropdown-notifications-container-empty-background: $color-gray-07;
$dropdown-notifications-container-empty-text: $color-gray-03;
$dropdown-notifications-top-separator: $separator-xl;

.dropdown-notifications__lib {
  margin-top: $dropdown-notifications-top-separator;

  .MuiPaper-root {
    padding-top: $separator-s;
    width: $dropdown-notifications-width;
  }

  .no-items__item--texts {
    margin-bottom: 0;
  }
}

.dropdown-notifications__lib--social {
  .MuiPaper-root {
    padding-bottom: $separator-s;
  }
}

.dropdown-notifications__text-empty-container {
  @extend .card-default-small;

  margin: 0 $padding-card-default-horizontal 0 $padding-card-default-horizontal;
  background: $dropdown-notifications-container-empty-background;
  border: 0;
  text-align: center;
}

.dropdown-notifications__text-empty {
  @extend .text-smallest;

  color: $dropdown-notifications-container-empty-text;
}
