$next-event-dropdown-item-title-color: $color-title;
$next-event-dropdown-item-detail-color: $color-label;

$next-event-dropdown-item-course-separator: $main-separator-items;
$next-event-dropdown-item-hover-background: $color-gray-07;

.next-event-dropdown-item {
  @extend .card-default-small;
  @extend .hover-basic;

  border: 0;

  &:hover {
    background: $next-event-dropdown-item-hover-background;
  }
}

.next-event-dropdown-item__center {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: $separator-xs;
}

.next-event-dropdown-item__title {
  @extend .text-extra;
  @extend .ellipsis-complex;

  color: $next-event-dropdown-item-title-color;
}

.next-event-dropdown-item__detail {
  @extend .text-extra;

  display: flex;
  align-items: center;
  color: $next-event-dropdown-item-detail-color;
}

.next-event-dropdown-item__course-name {
  @extend .text-xs;

  margin-left: $next-event-dropdown-item-course-separator;
}
