$dialog-configure-course-header-height: $size-layout-progress-header-height;
$course-picture-size: 100px;

.dialog-configure-course {
  z-index: $z-index-popup !important;
}

.dialog-configure-course__header {
  height: $layout-progress-image-height-mobile;
  @media #{$breakpoints-xlarge} {
    height: $layout-progress-image-height;
  }

  .header-info-course__container {
    @extend .grid;

    position: relative;

    .program-image__picture {
      height: $course-picture-size;
      width: $course-picture-size;
    }
  }

  .header-info-course__icon-container {
    position: relative;
  }
}

@media #{$breakpoints-xxxlarge} {
  .dialog-configure-course__header {
    .header-info-course__icon-container {
      position: absolute;
      left: calc(-#{$size-icon-bubble-big} - #{$main-separator-components});
    }

    .header-info-course__info-container {
      margin-left: 0;
    }
  }
}
