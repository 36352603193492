$pdf-content-page-color: $color-label2;
$pdf-content-page-separator: $main-separator-items;
.pdf-content {
  .react-pdf__Page__canvas {
    margin: auto;
    max-width: 100%;
    height: auto !important;
  }

  .pdf-content__document {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .pdf-content__page {
    width: 50%;
  }
}

.content-detail__page-number {
  @extend .text-small-bold;

  color: $pdf-content-page-color;
  margin-top: $pdf-content-page-separator;
  margin-bottom: $pdf-content-page-separator;
}
.react-pdf__Page__textContent {
  max-width: 100%;
  max-height: 100%;
}
