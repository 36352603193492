$comment-item-date-spaces: $separator-m;
$comment-item-user-color: $color-title;
$comment-item-date-color: $color-label;
$comment-item-text-color: $color-title;

.comment-item__info {
  display: flex;
  flex-direction: column;
}

.comment-item__info-main {
  @extend .ellipsis-complex;
}

.comment-item__user {
  @extend .text-small-bold;

  color: $comment-item-user-color;
}

.comment-item__date {
  @extend .text-smallest;

  color: $comment-item-date-color;
  margin-left: $comment-item-date-spaces;
}

.comment-item__text {
  @extend .text-small;

  color: $comment-item-text-color;
}

.comment-item__options {
  @extend .hover-basic;
}
