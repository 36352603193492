@import './GamifiedCardClass/GamifiedCardClassStudent.scss';
@import './GamifiedCardClass/GamifiedCardClassTeacher.scss';
@import './GamifiedExpProgress/GamifiedExpProgress.scss';
@import './GamifiedLevelProgress/GamifiedLevelProgress.scss';
@import './GamifiedMedalProgress/GamifiedMedalProgress.scss';
@import './GamifiedToast/GamifiedToast.scss';
@import './icons/index';

.gamified {
  &__subtitle {
    color: var(--grays-gray-03, $color-gray-03);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__box {
    background: #fff;
    border-radius: 12px;
    border: 2px solid var(--background-strokes, #eee4dc);
    padding: 8px 20px;

    display: flex;
    flex-direction: column;
  }
}
