$videolesson-results-view-layout-calendar-right-background: $color-background;
$videolesson-results-view-search-separator: $separator-xxxl;
$videolesson-results-view-list-title-border: $border-size-01 solid $color-borders;
$videolesson-results-view-list-padding: $separator-s;
$videolesson-results-view-list-separator: $main-separator-items;
$videolesson-results-view-list-title-color: $color-label;
$videolesson-results-view-doughnut-with: 400px;
$videolesson-results-view-doughnut-height: 300px;
$videolesson-results-view-attention-card-separator: $separator-xxxxl;
$videolesson-results-view-title-padding: $main-separator-items;
$videolesson-results-view-title-color: $color-gray-01;
$videolesson-results-view-due-date-separator: $main-separator-items;
$videolesson-results-view-due-date-value-color: $color-first;
$videolesson-results-view-results-separator: $main-separator-components;
$videolesson-results-view-results-stats-separator: $separator-xxxl;
$videolesson-results-view-results-note-width: 175px;
$videolesson-results-view-left-padding: $main-separator-items;
$videolesson-results-view-separators: $main-separator-components;
$videolesson-results-view-list-profile-width: 265px;
$videolesson-results-view-text-separator: $main-separator-items;
$videolesson-results-view-text-color: $color-title;
$videolesson-results-view-on-time-separator: $main-separator-items;
$videolesson-results-view-on-time-value-color: $color-title;
$videolesson-results-view-no-users-width: 80%;
$videolesson-results-view-student-list-border: $border-size-active;
$videolesson-results-view-student-search-border: $border-size-01;
$videolesson-results-view-student-padding: $separator-l;
$videolesson-results-view-student-send-margin: $separator-xl;
$videolesson-results-view-student-send-width: 100px;
$videolesson-results-view-student-icon-search-left: $separator-m;
$videolesson-results-view-student-icon-search-width: 24px;
$videolesson-results-view-student-title-height: 56px;
$videolesson-results-view-student-title-color: $color-gray-01;
$videolesson-results-view-student-list-color: $color-gray-07;
$videolesson-results-view-status-entry-margin: 53px;
$videolesson-results-view-legend-circle-size: 10px;

.videolesson-results-view {
  overflow-x: hidden;

  .layout-header {
    background: $color-white;
  }

  .MuiAccordion-root {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    height: auto !important;

    background: transparent;
    box-shadow: none;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    border-bottom: $videolesson-results-view-list-title-border;
    margin: 0;

    .videolesson-results-view__users-list-header-text,
    .videolesson-results-view__users-list-header-length {
      color: $color-gray-02;
    }
  }

  .MuiAccordionSummary-root {
    height: auto !important;
  }

  .MuiIconButton-root {
    margin-right: 0;
  }

  .note-card__text {
    font-family: $font-first;
  }

  .lemonade-exercises .lemo-multiple-choice .lemo-question-body.lemo-with-columns {
    flex-direction: column;
    align-items: stretch;
  }
  .lemonade-exercises .lemo-multiple-choice .lemo-question-body.lemo-with-columns .lemo-column-1 {
    width: auto;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts {
    background: $color-gray-07;
    margin-bottom: $separator-m;
    font-family: $font-first;
  }

  .vl-lesson .vl-lemonade-question__controls {
    display: none;
  }

  .vl-lesson .vl-wrapper-question .vl-question {
    border-bottom: 1px solid $color-gray-05;
  }
  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__label {
    font-size: 15px;
    font-weight: 700;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__label,
  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__number {
    color: $color-gray-03;

    svg {
      display: none;
    }
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts {
    justify-content: flex-end;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__right,
  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__left {
    button {
      background: transparent;
    }
  }

  .vl-lesson .vl-button--arrow-attempts {
    border-radius: 50%;
    border: 1px solid $color-gray-03;
    height: 28px;
    width: 28px;
    margin: auto $separator-s;
    svg {
      border: none;
    }
    svg rect {
      fill: transparent;
    }
    svg path {
      fill: $color-gray-03;
    }
  }

  .vl-lesson .vl-navigation-attempts__right {
    .vl-navigation-attempts__number {
      font-size: 22px;
      color: $color-gray-02;

      span {
        font-size: 15px;
        color: $color-gray-04;
      }
    }
  }

  .vl-lesson .vl-lemonade-question .vl-lemonade-question__score-wrapper {
    .assessment-grade-tag {
      padding: $separator-m;

      .assessment-grade-tag__grade-container {
        margin-right: $separator-s;
      }
    }
  }
}

.videolesson-results-view__center-column {
  border-radius: $videolesson-results-view-student-list-border;
  background: $videolesson-results-view-student-list-color;
  padding-top: $videolesson-results-view-student-send-margin;
}

.videolesson-results-view__send {
  display: flex;
  justify-content: center;
  margin-bottom: $videolesson-results-view-student-send-margin;

  .button {
    width: $videolesson-results-view-student-send-width;
  }
}

.videolesson-results-view__students-header {
  display: flex;
  align-items: center;
  height: $videolesson-results-view-student-title-height;
  padding-left: $videolesson-results-view-student-padding;
  cursor: pointer;

  &.selected {
    background-color: rgba(237, 112, 17, 0.1);
  }

  .videolesson-results-view__students-name {
    @extend .title-h6;

    margin-left: $videolesson-results-view-list-padding;
    color: $videolesson-results-view-student-title-color;
  }
}

.videolesson-results-view__search {
  width: 100%;
  padding: $videolesson-results-view-student-padding;

  .input-text__input {
    border-radius: $videolesson-results-view-student-search-border;
    padding-left: 52px;
  }

  .input-text__icon {
    width: $videolesson-results-view-student-icon-search-width;
    left: $videolesson-results-view-student-icon-search-left;
    right: 0;
  }
}

.videolesson-results-view__accordions {
  .basic-list__item {
    margin: $separator-xxl 0;
  }
}

.videolesson-results-view__users-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $videolesson-results-view-list-padding $videolesson-results-view-student-padding;
  width: 100%;
}

.videolesson-results-view__users-list-header-left {
  display: flex;
}

.videolesson-results-view__users-list-header-text,
.videolesson-results-view__users-list-header-length {
  @extend .text-small-bold;
  color: $videolesson-results-view-list-title-color;
}

.videolesson-results-view__users-list-select-all {
  margin-right: $videolesson-results-view-student-padding;
}

.videolesson-results-view__user-empty {
  @extend .text-small;

  display: flex;
  justify-content: center;
  padding: $videolesson-results-view-student-padding 0;
  color: $videolesson-results-view-list-title-color;
}

.videolesson-results-view__user-list {
  margin-top: $videolesson-results-view-list-padding;
  padding-right: $videolesson-results-view-left-padding;
  width: 100%;

  .basic-list__item {
    @extend .hover-basic;

    margin-left: $videolesson-results-view-student-padding;
  }

  .basic-list__item:last-child {
    margin-bottom: $main-separator-items;
  }
}

.videolesson-results-view__top-info {
  display: flex;
  justify-content: space-between;

  margin-bottom: $videolesson-results-view-attention-card-separator;
}

.asssesment-results-view__status {
  display: flex;
}

.asssesment-results-view__status-entry {
  margin-right: $videolesson-results-view-status-entry-margin;
}

.videolesson-results-view__attention-card {
  flex: 1 1 auto;
  margin-right: $videolesson-results-view-results-stats-separator;

  .attention-card {
    height: 100%;
  }
}

.assessment-result-view__bars-legend {
  display: none;
  margin-top: $separator-xxl;
}

.assessment-result-view__doughnut-legend {
  display: none;
  justify-content: space-evenly;
}

.assessment-result-view__bars-legend-bad,
.assessment-result-view__bars-legend-good,
.assessment-result-view__bars-legend-not-turned {
  display: flex;
  align-items: center;
}

.assessment-result-view__bars-legend-good {
  margin-left: $separator-xxxl;
}

.assessment-result-view__bars-legend-not-turned {
  margin-right: $separator-xxxl;
}

.assessment-result-view__bars-legend-circle {
  width: $videolesson-results-view-legend-circle-size;
  height: $videolesson-results-view-legend-circle-size;
  border-radius: 100%;
  margin-right: $separator-s;
}

.assessment-result-view__bars-legend-circle--not-done {
  background: $color-gray-06;
}

.assessment-result-view__bars-legend-circle--bad {
  background: $color-error;
}
.assessment-result-view__bars-legend-circle--good {
  background: $color-success;
}

.assessment-result-view__bars-legend-text {
  @extend .text-smallest;

  color: $color-gray-02;
}

.assessment-result-view__stats-pie {
  margin: auto;

  .doughnut-chart {
    display: flex;
    justify-content: center;
  }

  .doughnut-rechart {
    width: 100%;
    height: $videolesson-results-view-doughnut-height;
  }

  .analytic-card-donuts__legend {
    @extend .title-h6;
  }
}

.videolesson-results-view__title-container {
  padding: $videolesson-results-view-title-padding 0;
}

.videolesson-results-view__title {
  @extend .title-h5;

  color: $videolesson-results-view-title-color;
}

.videolesson-results-view__subtitle {
  @extend .text-p;

  margin-top: $separator-xs;
  color: $color-gray-02;
}

.videolesson-results-view__due-date-container {
  display: flex;
  align-items: center;
}

.videolesson-results-view__due-date {
  @extend .text-extra;

  display: flex;
  align-items: center;

  margin-left: $videolesson-results-view-due-date-separator;
}

.videolesson-results-view__due-date-value {
  color: $videolesson-results-view-due-date-value-color;
}

.videolesson-results-view__results-info {
  display: flex;
  align-items: start;
  justify-content: space-between;

  margin-top: $videolesson-results-view-results-separator;
}

.videolesson-results-view__stats {
  flex: 1 1 auto;
  margin: 0 $videolesson-results-view-results-stats-separator;
}

.videolesson-results-view__note {
  .note_card {
    padding: 0 !important;
  }
}

.videolesson-results-view__on-time-container {
  display: flex;
  align-items: center;

  border-bottom: $videolesson-results-view-list-title-border;
  margin-top: $videolesson-results-view-on-time-separator;
  padding-bottom: $videolesson-results-view-on-time-separator;
}

.videolesson-results-view__on-time {
  @extend .text-extra;

  display: flex;
  align-items: center;

  margin-left: $videolesson-results-view-on-time-separator;
}

.videolesson-results-view__on-time-value {
  color: $videolesson-results-view-on-time-value-color;
}

.videolesson-results-view__no-items {
  margin: auto;
  width: $videolesson-results-view-no-users-width;
}

.vl-lesson .vl-solutions-list-component {
  align-items: flex-start;
}

.vl-lesson .vl-solutions-list-component .vl-solutions-list-component__question {
  max-width: 100%;
}
.vl-lesson .vl-lemonade-question .vl-lemonade-question__question .vl-lemonade-question__time {
  display: none;
}
// Responsive

.videolesson-results-view {
  .videolesson-results-view__mobile-menu-button {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 9999;

    .button {
      border-radius: 50%;
      padding: 12px 12px;
    }
  }

  .videolesson-results-view__mobile-menu-backdrop {
    position: fixed;
    display: none;
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0);
    top: 0;
    right: 0;
    opacity: 0;
    z-index: $z-index-aside-overlay;
  }

  .layout-calendar__column-1 {
    padding-top: 84px;
    padding-right: 0;
    right: -450px;
    left: unset;
    top: 0;
    height: 100vh !important;
    z-index: $z-index-aside;
    transition: right 0.3s ease-in-out;
  }

  .layout-calendar__column-2 {
    padding-left: 28px;
  }

  &.videolesson-results-view__mobile-menu--open {
    .layout-calendar__column-1 {
      right: 0;
      transition: right 0.3s ease-in-out;
    }
    .videolesson-results-view__mobile-menu-button {
      right: 57px;
    }
    .videolesson-results-view__mobile-menu-backdrop {
      display: block;
      opacity: 0.25;
    }
  }
}

.videolesson-results-view__attention-card {
  flex: 1 1 auto;
  margin-right: $videolesson-results-view-results-stats-separator;
  max-width: 550px;

  .attention-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .attention-card__actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: $separator-s;

      .button {
        margin-top: $separator-m;
      }
    }
  }
}

.videolesson-results-view__top-info .videolesson-results-view__note {
  display: block;
  margin-left: 30px;
  align-self: center;
  min-width: 119px;
  .note-card {
    padding: $separator-m $separator-s;
  }

  .note-card__note {
    font-size: $font-size-03;
  }

  .note-card__note-total {
    font-size: $font-size-06;
    color: $color-gray-04;
    margin-left: $separator-xxs;
  }
}

.videolesson-results-view__right {
  .vl-lesson,
  .lemonade-exercises .lemo-multiple-choice,
  .vl-lesson .vl-wrapper-question .vl-lemonade-question__question,
  .lemonade-exercises .lemo-multiple-choice .lemo-option-content,
  .vl-lesson .vl-lemonade-question .vl-lemonade-question__question .vl-lemonade-question__intents,
  .vl-lesson .vl-results-counter .vl-results-counter__text,
  .vl-font-heading-h2,
  .vl-lesson .vl-results-counter .vl-results-counter__number {
    font-family: $font-second !important;
  }
  .lemonade-exercises .lemo-multiple-choice .lemo-option-content {
    color: $color-gray-02;
  }
  .vl-lesson .vl-wrapper-question .vl-lemonade-question__question {
    border: none;
    box-shadow: none;
    font-size: 18px;
    border-radius: 0 !important;
  }
  .lemonade-exercises .lemo-base-option .lemo-base-option__wrapper {
    background-color: transparent !important;
  }
  .lemonade-exercises .lemo-base-checkbox--feedback-success,
  .lemonade-exercises .lemo-dropdown-control--feedback-success,
  .lemonade-exercises .lemo-success,
  .lemonade-exercises .lemo-success .lemo-drag-option {
    background: rgba(65, 209, 88, 0.1);
    border-radius: 8px;
    width: auto;
  }
  .lemonade-exercises .lemo-base-checkbox--feedback-error,
  .lemonade-exercises .lemo-dropdown-control--feedback-error,
  .lemonade-exercises .lemo-error,
  .lemonade-exercises .lemo-error .lemo-drag-option {
    background: rgba(247, 104, 104, 0.1);
    border-radius: 8px;
    width: auto;
  }

  .vl-lesson .vl-results {
    border-radius: 0;
    border: none;
    padding: 0;
    justify-content: flex-end;
    gap: $separator-xl;
    margin-bottom: $separator-xxxxxl;
    display: none;
  }

  .vl-lesson .vl-results .vl-results-separator {
    display: none;
  }

  .vl-lesson .vl-results-counter {
    align-items: center;
    padding: 12px 8px;
  }

  .vl-results-counter:last-child {
    border: 1px solid #dbd5ce;
  }
  .vl-lesson .vl-lemonade-question .vl-lemonade-question__question .vl-lemonade-question__intents {
    font-weight: 600;
  }

  .vl-lesson .vl-results-counter .vl-results-counter__text {
    color: $color-gray-03;
    font-weight: 700;
    font-size: 15px;
  }
  .vl-font-heading-h2,
  .vl-lesson .vl-results-counter .vl-results-counter__number {
    color: $color-gray-02;
  }

  .lemonade-exercises .lemo-title,
  .lemonade-exercises .lemo-stimulus {
    color: $color-gray-02;
    font-weight: 600;
    font-size: 18px;
  }

  .videolesson-results__header {
    font-family: $font-second;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .videolesson-results__header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $separator-xxxxxl;
    width: 50%;
  }

  .videolesson-results__correct-answers,
  .videolesson-results__wrong-answers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .videolesson-results__correct-answers-counter {
    font-size: 32px;
    font-weight: 600;
    color: $color-gray-02;
    margin-bottom: $separator-m;
  }

  .videolesson-results__correct-answers-text,
  .note-card__text {
    font-family: $font-first;
    color: $color-gray-03;
    font-size: 15px;
    font-weight: 700;
  }

  .videolesson-results__header-left {
    @extend .custom-assessment-student-results__header-left;
    margin-bottom: $separator-xxxl;
    width: 50%;
  }
  .videolesson-results__back {
    @extend .custom-assessment-student-results__back;
  }

  .videolesson-results__info {
    @extend .custom-assessment-student-results__info;
  }

  .videolesson-results__avatar {
    @extend .custom-assessment-student-results__avatar;
  }
  .videolesson-results__name {
    @extend .custom-assessment-student-results__name;
  }
  .videolesson-results__date {
    @extend .custom-assessment-student-results__date;
  }

  .vl-lesson .vl-lemonade-question .vl-lemonade-question__question-points {
    display: none;
  }
  .vl-lesson .vl-lemonade-question .vl-lemonade-question__question .vl-lemonade-question__intents {
    background-color: #f7f4f2;
    color: #a39b93;
    font-family: $font-second;
    font-weight: 700;
    font-size: 500;
    svg {
      display: none;
    }
  }
}

@media #{$breakpoints-medium} {
  .videolesson-results-view {
    .videolesson-results__header-left,
    .videolesson-results__header-right {
      width: 50%;
    }
  }
}

@media #{$breakpoints-large} {
  .videolesson-results-view {
    .videolesson-results__header-left,
    .videolesson-results__header-right {
      width: 100%;
    }
    .videolesson-results-view__mobile-menu-button {
      display: none;
    }
    .videolesson-results-view__mobile-menu-backdrop {
      display: none;
    }

    .layout-calendar__column-1 {
      background-color: $color-white;
      border-left: $videolesson-results-view-list-title-border;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-right: 0;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 64px) !important;
      position: fixed;
      left: 0;
      padding-left: 32px;
      top: unset;
    }

    .layout-calendar__column-2 {
      padding-top: 40px;
      padding-left: calc(450px + 40px);
      padding-bottom: 20px;
      width: 100%;
    }

    .videolesson-results-view__results-info .videolesson-results-view__note {
      display: block;
    }

    .videolesson-results-view__attention-card {
      max-width: none;

      .attention-card {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .attention-card__actions {
          display: flex;
          flex-direction: row;

          .button {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media #{$breakpoints-xlarge} {
  .videolesson-results-view {
    .videolesson-results__header-left,
    .videolesson-results__header-right {
      width: 50%;
    }
  }
}
