$dialog-status-big: $size-dialog-small-max-width;
$dialog-status-big-icon-separator: $main-separator-components;
$dialog-status-big-icon-size: 133px;

.dialog-status-big {
  .MuiPaper-root {
    max-width: $dialog-status-big;
  }
}

.dialog-status-big__title {
  @extend .title-h5;

  text-align: center;
}

.dialog-status-big__icon {
  text-align: center;
  margin-top: $dialog-status-big-icon-separator;

  .icon {
    height: $dialog-status-big-icon-size;
    width: $dialog-status-big-icon-size;
  }
}
