.scorm-individual-results-progress {

  width: 182px;
  background: $color-gray-07;
  border-radius: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .scorm-individual-results-progress__bar {
    position: relative;
    margin: 4px 0;
    height: 4px;
    flex-grow: 1;

    .scorm-individual-results-progress__fill {
      position: absolute;
      left: 0;
      background: $color-label;
      height: 4px;
      z-index: 1;
    }

    .scorm-individual-results-progress__bg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      background: $color-gray-05;
    }
  }

  .scorm-individual-results-progress__text {
    @extend .title-h6;

    color: $color-title;
    display: block;
    margin-left: 18px;
  }
}
