.custom-tooltip {
  padding: 4px 8px;
  border-radius: 8px;
  background: #f3f6f9;
  box-shadow: 0px 4px 4px 0px rgba(140, 153, 173, 0.25);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #303949;
}
