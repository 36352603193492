$kanban-view-students-margin: $separator-l;

.kanban-view {
  .footer-banner {
    top: 0;
    bottom: auto;
    z-index: $z-index-footer;
    padding: $separator-m;
  }
}

.kanban-view__students {
  display: flex;
  margin-bottom: $kanban-view-students-margin;
  padding: 0 $separator-s;
}
