$message-sender-simple-textarea-height: $size-height-input;
$message-sender-simple-separator: $main-separator-items;
$message-sender-simple-with-left: $size-avatar-default;
$message-sender-simple-main-right-icons: $textarea-padding;
$message-sender-simple-padding: $separator-l;

.message-sender-simple {
  @extend .card-background;

  padding-left: $message-sender-simple-padding;
  padding-right: $message-sender-simple-padding;

  .textarea__input {
    height: $message-sender-simple-textarea-height;
    min-height: $message-sender-simple-textarea-height !important;
  }
}

.message-sender-simple__main {
  display: flex;
}

.message-sender-simple__avatar {
  width: $message-sender-simple-with-left;
  text-align: center;
}

.message-sender-simple__textarea {
  flex: 1 1 auto;
  margin: 0 $message-sender-simple-separator;
  position: relative;
  .textarea__input {
    resize: none;
  }
}

.message-sender-simple__send {
  @extend .hover-basic;

  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: $message-sender-simple-main-right-icons;
}
