$question-item-numeration-separator: $main-separator-components;
$question-item-points-width: 110px;
$question-item-numeration-size: 42px;
$question-item-limit-separator: $main-separator-components;
$solution-corrective-feedback-padding: $separator-m;
$solution-corrective-feedback-show-height: 70px;
$solution-corrective-feedback-max-height: 170px;
$solution-corrective-feedback-expanded-max-height: 700px;
$solution-corrective-feedback-border: $border-radius-01;
$solution-corrective-feedback-border-color: $color-error;
$solution-corrective-feedback-color: $color-error;
$solution-corrective-feedback-background: $color-first-alpha;
$question-item-limit-color: $color-label;
$question-item-ranking-scale-color: $color-gray-03;

.question-item {
  @extend .card-default;

  .lemonade-view__container {
    border: none;
  }

  .lemo-question-controls {
    margin: 0;
  }
  .lemo-question-controls,
  .lemo-send-controls {
    width: 100%;
  }
}

.question-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question-item__header-left {
  display: flex;
  align-items: center;
}

.question-item__header-right {
  display: flex;
  flex-direction: column;
}

.question-item__result-tag {
  margin-top: $solution-corrective-feedback-padding;
}

.question-item__numeration {
  @extend .title-h5;

  margin-right: $question-item-numeration-separator;

  .numeration {
    display: flex;
    justify-content: center;
    width: $question-item-numeration-size;
    height: $question-item-numeration-size;
  }
}

.question-item__points {
  width: $question-item-points-width;
}

.question-item__limit-wrong {
  display: flex;
  justify-content: flex-end;
}

.question-item__limit-wrong-text {
  @extend .text-small;

  color: $question-item-limit-color;
  display: block;
  margin-top: $question-item-limit-separator;
}

.question-item__ranking-scale {
  @extend .text-p;

  color: $question-item-ranking-scale-color;
}

.question-item__student-result-score {
  @extend .text-p;

  color: $question-item-ranking-scale-color;
}

.solution-corrective-feedback__container {
  position: relative;
  border: 1px solid $solution-corrective-feedback-border-color;
  border-radius: $solution-corrective-feedback-border;
  background: $solution-corrective-feedback-background;
}

.solution-corrective-feedback {
  max-height: $solution-corrective-feedback-max-height;
  overflow: hidden;
  padding: $solution-corrective-feedback-padding;
  font-family: $font-second;
  * {
    color: $solution-corrective-feedback-color !important;
  }
  img {
    max-width: 100%;
  }
}

.solution-corrective-feedback__show-solution {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: $question-item-limit-separator;
  .switch {
    align-items: center;
  }
}

.solution-corrective-feedback__show {
  @extend .hover-basic;

  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: $solution-corrective-feedback-padding;
  height: $solution-corrective-feedback-show-height;
  background: linear-gradient(to top, #ffffff 33.16%, rgba(255, 255, 255, 0) 72.4%);
  border-radius: 0 0 $solution-corrective-feedback-border $solution-corrective-feedback-border;
}

.solution-corrective-feedback__sample-answer {
  margin: $separator-xxl auto;
  padding: $separator-l $separator-m;
  border-left: 4px solid $color-first;
  background: $color-gray-07;
  border-radius: 0px 8px 8px 0px;
  p {
    font-size: 18px;
  }
}

.solution-corrective-feedback__show-text {
  @extend .title-h6;

  color: $color-first;
}

.solution-corrective-feedback__container--expanded {
  .solution-corrective-feedback {
    //transition: max-height 0.3s ease-in;
    max-height: $solution-corrective-feedback-expanded-max-height;
    overflow-y: auto;
  }

  .solution-corrective-feedback__show {
    position: relative;
  }
}
