$unoi-lesson-info-nav-height: 120px;
$unoi-lesson-info-font-family: 'Dosis Semi Bold';
$unoi-lesson-info-primary-color: #4769e3;
$unoi-lesson-info-background-color: #f1f3f5;
$unoi-lesson-info-navigation-width: 229px;

.unoi-lesson-info {
  padding: 0;
  margin: 0;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $unoi-lesson-info-background-color;
  font-family: $unoi-lesson-info-font-family;
}

.unoi-lesson-info__navigation {
  height: $unoi-lesson-info-nav-height;
  width: 100vw;
  margin-left: -50px;
}

.unoi-lesson-info__content {
  margin: 0 auto;
  margin-top: $separator-l;
  padding: $separator-l;
  width: 100%;
}

@media #{$breakpoints-large} {
  .unoi-lesson-info {
    flex-direction: row;
  }
  .unoi-lesson-info__navigation {
    width: $aside-lesson-navigation-width;
  }
  .unoi-lesson-info__content {
    width: calc(100% - #{$separator-xxl} - #{$aside-lesson-navigation-width});
  }
}
