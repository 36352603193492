.app-content--publisher {
  display: flex;

  & > :not(.bar-menu) {
    flex: 1 1 auto;
  }

  .bar-menu {
    position: sticky;
    top: 0;
    height: 100vh;
  }

  .profile-view__mobile-menu-header {
    width: calc(100% - #{$size-bar-menu-width}) !important;
  }
}

.client--SANTILLANA_UNOI,
.client--SANTILLANA_ALFA {
  .my-classrooms-view__top {
    .header-tabs {
      display: none;
    }
  }
}

@media #{$breakpoints-xlarge} {
  .app-content--publisher {
    .layout-calendar__column-1 {
      left: $size-bar-menu-width !important;
    }
  }
}

.client--MACMILLAN {
  .home-page {
    padding-bottom: 40px;
    .home-page__footer {
      background: $gradient;
      position: fixed;
      width: 100%;
      box-shadow: $box-shadow-02;
      border: none;
      height: 40px;
      .home-page__politics,
      .home-page__desktop-versions {
        .login-form__legal-advice,
        .login-form__privacy,
        .login-form__desktop-versions {
          color: $color-white;
        }
      }
    }
  }
}

.app-content--msteams {
  .msteams-container {
    @extend .grid;

    width: 70%;
  }

  padding: 0 $separator-m $separator-m 0;
  .header-tabs.header-tabs__type-view-default.header-tabs__align-default {
    justify-content: flex-start;
  }
  .header-left {
    display: none;
  }
  .header-right {
    .header-button-right {
      .header-button-right__item {
        display: none;
      }
    }
  }

  .mosaic-list--col-2 {
    .mosaic-list__item {
      @include column(6);
    }
  }

  .book-card {
    .book-card__image {
      .program-image__picture {
        border-radius: 0;
      }
    }
  }

  .signup-step-back-button {
    margin-top: $separator-m;
    cursor: pointer;
  }

  .button__spinner {
    .sk-spinner {
      color: #333 !important;
    }
  }

  .welcome-step__container {
    margin-top: $separator-xxl;
    text-align: center;
    padding: $separator-m;

    .welcome-step__welcome-text {
      @extend .title-h6;
      margin: $separator-m;
    }
  }

  .signup__description,
  .signup-step__join-class-step,
  .tab-auth-end {
    @extend .title-h5;
    display: flex;
    align-items: center;
    margin: $separator-m auto;
  }

  .signup__license-disclaimer {
    @extend .label;

    margin-top: $separator-xxl;
  }

  .signup-step__teams-select-class,
  .signup-step__teams-select-class-to-create {
    @extend .title-h6;
    text-align: left;
    margin-bottom: $separator-m;
  }

  .signup-step__teams-select-class__select {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $separator-l;

    .select-customizable {
      width: 68%;
    }

    .button--default {
      width: 30%;
    }
  }
}

.app-content--msteams-dark {
  .welcome-step__container,
  .input-checkbox__label,
  .signup-step-license__licenses-title,
  .signup-step-back-button,
  .signup-step__teams-add-class,
  .line-with-text__text,
  .signup-step__teams-select-class-to-create,
  .signup-step__teams-select-class {
    color: $color-white;
  }

  .line-with-text__text {
    background: #292929;
  }

  .button__spinner {
    .sk-spinner {
      color: #333 !important;
    }
  }
}

@media (max-width: 960px) {
  .app-content--msteams {
    .msteams-container {
      width: 95%;
    }
  }
}
