$program-vanilla-dirty-padding-bottom: 90px;
$program-max-width: $size-program-max-width;
$program-max-width-books: 956px;

.program--dirty {
  padding-bottom: 90px;
}

.program {
  max-width: $size-layout-content-width;
}

.program--books {
  max-width: $program-max-width-books;
}

.program__times-list {
  padding-left: 0 !important;
  .times-accordion {
    width: 100%;
  }

  .book-card {
    margin-bottom: $separator-m;
    margin-right: $separator-m;

    flex: 0 0 auto;
    max-width: 150px;
  }

  .program-image__picture {
    border-top-right-radius: var(--border-radius-01);
    border-top-left-radius: var(--border-radius-01);
  }

  .times-accordion > div > .MuiAccordion-root {
    margin-top: 0 !important;
  }
}

.program__books-list {
  display: flex;
  flex-wrap: wrap;
}

.program__books-container {
  margin: 0 $separator-xxl $separator-xxl 0;
  flex: 0 0 auto;
}

.program__books-title {
  display: flex;
  align-items: center;

  margin-bottom: $separator-m;
}

.program__student-version-units {
  width: 100%;

  .lessons-list {
    .mosaic-list--col-3 {
      .mosaic-list__item {
        @include column(12);
      }
    }
  }
}

.program__student-version-text {
  @extend .title-h6;

  margin-bottom: $separator-m;
  color: $color-gray-02;
}

.program__times-list--with-times {
  .times-accordion {
    padding-left: 40px;
  }
}

@media #{$breakpoints-medium} {
  .program__times-list {
    display: flex;
  }

  .program__books-list {
    flex-direction: column;
  }

  .book-card {
    margin-right: 0 !important;
    flex: 1 1 auto !important;
    max-width: 100% !important;
  }

  .program__books-container {
    max-width: 150px;
    width: 100%;
  }
}
