$behaviour-form-textarea-separator: $main-separator-components;
$behaviour-form-textarea-min-height: 100px;

.behaviour-form__textarea {
  margin-top: $behaviour-form-textarea-separator;
  position: relative;

  textarea {
    min-height: $behaviour-form-textarea-min-height;
    padding-left: $separator-xxxxl;
  }

  .behaviour-form__icon {
    top: 10px;
    left: 10px;
    position: absolute;
  }
}
