$button-split-border: 1px solid white;
$button-split-icon-sepator: $main-separator-items;
$button-split-option-text-color: $color-label;
$button-split-option-text-color-hover: $color-title;
$button-split-option-height: 36px;
$button-split-option-selected-background: $color-gray-07;
$button-split-menu-border-radius: $border-radius-01;
$button-split-menu-box-shadow: $border-radius-01;

.button-split {
  .MuiMenuItem-root {
    padding: $separator-s $separator-m;
    height: $button-split-option-height;

    &:hover {
      .button-split__option-text {
        color: $button-split-option-text-color-hover;
      }
    }
  }

  .MuiList-padding {
    padding: 0;
  }

  .MuiPaper-root {
    @extend .border-default;

    border-radius: $button-split-menu-border-radius;
    box-shadow: $box-shadow-03;
  }
}

.MuiListItem-root.Mui-selected {
  background-color: $button-split-option-selected-background !important;

  .button-split__option-text {
    color: $button-split-option-text-color-hover;
  }
}

.button-split__container-buttons {
  display: inline-flex;
}

.button-split__button-main {
  .button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.button-split__button-menu {
  border-left: $button-split-border;
  .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: initial;
  }
}

.button-split__popper {
  z-index: $z-index-popup;
}

.button-split__option-icon {
  display: flex;
  align-items: center;
  margin-right: $button-split-icon-sepator;
}

.button-split__option-text {
  @extend .text-smallest;

  color: $button-split-option-text-color;
}

.button-split--disabled {
  .icon--chevron-down.icon--color-default path {
    fill: #ffffff;
  }
}
