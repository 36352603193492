.select-periods-time {
  label {
    display: none;
  }

  .select-customizable__control {
    min-height: 0 !important;
  }

  .select-customizable__control,
  .select-customizable__control:hover {
    border: none !important;
  }

  .select-customizable {
    min-width: 180px;
  }

  .button__text {
    color: var(--grays-06, #414b5c);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
}
