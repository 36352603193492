$avatar-with-name-color-active: $color-first;
$avatar-with-name-color-semiactive: $color-gray-06;
$avatar-with-name-name-separator: $main-separator-items;
$avatar-with-name-name-color: $color-title;
$avatar-with-name-border-size: $border-size-01;
$avatar-with-name-button-right: 8px;

.avatar-with-name {
  @extend .hover-basic;
  @extend .card-default-small;

  display: flex;
  flex-direction: column;
  position: relative;

  background: transparent;
  border: 0;
  height: 100%;
  padding: $padding-card-default;

  .avatar {
    // background: $avatar-with-name-color-active;
    border: $avatar-with-name-border-size solid transparent;
  }
}

.avatar-with-name__name {
  @extend .text-small;

  display: block;
  color: $avatar-with-name-name-color;
  margin-top: $avatar-with-name-name-separator;
}

.avatar-with-name--semiactive {
  background: $avatar-with-name-color-semiactive;

  .avatar-with-name__close {
    display: block;
  }
}

.avatar-with-name--active {
  .avatar {
    border: $avatar-with-name-border-size solid $avatar-with-name-color-active;
  }

  .avatar-with-name__name {
    color: $avatar-with-name-color-active;
  }

  .avatar-with-name__close {
    display: none;
  }
}

.avatar-with-name__close {
  position: absolute;
  right: $avatar-with-name-button-right;
}
