.ai-assistant {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1003;
  bottom: 24px;
  right: 24px;

  // reset
  ul,
  ol {
    list-style: initial;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    font-size: smaller;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
}

.ai-assistant__button {
  cursor: pointer;
  img {
    height: 120px;
    width: 120px;
    object-fit: contain;
  }
}

.ai-assistant__container {
  @extend .card-default;

  min-width: 200px;
  margin-bottom: $separator-m;
  max-height: 60vh;
  max-width: 80vw;
  overflow-y: auto;
}

.ai-assistant__header {
  @extend .hover-basic;

  display: flex;
  justify-content: flex-end;
}

.ai-assistant__content {
  margin-top: $separator-s;
}

.ai-assistant__hotspot {
  position: relative;
  float: left;
  z-index: 1;
  height: 50px;
  width: 50px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.5s ease;
}

.ai-assistant__dialog {
  .MuiDialog-paper {
    min-height: 400px !important;
    border-radius: 16px !important;
  }
  // .dialog__custom-header {
  //   display: flex;
  //   justify-content: center;
  // }
  .dialog__actions {
    padding: $separator-l 5%;
    .button {
      z-index: 10000;
    }
  }

  .loading-robot {
    svg {
      max-height: 200px;
    }
  }

  // reset
  ul,
  ol {
    list-style: initial;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    font-size: smaller;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
}

.ai-assistant__dialog-header {
  padding: $separator-l 5%;
  span {
    background-color: $color-first;
    color: $color-white;
    line-height: 20px;
    font-size: 15px;
    padding: 4px 12px;
    border-radius: 43px;
    font-weight: 700;
  }
}

.ai-assistant__dialog-image {
  display: flex;
  justify-content: center;
}

.ai-assistant__dialog-text {
  padding: 0 5%;
  font-family: $font-second;
  line-height: 25px;

  // p {
  //   margin: $separator-m auto;
  // }
}

.ai-assistant__hotspot:hover {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.ai-assistant__container--visible {
  display: block;
}

.ai-assistant__container--hidden {
  display: none;
}

.ai-assistant__hotspot--hover {
  background-color: $color-first-alpha !important;
}

.ai-assistant__loading {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.ai-assistant__loading {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.mint-epigraph {
  @extend .hover-basic;

  transition: all 0.5s ease;
  margin-bottom: $separator-m;
  * {
    background-color: transparent !important;
  }

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.xapi-content {
  cursor: pointer !important;

  transition: all 0.5s ease !important;
  padding: $separator-m !important;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
