$vertical-students-results-bar-height: $size-graph-bars-height;
$vertical-students-results-bar-width: $size-graph-bars-width;
$vertical-students-results-bar-border-radius: $border-radius-bars-vertical-users-graph;
$vertical-students-results-background: $color-gray-07;
$vertical-students-results-count-bubble-size: $size-avatar-small;
$vertical-students-results-count-bubble-text-color: $color-first;
$vertical-students-results-count-bubble-background: $color-white;
$vertical-students-results-count-bubble-move: 16%;
$vertical-students-results-bar-good: $color-second-blue-02;
$vertical-students-results-bar-bad: $color-first;
$vertical-students-results-score-separator: $main-separator-items;
$vertical-students-results-score-color: $color-label;
$vertical-students-results-score-percent-separator: $separator-m;
$vertical-students-results-score-percent-color: $color-first;
$vertical-students-results-metadata-separator: $separator-xxxl;
$vertical-students-results-metadata-separator-text: $main-separator-items;
$vertical-students-results-metadata-average-color-number: $color-gray-03;
$vertical-students-results-legend-separator: $main-separator-items;
$vertical-students-results-padding-top: calc(#{$size-avatar-small} + 14px);
$vertical-students-results-not-turned-bar-background: rgba(255, 83, 34, 0.1);
$vertical-students-results-bar-color: linear-gradient(180deg, #56ccf2 47.92%, #2f80ed 100%);
$vertical-students-results-bar-not-turned-color: $gradient-visual;
$vertical-students-results-bar-not-turned-all-tunerd-background: $gradient-natural;
$vertical-students-results-info-top: -33px;

.vertical-students-results {
  padding-top: $vertical-students-results-padding-top;
}

.vertical-students-results__bar {
  text-align: center;
}

.vertical-students-results__bars-wrapper {
  display: flex;
  justify-content: space-between;
}

.vertical-students-results__bar-container {
  position: relative;
  height: $vertical-students-results-bar-height;
  width: calc(#{$vertical-students-results-bar-width} / 2);
  border-radius: $vertical-students-results-bar-border-radius;
  background-color: $vertical-students-results-background;
  box-shadow: inset 2px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
}

.vertical-students-results__bar-progress-container {
  height: 100%;
  border-radius: 16px 16px 0px 0px;
  box-shadow: inset 2px 3px 4px rgba(0, 0, 0, 0.1);
}

.vertical-students-results__bar-progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: $vertical-students-results-bar-color;
  border-radius: 16px 16px 0px 0px;
}

.vertical-students-results__bar-students {
  @extend .hover-basic;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .horizontal-students-result__student {
    position: relative;
    .horizontal-students-result__icon {
      margin-left: 0;
    }
  }
}

// .vertical-students-results__bar:nth-child(4) ~ .vertical-students-results__bar {
//   .vertical-students-results__bar-progress {
//     background: $vertical-students-results-bar-good;
//   }
// }

.vertical-students-results__score {
  @extend .text-xs;

  color: $vertical-students-results-score-color;
  margin-top: $vertical-students-results-score-separator;
}

@media #{$breakpoints-medium} {
  .vertical-students-results__bar-container {
    width: $vertical-students-results-bar-width;
  }
}

.vertical-students-results__count-bubble {
  @extend .border-default;

  border-radius: 100%;
  background: $vertical-students-results-count-bubble-background;
  height: $vertical-students-results-count-bubble-size;
  width: $vertical-students-results-count-bubble-size;
  position: relative;
}

.vertical-students-results__count-bubble-text {
  @extend .text-small-bold;

  white-space: nowrap;
  color: $vertical-students-results-count-bubble-text-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical-students-results__avatars-container {
  display: flex;
  justify-content: center;
}

.vertical-students-results__avatars {
  display: flex;
}

.vertical-students-results__avatars--2 {
  .horizontal-students-result__student:nth-child(1) {
    left: $vertical-students-results-count-bubble-move;
  }
  .horizontal-students-result__student:nth-child(2) {
    left: -$vertical-students-results-count-bubble-move;
  }
}

.vertical-students-results__avatars--more {
  .horizontal-students-result__student:nth-child(1) {
    left: $vertical-students-results-count-bubble-move;
  }
  .vertical-students-results__count-bubble {
    left: -$vertical-students-results-count-bubble-move;
  }
}

.vertical-students-results__arrow {
  position: absolute;
  top: 90%;
}

.vertical-students-results__legend-item,
.vertical-students-results__legend,
.vertical-students-results_info {
  display: flex;
  align-items: center;
}

.vertical-students-results_info {
  align-items: center;
  justify-content: flex-end;

  margin-bottom: $vertical-students-results-legend-separator;
  position: relative;
  top: $vertical-students-results-info-top;
}

.vertical-students-results__legend-item {
  margin-right: $vertical-students-results-metadata-separator;
}

.vertical-students-results__legend-item-text,
.vertical-students-results__average-text {
  @extend .text-extra;

  color: $color-label;
  margin-left: $vertical-students-results-metadata-separator-text;
}

.vertical-students-results__average-number {
  margin-left: $vertical-students-results-metadata-separator-text;
}

.vertical-students-results__average-number {
  @extend .text-small-bold;

  color: $vertical-students-results-metadata-average-color-number;
}

.vertical-students-results__bar-not-turned {
  .vertical-students-results__bar-container {
    margin: auto;
    background: $vertical-students-results-not-turned-bar-background;
  }

  .vertical-students-results__bar-progress {
    background: $vertical-students-results-bar-not-turned-color;
  }

  .vertical-students-results__score-percent {
    margin: $vertical-students-results-score-percent-separator auto;
  }
}

.vertical-students-results__score--notturned {
  font-family: $font-first !important;
  font-size: $font-size-05 !important;
  color: $vertical-students-results-score-percent-color;
  width: 100px;
}

.vertical-students-results__score-percent {
  @extend .text-smallest;
}

.vertical-students-results__bar--notturned {
  border-right: $border-size-02 solid $color-borders;
  padding-right: $separator-xs;
}

.vertical-students-results__bar-progress-container--notturned-all-turned {
  background: $vertical-students-results-bar-not-turned-all-tunerd-background;
}
