.rdp {
  --rdp-accent-color: $color-first;
  --rdp-background-color: $color-first;
}

.rdp-day_selected {
  color: white;
}

.rdp-button:hover {
  color: white;
}

.rdp-button {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}

.rdp-day_today {
  font-weight: bold;
  color: white;
  background-color: $color-gray-05;
}

.rdp-day_today.rdp-day_selected {
  font-weight: bold;
  color: white;
  background-color: #414b5c;
}

.rdp-head_row {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  color: $color-gray-03;
}
