@import './components/CustomTooltip.scss';
@import './BarChartHorizontal/index';
@import './CircleChart/index';

.linear-graphic__tick-x-y-title {
  fill: #8c99ad;
  text-align: center;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
