$new-content-view-separators: $main-separator-components;
$new-content-view-height-section-learning: 100px;

.new-content-view {
  .layout-calendar__column-1 {
    height: 100vh;
  }
}

.new-content-view__left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 100%;
}

.new-content-view__left-container {
  height: calc(100vh - #{$new-content-view-height-section-learning});
  overflow-y: auto;
  padding-bottom: $new-content-view-separators;
  padding-right: $main-separator-components;
}

// Responsive

.new-content-view {
  .new-content-view__mobile-menu-header {
    height: 48px;
    background: $color-responsive-header;
    width: 100%;
    z-index: $z-index-header;
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;

    .new-content-view__mobile-menu-header-title {
      @extend .title-h5;
      color: $color-white;
    }
    .new-content-view__mobile-menu-header-button {
      cursor: pointer;
    }
  }

  .new-content-view__mobile-menu-backdrop {
    position: fixed;
    display: none;
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0);
    top: 0;
    right: 0;
    opacity: 0;
    z-index: $z-index-aside-overlay;
  }

  .layout-calendar__column-1 {
    width: 378px;
    right: -378px;
    left: unset;
    top: 0;
    padding-top: 20px;
    z-index: $z-index-aside;
    transition: right 0.3s ease-in-out;

    .back-button {
      display: none;
    }

    .new-content-view__item--title {
      display: none;
    }

    .new-content-view__mobile-menu-close-button {
      text-align: right;
      .icon {
        cursor: pointer;
      }
    }
  }

  .layout-calendar__column-2 {
    padding-left: 28px;
    margin-top: 64px;
  }

  &.new-content-view__mobile-menu--open {
    .layout-calendar__column-1 {
      right: 0;
      transition: right 0.3s ease-in-out;
    }
    .new-content-view__mobile-menu-backdrop {
      display: block;
      opacity: 0.25;
    }
  }
}

@media #{$breakpoints-xlarge} {
  .new-content-view {
    .new-content-view__mobile-menu-header {
      display: none;
    }
    .new-content-view__mobile-menu-backdrop {
      display: none;
    }

    .layout-calendar__column-1 {
      padding-top: 20px;
      padding-bottom: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100vh;
      position: fixed;
      left: 0;
      top: unset;
      width: 450px;
      .back-button {
        display: block;
      }
      .new-content-view__item--title {
        display: block;
      }
      .new-content-view__mobile-menu-close-button {
        display: none;
      }
    }

    .layout-calendar__column-2 {
      padding-top: 20px;
      padding-left: calc(450px + 20px);
      padding-bottom: 20px;
      width: 100%;
    }
  }
}
