$timeline-two-sides-background-color: $color-white;
$timeline-two-sides-left-width: 150px;

.timeline-two-sides {
  .MuiTimeline-root {
    padding: 0;
  }

  .MuiTimelineOppositeContent-root {
    margin-top: $separator-l;
    margin-bottom: $separator-l;
    flex: 0 0 $timeline-two-sides-left-width;
  }

  .MuiTimelineItem-root {
    &:hover {
      @extend .hover-basic;

      background: $color-first-alpha;
      opacity: 1;

      .unoi-course-view-program__image {
        background-size: 120% 120%;
        
      }
    }
  }

  .MuiTimelineContent-root {
    display: flex;
    align-items: center;
  }

  .MuiTimelineDot-root {
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
    margin: -2px 0px;
  }

  .MuiTimelineContent-root {
    margin-left: $main-separator-items;
  }
}

.timeline-two-sides__line-active {
  background-color: $color-first !important;
}

.timeline-two-sides__current {
  display: block;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba($color-first-alpha, 0.4);  
  animation: pulse 2s infinite;
}
