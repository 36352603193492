$card-resource-document-height: 142px;
$card-resource-document-icon-container-size: 80px;
$card-resource-document-icon-container-color: $color-gray-07;

.card-resource-document {
  @extend .hover-basic;
  @extend .card-default;

  height: $card-resource-document-height;
  box-shadow: $box-shadow-06;

  display: flex;
  flex-direction: column;
}

.card-resource-document__top {
  display: flex;
  align-items: flex-start;
}

.card-resource-document__icon-container {
  background: $card-resource-document-icon-container-color;
  height: $card-resource-document-icon-container-size;
  width: $card-resource-document-icon-container-size;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 0 0 auto;
}

.card-resource-document__icon-play {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.card-resource-document__texts {
  display: flex;
  flex-direction: column;
  margin-left: $main-separator-items;
}

.card-resource-document__name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $color-gray-02;
  display: block;
}

.card-resource-document__size {
  @extend .text-smallest;

  color: $color-gray-03;
  display: block;
  margin-top: $main-separator-items;
}

.card-resource-document__only-teacher {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
  align-items: flex-end; 
  margin-left: $main-separator-items;

  .bubble-content {
    background-color: $color-gray-02;
    border: 0;
  }
}

.card-resource-document__bottom {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
  align-items: flex-end;
}
