$student-grades-graphics-text-separator: $main-separator-items;
$student-graphics-separator: $main-separator-components;
$student-graphics-selector-width: 183px;
$student-grades-graphics-analysis-card-padding-bottom: 60px;

.student-grades-graphics {
  display: flex;
  flex-direction: column;

  .student-grades-graphics__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex: 0 0 auto;
  }

  .student-grades-graphics__title {
    @extend .grades__title;
  }

  .student-grades-graphics__text {
    @extend .grades__text;

    margin-top: $student-grades-graphics-text-separator;
  }

  .student-grades-graphics__selector {
    min-width: $student-graphics-selector-width;
  }

  .student-grades-graphics__tabs {
    margin-top: $student-graphics-separator;
  }

  .student-grades-graphics__analysis-card {
    @extend .card-secondary;

    margin-top: $student-graphics-separator;
  }

  .student-grades-graphics__chart {
    overflow: auto hidden;
    padding-bottom: 30px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f7f4f2;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c7bfb7;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #77736f;
      cursor: pointer;
    }

    .graphics-score {
      font-family: $font-first;
      font-size: 22px;
    }
  }
}
