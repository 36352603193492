.home-page__footer {
  background-color: $color-white;
  border-top: $border-size-01 solid $color-borders;
  bottom: 0;
  padding: $separator-s;
}

.home-page__footer-grid {
  @extend .grid;

  display: flex;
  justify-content: space-between;
}
.home-page__politics {
  display: flex;
}

.home-page__desktop-versions {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .login-form__desktop-versions {
    @extend .hover-basic;
    @extend .text-small;

    color: $color-first;
    margin-left: $separator-s;
  }
}
