// $back-title-and-icon-width: 265px;
$back-title-and-icon-separators: $main-separator-components;

.back-title-and-icon__item {
  margin-bottom: $back-title-and-icon-separators;
}

.back-title-and-icon__container {
  display: flex;
  flex-direction: column;
}

.back-title-and-icon__icon-container {
  margin-bottom: $back-title-and-icon-separators;
  // width: $back-title-and-icon-width;
}
