$dialog-desktop-version-max-width: 565px;
$dialog-desktop-version-width: 565px;
$dialog-desktop-version-height: 367px;
$dialog-desktop-version-separator-items: $main-separator-items;
$dialog-desktop-version-main-color: $color-gray-01;
$dialog-desktop-version-font-normal: 15px;
$dialog-desktop-version-font-sm: 14px;

.dialog-desktop-version {
  .MuiPaper-root {
    max-width: $dialog-desktop-version-max-width;
    width: $dialog-desktop-version-width;
    height: $dialog-desktop-version-height;
  }

  .dialog-desktop-version__title-text {
    margin-left: $separator-s;
  }
}

.dialog-desktop-version__content {
  display: flex;
  flex-direction: column;
  margin-top: $dialog-desktop-version-separator-items;
}

.dialog-desktop-version__header {
  @extend .title-h5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $dialog-desktop-version-main-color;
}

.dialog-desktop-version__title {
  display: flex;
  align-items: center;
}

.dialog-desktop-version__title-icon {
  margin-right: $dialog-desktop-version-separator-items;
}

.dialog-desktop-version__close {
  justify-self: flex-end;
  cursor: pointer;
}

.dialog-desktop-version__texts {
  margin-left: $dialog-desktop-version-separator-items;
}

.dialog-desktop-version__text {
  @extend .text-p;
  font-size: $dialog-desktop-version-font-normal;
  color: $dialog-desktop-version-main-color;
  margin-top: $dialog-desktop-version-separator-items;
}

.dialog-desktop-version__so {
  margin-top: $separator-xxxxxl;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.dialog-desktop-version__so-ver {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-desktop-version__so-ver-text {
  margin-top: $separator-l;
  font-size: $dialog-desktop-version-font-sm;
  line-height: 20px;
  color: $color-gray-03;
  margin-bottom: $separator-l;
}

.dialog-desktop-version__so-ver-text--selected {
  color: $dialog-desktop-version-main-color;
}

.dialog-desktop-version__so-ver-so {
  text-align: center;
  display: block;
  font-size: $dialog-desktop-version-font-normal;
  font-weight: 700;
}
