.dialog-carrousel-images {
  .dialog__hr {
    display: none;
  }

}
.dialog-carrousel-images__body {
  @extend .grid;

  margin: auto;
}

.dialog-carrousel-images__lib--images {
  
  .swiper-button-next,
  .swiper-button-prev {
    position: fixed;
    color: $color-gray-02;
    border: 2px solid $color-gray-02;
    top: calc(50% - 39px);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    &:after {
      font-size: 18px;
    }
  }
}

.dialog-carrousel-images__image-container {
  height: calc(100vh - 74px - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-carrousel-images__image {
  max-height: 100%;
  max-width: 100%;
}

.dialog-carrousel-images__thumbnail-container {
  height: 60px;
  width: 60px;
}

.dialog-carrousel-images__thumbnail {
  @extend .hover-basic;

  height: 100%;
  background-size: cover;
  width: 100%;
}

.dialog-carrousel-images__lib--thumbnail {
  margin-top: $main-separator-items;
  
  .swiper-slide {
    display: block;
    width: auto !important;
  }

  .swiper-wrapper {
    display: flex;
    justify-content: center
  }
}

.dialog-carrousel-images__thumbnail-container--active {
  border: $border-size-01 solid $color-first;
}

.dialog-carrousel-images__thumbnail-container--no-active {
  border: $border-size-01 solid transparent;
  .dialog-carrousel-images__thumbnail {
    opacity: 0.4;
  }
}
