$lesson-info-lesson-items-components-separator: $main-separator-components;
$lesson-info-lesson-items-move-icon-separator: $separator-l;
$lesson-info-lesson-items-title-separator: $main-separator-items;
$lesson-info-lesson-items-title-color: $color-label;
$lesson-info-lesson-items-section-empry-separator: $main-separator-items;
$lesson-info-lesson-items-section-background: $color-gray-06;

.lesson-info__right {
  .lesson-info-lesson-items__list {
    margin-bottom: $lesson-info-lesson-items-components-separator;
    background-color: transparent !important;
    box-shadow: none !important;
    margin-top: $separator-xxl;

    .MuiAccordionSummary-content {
      display: block;
      width: 100%;
    }
  }
}

.lesson-info-lesson-items__actions-move-icon {
  @extend .hover-basic;

  margin-left: $lesson-info-lesson-items-move-icon-separator;
  visibility: hidden;
}

.lesson-info-lesson-items__header {
  width: 100%;

  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  align-items: center;

  margin: $lesson-info-lesson-items-title-separator auto;

  &:hover {
    .lesson-info-lesson-items__actions-move-icon {
      visibility: visible;
    }
  }
  .hr {
    width: 100%;
  }

  .MuiAccordionSummary-expandIcon {
    position: absolute;
    top: -$separator-m;
    right: 0;
  }
}

.lesson-info-lesson-items__content {
  width: 100%;
  margin: $separator-l auto;
}

.lesson-info-lesson-items__title-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $separator-s;
  .learning-objetives-tag-list {
    margin-bottom: 0;
  }

  .icon {
    margin-right: $lesson-info-lesson-items-title-separator;
  }
}

.lesson-info-lesson-items__title {
  @extend .title-h6;
  @extend .ellipsis;

  display: block;
  color: $lesson-info-lesson-items-title-color !important;
}

.lesson-info-lesson-items__actions {
  display: flex;
}

.lesson-info-lesson-items__empty-section {
  width: 100%;
  margin-top: $lesson-info-lesson-items-section-empry-separator;

  .no-items-mode-edit {
    background: $lesson-info-lesson-items-section-background;
  }
}
