$projection-view-container-max-width: 1078px;
$projection-view-gradient-height: 228px;
$projection-view-container-pdftron-top-margin: 92px;
$projection-view-container-pdftron-small-top-margin: 135px;
$projection-view-container-arrows-size: 46px;
$projection-view-container-fullscreen-button-height: 40px;
$projection-view-container-fullscreen-button-width: 48px;
$projection-view-container-padding: $separator-xxxxl;
$projection-view-content-padding: $separator-xl;
$projection-view-lesson-name-margin: $separator-m;
$projection-view-content-name-margin: $separator-m;
$projection-view-paginator-size: 70px;
$projection-view-content-radius: $border-radius-01;
$projection-view-content-background: $color-white;
$projection-view-content-shadow: $box-shadow-03;
$projection-view-button-gradient: $gradient;
$projection-view-footer-bar-bottom: 100px;
$projection-view-paginator-backgroud: $color-gray-01;

.projection-view {
  .layout-header__children {
    padding-top: $separator-xxxxl;
  }

  .wrap_media_viewer {
    background-color: $color-white !important;
  }

  .MediaViewerVideo_content {
    position: initial !important;
  }

  .MediaViewerVideo_content-video {
    max-width: none !important;
    max-height: none !important;
  }
}

.projection-view--fullscreen {
  .projection-view__content-visor,
  video {
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .vProgress,
  .VtimePart,
  .VtimeAll {
    z-index: 9999;
  }

  .MediaViewerImage_content {
    height: 100%;
    top: 0;
  }
}

.projection-view__gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: $projection-view-gradient-height;
  width: 100%;
  background: $gradient;
}

.projection-view__container {
  position: relative;
  margin: 0 auto;
  padding-bottom: calc(#{$size-height-footer} + #{$main-padding-page});
}

.projection-view__book-container {
  /*height: 80vh;
  background-color: $projection-view-content-background;*/
}

.projection-view__book-container--responsive {
  height: 70vh;
}

.projection-view__content {
  padding: $projection-view-content-padding;
  background-color: $projection-view-content-background;
  border-radius: $projection-view-content-radius;
  box-shadow: $projection-view-content-shadow;
}

.paginator__arrow-big {
  position: fixed;
  top: calc(50vh - #{$projection-view-paginator-size/2});
  z-index: 998;
}

.paginator__arrow__container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $projection-view-paginator-size * 2;
  width: $projection-view-paginator-size;
  background-color: $projection-view-paginator-backgroud;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

.paginator__arrow__container-right {
  border-bottom-left-radius: $projection-view-paginator-size * 2;
  border-top-left-radius: $projection-view-paginator-size * 2;
  transform: translate($projection-view-paginator-size/2);

  .icon {
    margin-left: $projection-view-paginator-size / 4;
  }

  &:hover {
    transform: translate(0);
    opacity: 0.7;
  }
}

.paginator__arrow__container-left {
  border-bottom-right-radius: $projection-view-paginator-size * 2;
  border-top-right-radius: $projection-view-paginator-size * 2;
  transform: translate(-$projection-view-paginator-size/2);

  .icon {
    margin-right: $projection-view-paginator-size / 4;
  }

  &:hover {
    transform: translate(0);
    opacity: 0.7;
  }
}

.paginator__arrow__left {
  left: 0;
}

.paginator__arrow__right {
  right: 0;
}

.bubble-content {
  width: $projection-view-container-arrows-size;
  height: $projection-view-container-arrows-size;
}

.projection-view__content-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;

  // .paginator__arrow {
  //   position: absolute;
  // }

  // .paginator__arrow__left {
  //   left: 0;
  // }

  // .paginator__arrow__right {
  //   right: 0;
  // }
}

.projection-view__content-visor {
  width: 100%;
  height: 100%;
}

.projection-view__test {
  overflow-y: auto;
  height: 100%;

  .layout-header,
  .layout-header__content {
    background: $color-white;
  }

  .answer-test-view__header-right {
    display: none;
  }

  .layout-header {
    min-height: initial;
  }

  .answer-test-view__footer-bar,
  .answer-test-view__hint {
    bottom: $projection-view-footer-bar-bottom;
  }

  .layout-header__header {
    z-index: initial;
  }
}

.projection-view__test--no-items {
  .header-custom {
    border-bottom: 0;
  }
}

.projection-view__content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projection-view__content-info {
  display: flex;
  align-items: center;
}

.projection-view__content-name {
  @extend .title-h6;

  margin-left: $projection-view-content-name-margin;
}

.projection-view__content-close {
  @extend .hover-basic;
}
.projection-view__content-description {
  @extend .text-small;

  margin: $projection-view-content-padding 0;
}

.projection-view__book-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $projection-view-container-padding;
  align-items: center;
}

.projection-view__book-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projection-view__book-lesson-name {
  @extend .title-h6;

  margin-left: $projection-view-lesson-name-margin;
  color: $color-white !important;
}

.projection-view__book-open-toc,
.projection-view__book-close {
  @extend .hover-basic;
}

.projection-view__fullscreen {
  position: absolute;
  z-index: 5000;
  top: 85vh;
  bottom: 0;
  right: $projection-view-content-padding;

  .button {
    padding: 0;
    width: $projection-view-container-fullscreen-button-width;
    height: $projection-view-container-fullscreen-button-height;
    min-width: auto;
    min-height: auto;

    svg {
      margin-right: 0;
    }
  }
}

.projection-view__content-container--small {
  height: 20vh;
}
.projection-view__content-container--link {
  height: 20vh;
  .wrap_viewer_module {
    height: auto;
    background: none;
  }
  .mvl_card_title,
  .mvl_card_title_desc {
    width: 0 !important;
  }
  .mvl_card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .mvl_card_thumb {
    height: 0 !important;
    width: 0 !important;
  }
  .mvl_card_button {
    @extend .button;

    font-size: $font-size-06 !important;
    margin: 44px 0 0 0 !important;
    background-image: $projection-view-button-gradient !important;
  }
}

.projection-view__content-container--test {
  height: auto;
  // min-height: 70vh;
  // .paginator__arrow {
  //   top: 33.5vh;
  // }
}

.projection-view__content-container--hidden {
  .projection-view__content-visor {
    display: none;
  }
}

.projection-view__fullscreen--active {
  z-index: 5000;
  top: 90vh;
  right: 40px
}

// CLASSES FOR OVERRIDE PDFTRON CONTAINER

.pdf-container-projection {
  @extend .grid;
  position: absolute !important;
  margin-top: $projection-view-container-pdftron-top-margin !important;
  height: 80vh !important;
  right: 0 !important;
  left: 0 !important;
}

.pdf-container-projection--small {
  width: 78% !important;
  height: 70vh !important;
  top: $projection-view-container-pdftron-small-top-margin !important;
}

.pdf-container-projection--fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  max-width: none !important;

  z-index: 4001;
  margin: 0 !important;
  
}
