$header-course-selector-item-spaces: $separator-s;
$header-course-selector-text-color: $color-gray-01;

.header-course-selector {
}

.header-course-selector__wrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.header-course-selector__item {
  margin-right: $header-course-selector-item-spaces;
}

.header-course-selector__item--arrow {
  @extend .hover-basic;
}

.header-course-selector__item--name-container {
  display: flex; 
  flex-direction: column;
  justify-content: center;
}

.header-course-selector__item--name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $header-course-selector-text-color;
  margin-bottom: $separator-xxs;
}

.header-course-selector__item--class-name {
  @extend .text-small;
  color: $color-gray-01;
}

.header-course-selector__item--share {
  @extend .hover-basic;

  position: relative;
  top: -2px;
}
