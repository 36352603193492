.header-buttons-right-responsive {
  display: flex;
  align-items: center;
  .button--icon-position-right {
    .button__text {
      padding-right: $separator-xl;
    }

    .icon {
      right: $separator-m;
    }
  }
}

.header-buttons-right-responsive__desktop {
  display: none;
}

.header-buttons-right-responsive__mobile {
  display: block;
}

@media #{$breakpoints-large} {
  .header-buttons-right-responsive__desktop {
    display: block;
  }

  .header-buttons-right-responsive__mobile {
    display: none;
  }
}
