$kids-card-border-radius: 32px;
$kids-color-black: #2d2d2d;
$kids-background-yellow: #ffe194;

.app-content--kids {
  //FONTS
  .title-h2,
  .title-h4,
  .title-h6,
  .text-link {
    font-family: $font-first-kids !important;
  }
  .text-small,
  .text-smallest {
    font-family: $font-second-kids !important;
  }

  //CORE COMPONENTS
  .button {
    box-shadow: 0px 4px 0px 0px #0000001a;

    box-shadow: 0px -4px 0px 0px #0000001a inset;
  }
  .layout-calendar__column-1 {
    background: $kids-background-yellow;
  }
  .layout-calendar--responsive {
    .header-responsive {
      .back-button {
        svg {
          path {
            fill: $color-white;
          }
        }
      }
    }
  }
  .header-simple {
    .header-button-right__todo {
      svg {
        path {
          fill: #f569f8;
        }
      }
    }
    .header-button-right_calendar {
      svg {
        path {
          fill: #63aed8;
        }
      }
    }
    .header-button-right__notifications {
      svg {
        path {
          fill: #ffb038;
        }
      }
    }
  }

  .card-generic {
    border-radius: $kids-card-border-radius;
  }

  .class-room-card {
    box-shadow: 0px 4px 0px 0px #0000001a;
    padding: 20px;

    .classroom-code__student-key,
    .class-room-card__courses-length {
      display: none;
    }

    .class-room-card__name {
      margin-bottom: $separator-m;
    }
  }

  .course-card {
    padding: 20px;
    box-shadow: 0px 4px 0px 0px #0000001a;
    box-shadow: 0px -4px 0px 0px #0000001a inset;

    .image-bubble__picture {
      border-radius: 24px;
    }

    .icon-bubble {
      border-radius: 24px;
    }

    .course-card__info {
      height: auto;
      flex-direction: row;
    }

    .course-card__info-item--name {
      text-align: center;
      padding: 12px;
      font-size: 24px;
      border-radius: 80px;
      color: $color-white;
      background-color: $kids-color-black;
    }

    .program-image__picture-container {
      height: 70px;
      width: 70px;
      border-radius: 24px;
    }

    .program-image__picture {
      border-radius: 24px;
    }
  }

  .icon-bubble {
    box-shadow: 0px 4px 0px 0px #0000001a;
  }

  .list-simple-dropdown__li--active {
    border-top-left-radius: $kids-card-border-radius;
    border-bottom-left-radius: $kids-card-border-radius;
  }

  .list-simple-dropdown__li {
    &:hover {
      border-top-left-radius: $kids-card-border-radius;
      border-bottom-left-radius: $kids-card-border-radius;
    }
  }
  .header-simple__title,
  .class-room-card__name {
    color: $kids-color-black;
  }

  //VIEWS
  .my-classrooms-view {
    .layout-header {
      background: $kids-background-yellow;
    }

    .header-simple__subheader {
      margin-bottom: 0;
    }
  }
  .profile-view {
    .profile-view__item--title {
      text-align: center;
      padding: 12px;
      font-size: 24px;
      border-radius: 80px;
      color: $color-white;
      background-color: $kids-color-black;

      svg {
        path {
          fill: $color-white;
        }
      }
      .icon-title__title {
        color: $color-white;
      }
    }
  }

  .vanilla-course-view,
  .unoi-course-view {
    .header-course {
      border-bottom: none;
      .header-button-right__todo {
        svg {
          path {
            fill: #f569f8;
          }
        }
      }
      .header-button-right_calendar {
        svg {
          path {
            fill: #63aed8;
          }
        }
      }
      .header-button-right__notifications {
        svg {
          path {
            fill: #ffb038;
          }
        }
      }

      .header-tabs__tab {
        @extend .hover-basic;

        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 72px;
        height: 55px;
        bottom: -5px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        background: #ebebeb;
      }

      .header-tabs__tab-icon {
        svg {
          height: 28px;
          width: 28px;
          path {
            fill: #929292;
          }
        }
      }

      .header-tabs__tab--active {
        .header-tabs__tab-icon {
          svg {
            path {
              fill: $color-white;
            }
          }
        }
        &:after {
          background-color: transparent;
        }
      }

      .header-tabs__tab--with-icon-kids {
        &:after {
          bottom: -10px;
        }
        margin: 0 5px;
        .header-tabs__tab-text-container {
          text-align: center;
        }
      }
    }

    .header-tabs__tab-text {
      display: none;
    }
  }
}

.tangerine-kids-course-view {
  .title-h1,
  .title-h2,
  .title-h4,
  .title-h6,
  .text-link {
    font-family: $font-first-kids !important;
  }
  .text-small,
  .text-smallest {
    font-family: $font-second-kids !important;
  }

  //PROGRAM
  .tangerine-kids-course-view__banner {
    @extend .title-h2;

    display: inline;
    padding: 5px 15px;
    border-radius: 80px;
    color: $color-white;
    background: $kids-color-black;
  }

  .program__times-list {
    margin-top: $gutter;
  }

  .program__content-skeleton {
    margin-top: $gutter;

    .skeleton-program-entry {
      border-radius: 44px;
      height: 70px;
    }
  }

  .units-accordion {
    .MuiPaper-root {
      border-radius: 44px;
    }
  }

  .units-accordion__thumbnail {
    height: 48px;
    width: 48px;
    border-radius: 80px;
    background: $kids-color-black;
    background-image: none !important;

    span {
      @extend .title-h4;

      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .units-accordion__name {
    @extend .title-h4;

    color: $kids-color-black;
  }

  .lessons-list {
    .mosaic-list--col-3 {
      .mosaic-list__item {
        @include column(12);
      }
    }
  }

  .lesson-power-header--student {
  }

  //STREAM
  .stream {
    .card-background-component {
      border-radius: 24px;
      padding: 12px;
    }
  }
  .post-list-and-create__no-posts {
    background: $color-white;
    border-radius: 24px;
    padding: 12px;
  }
  .post-card__text {
    @extend .text-small;
  }

  //GRADES
  .student-grades-grades__selector {
    display: none;
  }

  .student-grades__mobile-menu-button {
    display: none;
  }
  .student-grades-grades__title {
    @extend .title-h2;

    display: inline;
    padding: 5px 15px;
    border-radius: 80px;
    color: $color-white;
    background: $kids-color-black;
  }
  .student-grades-grades__text {
    display: none;
  }

  .student-grades-table__header {
    @extend .title-h6;

    color: $color-white;
  }

  .student-grades-table__unit-accordion {
    border-radius: 44px;
    padding: 8px 20px;
  }

  .student-grades-table__unit-accordion-title {
    @extend .title-h1;

    font-size: 24px !important;
    color: $kids-color-black;
  }

  .student-grades-table__lesson-accordion-score,
  .student-grades-table__unit-accordion-score {
    .score {
      @extend .title-h1;

      font-size: 32px !important;
    }
  }

  .student-grades-table__lesson-accordion-title {
    @extend .title-h1;

    font-size: 22px !important;
    color: $kids-color-black;
  }

  .student-grades-activity-item__count {
    @extend .text-small;
  }

  .student-grades-activity-item__title {
    @extend .title-h6;

    color: $kids-color-black;
  }

  .assessment-grade-tag__grade,
  .assessment-grade-tag__grade-total {
    @extend .title-h6;
  }

  .student-grades__kids {
    @extend .grid;

    margin-top: 50px;
  }

  //LESSON
  .header-lesson--student {
    height: 140px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  .header-lesson__container {
    align-items: center;
  }

  .header-lesson__info {
    margin-bottom: 0;
  }

  .header-lesson__back {
    .back-button {
      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }
  .header-lesson__info-title {
    span {
      @extend .text-small;

      font-size: 16px;
    }
  }
  .lesson-item-list--student {
    .MuiAccordion-root {
      border-radius: 50px !important;
      box-shadow: 0px 2px 0px 0px #0000001a;
    }
    .post-card__meta-info {
      margin-left: 12px;
    }
    .message-sender-simple {
      border-bottom-left-radius: 50px !important;
      border-bottom-right-radius: 50px !important;
    }
    .button {
      border-radius: 40px !important;
    }
  }
}

.app-content--teacher {
  .tangerine-kids-course-view {
    .units-accordion {
      .MuiButtonBase-root {
        margin: 16px 10px;
      }
    }
    .custom-banner {
      padding: $gutter 36px;
      border-radius: 40px;
      background: $kids-color-black !important;
    }
    .times-accordion__name,
    .times-accordion__lessons-length {
      @extend .title-h6;

      color: $color-white;
    }

    .no-items {
      background-color: $color-white;
      padding: 12px;
      border-radius: 8px;
    }
    .units-accordion__heighest {
      border-radius: 40px;
    }
  }
}

.background-color-ed6,
.background-color-ed1 {
  background: #14be6a !important;
}

.background-color-ed7,
.background-color-ed5 {
  background: #4949db !important;
}

.background-color-ed2,
.background-color-a255da6f-449e-4b74-b0eb-b7a7a4b5405e {
  background: #ea3752 !important;
}
.background-color-ed3,
.background-color-8696fe77-12f1-4a7a-8455-77206ded6f45 {
  background: #ca47e2 !important;
}

.background-discipline-ed1 {
  background: linear-gradient(91.44deg, #86e328 15.29%, #00b775 98.66%) !important;
}

.background-discipline-ed2 {
  background: linear-gradient(135deg, #ff7171 14.75%, #e3284a 100%) !important;
}
.background-discipline-ed3 {
  background: linear-gradient(135deg, #2aecc9 14.5%, #01a8b3 100%) !important;
}
.background-discipline-ed4 {
  background: linear-gradient(111.12deg, #ff5029 12.58%, #f91b6b 100%) !important;
}
.background-discipline-ed5 {
  background: linear-gradient(135deg, #72c4ff 14.75%, #4325fa 100%) !important;
}
.background-discipline-ed6 {
  background: linear-gradient(135deg, #86e328 15.25%, #00b775 100%) !important;
}
.background-discipline-ed7 {
  background: linear-gradient(135deg, #72c4ff 14.75%, #4325fa 100%) !important;
}
.background-discipline-ed8 {
  background: linear-gradient(to top, #40afff, #361bdc) !important;
}
.background-discipline-ed9 {
  background: linear-gradient(135deg, #bf48f8 14.75%, #5714e7 100%) !important;
}

@media #{$breakpoints-medium} {
  .app-content--kids {
    .mosaic-list--col-4 {
      .mosaic-list__item {
        @include column(6);
      }
    }

    .mosaic-list--col-3 {
      .mosaic-list__item {
        @include column(6);
      }
    }

    .mosaic-list--col-2 {
      .mosaic-list__item {
        @include column(6);
      }
    }
  }
}

@media #{$breakpoints-large} {
  .app-content--kids {
    .mosaic-list--col-4 {
      .mosaic-list__item {
        @include column(6);
      }
    }
  }
}

@media #{$breakpoints-xlarge} {
  .app-content--kids {
    .mosaic-list--col-4 {
      .mosaic-list__item {
        @include column(4);
      }
    }

    .mosaic-list--col-3 {
      .mosaic-list__item {
        @include column(4);
      }
    }
  }
}
