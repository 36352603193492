$for-users-avatar-size: 30px;
$for-users-avatar-zero-size: 32px;

.for-users {
  .avatars-list__count-bubble {
    background-color: $color-first;
    border-color: $color-first;
  }

  .avatars-list__count-bubble-text {
    color: $color-white;
    font-size: $font-size-10;
  }

  .avatar, 
  .avatars-list__count-bubble {
    height: $for-users-avatar-size;
    width: $for-users-avatar-size;
  }
}

.for-users__zero {
  display: flex;
  align-items: center;
  justify-content: center;
  

  .icon-bubble {
    background-color: transparent !important;
    width: auto;
    height: auto;
  }
}

.for-users__zero-text {
  @extend .text-small-bold;


  color: $color-gray-03;
  margin-left: $separator-s;
}

.for-users--type-view-zero-icon {

  .for-users__zero {
    background: $color-gray-07;
    border-radius: 32px;
    padding: $separator-xs;
    
    width: $for-users-avatar-zero-size;
    height: $for-users-avatar-zero-size;
  }


  .for-users__bubble {
    display: none;
  }
  .for-users__icon {
    display: block;
  }
}

.for-users--type-view-zero-bubble {
  .for-users__icon {
    display: none;
  }
  .for-users__bubble {
    display: flex;
  }
}

.for-users__bubble {
  align-items: center;
}

.for-users__bubble-item-number {
  @extend .text-extra;

  color: $color-white;
}

.for-users__bubble-item--1 {
  position: relative;
  left: $separator-l;
  opacity: 0.5;
}
