$lesson-header-project-button-padding-mobile: 26px;
$lesson-header-project-button-padding: 45px;
$personalize-text-size: 86px;
$lesson-header-title-color: $color-gray-01;
$lesson-header-subtitle-color: $color-gray-02;
$lesson-header-menu-active-color: $color-gray-06;
$lesson-header-edit-background: $color-gray-01;
$lesson-header-edit-title-color: $color-white;
$lesson-header-hidden-text-color: $color-gray-02;

.lesson-view {
  .header-custom__center {
    width: 0% !important;
  }
  .header-custom__right,
  .header-custom__left {
    width: auto !important;
  }

  .avatars-list__avatars {
    left: 16%;
    position: relative;
  }

  .avatars-list__avatars--1 {
    left: 0;
  }
}

.lesson-header__left,
.lesson-header__right {
  display: flex;
  align-items: center;
  white-space: break-spaces;
}

.lesson-header__unit-hidden {
  display: flex;
  align-items: center;
  margin-left: $main-separator-items;
}

.lesson-header__unit-hidden-text {
  @extend .text-small;

  color: $lesson-header-hidden-text-color;
  margin-left: $main-separator-items;
  display: none;
}

.lesson-header__menu,
.lesson-header__close {
  @extend .hover-basic;
}

.lesson-header__menu {
  padding: $separator-xs;
  border-radius: $border-size-active;
}

.lesson-header__unit-name {
  @extend .title-h7;

  margin-left: $separator-m;
  color: $lesson-header-title-color;
  flex: 0 0 auto;
  max-width: 90%;
}

.lesson-header__lesson-name {
  @extend .text-p;

  display: none;
  margin-left: $separator-m;
  color: $lesson-header-subtitle-color;
  .text-html {
    padding-right: $separator-m;
  }
}

.lesson-header__view-as {
  svg {
    path {
      fill: $color-gray-03 !important;
    }
  }
}

.lesson-header__separator {
  height: 100%;
  width: 1px;
  background-color: $color-gray-05;
}

.lesson-header__all-class {
  @extend .hover-basic;

  display: flex;
  align-items: center;
}

.lesson-header__project {
  @extend .hover-basic;

  display: flex;
  align-items: center;
}

.lesson-header--edit__right {
  .button--color-transparent {
    margin-right: $main-separator-items;
    .button__text {
      color: $color-white;
    }
  }
}

.lesson-header__edit,
.lesson-header__see-as,
.lesson-header__personalize {
  @extend .hover-basic;

  display: flex;
  align-items: center;
}

.lesson-header__see-as-info {
  display: flex;
  align-items: center;
}

.lesson-header--see-as__title {
  @extend .text-small;

  margin-left: $separator-xl;
  color: $color-white;
}

.lesson-header__menu--active {
  background-color: $lesson-header-menu-active-color;
}

.lesson-header__more-menu {
  .more-icon-menu {
    margin-left: 0;
    margin-right: $separator-m;
  }
}

.lesson-header--edit {
  background: $lesson-header-edit-background;
  border-bottom: 0;
}

.lesson-header--edit__title {
  @extend .title-h6;

  color: $lesson-header-edit-title-color;
}

.lesson-header__see-as,
.lesson-header__personalize,
.lesson-header__project,
.lesson-header__edit {
  display: none;
}

.lesson-header__all-class,
.lesson-header__see-as,
.lesson-header__project,
.lesson-header__edit,
.lesson-header__personalize {
  padding: 0 $separator-m;
}

.lesson-header__close {
  padding-left: $separator-m;
}

.lesson-header__personalize-text {
  @extend .text-small-bold;

  display: none;
  margin-left: $separator-m;
  color: $color-gray-02;
}

.lesson-header__unit-name-text {
  @include ellipsis-line(2);
}

@media #{$breakpoints-large} {
  .lesson-header__all-class,
  .lesson-header__see-as,
  .lesson-header__project,
  .lesson-header__edit,
  .lesson-header__personalize {
    padding: 0 $separator-xl;
  }

  .lesson-header__close {
    padding-left: $separator-xl;
  }

  .lesson-header__lesson-name,
  .lesson-header__personalize-text {
    display: block;
  }

  .lesson-header__personalize-text {
    min-width: $personalize-text-size;
  }

  .lesson-header__unit-name {
    max-width: 70%;
  }
}

@media #{$breakpoints-tablet} {
  .lesson-header__see-as,
  .lesson-header__personalize,
  .lesson-header__project,
  .lesson-header__edit {
    display: flex;
  }

  .lesson-header__unit-name {
    font-size: $font-size-06;
  }

  .lesson-header__unit-hidden-text {
    display: block;
  }

  .lesson-header__unit-hidden {
    background: $color-gray-07;
    border-radius: $border-radius-03;
    padding: $padding-card-default;
  }
}
