$gamified-card-class-student-svg: 32px;

.gamified-card-class-student {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #faf7f5;
  padding: 6px 0 5px 8px;

  &__level {
    svg {
      height: $gamified-card-class-student-svg;
      width: $gamified-card-class-student-svg;
    }
  }
}
