$horizontal-students-results-height: 16px;
$horizontal-students-results-border-radius: $border-radius-01;
$horizontal-students-results-bad-background: $color-error;
$horizontal-students-results-good-background: $color-second-orange-01;
$horizontal-students-results-very-good-background: $color-success;
$horizontal-students-results-number-color: $color-label;
$horizontal-students-results-pixels-separator: 4px;

.horizontal-students-results {
  display: flex;
  height: $horizontal-students-results-height;
}

.horizontal-students-results__step {
  position: relative;
  width: 10%;

  &:first-child {
    border-top-left-radius: $horizontal-students-results-border-radius;
    border-bottom-left-radius: $horizontal-students-results-border-radius;
  }

  &:nth-last-child(2) {
    border-top-right-radius: $horizontal-students-results-border-radius;
    border-bottom-right-radius: $horizontal-students-results-border-radius;
  }
}

.horizontal-students-results__step--bad {
  background: $horizontal-students-results-bad-background;
}

.horizontal-students-results__step--good {
  background: $horizontal-students-results-good-background;
}

.horizontal-students-results__step--very-good {
  background: $horizontal-students-results-very-good-background;
}

.horizontal-students-results__step-number {
  @extend .text-smallest;

  font-style: italic;
  color: $horizontal-students-results-number-color;
  position: relative;
  top: calc(100% + #{$horizontal-students-results-pixels-separator});
  left: -6px;
  white-space: nowrap;
}

.horizontal-students-results__step--10 {
  width: 0;
}

.horizontal-students-results__avatars {
  position: absolute;
  top: -47px;
  transform: translateX(-50%);
}
