$line-with-text-background: $color-gray-04;
$line-with-text-color: $color-gray-04;

.line-with-text__line {
  background: $line-with-text-background;
  position: relative;

  height: 1px;
  width: 100%;
}

.line-with-text__text {
  @extend .text-small;

  background: $color-white;
  color: $line-with-text-color;
  margin: auto;
  text-align: center;
  padding: 1px 10px;

  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
