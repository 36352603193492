.incoming-call {
  position: absolute;
  top: $gutter;
  left: $gutter;
  width: 400px;
  z-index: 10000 !important;
  .cc-incomingcall__wrapper {
    z-index: 10000 !important;
  }
}
