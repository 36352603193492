.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

// -------------------------------------------------
// sm
// -------------------------------------------------
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-block {
    display: block !important;
  }
}

// -------------------------------------------------
// md
// -------------------------------------------------
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }
}

// -------------------------------------------------
// lg
// -------------------------------------------------
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

// -------------------------------------------------
// xl
// -------------------------------------------------
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-block {
    display: block !important;
  }
}

// -------------------------------------------------
// xxl
// -------------------------------------------------
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-block {
    display: block !important;
  }
}
