.select-topics__button {
  display: flex;

  .button {
    width: 100%;
    height: 48px;
    border-radius: 0 4px 4px 0;
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
