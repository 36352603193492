$dialog-upload-max-width: $size-dialog-xs-max-width;
$dialog-upload-separator-items: $main-separator-items;
$dialog-upload-border-radius: $border-radius-05;
$dialog-upload-title-color: $color-title;
$dialog-upload-text-color: $color-label;
$dialog-upload-background-color: $color-gray-07;
$dialog-upload-container-max-width: 765px;

$upload-file-container-border-color: $color-first;
$upload-file-container-color-icon: $color-first;
$upload-file-container-color-text: $color-gray-07;
$upload-file-container-color-link: $color-first;

.dialog-upload {
  .MuiPaper-root {
    max-width: 600px;
    width: 100%;
  }
}

.dialog-upload__icon {
  margin-top: 4px;
}
.dialog-upload__content {
  display: flex;
  margin-top: $dialog-upload-separator-items;
}

.dialog-upload__texts {
  margin-left: $dialog-upload-separator-items;
}

.dialog-upload__title {
  /*@extend .title-h5;*/

  color: $dialog-upload-title-color;
}

.dialog-upload__text {
  /*@extend .text-p;*/

  color: $dialog-upload-text-color;
  margin-top: $dialog-upload-separator-items;
}

.upload-file-container__drag-container {
  @extend .border-dashed;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $dialog-upload-background-color;
  border-radius: $dialog-upload-border-radius;
  padding: 100px 30px;
  max-height: 200px;
  max-width: $dialog-upload-container-max-width;
  margin: auto;
}

.upload-file-container__preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  canvas {
    border-radius: 6px;
  }
}

.upload-file-container__drag-icon {
  /*@extend .blueIcon;*/

  text-align: center;

  & .icon {
    height: 69px;
    width: 69px;
  }
}

.upload-file-container__text-container {
  @extend .text-p;

  display: flex;
}

.upload-file-container__drag-title {
  color: #aaa;
  display: block;
  margin-top: 10px;
  margin-right: 5px;
}

.upload-file-container__link {
  cursor: pointer;

  margin-top: 10px;

  color: $upload-file-container-color-link;
}

.upload-file-container__max-size {
  @extend .text-smallest;

  text-align: center;
  color: $color-gray-03;
  margin-top: $padding-card-default-horizontal;
}

/* LISTA DE ARCHVIOS A SUBIR */

.upload-attach__uploader-list {
  max-height: 30vh;
  margin: 10px auto 0 auto;
  max-width: $dialog-upload-container-max-width;
  overflow: hidden;
  overflow-y: auto;

  .attachment-card {
    margin-top: $main-separator-items;
  }
}

.upload-attach__entry {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.upload-attach__entry-name {
  /*@extend .s-regular-2;*/
}
.upload-attach__entry-size {
  /*@extend .smallest-regular-2;*/
  margin-top: 5px;
  color: #6d6d6d;
}

.upload-attach__entry-remove {
  /*@extend .hover-basic;*/
}

.form-upload__status {
  display: flex;
  align-items: center;
}

.form-upload__percentage {
  /*@extend .p-especial-1-2;*/
  margin-right: 10px;
}

.form-upload__file {
  /*@extend .s-regular-2;*/

  color: #6a6a6a;
}

/* POPUP MIENTRAS HACE UPLOAD */

.dialog-upload-progress {
  .MuiPaper-root {
    max-width: 600px;
    width: 100%;
  }
}

/* LISTA DE ARCHIVOS ADJUNTOS AL MENSAJE */

.post-card__attachments {
  //display: flex;
  //flex-wrap: wrap;
  margin-bottom: 15px;
}

.post-card__attachment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
  /*background-color: #393e921a;
  border-radius: 8px;*/
}

.post-card__attachment-remove,
.post-card__attachment-img {
  /*@extend .hover-basic;*/
}

.post-card__attachment-link {
  /*@extend .smaller-2;*/

  display: flex;
  align-items: center;
  background-color: #f5f7f9;
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 6px;

  a {
    /*@extend .smaller-2;*/

    text-decoration: none;
    color: $color-first;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}

.post-card__attachment-remove {
  opacity: 0.5;
  margin-right: 5px;
}

.post-card__attachment-icon {
  margin-right: 5px;
  svg {
    opacity: 0.5;
    width: 18px;
    height: 18px;
  }
}

/* POPUP EDITAR MENSAJE  */

.form-delete-collection__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.form-delete-collection__buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  & > div {
    width: 112px;
    margin-left: 12px;
  }
}

.form-edit-message__buttons {
  display: flex;
  flex: auto;
  justify-content: space-between;
}

.form-delete-message__buttons-right {
  display: flex;
}

.form-delete-message__attach {
  /*@extend .hover-basic;*/

  opacity: 0.6;
}

.form-edit-message__attachments {
  margin-top: 10px;
}
