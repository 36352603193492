$scorm-individual-results-view-text-color: $color-label;
$scorm-individual-results-view-button-arrow-separator: $main-separator-components;
$scorm-individual-results-view-button-action-separator: $separator-xxxxl;
$scorm-individual-results-view-left-separator: $main-separator-components;
$scorm-individual-results-view-name-separator: $main-separator-items;
$scorm-individual-results-view-questions-color: $color-label;
$scorm-individual-results-view-points-width: 110px;
$scorm-individual-results-view-list-separator: $main-separator-components;
$input-points-border-radius-big: $border-radius-02;

.scorm-individual-results-view__left {
  padding-right: $scorm-individual-results-view-left-separator;
}

.scorm-individual-results-view__avatar {
  position: relative;
  z-index: 1;

  .avatar {
    margin: auto;
  }
}

.scorm-individual-results-view__card {
  @extend .card-secondary;

  position: relative;
  top: -20px;
}

.scorm-individual-results-view__name {
  @extend .title-h4;

  display: block;
  margin-bottom: $scorm-individual-results-view-name-separator;
  text-align: center;
}

.scorm-individual-results-view__text-info {
  @extend .text-smallest;

  color: $scorm-individual-results-view-text-color;
  display: block;
  margin-bottom: $scorm-individual-results-view-name-separator;
  text-align: center;
}

.scorm-individual-results-view__feedback {
  text-align: center;
}

.scorm-individual-results-view__right {
  padding-right: $grid-padding-left-xxlarge;
}

.scorm-individual-results-view__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}

.scorm-individual-results-view__title {
  @extend .title-h4;

  display: block;
}

.scorm-individual-results-view__questions {
  @extend .text-p;

  color: $scorm-individual-results-view-questions-color;
}

.scorm-individual-results-view__header-left {
  display: flex;

  .scorm-individual-results-view__icon {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: $separator-m;
    background-color: #2a5576;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.scorm-individual-results-view__header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.scorm-individual-results-view__header-points {
  position: relative;
  width: $scorm-individual-results-view-points-width;
  margin-left: 14px;

  .input-points {
    .input-points__input-container {
      border: 1px solid $color-gray-04;
      border-radius: $input-points-border-radius-big;
      padding-right: 8px !important;
    }
    .input-points__input {
      color: $color-gray-02;
    }
  }

  .icon {
    position: absolute;
    top: -$separator-s;
    right: -$separator-s;
    path {
      fill: #f2aa3e !important;
    }
  }
}

.scorm-individual-results-view__content {
  margin-top: $scorm-individual-results-view-list-separator;
}

.scorm-individual-results-view__text-info {
  text-align: left;
}

.scorm-individual-results-view__text-info__date {
  display: block;
  font-weight: 600;
}

.scorm-individual-results-view__header-select {
  margin-right: $separator-s;

  .select-customizable {
    display: flex;
    height: 40px;
    padding: 8px 8px 8px 12px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid $color-gray-05;
    width: 160px;

    .select-customizable__single-value {
      color: $color-gray-02;
      font-size: 14px;
      line-height: 20px;
    }
    .select-customizable__control {
      height: auto;
      width: 100%;
      border: none;
    }
    .select-customizable__indicator {
      .icon--size-big {
        width: 16px;
        height: 16px;
      }
    }
  }
}

// Responsive

.scorm-individual-results-view {
  .layout-calendar__column-1 {
    width: 275px;
    padding-right: 0;
    transition: all 0.2s ease;
  }

  .layout-calendar__column-2 {
    padding-top: 20px;
    padding-left: calc(275px + 20px);
    padding-bottom: 20px;
    width: 100%;
    transition: all 0.2s ease;
  }
}

@media #{$breakpoints-large} {
  .scorm-individual-results-view {
    .scorm-individual-results-view__header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media #{$breakpoints-xlarge} {
  .scorm-individual-results-view {
    .layout-calendar__column-1 {
      width: 450px;
      padding-right: 20px;
      transition: all 0.2s ease;
    }

    .layout-calendar__column-2 {
      padding-top: 20px;
      padding-left: calc(450px + 20px);
      padding-bottom: 20px;
      width: 100%;
      transition: all 0.2s ease;
    }
  }
}
