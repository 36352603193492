$xapi-header-background-color: #f2ecf2;
$xapi-header-border-color: #e7deeb;

.xapi-results-learning-objectives__title {
  @extend .text-s;
  color: $color-gray-03;
}

.xapi-results-learning-objectives__container {
  margin-top: $separator-xl;
}

.xapi-results-learning-objectives__header {
  width: 100%;
  display: flex;
  padding: $separator-m;
  border: 1px solid $xapi-header-border-color;
  border-radius: $border-size-active;
  background-color: $xapi-header-background-color;
}

.xapi-results-learning-objectives__header-objectives {
  @extend .text-s;

  width: 60%;
  color: $color-gray-03;
}

.xapi-results-learning-objectives__header-activities {
  @extend .text-s;

  width: 20%;
  color: $color-gray-03;
}

.xapi-results-learning-objectives__header-results {
  @extend .text-s;

  width: 20%;
  color: $color-gray-03;
}

.xapi-results-learning-objectives__items {
  width: 100%;
  margin-top: $separator-l;
}
