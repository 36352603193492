$event-long-padding: $separator-xs;
$event-long-events-border-radius: $border-radius-02;

.event-long {
  @extend .border-light;

  border-radius: $event-long-events-border-radius;
  display: flex;
  justify-content: center !important;
  padding: 0 $event-long-padding;
}
