$buble-number-size: $size-number-bubble;
$buble-number-size-m: $size-number-bubble-m;
$buble-number-size-xl: $size-number-bubble-xl;
$buble-number-size-s: $size-number-bubble-s;
$buble-number-background: $color-background;

$buble-number-background-fill-white: $color-white;;
$buble-number-background-fill-white-border-color: $color-white;
$buble-number-background-gray: $color-gray-07;
$buble-number-background-gray-border-color: $color-gray-07;
$buble-number-background-dark: $color-gray-01;
$buble-number-background-white: transparent;
$buble-number-background-white-border-color: $color-white;
$buble-number-background-primary: $color-first;
$buble-number-background-primary-border-color: $color-first;
$buble-number-size-after: calc(#{$size-number-bubble} / 2);
$buble-number-color: $color-gray-03;
$buble-number-fill-background: $gradient;
$buble-number-fill-color: $color-white;
$buble-number-current-border-width: 3px;
$buble-number-bordered-background: $color-white;
$buble-number-bordered-background-after: $gradient;
$buble-number-bordered-color: $color-first;
$buble-number-bordered-height-after: $border-size-03;
$buble-number-bordered-width-after: 20px;
$buble-number-disabled-color: $color-gray-05;
$buble-number-background-green: $color-success;


.bubble-content {
  @extend .hover-basic;

  border: $border-size-03 solid $buble-number-background;

  height: $buble-number-size;
  width: $buble-number-size;

  background: $buble-number-background;
  border-radius: 100%;
  position: relative;
}

.bubble-content--size-xl {
  height: $buble-number-size-xl;
  width: $buble-number-size-xl;
}

.bubble-content--size-s {
  height: $buble-number-size-s !important;
  width: $buble-number-size-s !important;
}

.bubble-content--size-m {
  height: $buble-number-size-m !important;
  width: $buble-number-size-m !important;
}

.bubble-content--color-white {
  background: $buble-number-background-white !important;
  border-color: $buble-number-background-white-border-color !important;
}

.bubble-content--color-dark {
  background: $buble-number-background-dark !important;
  border-color: $buble-number-background-dark !important;
}

.bubble-content--color-fill-white {
  background: $buble-number-background-fill-white !important;
  border-color: $buble-number-background-fill-white-border-color !important;
}

.bubble-content--color-green {
  background: $buble-number-background-green !important;
  border-color: $buble-number-background-green !important;
}

.bubble-content--color-gray {
  background: $buble-number-background-gray !important;
  border-color: $buble-number-background-gray-border-color !important;
}

.bubble-content--color-primary {
  background: $buble-number-background-primary !important;
  border-color: $buble-number-background-primary-border-color !important;
}

.bubble-content__content {
  @extend .title-h6;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;

  color: $buble-number-color;
  white-space: nowrap;
}

.bubble-content--fill {
  background: $buble-number-fill-background;
  border-color: $color-first !important;

  .bubble-content__content {
    color: $buble-number-fill-color;
  }
}

.bubble-content--bordered {
  border-color: $buble-number-bordered-color;
  background: $buble-number-bordered-background;

  .bubble-content__content {
    color: $buble-number-bordered-color;
  }
}

.bubble-content--current {
  @extend .bubble-content--bordered;

  border-width: $buble-number-current-border-width;

  &:after {
    content: '';

    height: $buble-number-bordered-height-after;
    width: $buble-number-bordered-width-after;

    position: absolute;
    background: $buble-number-bordered-background-after;
    bottom: -20px;
    transform: translate(-50%);
    left: 50%;
  }
}

.bubble-content--disabled {
  opacity: 0.9;
  border-color: $color-gray-05 !important;
}
