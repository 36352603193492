$notifications-list-notification-separator: $main-separator-items;

.notifications-list {
  @extend .list-complex-dropdown;

  .item-complex-list__left {
    width: 100%;
  }

  .item-complex-list__center {
    flex: 1 1 auto;
  }

  .notifications-list__notification {
    margin-bottom: $notifications-list-notification-separator;
  }
}
