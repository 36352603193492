$filters-bar-max-width: 312px;
$filters-bar-color-text: $color-title;

.filters-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters-bar__text {
  @extend .title-h6;

  color: $filters-bar-color-text;
}

.filters-bar__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 50%;
}

.filters-bar__search {
  flex: 1 1 auto;
  max-width: $filters-bar-max-width;
}

.filters-bar__filter {
  @extend .hover-basic;

  margin-left: $main-separator-components;
}
