.text-editor__container {
  margin-bottom: $separator-l;
  .mint-blocks-sidebar__content---keyboard-visible {
    margin-bottom: 270px;
  }
}

.fr-counter {
  display: none;
}

.fr-second-toolbar {
  display: none;
}

.fr-box.fr-basic .fr-wrapper {
  border-bottom: 1px solid #cccccc;
  border-radius: 0 0 10px 10px;
}

.fr-popup.fr-active {
  z-index: 10000 !important;
}
