$dialog-delete-max-width: $size-dialog-xs-max-width;
$dialog-delete-separator-items: $main-separator-items;
$dialog-delete-title-color: $color-title;
$dialog-delete-text-color: $color-label;

.dialog-delete {
  .MuiPaper-root {
    max-width: $dialog-delete-max-width;
  }
}

.dialog-delete__icon {
  margin-top: 4px;
}
.dialog-delete__content {
  display: flex;
  margin-top: $dialog-delete-separator-items;
}

.dialog-delete__texts {
  margin-left: $dialog-delete-separator-items;
}

.dialog-delete__title {
  @extend .title-h5;

  color: $dialog-delete-title-color;
}

.dialog-delete__text {
  @extend .text-p;

  color: $dialog-delete-text-color;
  margin-top: $dialog-delete-separator-items;
}
