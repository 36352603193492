$list-simple-dropdown-color-name: $color-title;
$list-simple-dropdown-color-description: $color-label;
$list-simple-dropdown-hover-background: $color-hover-dropdowns-items-hover;
$list-simple-dropdown-padding: $padding-dropdown;
$list-simple-dropdown-separator: $main-separator-items;
$list-simple-dropdown-transition-duration: $transition-duration-01;
$list-simple-dropdown-li-active-background: $color-white;
$list-simple-dropdown-li-active-color: $color-first;
$list-simple-dropdown-transition-duration: $transition-duration-01;

.list-simple-dropdown {
  padding: $padding-card-default-small 0;
}

.list-simple-dropdown__li {
  display: flex;
  // align-items: center;

  padding: $list-simple-dropdown-padding;
  transition-duration: $list-simple-dropdown-transition-duration;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background: $list-simple-dropdown-hover-background;
  }
}

.list-simple-dropdown__li--disabled {
  .span {
    color: $color-gray-02;
  }

  &:hover {
    cursor: no-drop;
    background: $list-simple-dropdown-hover-background;
  }
}

.list-simple-dropdown__li--active {
  background: $list-simple-dropdown-li-active-background;

  .list-simple-dropdown__name {
    color: $list-simple-dropdown-li-active-color;
  }
}

.list-simple-dropdown__icon {
  margin-right: $list-simple-dropdown-separator;
  margin-top: 1px;
}

.list-simple-dropdown__icon--right {
  margin-right: 0;
  margin-left: auto;
}

.list-simple-dropdown__texts {
  display: flex;
  flex-direction: column;
}

.list-simple-dropdown__name {
  @extend .text-small;

  color: $list-simple-dropdown-color-name;
}

.list-simple-dropdown__description {
  @extend .text-smallest;

  color: $list-simple-dropdown-color-description;
}

.list-simple-dropdown__tooltip {
  background-color: #303949 !important;
  border-radius: 4px;
  border: solid 1px #ebdfb6;
  color: #ffffff !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 19px !important;
}
