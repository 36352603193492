$course-card-student-image-size: 150px;

.course-card--student {
  height: 100%;
  max-width: 100% !important;
  box-shadow: 0 1px 2px 0 rgba(112, 107, 101, 0.15) !important;

  .course-card__open-book {
    margin-top: $separator-l;
  }

  .course-card__icon-container {
    height: $course-card-student-image-size;
  }

  .program-image__picture {
    height: $course-card-student-image-size;
    width: $course-card-student-image-size;
  }

  .course-card__info {
    height: calc(100% - #{$course-card-student-image-size} - #{$padding-card-default-vertical});
  }
}

.course-card--student-isdisregarded {
  &:hover {
    opacity: 1;
    cursor: default;
  }
}
