.custom-lesson-item {
  @extend .hover-basic;

  padding: $separator-m $separator-xl;
  border-bottom: 1px solid $color-gray-05;
  color: $color-gray-02;
  border-left: 4px solid transparent;

  &:hover {
    .primary-2022-card__menu {
      opacity: 1;
    }
  }

  .avatars-list__avatars {
    left: 16%;
    position: relative;
  }

  .avatars-list__avatars--1 {
    left: 0;
  }
}

.custom-lesson-item__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-lesson-item__name {
  @extend .text-small;
}

.custom-lesson-item__bottom {
  display: flex;
  align-items: center;
}

.custom-lesson-item__only-you {
  margin-top: $separator-s;
  margin-right: $separator-s;
}

.custom-lessont-item__edited-by {
  margin-top: $separator-s;
}

// .primary-2022-card__menu {
//   opacity: 0;
//   transition: 0.3s;
// }

.custom-lesson-item--selected {
  border-left: 4px solid $color-first;

  .custom-lesson-item__name {
    @extend .text-small-bold;
    color: $color-gray-01;
  }
}

.custom-lesson-item--invisible {
  .custom-lesson-item__name {
    color: $color-gray-03;
  }
}

.custom-lesson-item__right {
  display: flex;
  align-items: center;
}
