.list-cards-resources {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

@media #{$breakpoints-medium} {
  .list-cards-resources {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media #{$breakpoints-large} {
  .list-cards-resources {
    grid-template-columns: repeat(3, 1fr);
  }

  .list-cards-resources--two-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}
