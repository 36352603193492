.new-classroom-step-basic-settings__container {
  margin-top: $main-separator-components;
}

.new-classroom-step-basic-settings__title {
  @extend .title-h5;
  margin-bottom: $separator-xl;
}

.new-classroom-step-basic-settings__section {
  .new-classroom-step-basic-settings__section-title {
    @extend .title-h6;
    color: $color-gray-02;
    margin-bottom: $separator-l;
  }

  .input-radio {
    margin-bottom: $separator-l;

    .input-radio__label {
      @extend .text-button;
      color: $color-gray-01;

      &.input-radio__label--no-description {
        @extend .text-small;
        font-family: $font-first;
        color: $color-gray-01;
      }
    }

    &.input-radio--unchecked {
      .input-radio__label,
      .input-radio__description {
        color: $color-gray-03;
      }
    }
  }

  .input-radio__description {
    @extend .title-h7;
    font-family: $font-first;
    color: $color-gray-02;
  }

  &:last-child {
    padding-bottom: $separator-l;
  }
}

.new-classroom-step-basic-settings__section-separator {
  background-color: $color-gray-05;
  height: 1px;
  width: 100%;
  margin-bottom: $separator-l;
}

.new-classroom-step-basic-settings__devices {
  .input-checkbox__label {
    @extend.text-p;

    color: $color-gray-01;
  }

  margin-bottom: $separator-l;
}

.new-classroom-step-basic-settings__devices-text {
  @extend .text-small;

  margin-top: $separator-xs;
  margin-left: $separator-xxl;
  color: $color-gray-02;
  max-width: 480px;
}
