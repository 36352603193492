$exercise-card-height-image: 104px;
$exercise-card-border-radius: $border-radius-01;
$exercise-card-title-color: $color-title;
$exercise-card-title-separator: $main-separator-items;
$exercise-card-background-position: -5px;
$exercise-card-background-image-default: $color-gray-07;

.exercise-card {
  @extend .card-default-small;

  height: 100%;
}

.exercise-card--selected {
  border: $border-size-02 solid $color-first;
}

.exercise-card__image-container {
  background-size: cover;
  background-position-y: $exercise-card-background-position;
  background-color: $exercise-card-background-image-default;
  height: $exercise-card-height-image;

  border-top-left-radius: $exercise-card-border-radius;
  border-top-right-radius: $exercise-card-border-radius;

  width: calc(100% + #{$padding-card-default-small} * 2);
  position: relative;
  left: calc(-#{$padding-card-default-small});
  top: -#{$padding-card-default-small};
}

.exercise-card__title-container {
  display: flex;
  align-items: center;
}

.exercise-card__icon {
  flex: 0 0 auto;
}

.exercise-card__title {
  @include ellipsis-line(2);

  color: $exercise-card-title-color;
  margin-left: $exercise-card-title-separator;
}
