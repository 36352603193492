$units-accordion-thumbnail-size: 70px;
$units-accordion-thumbnail-separator: $main-separator-components;
$units-accordion-thumbnail-small-separator: $main-separator-items;
$units-accordion-index-color: $color-white;

.units-accordion__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  width: 100%;
}

.units-accordion__title-container--student {
  // .units-accordion__main-info {
  //   margin-left: $units-accordion-main-info-separator;
  // }
}

.units-accordion__title-container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.units-accordion__title-container-flex-left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.units-accordion__title-container--student {
  .units-accordion__title-container-flex-left {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}

.units-accordion__main-info {
  display: flex;
}

.units-accordion__thumbnail {
  height: $units-accordion-thumbnail-size;
  width: $units-accordion-thumbnail-size;

  background-size: cover;
  border-radius: $border-radius-05;
  margin-right: $units-accordion-thumbnail-separator;
  margin-left: $units-accordion-thumbnail-small-separator;

  position: relative;
}

.units-accordion__index {
  @extend .title-h2;

  color: $units-accordion-index-color;

  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.units-accordion__main-info-texts {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.units-accordion__name {
  @extend .title-h5;
  @include ellipsis-line(1);

  color: $units-accordion-text-color;
}

.units-accordion__main-content-not-available {
  @extend .title-h6;

  margin-right: $separator-m;
  color: $color-gray-03;
}

.units-accordion__lessons-length {
  @extend .text-smallest;

  color: $units-accordion-text-color;
  flex: 0 0 auto;
}

.units-accordion__times-icons {
  position: absolute;
  right: calc(100% + 25px);
}

.units-accordion__main-hide-icon {
  display: flex;
  align-items: center;
  margin-right: $separator-s;
}
