$margin-1: 0.25rem;
$margin-2: 0.5rem;
$margin-3: 1rem;
$margin-4: 1.5rem;
$margin-5: 3rem;

// MARGIN
.m-1 {
  margin: $margin-1 !important;
}

.m-2 {
  margin: $margin-2 !important;
}

.m-3 {
  margin: $margin-3 !important;
}

.m-4 {
  margin: $margin-4 !important;
}

.m-5 {
  margin: $margin-5 !important;
}

// TOP
.mt-1 {
  margin-top: $margin-1 !important;
}

.mt-2 {
  margin-top: $margin-2 !important;
}

.mt-3 {
  margin-top: $margin-3 !important;
}

.mt-4 {
  margin-top: $margin-4 !important;
}

.mt-5 {
  margin-top: $margin-5 !important;
}

// BOTTOM

.mb-1 {
  margin-bottom: $margin-1 !important;
}

.mb-2 {
  margin-bottom: $margin-2 !important;
}

.mb-3 {
  margin-bottom: $margin-3 !important;
}

.mb-4 {
  margin-bottom: $margin-4 !important;
}

.mb-5 {
  margin-bottom: $margin-5 !important;
}

// LEFT

.ml-1 {
  margin-left: $margin-1 !important;
}

.ml-2 {
  margin-left: $margin-2 !important;
}

.ml-3 {
  margin-left: $margin-3 !important;
}

.ml-4 {
  margin-left: $margin-4 !important;
}

.ml-5 {
  margin-left: $margin-5 !important;
}

// RIGHT

.mr-1 {
  margin-right: $margin-1 !important;
}

.mr-2 {
  margin-right: $margin-2 !important;
}

.mr-3 {
  margin-right: $margin-3 !important;
}

.mr-4 {
  margin-right: $margin-4 !important;
}

.mr-5 {
  margin-right: $margin-5 !important;
}

// -------------------------------------------------
// sm
// -------------------------------------------------
@media (min-width: 576px) {
  // MARGIN
  .m-sm-1 {
    margin: $margin-1 !important;
  }

  .m-sm-2 {
    margin: $margin-2 !important;
  }

  .m-sm-3 {
    margin: $margin-3 !important;
  }

  .m-sm-4 {
    margin: $margin-4 !important;
  }

  .m-sm-5 {
    margin: $margin-5 !important;
  }

  // TOP
  .mt-sm-1 {
    margin-top: $margin-1 !important;
  }

  .mt-sm-2 {
    margin-top: $margin-2 !important;
  }

  .mt-sm-3 {
    margin-top: $margin-3 !important;
  }

  .mt-sm-4 {
    margin-top: $margin-4 !important;
  }

  .mt-sm-5 {
    margin-top: $margin-5 !important;
  }

  // BOTTOM

  .mb-sm-1 {
    margin-bottom: $margin-1 !important;
  }

  .mb-sm-2 {
    margin-bottom: $margin-2 !important;
  }

  .mb-sm-3 {
    margin-bottom: $margin-3 !important;
  }

  .mb-sm-4 {
    margin-bottom: $margin-4 !important;
  }

  .mb-sm-5 {
    margin-bottom: $margin-5 !important;
  }

  // LEFT

  .ml-sm-1 {
    margin-left: $margin-1 !important;
  }

  .ml-sm-2 {
    margin-left: $margin-2 !important;
  }

  .ml-sm-3 {
    margin-left: $margin-3 !important;
  }

  .ml-sm-4 {
    margin-left: $margin-4 !important;
  }

  .ml-sm-5 {
    margin-left: $margin-5 !important;
  }

  // RIGHT

  .mr-sm-1 {
    margin-right: $margin-1 !important;
  }

  .mr-sm-2 {
    margin-right: $margin-2 !important;
  }

  .mr-sm-3 {
    margin-right: $margin-3 !important;
  }

  .mr-sm-4 {
    margin-right: $margin-4 !important;
  }

  .mr-sm-5 {
    margin-right: $margin-5 !important;
  }
}

// -------------------------------------------------
// md
// -------------------------------------------------
@media (min-width: 768px) {
  // MARGIN
  .m-md-1 {
    margin: $margin-1 !important;
  }

  .m-md-2 {
    margin: $margin-2 !important;
  }

  .m-md-3 {
    margin: $margin-3 !important;
  }

  .m-md-4 {
    margin: $margin-4 !important;
  }

  .m-md-5 {
    margin: $margin-5 !important;
  }

  // TOP
  .mt-md-1 {
    margin-top: $margin-1 !important;
  }

  .mt-md-2 {
    margin-top: $margin-2 !important;
  }

  .mt-md-3 {
    margin-top: $margin-3 !important;
  }

  .mt-md-4 {
    margin-top: $margin-4 !important;
  }

  .mt-md-5 {
    margin-top: $margin-5 !important;
  }

  // BOTTOM

  .mb-md-1 {
    margin-bottom: $margin-1 !important;
  }

  .mb-md-2 {
    margin-bottom: $margin-2 !important;
  }

  .mb-md-3 {
    margin-bottom: $margin-3 !important;
  }

  .mb-md-4 {
    margin-bottom: $margin-4 !important;
  }

  .mb-md-5 {
    margin-bottom: $margin-5 !important;
  }

  // LEFT

  .ml-md-1 {
    margin-left: $margin-1 !important;
  }

  .ml-md-2 {
    margin-left: $margin-2 !important;
  }

  .ml-md-3 {
    margin-left: $margin-3 !important;
  }

  .ml-md-4 {
    margin-left: $margin-4 !important;
  }

  .ml-md-5 {
    margin-left: $margin-5 !important;
  }

  // RIGHT

  .mr-md-1 {
    margin-right: $margin-1 !important;
  }

  .mr-md-2 {
    margin-right: $margin-2 !important;
  }

  .mr-md-3 {
    margin-right: $margin-3 !important;
  }

  .mr-md-4 {
    margin-right: $margin-4 !important;
  }

  .mr-md-5 {
    margin-right: $margin-5 !important;
  }
}

// -------------------------------------------------
// lg
// -------------------------------------------------
@media (min-width: 992px) {
  // MARGIN
  .m-lg-1 {
    margin: $margin-1 !important;
  }

  .m-lg-2 {
    margin: $margin-2 !important;
  }

  .m-lg-3 {
    margin: $margin-3 !important;
  }

  .m-lg-4 {
    margin: $margin-4 !important;
  }

  .m-lg-5 {
    margin: $margin-5 !important;
  }

  // TOP
  .mt-lg-1 {
    margin-top: $margin-1 !important;
  }

  .mt-lg-2 {
    margin-top: $margin-2 !important;
  }

  .mt-lg-3 {
    margin-top: $margin-3 !important;
  }

  .mt-lg-4 {
    margin-top: $margin-4 !important;
  }

  .mt-lg-5 {
    margin-top: $margin-5 !important;
  }

  // BOTTOM

  .mb-lg-1 {
    margin-bottom: $margin-1 !important;
  }

  .mb-lg-2 {
    margin-bottom: $margin-2 !important;
  }

  .mb-lg-3 {
    margin-bottom: $margin-3 !important;
  }

  .mb-lg-4 {
    margin-bottom: $margin-4 !important;
  }

  .mb-lg-5 {
    margin-bottom: $margin-5 !important;
  }

  // LEFT

  .ml-lg-1 {
    margin-left: $margin-1 !important;
  }

  .ml-lg-2 {
    margin-left: $margin-2 !important;
  }

  .ml-lg-3 {
    margin-left: $margin-3 !important;
  }

  .ml-lg-4 {
    margin-left: $margin-4 !important;
  }

  .ml-lg-5 {
    margin-left: $margin-5 !important;
  }

  // RIGHT

  .mr-lg-1 {
    margin-right: $margin-1 !important;
  }

  .mr-lg-2 {
    margin-right: $margin-2 !important;
  }

  .mr-lg-3 {
    margin-right: $margin-3 !important;
  }

  .mr-lg-4 {
    margin-right: $margin-4 !important;
  }

  .mr-lg-5 {
    margin-right: $margin-5 !important;
  }
}

// -------------------------------------------------
// xl
// -------------------------------------------------
@media (min-width: 1200px) {
  // MARGIN
  .m-xl-1 {
    margin: $margin-1 !important;
  }

  .m-xl-2 {
    margin: $margin-2 !important;
  }

  .m-xl-3 {
    margin: $margin-3 !important;
  }

  .m-xl-4 {
    margin: $margin-4 !important;
  }

  .m-xl-5 {
    margin: $margin-5 !important;
  }

  // TOP
  .mt-xl-1 {
    margin-top: $margin-1 !important;
  }

  .mt-xl-2 {
    margin-top: $margin-2 !important;
  }

  .mt-xl-3 {
    margin-top: $margin-3 !important;
  }

  .mt-xl-4 {
    margin-top: $margin-4 !important;
  }

  .mt-xl-5 {
    margin-top: $margin-5 !important;
  }

  // BOTTOM

  .mb-xl-1 {
    margin-bottom: $margin-1 !important;
  }

  .mb-xl-2 {
    margin-bottom: $margin-2 !important;
  }

  .mb-xl-3 {
    margin-bottom: $margin-3 !important;
  }

  .mb-xl-4 {
    margin-bottom: $margin-4 !important;
  }

  .mb-xl-5 {
    margin-bottom: $margin-5 !important;
  }

  // LEFT

  .ml-xl-1 {
    margin-left: $margin-1 !important;
  }

  .ml-xl-2 {
    margin-left: $margin-2 !important;
  }

  .ml-xl-3 {
    margin-left: $margin-3 !important;
  }

  .ml-xl-4 {
    margin-left: $margin-4 !important;
  }

  .ml-xl-5 {
    margin-left: $margin-5 !important;
  }

  // RIGHT

  .mr-xl-1 {
    margin-right: $margin-1 !important;
  }

  .mr-xl-2 {
    margin-right: $margin-2 !important;
  }

  .mr-xl-3 {
    margin-right: $margin-3 !important;
  }

  .mr-xl-4 {
    margin-right: $margin-4 !important;
  }

  .mr-xl-5 {
    margin-right: $margin-5 !important;
  }
}

// -------------------------------------------------
// xxl
// -------------------------------------------------
@media (min-width: 1400px) {
  // MARGIN
  .m-xxl-1 {
    margin: $margin-1 !important;
  }

  .m-xxl-2 {
    margin: $margin-2 !important;
  }

  .m-xxl-3 {
    margin: $margin-3 !important;
  }

  .m-xxl-4 {
    margin: $margin-4 !important;
  }

  .m-xxl-5 {
    margin: $margin-5 !important;
  }

  // TOP
  .mt-xxl-1 {
    margin-top: $margin-1 !important;
  }

  .mt-xxl-2 {
    margin-top: $margin-2 !important;
  }

  .mt-xxl-3 {
    margin-top: $margin-3 !important;
  }

  .mt-xxl-4 {
    margin-top: $margin-4 !important;
  }

  .mt-xxl-5 {
    margin-top: $margin-5 !important;
  }

  // BOTTOM

  .mb-xxl-1 {
    margin-bottom: $margin-1 !important;
  }

  .mb-xxl-2 {
    margin-bottom: $margin-2 !important;
  }

  .mb-xxl-3 {
    margin-bottom: $margin-3 !important;
  }

  .mb-xxl-4 {
    margin-bottom: $margin-4 !important;
  }

  .mb-xxl-5 {
    margin-bottom: $margin-5 !important;
  }

  // LEFT

  .ml-xxl-1 {
    margin-left: $margin-1 !important;
  }

  .ml-xxl-2 {
    margin-left: $margin-2 !important;
  }

  .ml-xxl-3 {
    margin-left: $margin-3 !important;
  }

  .ml-xxl-4 {
    margin-left: $margin-4 !important;
  }

  .ml-xxl-5 {
    margin-left: $margin-5 !important;
  }

  // RIGHT

  .mr-xxl-1 {
    margin-right: $margin-1 !important;
  }

  .mr-xxl-2 {
    margin-right: $margin-2 !important;
  }

  .mr-xxl-3 {
    margin-right: $margin-3 !important;
  }

  .mr-xxl-4 {
    margin-right: $margin-4 !important;
  }

  .mr-xxl-5 {
    margin-right: $margin-5 !important;
  }
}
