$license-expired-item-thumbnail-width: 69px;
$license-expired-item-separator: $main-separator-components;
$license-expired-item-separator-items: $main-separator-items;
$license-expired-item-color-title: $color-title;
$license-expired-item-expired-font: 'Lato Regular';
$license-expired-item-expired-color: #f66868;

.license-expired-item {
  display: flex;
  position: relative;
  min-height: 40px;
  margin-bottom: $separator-s;
  padding: $separator-m;
  border-bottom: 1px solid $color-gray-05;
}

.license-expired-item__image {
  background-size: cover;
  border-top-left-radius: $card-simple-border-radius;
  border-bottom-left-radius: $card-simple-border-radius;
  top: 0;
  height: 39px;
  left: 0;
  position: absolute;
  width: $license-expired-item-thumbnail-width;

  .program-image__picture-container,
  .program-image__picture {
    height: 60px;
    width: 60px;
  }

  .program-image__picture-container {
    padding: 0;
    border-radius: 0 !important;
  }

  .program-image__picture {
    border-radius: 0 !important;
  }
}

.license-expired-item__content {
  display: block;
  margin-left: $license-expired-item-thumbnail-width;
  width: 100%;
}

.license-expired-item__content-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: #{$license-expired-item-thumbnail-width} + #{$license-expired-item-separator};
  width: 50%;
}

.license-expired-item--typeview-simple {
  .license-expired-item__content-texts {
    width: auto;
    margin-right: $license-expired-item-separator;
  }
}

.license-expired-item__content-name {
  @extend .title-h6;
  @include ellipsis-line(1);
}

.license-expired-item__content-level {
  @extend .text-small;

  color: $license-expired-item-color-title;
}

.license-expired-item__content-isbn {
  @extend .text-extra;

  color: $color-gray-03;
}

.license-expired-item__content-meta-info {
  display: flex;
  flex-direction: column;
  margin-top: $license-expired-item-separator-items;
}

.license-expired-item__content-code {
  @extend .text-small;
}

.license-expired-item__content-range-text {
  @extend .text-small;
}

.license-expired-item__content-range {
  @extend .text-small-bold;

  color: $license-expired-item-color-title;
}

.license-expired-item__content-delete {
  @extend .hover-basic;

  //margin: auto;
  margin-left: auto;
  margin-right: $license-expired-item-separator;
}

.license-expired-item-expired {
  display: flex;
  align-items: center;
}

.license-expired-item-expired__text {
  margin-left: $separator-s;
  color: $license-expired-item-expired-color;
  font-family: $license-expired-item-expired-font;
  font-size: $font-size-08;
}

@media #{$breakpoints-medium} {
  .license-expired-item__content {
    display: flex;
    align-items: center;
  }

  .license-expired-item__content-meta-info {
    margin-left: $license-expired-item-separator;
    margin-top: 0;
  }
}
