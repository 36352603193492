.dialog-videolesson {
  @extend .dialog-activity;
  .layout-header {
    min-height: auto !important;
  }

  .layout-header__content {
    background-color: $color-white;
    height: 100vh;
    .video-lesson .video-lesson-content {
      background-color: $color-white !important;
    }
  }

  .layout-header__children {
    display: flex;
    justify-content: center;
  }

  .dialog-videolesson__videolesson {
    width: 100%;
  }

  .layout-header__footer {
    padding-right: $separator-l;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.dialog-videolesson__name {
  @extend .dialog-activity__group-name;
}
