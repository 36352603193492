$student-classrooms-subheader-info-margin: $separator-xxl;
$student-classrooms-subheader-courses-margin: $separator-xxxl;
$student-classrooms-subheader-avatar-size: 68px;
$student-classrooms-subheader-name-color: $color-gray-01;

.student-classrooms-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-classrooms-subheader__left {
  display: flex;
  align-items: center;

  .avatar {
    height: $student-classrooms-subheader-avatar-size;
    width: $student-classrooms-subheader-avatar-size;
  }
}

.student-classrooms-subheader__right {
  .button__text {
    @extend .text-small-bold;
  }
}

.student-classrooms-subheader__info {
  margin-left: $student-classrooms-subheader-info-margin;
}

.student-classrooms-subheader__name {
  @extend .title-h3;

  color: $student-classrooms-subheader-name-color;
}

.student-classrooms__courses {
  margin-top: $student-classrooms-subheader-courses-margin;
}
