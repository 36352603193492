$input-points-border-radius: $border-radius-inputs;
$input-points-border-radius-big: $border-radius-02;
$input-points-height: $size-height-input-points;
$input-points-height-big: $size-height-input-points-big;
$input-points-height-medium: $size-height-input-points-medium;
$input-points-padding: $input-padding;
$input-points-color-placeholder: $color-placeholder;
$input-points-background: $color-input-background;
$input-points-text-points-color: $color-gray-04;
$input-points-padding-right: 40px;
$input-points-padding-right-big: 46px;
$input-points-padding-right-medium: 26px;

.input-points {
  display: flex;
  flex-direction: column;
  position: relative;

  .has-error {
    border-color: #f04d29 !important;
  }
}

.input-points--read-only {
  .input-points__input-container {
    border: 0;
  }
  .input-points__input {
    background: transparent;
  }
}

.input-points--size-big {
  .input-points__input {
    height: $input-points-height-big;
  }

  .input-points__points-total {
    @extend .text-p;

    bottom: 8px;
  }

  .input-points__input {
    @extend .title-h4;
  }
}

.input-points--size-medium {
  .input-points__input-container {
    border-radius: $input-points-border-radius;
    height: $input-points-height-medium;
  }

  .input-points__points-total {
    @extend .text-p;

    bottom: 2px;
  }

  .input-points__input {
    @extend .title-h5;

    // border: 0;
  }
}

.input-points__input-container {
  @extend .border-light-black;

  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.input-points__input {
  @extend .transition-hover-border;
  @extend .text-small-bold;

  background: $input-points-background;
  border-radius: $input-points-border-radius;
  height: $input-points-height;
  padding: $input-points-padding;
  text-align: right;

  width: 100%;
  height: 100%;

  &::placeholder {
    color: $input-points-color-placeholder;
  }
}

.input-points__points-total {
  @extend .text-small;

  color: $input-points-text-points-color;
  white-space: nowrap;
  margin-right: $separator-xs;
}

.input-points__late {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 5010;
}

.input-points__input-container--dotted {
  border: 2px dashed $color-alert !important;
}
