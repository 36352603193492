$forgot-password-title-margin: $separator-s;
$forgot-password-subtitle-margin: $separator-xxl;
$forgot-password-title-color: $color-gray-01;
$forgot-password-subtitle-color: $color-gray-02;

.forgot-password__success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password__title {
  @extend .title-h5;

  margin-bottom: $forgot-password-title-margin;
  color: $forgot-password-title-color;
}

.forgot-password__subtitle {
  @extend .text-p;

  margin-bottom: $forgot-password-subtitle-margin;
  color: $forgot-password-subtitle-color;
}

.forgot-password__success-text {
  @extend .text-p;

  margin: $forgot-password-subtitle-margin 0;
  color: $forgot-password-subtitle-color;
}
