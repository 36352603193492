$calendar-big-border-color: $color-borders;
$calendar-big-indicator-height: $size-calendar-big-indicator-height;
$calendar-big-indicator-background: $color-first;
$calendar-big-label-time-color: $color-label2;
$calendar-big-day-height: $size-calendar-big-day-height;
$calendar-big-view-month-height: $size-calendar-big-month-view-height;
$calendar-big-current-day-after-size: 24px;
$calendar-big-current-day-color: $color-gray-02;
$calendar-big-current-day-active-color: $color-white;
$calendar-big-more-events-color: $color-link;
$calendar-big-more-events-font-size: 12px;
$calendar-big-indicator-bubble-size: 10px;
$calendar-big-indicator-bubble-size: 10px;
$calendar-big-name-out-range: $color-gray-06;
$calendar-big-today-background-after: $color-first;
$calendar-big-overlay-box-shadow: $box-shadow-02;

.calendar-big {
  * {
    border-color: $calendar-big-border-color !important;
  }

  .rbc-time-view,
  .rbc-header,
  .rbc-time-slot,
  .rbc-time-content,
  .rbc-time-gutter .rbc-timeslot-group {
    border: 0 !important;
  }

  .rbc-time-header-gutter,
  .rbc-time-slot {
    display: none;
  }

  .rbc-timeslot-group {
    min-height: $calendar-big-day-height;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
    border-top: 1px solid $calendar-big-border-color;
  }

  .rbc-time-column:last-child {
    border-right: 1px solid $calendar-big-border-color;
  }

  .rbc-today {
    background: transparent;
  }

  .rbc-show-more {
    @extend .text-smallest;

    color: $calendar-big-more-events-color;
    font-size: $calendar-big-more-events-font-size;
  }

  .rbc-current-time-indicator {
    height: $calendar-big-indicator-height;
    background: $calendar-big-indicator-background;

    &::before {
      content: '';
      height: $calendar-big-indicator-bubble-size;
      width: $calendar-big-indicator-bubble-size;

      background-color: $calendar-big-indicator-background;
      border-radius: 100%;
      position: relative;

      display: block;
      top: -3px;
      left: -5px;
    }
  }

  .rbc-label {
    @extend .text-smallest;

    color: $calendar-big-label-time-color;
  }

  .rbc-overlay-header {
    @extend .text-smallest;

    color: $calendar-big-label-time-color;
  }
  .holiday {
    .rbc-timeslot-group {
      background-color: #f9f9f9 !important;
    }
    &.rbc-day-bg {
      background-color: #f9f9f9 !important;
    }
  }

  .rbc-allday-cell {
    .rbc-day-bg {
      background-color: #ffffff !important;
    }
  }
}

.rbc-overlay {
  @extend .card-default-small;

  box-shadow: $calendar-big-overlay-box-shadow !important;
  .rbc-event {
    padding-left: 0;
    padding-right: 0;
  }
}

.rbc-event:focus {
  outline: none !important;
}

.rbc-event.rbc-selected,
.rbc-event {
  background-color: transparent !important;
}

.calendar-big--view-month {
  .rbc-calendar {
    height: $calendar-big-view-month-height;
  }

  //.rbc-current {
  .rbc-now {
    position: relative;
    a {
      color: $calendar-big-current-day-active-color;
    }
    &::after {
      content: '';
      height: $calendar-big-current-day-after-size;
      width: $calendar-big-current-day-after-size;
      background: $calendar-big-today-background-after;
      border-radius: 100%;
      display: block;
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: -1;
    }
  }

  .rbc-date-cell {
    @extend .text-small-bold;

    color: $calendar-big-current-day-color;
  }

  .rbc-event {
    background: transparent;
  }

  .rbc-off-range-bg {
    background: transparent;
  }

  .rbc-off-range {
    a {
      color: $calendar-big-name-out-range;
    }
  }
}

@media #{$breakpoints-tablet} {
  .rbc-time-slot,
  .rbc-time-header-gutter {
    display: block !important;
  }

  .rbc-month-view {
    width: calc(100% - #{$toolbar-left}) !important;
    margin-left: $toolbar-left;
  }
}
