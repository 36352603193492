$google-classroom-student-avata-size: 26px;

.google-classroom-student-item {
  padding: $separator-s 0;
  border-bottom: 1px solid $color-gray-06;
}

.google-classroom-student-item__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.google-classroom-student-item__left,
.google-classroom-student-item__right {
  display: flex;
  align-items: center;
}

.google-classroom-student-item__avatar {
  margin-left: $separator-m;
  img {
    height: $google-classroom-student-avata-size;
    width: $google-classroom-student-avata-size;
    border-radius: 100%;
  }
}

.google-classroom-student-item__name {
  @extend .title-h6;

  margin-left: $separator-m;
  color: $color-gray-01;
}

.google-classroom-student-item__email {
  @extend .text-smallest;

  color: $color-gray-03;
}
