$separator-xxs: 2px !default;
$separator-xs: 4px !default;
$separator-s: 8px !default;
$separator-m: 12px !default;
$separator-l: 16px !default;
$separator-xl: 20px !default;
$separator-xxl: 22px !default;
$separator-xxxl: 30px !default;
$separator-xxxxl: 40px !default;
$separator-xxxxxl: 50px !default;
$separator-xxxxxxl: 60px !default;

// section -- default 16, 24, 32
// section-footer -- default 16, 24, 32
// section-components -- default 12, 16
// section-lists -- section-footer
