$assigment-list-separator-mobile: $main-separator-items;
$assigment-list-separator-graph: $main-separator-components;
$assigment-list-separator-loader: $main-separator-components;
$assigment-list-separator-min-height: 550px;
$assigment-list-separator-counts: $main-separator-components;
$assigment-list-border-publisher: $border-size-active solid $color-first;
$assigment-list-content-instructions-color: $color-gray-04;
$assigment-list-preview-max-height: 400px;

.lesson-item-list {
  width: 100%;
  .MuiAccordion-root {
    @extend .card-default;
    border-radius: $border-radius-01 !important;
    margin-top: $assigment-list-separator-mobile !important;
  }

  .MuiAccordionSummary-root {
    height: initial !important;
  }

  .MuiButtonBase-root {
    align-items: flex-start;
  }

  .comments-list-and-create__sender {
    left: calc(((#{$padding-card-default-horizontal}) * -1) - 1px);
    overflow: visible;
    width: calc((100% + (#{$padding-card-default-horizontal} * 2)) + 1px);

    position: relative;
    bottom: calc((#{$padding-card-default-vertical} * -1));
  }
}

.lesson-item-list--student {
  .MuiAccordion-root {
    border-radius: $border-radius-05 !important;
  }

  .message-sender-simple {
    border-bottom-left-radius: $border-radius-05;
    border-bottom-right-radius: $border-radius-05;
  }
}

.lesson-item-list--student-assignment {
  .MuiAccordionSummary-expandIcon,
  .MuiCollapse-container {
    //display: none;
  }
}

.lesson-item-list__title-container {
  width: 100%;
}

.lesson-item-list__is-publisher {
  border-left: $assigment-list-border-publisher !important;
}

.lesson-item-list--teacher {
  .lesson-item-list__no-is-publisher {
    .MuiAccordionSummary-content {
      padding-left: $border-size-active;
    }
  }
}

.lesson-item-list__children-content {
  display: flex;
  align-items: center;

  .count {
    text-align: left;
  }
}

.lesson-item-list__children-description {
  @extend .text-small;

  color: $assigment-list-content-instructions-color;
  margin-top: $main-separator-items;
}

.lesson-item-list__children-container {
  border: 0;
  padding-top: 0;
  margin-top: $assigment-list-separator-mobile;
  width: 100%;
}

.lesson-item-list__children-container-graph {
  margin-top: $assigment-list-separator-graph;
}

.lesson-item-list__children-container-graph-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: $gutter 0;
}

.lesson-item-list__children-container-feedback {
  padding-top: $separator-l;
}

.lesson-item-list__open-assessment {
  display: flex;
  justify-content: flex-end;
}

.lesson-item-list__children-results-text {
  @extend .text-link;
  @extend .text-small-bold;
  display: flex;
  justify-content: flex-end;
  .button--default {
    width: auto !important;
  }
}

.lesson-item-list__children-results-text-interactive {
  margin-right: $separator-l;
}

.lesson-item-list__children-counts {
  display: flex;
  margin-top: $assigment-list-separator-counts;

  .count {
    margin-right: $assigment-list-separator-counts;
  }
}

.lesson-item-list__children-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: $assigment-list-separator-min-height;
}

.lesson-item-list__children-content-container {
  .preview-content__img,
  video {
    margin: auto;
    max-height: $assigment-list-preview-max-height;
  }
}

.lesson-item-list--active-url {
  transition-duration: 0.9s !important;
  box-shadow: 0 0 0 2px $color-first !important;
}

.lesson-item-list__children__teacher-notes {
  margin-top: $separator-m;
}

.lesson-item-list__students-graph {
  .horizontal-students-progress {
    box-shadow: none;
    border-radius: 0;
    border: 0;
  }
}

.lesson-item-list__children-iframe {
  margin-top: $separator-m;
}
.lesson-item-list__children-iframe__footer {
  margin-top: $separator-m;
  display: flex;
  justify-content: flex-end;
}
.lesson-item-list__children-iframe__fullscreen {
  @extend .hover-basic;
}
