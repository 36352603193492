$event-month-separator: $separator-xs;
$event-month-name-color: $color-black;
$event-month-name-separator: $separator-xs;
$event-month-event-all-day-color-text: $color-white;
$event-month-date-font-size: 10px;
$event-month-padding: $separator-xs;

.event-month {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.event-month__info {
  display: flex;
  align-items: center;
  width: 70%;
}

.event-month__bubble {
  flex: 0 0 auto;
}

.event-month__name {
  @extend .text-extra;
  @extend .ellipsis;

  color: $event-month-name-color;
  margin-left: $event-month-name-separator;
}

.event-month__date {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.event-month__date-text {
  @extend .text-extra;

  font-size: $event-month-date-font-size;
}

.event-month--passed {
  opacity: 0.6;
}
