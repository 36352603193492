$basic-list-item-margin-bottom: $main-separator-items;

.basic-list__item {
  transition-duration: 0.9s;

  margin-bottom: $basic-list-item-margin-bottom;
}

.basic-list__item:last-child {
  margin-bottom: 0;
}

.basic-list__item--active {
  > div {
    transition-duration: 0.9s;
    // background: lighten($color-first, 50%);
    box-shadow: 0 0 0 2px $color-first;
    // background: red;;
  }
}

.basic-list--with-border {
  .basic-list__item {
    border-bottom: $border-size-01 solid $color-borders;
    padding: $separator-s 0;
    margin-bottom: 0;
  }

  .basic-list__item:last-child {
    border-bottom: 0;
  }
}
