$grid-flexible: 90% !default;
$grid-max-width: 1200px !default;
$grid-max-width-fullscreen: 1200px !default;
$grid-max-width-content: 980px  !default;
$gutter: 24px !default;

$grid-padding-left-mobile: calc((100% - #{$grid-flexible}) / 2) !default;
$grid-padding-left-xxlarge: calc(50% - #{$grid-max-width} / 2)  !default;
$grid-padding-left-xxxlarge: calc(50% - #{$grid-max-width-fullscreen} / 2)  !default;

$margin-default-mobile: 24px  !default;
$margin-default-xxlarge: 32px  !default;
$margin-default-xxxlarge: 64px  !default;
