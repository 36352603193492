$result-tag-padding-horizontal: $separator-s;
$result-tag-padding-vertical: $separator-xs;
$result-tag-border: $border-radius-02;
$result-tag-red-background: $color-first-alpha;
$result-tag-red-color: $color-error;
$result-tag-green-background: $color-second-green-02;
$result-tag-green-color: $color-success;
$result-tag-blue-background: $color-second-blue-03;
$result-tag-blue-color: $color-link;

.result-tag__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $result-tag-padding-vertical $result-tag-padding-horizontal;
  border-radius: $result-tag-border;
}

.result-tag__text {
  @extend .title-h6;

  margin-left: $result-tag-padding-horizontal;
}

.result-tag--green {
  .result-tag__wrapper {
    background: $result-tag-green-background;
  }
  .result-tag__text {
    color: $result-tag-green-color;
  }
}

.result-tag--red {
  .result-tag__wrapper {
    background: $result-tag-red-background;
  }
  .result-tag__text {
    color: $result-tag-red-color;
  }
}

.result-tag--blue {
  .result-tag__wrapper {
    background: $result-tag-blue-background;
  }
  .result-tag__text {
    color: $result-tag-blue-color;
  }

  svg {
    circle {
      fill: $result-tag-blue-color !important;
    }
  }
}
