$dialog-content-library-container-padding: $form-spacing-big;
$dialog-content-hr-sepator: $main-separator-items;
$dialog-content-library-upload-padding: $separator-xxl;
$dialog-content-library-upload-max-width: 765px;
$dialog-content-header-components-margin: $separator-xxl;
$dialog-content-total-padding: $separator-m;
$dialog-content-list-margin: $separator-m;
$dialog-content-document-size: $size-document-icon;
$dialog-content-upload-disabled-radius: $border-radius-05;
$dialog-content-document-color: $color-gray-06;
$dialog-content-total-color: $color-gray-03;
$dialog-content-upload-disabled-color: $color-gray-07;
$dialog-content-upload-disabled-icon-color: $color-gray-04;

.dialog-content-library {
  .MuiDialogContent-root {
    padding-bottom: 0 !important;
  }
}

.dialog-content-library__hr {
  .dialog__hr {
    margin-top: $dialog-content-hr-sepator;
  }
}

.dialog-content-library__wrapper {
  height: 66vh;
}

.dialog-content-library__container {
  padding-top: $dialog-content-library-container-padding;
  //min-height: 500px;
}

.dialog-content-library__upload {
  padding: 0 $dialog-content-library-upload-padding;
}

.dialog-content-library__header {
  display: flex;
}

.dialog-content-library__search {
  width: 28%;
}

.dialog-content-library__disciplines,
.dialog-content-library__types,
.dialog-content-library__owner {
  width: 18%;

  .select-customizable__control {
    border: 0;
  }
}

// .dialog-content-library__search,
// .dialog-content-library__disciplines,
// .dialog-content-library__types,
// .dialog-content-library__owner {
//   flex: 1 1 0px;
// }

.dialog-content-library__search,
.dialog-content-library__disciplines,
.dialog-content-library__types {
  margin-right: $dialog-content-header-components-margin;
}

.dialog-content-library__columns {
  @extend .grid-column--without-gutter;

  // padding-top: $dialog-content-total-padding;
  //border-bottom: $border-size-02 solid $color-borders;

  // width: 100%;
  // margin: auto;
}

.dialog-content-library__left-column {
  @include column--without-gutter(5);

  border-right: $border-size-01 solid $color-borders;
  padding-top: $dialog-content-total-padding;
}

.dialog-content-library__right-column {
  @include column(7);

  padding-top: $dialog-content-total-padding;
}

.dialog-content-library__list {
  margin-top: $dialog-content-list-margin;
  overflow-y: auto;
  max-height: 50vh;
}

.dialog-content-library__tabs {
  position: absolute;
  top: 15px;
  left: 45%;
  /*display: flex;
  justify-content: center;*/
}

.dialog-content-library__contents-total {
  @extend .text-small;

  padding-bottom: $dialog-content-total-padding 0;
  color: $dialog-content-total-color;
}
.dialog-content-library__name {
  @extend .title-h6;
}

.dialog-content-library__owner {
  @extend .title-h7;

  margin-bottom: $dialog-content-list-margin;
}

.dialog-content-library__preview {
  //OVERRIDES
  .preview-content__image {
    margin: 0;
  }

  .preview-content__img {
    max-height: 30vh;
    margin: 0;
  }

  .preview-content__video,
  .preview-content__audio {
    .preview-content__name {
      display: none;
    }
  }

  .preview-content__audio-icons {
    margin-top: 0;
  }

  video {
    max-height: $size-video-small-height;
  }
}

.dialog-content-library__document {
  @extend .hover-basic;

  width: $dialog-content-document-size;
  height: $dialog-content-document-size;
  background-color: $dialog-content-document-color;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $dialog-content-upload-disabled-radius;
}

.upload-attach__uploader-container {
  max-width: $dialog-content-library-upload-max-width;
  margin: 0 auto;
  position: relative;
}

.dialog-content-library__no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.dialog-content-library__upload-wrapper {
  position: absolute;
  height: 0%;
  width: 0;
}

//MODIFIERS
.dialog-content-library__upload-wrapper--uploading {
  cursor: not-allowed;
  height: 100%;
  width: 100%;
  background-color: $translucid-black;
  border-radius: $dialog-content-upload-disabled-radius;
}

.dialog-content-library__upload-wrapper--disabled {
  .upload-file-container__drag-container {
    background-color: $dialog-content-upload-disabled-color;
    border: none;
  }

  .upload-file-container__link {
    color: #aaa;
  }

  svg {
    fill: $dialog-content-upload-disabled-icon-color !important;
  }
}

//SKELETONS
.content-detail-skeleton__owner {
  height: 20px;
  margin-bottom: $dialog-content-list-margin;
  margin-top: $dialog-content-list-margin;
}

.content-detail-skeleton__content {
  height: 300px;
}
