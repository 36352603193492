$content-detail-feed-back-margin: $main-separator-components;
$content-detail-arrow-separator: $main-separator-components;

.content-detail__pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-detail__arrows {
  display: flex;
}

.content-detail {
  @extend .card-default;
}

.content-detail__feedback {
  margin-top: $content-detail-feed-back-margin;

  .comments-list-and-create__sender {
    width: calc(100% + (#{$padding-card-default-horizontal} * 2));
    left: -#{$padding-card-default-horizontal};
    position: relative;
    bottom: -#{$padding-card-default-vertical};
  }
}

.content-detail__arrow {
  @extend .hover-basic;
  margin-left: $content-detail-arrow-separator;
}
