$buble-and-date-circle-size: 12px;
$buble-and-date-circle-separator: $main-separator-items;
$buble-and-date-day-color: $color-title;
$buble-and-date-month-color: $color-label;

.buble-and-date {
  display: flex;
  align-items: center;
}

.buble-and-date__circle {
  width: $buble-and-date-circle-size;
  height: $buble-and-date-circle-size;

  border-radius: 100%;
  margin-right: $buble-and-date-circle-separator;
}

.buble-and-date__date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buble-and-date__day {
  @extend .title-h5;

  margin-right: $separator-s;
  color: $buble-and-date-day-color;
}

.buble-and-date__month {
  @extend .text-xs;

  color: $buble-and-date-month-color;
  text-transform: uppercase;
}
