$header-button-right-user-size: 40px;
$header-button-right-item-spaces: $separator-xxl;

.header-button-right {
  display: flex;
  align-items: center;

  position: relative;
}

.header-button-right__item {
  @extend .hover-basic;

  margin-left: $header-button-right-item-spaces;
  position: relative;
}

.header-button-right__bubble {
  position: absolute;
  z-index: $z-index-header-elements;
  top: -4px;
  right: -4px;
}
