$icon-time-check-text-color: $color-gray-04;
$icon-time-check-text-margin-right: $separator-m;
$icon-time-check-current-shadow-size: $size-program-icon-current-background-size;
$icon-time-check-current-shadow-background: $color-first;
$icon-time-check-after-background: $color-gray-04;
$icon-time-check-after-done-background: $color-first;

.icon-time-check {
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    right: 11px;
    bottom: calc(100% + 4px);
    background: $icon-time-check-after-background;
    z-index: 0;
  }
  &:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    right: 11px;
    top: calc(100% + 4px);
    background: $icon-time-check-after-background;
    z-index: 1;
  }
}

.icon-time-check--done {
  &:after {
    background: $icon-time-check-after-done-background;
  }
}

.icon-time-check--first {
  &:before {
    display: none;
  }
}

.icon-time-check--last {
  &:after {
    display: none;
  }
}

.icon-time-check--unique {
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
}
// .icon-time-check--current {
//   left: 3px;
// }

.icon-time-check__text {
  @extend .text-smallest;

  color: $icon-time-check-text-color;
  white-space: nowrap;
  margin-right: $icon-time-check-text-margin-right;
}

.icon-time-check__icon {
  flex: 0 0 auto;

  position: relative;
}

.icon-time-check__icon-background-active {
  background: $icon-time-check-current-shadow-background;
  border-radius: 100%;
  opacity: 0.2;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: $icon-time-check-current-shadow-size;
  width: $icon-time-check-current-shadow-size;
}
