.lesson-power-mint__actions-buttons {
  display: flex;
  align-items: center;
}

.lesson-power-mint__actions-button-cancel {
  margin-right: 10px;
  .button__text {
    color: $color-white;
  }
}

.lesson-power-mint__analytics {
  .button-fixed-with-hover {
    right: 100px;
  }
}
