.gamified-card-class-teacher {
  @extend .hover-basic;

  display: flex;
  align-items: center;

  border-radius: 8px;
  border: 1px solid #eee4dc;
  background: #fff;
  padding: 8px 12px 8px 4px;
  height: 36px;

  svg {
    height: 22px;
  }

  &__title {
    color: #4f4f4f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}
