$card-resource-activity-height: 200px;
$card-resource-activity-image-height: 117px;
$card-resource-activity-bottom-meta-height: 36px;
$card-resource-activity-progress-width: 66px;

.card-resource-activity {
  @extend .hover-basic;

  height: $card-resource-activity-height;
  box-shadow: $box-shadow-06;

  display: flex;
  flex-direction: column;

  .icon-bubble {
    background: rgba(0, 0, 0, 0.8);
  }
}

.card-resource-activity__image-container {
  @extend .card-default-small;

  background-color: $color-gray-06;
  border-bottom: 0;
  height: $card-resource-activity-image-height;
}

.card-resource-activity__image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  flex: 0 0 auto;
  background-position: center;
}

.card-resource-activity__bottom {
  @extend .card-default;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1 1 auto;

  border-top: 0;
}

.card-resource-activity__bottom-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-resource-activity__name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $color-gray-02;
  display: block;
  margin-top: $main-separator-items;

  img {
    display: none;
  }
}

.card-resource-activity__duration {
  @extend .text-smallest;

  color: $color-gray-03;
}

.card-resource-activity__bottom-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: $card-resource-activity-bottom-meta-height;
}

.card-resource-activity__bottom-bubbles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-resource-activity__date {
  margin-right: $main-separator-items;

  .icon-background__icon {
    border-radius: $border-radius-02;
  }
}

.card-resource-activity__progress {
  .progress-bar {
    max-width: $card-resource-activity-progress-width;
    width: 100%;
  }
}

.card-resource-activity__progress-text {
  @extend .text-extra-bold;

  color: $color-gray-03;
  margin-top: $main-separator-items;
}
