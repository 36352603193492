.resources-view {
  padding-top: $separator-m;

  .group-resources-slider {
    margin-bottom: $separator-xxxxl;
  }

  .group-resources-slider__title {
    @extend .grid;
  }

  .swiper-wrapper {
    padding-left: calc((100% - #{$grid-flexible}) / 2);
  }
}
