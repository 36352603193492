$dropdown-button-import-icon-size: 18px;
$dropdown-button-import-fontsize: 14px;
$dropdown-button-import-color: #787979;
$dropdown-button-import-margin-icon: 24px;
$dropdown-button-import-padding: 8px;

.dropdown-button-import__lib {
  .list-simple-dropdown__li {
    padding-left: $dropdown-button-import-padding;
    padding-right: $dropdown-button-import-padding;
  }

  .list-simple-dropdown__icon {
    margin-right: $dropdown-button-import-margin-icon;
  }
  .icon {
    height: $dropdown-button-import-icon-size;
    width: $dropdown-button-import-icon-size;
  }

  .list-simple-dropdown__name {
    color: $dropdown-button-import-color;
    font-size: $dropdown-button-import-fontsize;
    letter-spacing: 0.2px;
    font-family: 'Roboto Medium';
  }
}

.button-import__classroom {
  display: flex;
  align-items: center;
}

.button-import__classroom-text {
  @extend .text-small;

  min-width: 250px;
  margin-right: $separator-m;
}
