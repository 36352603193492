$lesson-item-separator: $separator-m;
$lesson-item-separator-right-items: $separator-xxl;

$lesson-item-tags-separator: $separator-m;
$lesson-item-time-icon-margin-right: 34px;
$lesson-item-time-color: $color-label2;

.lesson-item {
  @extend .hover-basic;
  @extend .card-default;

  border-radius: 0 !important;
  position: relative;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;

  .item-complex-list {
    align-items: flex-start;
  }

  .item-complex-list__left {
    width: 100%;
  }
}

.lesson-item__center {
  display: flex;
  align-items: flex-start;

  .icon {
    height: auto;
    flex: 0 0 auto;
  }
}

.lesson-item__icon-time {
  margin-right: $lesson-item-time-icon-margin-right;
  position: absolute;
  right: 100%;
}

.lesson-item__info {
  display: flex;
  flex-direction: column;

  margin-left: $lesson-item-separator;
}

.lesson-item__name {
  @extend .title-h6;
  @extend .ellipsis-complex;
}

.lesson-item__time {
  display: flex;
}

.lesson-item__date {
  @extend .text-small;
  margin-top: $separator-xs;
  display: flex;
  align-items: center;
  color: $color-gray-03;
  padding-right: $separator-m;
  border-right: 1.5px solid $color-gray-05;
  margin-right: $separator-m;
}

.lesson-item__date-text,
.lesson-item__sessions-text {
  @extend .text-small;
  color: $color-gray-03;
  margin-left: $separator-s;
}

.lesson-item__date-text {
  text-transform: capitalize;
}

.lesson-item__sessions-text {
  text-transform: lowercase;
}
.lesson-item__sessions {
  display: flex;
  align-items: center;
}

.lesson-item__date--current {
  margin-top: $separator-xs;
  text-transform: uppercase;
  letter-spacing: 0.2;
  color: $color-first;
  font-family: $font-first;
  font-size: 14px;
  padding-right: $separator-m;
  border-right: 1.5px solid $color-gray-05;
  margin-right: $separator-m;
}

.lesson-item__right {
  display: flex;
  align-items: center;
}

.lesson-item__consumption {
  display: flex;
  flex-direction: column;

  margin-right: $lesson-item-separator-right-items;
}

.lesson-item__options {
  @extend .hover-basic;
}

.lesson-item__tags {
  margin-top: $lesson-item-tags-separator;
}

// mode edit

.lesson-item {
  .item-complex-list__right {
    height: 0;
    overflow: hidden;
  }

  &:hover {
    .item-complex-list__right {
      height: 20px;
      transition-duration: 0.2s;
    }
  }
}

.lesson-item__right--mode-edit {
  display: flex;
  align-items: center;

  .lesson-item__menu {
    margin-right: $separator-m;
  }
}

.lesson-item__drag {
  @extend .hover-basic;

  cursor: move;
}

.lesson-item__info--edit-mode {
  // width: 100%;
}

.lesson-item__name-input {
  width: 100%;

  .input-text__input {
    @extend .title-h6;

    background: transparent;
    border: 0;
    width: 100%;
    padding: 0;
    height: 24px;
  }
}

.lesson-item--edit-input-mode {
  .item-complex-list__center {
    width: 100%;

    .lesson-item__center--edit-input-mode {
      width: 100%;
    }
  }
}

.tag-tooltip__popper {
  > div {
    max-width: 394px;
  }
}

.lesson-item__visibility-container {
  display: flex;
  align-items: center;
  margin-top: $main-separator-items;

  .icon {
    margin-right: $main-separator-items;
  }
}

.lesson-item__visibility {
  @extend .text-smallest;

  color: $color-gray-03;
  margin-right: $main-separator-items;
}

.lesson-item--no-visibility {
  .lesson-item__name {
    color: $color-gray-03;
  }

  .lesson-item__date {
    color: $color-gray-04;
  }
}

// .lesson-item--current-lesson {
//   border: 2px solid $color-first;
// }

.lesson-item__restore-dialog-message-attention {
  @extend .title-h6;

  color: $color-white;
  padding: $separator-m $separator-m;
  background-color: $color-error;
  margin-top: $separator-l;
}

.lesson-item--no-visibility,
.lesson-item {
  .lesson-item__cover {
    width: 107px;
    height: 80px;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
