$message-background-error-color: rgba(246, 104, 104, 0.15);
$message-background-success-color: rgba(65, 209, 88, 0.25);
$message-background-warning-color: $color-second-orange-03;
$message-background-text-color: $color-gray-01;

.message-background {
  display: inline-flex;
  padding: $padding-card-default-small;
  border-radius: $border-radius-02;
}

.message-background--error {
  background: $message-background-error-color;
}

.message-background--success {
  background: $message-background-success-color;
}

.message-background--warning {
  background: $message-background-warning-color;
}

.message-background__text-container {

}

.message-background__text {
  @extend .text-smallest;

  color: $message-background-text-color;
  margin-left: $main-separator-items;
}
