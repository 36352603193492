$next-event-item-title-color: $color-title;
$next-event-item-detail-color: $color-label;
$next-event-item-separator: $separator-xs;
$next-event-item-day-color: $color-title;
$next-event-item-month-color: $color-label;

.next-event-item {
  @extend .card-default-small;
  @extend .border-light;
  @extend .hover-basic;
}

.next-event-item__left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.next-event-item__day {
  @extend .text-p;

  color: $next-event-item-day-color;
}

.next-event-item__month {
  @extend .text-extra;

  color: $next-event-item-month-color;
  text-transform: uppercase;
}

.next-event-item__center {
  display: flex;
  flex-direction: column;
}

.next-event-item__title {
  @extend .text-small-bold;
  @extend .ellipsis-complex;

  color: $next-event-item-title-color;
  margin-bottom: $next-event-item-separator;
}

.next-event-item__detail {
  @extend .text-extra;

  color: $next-event-item-detail-color;
}

.next-event-item__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.next-event-item__view {
  @extend .text-small-bold;
  @extend .text-link;
}
