$audio-player-audio-bar-height: 2px;
$audio-player-audio-bar-background-circle: $color-first;
$audio-player-range-thumb-size: 16px;
$audio-player-range-thumb-color: $color-first;
$audio-player-range-track-height: 4px;
$audio-player-range-track-color: $color-gray-06;

.audio-player{
  @extend .hover-basic;

  display: flex;
  align-items: center;
}

.audio-player__icons {
  display: flex;
  align-items: center;

  margin-top: 4px;

}

.audio-player__bar-container {
  position: relative;
  flex: 1 1 auto;
  margin-left: $padding-card-default-horizontal;
  margin-right: $padding-card-default-horizontal;
}

.audio-player__bar {
  height: $audio-player-audio-bar-height;
  width: 100%;

  background: $audio-player-audio-bar-background-circle;
  transition: all ease 0.05s;
  cursor: pointer;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    background: $audio-player-range-track-color;
    height: $audio-player-range-track-height;
  }

  &::-webkit-slider-thumb {
    width: $audio-player-range-thumb-size;
    -webkit-appearance: none;
    height: $audio-player-range-thumb-size;
    background: $audio-player-range-thumb-color;
    border-radius: 100%;

    position: relative;
    top: -6px
  }
}

.audio-player__duration {
  @extend .text-smallest;

  color: $color-gray-03;
}
