.student-custom-footer {
  position: relative;
  .textarea {
    height: auto !important;
  }
}

.student-custom-footer__comment {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  margin-bottom: $separator-xxl;
}

.student-custom-footer__comment-amount {
  @extend .text-smallest;

  color: $color-gray-03;
  margin-left: $separator-s;
}

