$aside-lesson-navigation-title-font-size: 36px;
$aside-lesson-navigation-title-font-family: inherit;
$aside-lesson-navigation-info-width: 202px;
$aside-lesson-navigation-info-height: 120px;
$aside-lesson-navigation-width: 229px;
$aside-lesson-navigation-info-height-big: 167px;

.aside-lesson-navigation {
  display: flex;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  height: $aside-lesson-navigation-info-height;
}

.aside-lesson-navigation__lesson-info {
  height: $aside-lesson-navigation-info-height;
  width: $aside-lesson-navigation-info-width;
  display: flex;
  flex-direction: column;
  font-family: $aside-lesson-navigation-title-font-family;
}

.aside-lesson-navigation__lesson-title {
  z-index: 1;
  font-size: $aside-lesson-navigation-title-font-size;
  color: $color-white;
  margin: $separator-m;
}

.aside-lesson-navigation__lesson-open {
  z-index: 1;
  margin-left: $separator-m;
}

.aside-lesson-navigation__lesson-picture {
  width: $aside-lesson-navigation-info-width;
  height: $aside-lesson-navigation-info-height;
  position: absolute;
  overflow: hidden;
  img {
    margin: 0;
  }
}

.aside-lesson-navigation__timeline {
  // width: calc(100%-#{$aside-lesson-navigation-info-width});
  background-color: #ffffff;
}

@media #{$breakpoints-large} {
  .aside-lesson-navigation {
    margin-top: $separator-m;
    margin-left: $separator-m;
    flex-direction: column;
    width: $aside-lesson-navigation-width;
    height: auto;
    border-bottom-left-radius: $border-radius-01;
    border-bottom-right-radius: $border-radius-01;
  }

  .aside-lesson-navigation__lesson-info {
    height: $aside-lesson-navigation-info-height-big;
    width: 100%;
    justify-content: flex-end;
  }

  .aside-lesson-navigation__lesson-open {
    margin-bottom: $separator-m;
  }

  .aside-lesson-navigation__timeline {
    width: 100%;
    padding-bottom: $separator-l;
    border-bottom-left-radius: $border-radius-01;
    border-bottom-right-radius: $border-radius-01;
  }

  .aside-lesson-navigation__lesson-picture {
    width: $aside-lesson-navigation-width;
    height: $aside-lesson-navigation-info-height-big;
    border-top-left-radius: $border-radius-01;
    border-top-right-radius: $border-radius-01;
    img {
      border-top-left-radius: $border-radius-01;
      border-top-right-radius: $border-radius-01;
    }
  }

  .aside-lesson-navigation__lesson-picture__student {
    border-bottom-left-radius: $border-radius-01;
    border-bottom-right-radius: $border-radius-01;
    img {
      border-bottom-left-radius: $border-radius-01;
      border-bottom-right-radius: $border-radius-01;
    }
  }
}
