.listing-axis-x {
  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 34px;
    background: red;
  }

  &__title {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    color: #8c99ad;
  }

  &__data {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    color: #000;
  }
}
