$header-responsive-height: $size-height-header-responsive;
$header-responsive-background: $color-responsive-header;

.header-responsive {
  height: $header-responsive-height;
  background: $header-responsive-background;
  padding: 0 $separator-xl;
  width: 100%;
  z-index: $z-index-header;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;

  .header-responsive__mobile-menu-header-title {
    @extend .title-h5;
    
    color: $color-white;
  }

  .header-responsive__mobile-menu-header-button {
    cursor: pointer;
  }
}

