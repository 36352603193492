.google-classroom-item {
  padding: $separator-s;
}

.google-classroom-item__classroom {
  display: flex;
  align-items: center;
}

.google-clasroom-item__name {
  @extend .text-p;

  margin-left: $separator-l;
  color: $color-gray-02;
}

.google-classroom-item__students {
  margin-top: $separator-m;
}

.google-classroom-item--selected {
  border: 1px solid $color-gray-06;
}
