$card-resource-image-height: 213px;

.card-resource-image {
  @extend .hover-basic;
  
  height: $card-resource-image-height;
  position: relative;
}

.card-resource-image__image {
  background-size: cover;
  height: 100%;
  background-position: center;

  
}

.card-resource-image__only-teacher {
  position: absolute;
  top: $separator-m;
  right: $separator-m;

  .bubble-content {
    background-color: $color-gray-02;
    border: 0;
    margin-left: $main-separator-items;
  }
}
