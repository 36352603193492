$progress-donut-size-default: $size-icons-big-2;
$progress-donut-color: $color-first;
$progress-donut-color-secondary: $color-white;
$progress-donut-padding: $separator-s;

.progress-donut {
  background: $progress-donut-color;
  border-radius: 100%;
  position: relative;
  height: $progress-donut-size-default;
  width: $progress-donut-size-default;

  .CircularProgressbar {
    position: absolute;
    width: calc(100% - #{$progress-donut-padding});
    height: calc(100% - #{$progress-donut-padding});
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: $progress-donut-color;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: $progress-donut-color-secondary;
  }
}
