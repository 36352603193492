.area-graphic-card-legend {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #8c99ad;

  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 34px;
    background: red;
  }
}
