$new-lesson-form-buttons-separator: $main-separator-components;
$new-lesson-form-items-separator: $form-spacing;
$new-lesson-form-options-separator-icon: $main-separator-items;

.new-lesson-form {
}

.new-lesson-form__form {
  @extend .grid-column;
}

.new-lesson-form__big {
  @include column(12);

  margin-bottom: $new-lesson-form-items-separator;
}

.new-lesson-form__middle {
  @include column(6);
}

.new-lesson-form__buttons {
  margin-top: $new-lesson-form-buttons-separator;
}

.new-lesson-form__options {
  display: flex;
  align-items: center;
}

.new-lesson-form__text {
  margin-left: $new-lesson-form-options-separator-icon;
}
