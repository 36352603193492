$kanban-students-title-margin: $separator-m;
$kanban-students-border: 8px;
$kanban-students-title-color: $color-gray-01;

.kanban-students {
  @extend .card-default;

  width: 100%;
  border-radius: $kanban-students-border;
  border: transparent;
  padding: $separator-m $separator-l 0 $separator-m;
}

.kanban-students__title {
  @extend .title-h6;

  font-family: $font-first;
  margin-bottom: $kanban-students-title-margin;
  color: $kanban-students-title-color;
}

.kanban-students__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kanban-students__center {
  width: 90%;
}

.kanban-students__center--full {
  width: 100%;
}

.kanban-students__arrow {
  @extend .hover-basic;

  margin-bottom: $separator-m;
}
