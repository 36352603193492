.mint-view-lemonade__button {
  @extend .hover-basic;

  width: 100%;
  height: 50px;
  overflow: hidden;
  background: #f0f0f0;
  max-width: 100%;
  margin: 0 auto;

  border-radius: 2px;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 15%);
  margin-bottom: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-left: 10px;
    color: #aaa;
    font-size: 16px;
  }

  svg {
    margin-left: 12px;
  }
}

.mint-view-lemonade__button-title {
  display: flex;
  align-items: center;
}

.mint-view-lemonade__icon {
  margin-right: 12px;

  img {
    width: 75px;
  }
}

.mint-view-lemonade__edit {
  display: flex;

  .button {
    display: flex !important;
    justify-content: center !important;
    border-radius: $border-radius-03 !important;
    width: 200px !important;
    height: 40px !important;
    min-height: 40px !important;
    text-align: center !important;
  }
}
