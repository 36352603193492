$dialog-add-content-border-radius: 40px;

.dialog-add-content {
  .upload-attach__container {
    margin: $main-separator-components 0;
  }

  br {
    display: none;
  }

  .upload-file-container__drag-container {
    border-radius: 48px;
  }

  .dialog-add-content__buttons {
    .button {
      border-color: $color-gray-05;
      border-width: 1px;
    }
    .button:first-child {
      margin-right: $main-separator-items;
    }

    .button:nth-child(2) {
      margin-left: $main-separator-items;
    }
  }

  .input-text__input {
    border-radius: $dialog-add-content-border-radius;
  }
}

.dialog-add-content__content--with-atach {
  .upload-attach__uploader-container {
    display: none;
  }
}

.dialog-add-content__buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-top: $main-separator-items;
}

.dialog-add-content__google-drive {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-add-content__google-drive-text {
  @extend .text-small;

  margin-bottom: $separator-s;
}

.dialog-add-content__content-drive-card {
  margin-top: $main-separator-items;
}
