.saml-error__wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.saml-error_header {
  padding-top: $separator-xl;
  display: flex;
  justify-content: flex-end;
}

.saml-error__close-session {
  @extend .hover-basic;
  
  align-items: center;
  display: flex
}

.saml-error__close-session-text {
  @extend .text-small-bold;

  margin-left: $separator-m;
  color: $color-first;
}

.saml-error__text {
  @extend .title-h3;

  color: $color-gray-01;
}