.ui-view {
  .ui-view__left {
    padding: $separator-l;

    .ui-view__left-section {
      margin: $separator-xl 0;
      padding-bottom: $separator-l;
      border-bottom: 1px solid $color-gray-05;
      .select-customizable__label,
      .switch__label {
        text-transform: uppercase;
        color: $color-first;
      }
    }
  }

  .ui-view__icon-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .ui-view__icon-wrapper-icon {
      margin: $separator-l;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer !important;

      .ui-view__icon-wrapper-icon-title {
        margin-top: $separator-m;
        font-size: $font-size-10;
        font-family: $font-second;
        color: $color-gray-02;
      }
    }
  }
}

.ui-view--dark {
  .layout-calendar__column-2 {
    background-color: $color-gray-02;
  }
  .header-custom {
    background: $gradient;
    color: $color-gray-06;

    .header-close__close {
      svg {
        path {
          fill: $color-white !important;
        }
      }
    }
  }
  .ui-view__icon-wrapper {
    .ui-view__icon-wrapper-icon {
      .ui-view__icon-wrapper-icon-title {
        color: $color-white;
      }
    }
  }
}
