$attention-card-texts-separator: $main-separator-components;
$attention-card-text-color: $color-label;
$attention-card-text-button-separator: $main-separator-items;

.attention-card {
  @extend .card-secondary;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: $border-size-02 solid $color-first !important;
}

.attention-card__info {
  display: flex;
  align-items: center;

  height: 100%;
}

.attention-card__texts {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  margin-left: $attention-card-texts-separator;
}

.attention-card__actions {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: flex-end;

  .button {
    margin-left: $attention-card-text-button-separator;
    width: auto;
  }
}

.attention-card__title {
  @extend .title-h5;
}

.attention-card__text {
  @extend .text-small;

  color: $attention-card-text-color;
}
