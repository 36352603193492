$dropdown-schedule-padding: $separator-m;

.dropdown-schedule__wrapper {
  padding: $dropdown-schedule-padding;

  .dropdown-schedule__hour {
    margin-top: $dropdown-schedule-padding;

    input {
      @extend .input-text__input;

      width: 100%;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
      filter: invert(87%) sepia(0%) saturate(391%) hue-rotate(168deg) brightness(86%) contrast(68%);
    }
  }

  .input-date {
    input {
      padding-left: 8px;
    }

    .input-date__icon {
      right: 10px;
      left: 260px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.dropdown-schedule__footer-buttons {
  padding: $dropdown-schedule-padding;
}
