$dialog-add-videolesson-max-width: 551px;

.dialog-add-videolesson {
  .MuiPaper-root {
    max-width: $dialog-add-videolesson-max-width;
  }

  .input-text__label {
    @extend .text-small;
  }
  .dialog-add-videolesson__content {
    .input-text__input-container {
      margin-top: $separator-s;
    }
  }
}
