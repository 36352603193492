$group-resources-slider-dot-height: 4px;
$group-resources-slider-dot-width: 16px;
$group-resources-slider-dot-active-height: 6px;
$group-resources-slider-dot-active-width: 22px;
$group-resources-slider-dot-radius: 4px;
$group-resources-slider-dot-active-color: $color-first;
$group-resources-slider-arrow-size: 50px;

.group-resources-slider {
  position: relative;

  .swiper-slide {
    margin-top: 40px;
  }

  .swiper-pagination {
    @extend .grid-lateral-right;

    text-align: right;
    bottom: 94% !important;
    right: 0;
  }

  .swiper-pagination-bullet {
    border-radius: $group-resources-slider-dot-radius;
    height: $group-resources-slider-dot-height;
    width: $group-resources-slider-dot-width
    
  }

  .swiper-pagination-bullet-active {
    background: $group-resources-slider-dot-active-color;
    width: $group-resources-slider-dot-active-width;
    height: $group-resources-slider-dot-active-height;
    transform: translateY(1px);
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: $color-white;
    border-radius: 100%;
    display: none;
    height: $group-resources-slider-arrow-size;
    width: $group-resources-slider-arrow-size;

    transform: translateY(20px);
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 22px;
    color: $color-first;
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }
}

.group-resources-slider__title {
  @extend .grid-lateral;

  color: $color-gray-01;
  padding-left: 0;
}

.group-resources-slider__title-text {
  @extend .title-h5;

  color: $color-gray-01;
  margin-left: $main-separator-items;
  text-transform: uppercase;
}

.group-resources-slider__carrousel {
  margin: $main-separator-components 0;
}

.group-resources-slider__hr {
  @extend .grid;

  max-width: none;
}

@media #{$breakpoints-tablet} {
  .group-resources-slider__title {
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: $main-separator-items;
    padding-left: calc((100% - #{$grid-flexible}) / 2);
    top: -8px
  }
}
  