$individual-activity-container-avatar-size: 48px;
$individual-activity-container-score-height: 97px;
$individual-activity-container-score-width: 119px;
$individual-activity-container-comments-margin: 70px;
$individual-activity-container-attempt-arrow-width: 20px;
$individual-activity-container-sender-padding: $separator-l;
$individual-activity-container-name-color: $color-gray-01;
$individual-activity-container-date-color: $color-gray-02;
$individual-activity-container-date-attemtps-total: $color-gray-04;
$individual-activity-container-date-attemtps-text: $color-gray-03;

// .individual-activity-container {
//   width: 90%;
//   margin: auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;

//   .lemonade-exercises {
//     .lemo-points {
//       .assessment-grade-tag {
//         padding: $separator-s $separator-xl;
//       }
//     }

//     &.hide-reset {
//       button.lemo-controls-button-reset {
//         display: none !important;
//       }
//     }
//   }
// }

.individual-activity-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  // min-height: 100vh;
  background-color: $color-white;
  border-radius: 12px;
  box-shadow: $box-shadow-02;
  .individual-activity-container__header,
  .individual-activity-container__header-left,
  .individual-activity-container__header-right {
    display: flex;
    align-items: flex-end;
  }

  .individual-activity-container__header {
    flex-direction: column;
    justify-content: space-between;
    margin-top: $separator-xxl;
    margin-bottom: $separator-l;
    padding: $separator-xxl;
    width: 100%;
    max-width: 900px;
  }

  .individual-activity-container__header-right {
    margin-top: $separator-l;
  }

  .dialog-activity__body {
    .individual-activity-container__header {
      margin-bottom: $separator-xl;
    }

    .individual-activity-container__avatar {
      margin-left: 0;
    }
  }

  .individual-activity-container__back,
  .individual-activity-container__attemps-left,
  .individual-activity-container__attemps-right {
    @extend .hover-basic;
  }

  .individual-activity-container__avatar {
    margin-left: $separator-xl;

    .avatar {
      height: $individual-activity-container-avatar-size;
      width: $individual-activity-container-avatar-size;
    }
  }

  .individual-activity-container__info {
    margin-left: $separator-l;
  }

  .individual-activity-container__name {
    @extend .title-h5;

    color: $individual-activity-container-name-color;
  }

  .individual-activity-container__date {
    @extend .text-small;

    display: flex;
    align-items: center;
    color: $individual-activity-container-date-color;

    svg {
      margin-right: $separator-s;
    }
  }

  .individual-activity-container__attemps-top,
  .individual-activity-container__attemps-number,
  .individual-activity-container__attemps-bottom {
    display: flex;
  }

  .individual-activity-container__attemps-top {
    align-items: center;
    justify-content: space-between;
  }

  .individual-activity-container__attemps-bottom {
    justify-content: center;
  }

  .individual-activity-container__attemps-number {
    align-items: baseline;
  }

  .individual-activity-container__attemps-actual {
    @extend .title-h3;

    margin-left: $separator-xl;
    color: $individual-activity-container-date-color;
  }

  .individual-activity-container__attemps-total {
    @extend .text-button;

    margin-right: $separator-xl;
    color: $individual-activity-container-date-attemtps-total;
  }

  .individual-activity-container__attemps-text {
    @extend .text-small-bold;

    color: $individual-activity-container-date-attemtps-text;
  }

  .individual-activity-container__attemps-left-container,
  .individual-activity-container__attemps-right-container {
    width: $individual-activity-container-attempt-arrow-width;
  }

  .individual-activity-container__redo {
    margin-right: $separator-xxl;
  }

  .individual-activity-container__score {
    margin-left: $separator-xxl;
    max-width: 70px;
    .note-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: $individual-activity-container-score-width;
      height: $individual-activity-container-score-height;
      padding: 0;
    }
  }

  .lemonade-exercises {
    padding: $separator-xxxxl;
    width: 100%;
    max-width: 900px;

    .lemo-points {
      .assessment-grade-tag {
        padding: $separator-s $separator-xl;
      }
    }

    &.hide-reset {
      button.lemo-controls-button-reset {
        display: none !important;
      }
    }
  }
}

@media #{$breakpoints-medium} {
  .individual-activity-container {
    .individual-activity-container__header {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}
