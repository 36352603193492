$card-resource-audio-height: 152px;

.card-resource-audio {
  @extend .card-default;

  display: flex;
  flex-direction: column;

  height: $card-resource-audio-height;
}

.card-resource-audio__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .bubble-content {
    background-color: $color-gray-02;
    border: 0;
    margin-left: $main-separator-items;
  }
}

.card-resource-audio__name {
  @extend .title-h6;
  @include ellipsis-line(2);



  color: $color-gray-02;
  display: block;
  // margin-top: $main-separator-items;
}

.card-resource-audio__size {
  @extend .text-smallest;
  
  color: $color-gray-03;
}

.card-resource-audio__player {
  margin-bottom: $separator-l;
}

.card-resource-audio__texts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1 1 auto;

}

