$content-form-input-separator: $main-separator-components;
$content-form-button-separator-right: $main-separator-components;
$content-form-button-separator-top: $separator-xxxl;
$content-form-footer-margin-top: $separator-l;
$content-form-size-margin-top: $separator-s;
$content-form-date-height: 40px;
$content-form-preview-max-width: 70vh;
$content-form-date-color: $color-gray-02;
$content-form-preview-max-height: 400px;

.content-form__header {
  display: flex;
  justify-content: flex-end;
}

.content-form__date {
  // @extend .card-default-small;
  // @extend .hover-basic;
  // @extend .text-small;

  // display: flex;
  // align-items: end;
  height: $content-form-date-height;
  margin-right: $content-form-button-separator-right;
}

.content-form__date-title {
  color: $content-form-date-color;
  margin-right: $content-form-size-margin-top;
}

.content-form__button-split {
  margin-bottom: $content-form-input-separator;
  .MuiListItem-root.Mui-selected {
    background-color: $color-white !important;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
    .button-split__option-text {
      color: $color-gray-03;
      &:hover {
        color: $color-gray-02;
      }
    }
  }
  .MuiMenuItem-root {
    padding: 8px 12px;
    height: 36px;
    margin: 4px 0;
  }
  .hr {
    height: 1px;
  }
}

.content-form__field {
  margin-bottom: $content-form-input-separator;

  .input-text__input,
  .textarea__input {
    border: 0;
  }
  .textarea__input {
    resize: none;
    min-height: 85px;
  }
}

.content-form__buttons-attach {
  display: flex;
  align-items: center;
}

.content-form__buttons-attach {
  margin-top: $content-form-button-separator-top;
}

.content-form__button {
  margin-right: $content-form-button-separator-right;
}

.content-form__preview {
  margin-top: $content-form-button-separator-top;
  max-width: $content-form-preview-max-width;
  .preview-content__type-with-icon {
    width: 100%;
  }

  .preview-content__img,
  video {
    margin: auto !important;
    max-height: $content-form-preview-max-height;
  }

  //OVERRIDES
  .preview-content__image {
    margin: 0;
  }
  .preview-content__img {
    margin: 0;
    //max-height: 45vh;
  }
  .preview-content__video,
  .preview-content__audio {
    .preview-content__name {
      display: none;
    }
  }

  .preview-content__audio-icons {
    margin-top: 0;
  }

  video {
    max-height: $size-video-max-height;
  }
}

.content-form__preview-footer {
  display: flex;
  justify-content: space-between;
  margin-top: $content-form-footer-margin-top;
}

.content-form__preview-name {
  @extend .title-h6;

  color: $color-black;
}

.content-form__preview-size {
  @extend .text-smallest;

  margin-top: $content-form-size-margin-top;
}

.content-form__preview-delete {
  @extend .hover-basic;
}

.content-form__preview--with-border {
  @extend .card-default;
}
