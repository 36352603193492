$dialog-learning-objectives-max-width: $size-dialog-small-max-width;
$dialog-learning-objectives-list-height: 340px;
$dialog-learning-objectives-list-margin-top: $separator-xxxl;
$dialog-learning-objectives-actions-background: $color-gray-07;

.dialog-learning-objectives {
  .MuiPaper-root {
    max-width: $dialog-learning-objectives-max-width;
  }
  .MuiDialogActions-root {
    background: $dialog-learning-objectives-actions-background;
  }
}

.dialog-learning-objectives__list {
  overflow-y: auto;
  max-height: 55vh;
  padding-top: $dialog-learning-objectives-list-margin-top;
}

.dialog-learning-objectives__search {
  display: flex;
}
