.rubrics-dialog-dub-title {
  &__note {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    border: 1px solid var(--stroke-border-card, #ebe0d7);
    display: flex;
    gap: 22px;
    justify-content: flex-end;
    padding: 9px 15px;
  }

  &__note-number {
    color: var(--gray-color-gray-01, $color-gray-01);
    font-family: Dosis;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__note-text {
    color: var(--gray-color-gray-02, $color-gray-02);
    font-family: Dosis;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
