$times-accordion-padding: $separator-xs;
$times-accordion-separator: $separator-m;
$times-accordion-text-color: $color-gray-02;

.times-accordion {
  > div {
    .times-accordion__times-icons {
      > .icon-time-check {
        &::before {
          height: 30%;
        }
        &::after {
          height: 30%;
        }
      }
    }
    > {
      .MuiAccordion-root {
        background: transparent;
        border: 0px;
        box-shadow: none;
        margin-top: $separator-xl !important;
        &:hover {
          .MuiAccordionSummary-expandIcon {
            display: block;
          }
        }

        .MuiAccordionSummary-expandIcon {
          display: none;
        }

        .Mui-expanded {
          .times-accordion__times-icons {
            display: none;
          }
        }

        .Mui-disabled {
          background-color: $color-card-background;
          .MuiAccordionSummary-expandIcon {
            display: none !important;
          }
          .units-accordion__name {
            color: $color-gray-03 !important;
          }
          .units-accordion__main-hide-icon {
            svg {
              path {
                fill: $color-gray-03;
              }
            }
          }
          .units-accordion__actions-move,
          .lesson-item__menu {
            display: none;
          }
          .units-accordion__name--edit-mode {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.times-accordion__empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: $main-separator-components;
}

.times-accordion__empty-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .times-accordion__empty-button-editable {
    margin-top: $main-separator-items;
  }
}

.times-accordion__empty-button {
  margin-top: $main-separator-items;
}

.times-accordion__empty-title {
  @extend .title-h6;

  color: $color-gray-01;
}

.times-accordion__empty-text {
  @extend .text-small;

  color: $color-gray-03;
  margin-top: $main-separator-items;
  margin-bottom: $main-separator-items;
}

.times-accordion__title-container {
  @extend .border-light;

  padding: $times-accordion-padding;
  padding-bottom: $separator-xs;

  border-right: 0 !important;
  border-left: 0 !important;
  border-top: 0 !important;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.times-accordion__children-container {
  display: flex;
  width: 100%;
}

.times-accordion__children-unists {
  flex: 1 1 auto;
}

.accordion__tab--active {
  .times-accordion__title-container {
    border: 0;
  }
}

.times-accordion__name {
  @extend .text-extra;

  color: $times-accordion-text-color;
  margin-left: $times-accordion-separator;
}

.times-accordion__lessons-length {
  @extend .text-smallest;

  color: $times-accordion-text-color;
  margin-right: $times-accordion-separator;
}

.times-accordion__times-icons {
  position: absolute;
  right: calc(100% + 12px);
}

.times-accordion__units-empty {
  margin-top: $times-accordion-separator;
}
