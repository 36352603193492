$unoi-course-view-subtask-width: 135px;

.unoi-course-view {
  .header-width-tabs__left,
  .header-width-tabs__right {
    width: 100px;
  }
  .header-width-tabs__tabs {
    flex: 1 1 auto;
    width: auto;
  }

  &.course-view__tab-program {
    .layout-header__children,
    .program {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      width: 100%;
    }
  }

  .header-width-tabs {
    height: $size-height-footer-small;
    border-bottom: 0;
  }
  .header-width-tabs__right {
    .button {
      padding: $separator-s;
      min-height: initial;
    }
  }

  .layout-header__footer {
    .header-width-tabs__left {
      .button {
        border-radius: 40px;
        flex: 0 0 auto;
      }
    }
  }
}

.unoi-course-view-program__subtask-vertical,
.unoi-course-view-program__button-mode-student {
  display: none;
}

@media #{$breakpoints-large} {
  .unoi-course-view {
    .layout-header__footer {
      display: none;
    }

    &.course-view__tab-program {
      .layout-header__children,
      .program {
        max-width: $grid-max-width-content;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
      }
    }

    .agroup-timeline-two-sides-in-images__item {
      border-radius: $group-timeline-two-sides-in-images-radius;
      border-right: $border-size-01 solid $color-borders;
      border-left: $border-size-01 solid $color-borders;
    }

    .group-lessons-progress-card__image {
      border-top-left-radius: $group-lessons-progress-card-border-radius;
      border-top-right-radius: $group-lessons-progress-card-border-radius;
    }

    .group-lessons-progress-card__bottom {
      border-bottom-left-radius: $group-lessons-progress-card-border-radius;
      border-bottom-right-radius: $group-lessons-progress-card-border-radius;
    }
  }

  .unoi-course-view-program__button-mode-student {
    display: block;
  }

  .unoi-course-view-program__subtask-vertical {
    display: block;

    position: fixed;
    left: 0;
    top: 22vh;

    .header-tabs__tab {
      width: $unoi-course-view-subtask-width;
    }
  }
}
