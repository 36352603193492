$vanilla-course-footer-publish-buttons-min-width: 245px;
$vanilla-course-footer-action-cancel-color: $color-first;

.vanilla-course-footer__publish-banner {
  display: flex;
  align-items: center;

  svg {
    min-width: 24px;
  }
}

.vanilla-course-footer__publish-banner-text {
  margin-left: $separator-s;
}

.vanilla-course-footer__publish-banner-actions {
  display: flex;
  min-width: $vanilla-course-footer-publish-buttons-min-width;
}

.vanilla-course-footer__publish-banner-cancel {
  .button__text {
    color: $vanilla-course-footer-action-cancel-color;
  }
}
