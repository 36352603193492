$card-resource-video-lesson-height: 234px;
$card-resource-video-lesson-image-height: 112px;
$card-resource-video-lesson-bottom-meta-height: 36px;
$card-resource-video-lesson-progress-width: 66px;

.card-resource-video-lesson {
  @extend .hover-basic;

  height: $card-resource-video-lesson-height;
  box-shadow: $box-shadow-06;

  display: flex;
  flex-direction: column;

  .icon-bubble {
    background: rgba(0, 0, 0, 0.8);
  }
}

.card-resource-video-lesson__image {
  background-size: cover;
  height: $card-resource-video-lesson-image-height;
  background-position: center;

  position: relative;
  flex: 0 0 auto;
}

.card-resource-video-lesson__icon-play {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.card-resource-video-lesson__bottom {
  @extend .card-default;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1 1 auto;

  border-top: 0;
}

.card-resource-video-lesson__bottom-main {
  display: flex;
  align-items: center;
  justify-content: space-between

}

.card-resource-video-lesson__name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $color-gray-02;
  display: block;
  margin-top: $main-separator-items;
}

.card-resource-video-lesson__duration {
  @extend .text-smallest;

  color: $color-gray-03;
}

.card-resource-video-lesson__bottom-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: $card-resource-video-lesson-bottom-meta-height;
}

.card-resource-video-lesson__bottom-bubbles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-resource-video-lesson__date {
  margin-right: $main-separator-items;

  .icon-background__icon {
    border-radius: $border-radius-02;
  }
}

.card-resource-video-lesson__progress {
  .progress-bar {
    max-width: $card-resource-video-lesson-progress-width;
    width: 100%;
  }
}

.card-resource-video-lesson__progress-text {
  @extend .text-extra-bold;
  
  color: $color-gray-03;
  margin-top: $main-separator-items;
}

