$accodion-icon-margin-right: 12px;
$accodion-padding-tap: $separator-m 0;
$accodion-padding-separator: $separator-l;
$accodion-padding-children: $separator-xs;

.accordion__tab {
  flex: 1 1 auto;
  padding: $accodion-padding-tap 0;
}

.accordion__tab-icon {
  margin-right: $accodion-icon-margin-right;

  position: absolute;
  right: 100%;
}

.accordion__title-container {
  @extend .hover-basic;

  display: flex;
  justify-content: space-between;
}

.accordion__title {
  display: flex;
  align-items: center;
}

.accordion__arrow-icon {
  position: relative;

  height: 24px;
  width: 24px;
}

.accordion__title-icon-show {
  transition: transform 0.4s;
  transform: rotate(0);
  position: absolute;
  top: 0;
  left: 0;
}

.accordion__title-icon-show--with-icon-hide {
  top: -6px;
  left: 4px;
}

.accordion__title-icon-hide {
  transition: transform 0.4s;
  visibility: hidden;

  position: absolute;
  width: 0;
  top: -6px;
  left: 4px;
}

.accordion__title-text {
  font-size: 16px;
  font-weight: bold;
  margin-left: 8px;
}

.accordion__children {
  margin-top: $accodion-padding-separator;
  max-height: 0;
  transition: max-height 0.15s ease-out, visibility 0.15s ease-out;
  overflow: hidden;
  padding: $accodion-padding-children;
  visibility: hidden;
}

.accordion__tab-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.accordion__tab--active {
  .accordion__children {
    transition: max-height 0.15s ease-in, visibility 0.15s ease-in;
    visibility: visible;
  }

  > {
    .accordion__title-container {
      .accordion__title-icon-show--within-icon-hide {
        transform: rotate(180deg);
      }
    }
  }

  .accordion__title-icon-hide {
    visibility: visible;
  }

  .accordion__title-icon-show--with-icon-hide {
    visibility: hidden;
    width: 0;
  }
}

.accordion__custom-right-position-right {
  display: flex;
  align-items: center;
}
