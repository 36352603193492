$dialog-form-behaviour-title-color: $color-title;
$dialog-form-behaviour-date-color: $color-title;

.dialog-form-behaviour__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-form-behaviour__header-title {
  @extend .title-h5;

  color: $dialog-form-behaviour-title-color;
}

.dialog-form-behaviour__info {
  display: flex;
  align-items: center;
}

.dialog-list-behaviour__header-date {
  margin-right: $separator-s;
}

.dialog-list-behaviour__header-date {
  @extend .text-extra;

  color: $dialog-form-behaviour-date-color;
}

.dialog-form-behaviour__delete {
  @extend .hover-basic;
}
