$dialog-code-max-width: $size-dialog-xxl-max-width;
$dialog-code-name: $size-dialog-xxl-max-width;
$dialog-code-level-color: $color-label;
$dialog-code-container-height: 84vh;
$dialog-code-container-background: $color-gray-07;
$dialog-code-text-separator: $main-separator-components;
$dialog-code-code-font-size: 124px;
$dialog-code-code-font-size-mobile: 80px;
$dialog-code-code-font-family: $font-second-bold;

.dialog-code {
  .MuiPaper-root {
    // height: $dialog-code-container-height;
    // max-width: $dialog-code-max-width;
    // margin: auto;
    height: 100%;
    max-width: 100%;
    margin: 0;
  }
  .MuiDialog-paperFullWidth {
    width: 100%;
  }

  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }

  .dialog__title {
    white-space: normal;
  }
}

.dialog-code__content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.dialog-code__code-container {
  height: 100%;
  background: $dialog-code-container-background;

  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-code__code-text {
  font-size: $dialog-code-code-font-size-mobile;
  font-family: $dialog-code-code-font-family;
}

.dialog-code__texts {
  margin-top: $dialog-code-text-separator;
  white-space: normal;
}

.dialog-code__name {
  @extend .title-h6;

  display: block;
}

.dialog-code__level {
  @extend .text-smallest;

  color: $dialog-code-level-color;
  display: block;
}

@media #{$breakpoints-tablet} {
  .dialog-code {
    .MuiPaper-root {
      height: $dialog-code-container-height;
      max-width: $dialog-code-max-width;
      margin: auto;
    }
    .MuiDialog-paperFullWidth {
      width: calc(100% - 64px);
    }

    .MuiDialog-paperScrollPaper {
      max-height: calc(100% - 64px);
    }
  }

  .dialog-code__code-text {
    font-size: $dialog-code-code-font-size;
  }
}
