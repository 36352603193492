.course-view__tab-grades {
  .layout-header__content {
    padding: 0;

    height: 100%;

    max-width: 100%;
    width: 100%;
  }
}

.course-view__tab-grades {
  .layout-header__children {
    padding: 0;

    max-width: 100%;
    width: 100%;
  }
}

.course-view--disregarded {
  .program__content-left {
    display: none;
  }
}
