$icon-bubble-size: $size-icon-bubble;
$icon-bubble-size-big: $size-icon-bubble-big;
$icon-bubble-size-medium: $size-icon-bubble-medium;
$icon-bubble-size-small: $size-icon-bubble-small;
$icon-bubble-size-s: $size-icon-bubble-s;
$icon-bubble-size-xs: $size-icon-bubble-xs;
$icon-bubble-size-l: $size-icon-bubble-l;
$icon-bubble-size-xl: $size-icon-bubble-xl;
$icon-bubble-border-radius: $border-radius-icon-bubble;
$icon-bubble-gradient: $gradient;
$icon-bubble-inverse-background: $color-white;
$icon-bubble-inverse-border: $color-white;
$icon-bubble-orange-background: $color-second-orange-01;
$icon-bubble-orange-primary-background: $color-first;
$icon-bubble-green-background: $color-second-green-03;
$icon-bubble-gray-background: $color-gray-04;
$icon-bubble-dark-background: $color-gray-02;
$icon-bubble-white-background: $color-white;

.icon-bubble {
  border-radius: $icon-bubble-border-radius;

  display: flex;
  align-items: center;
  justify-content: center;

  height: $icon-bubble-size;
  width: $icon-bubble-size;
}

.icon-bubble--variant-default {
  background-image: $icon-bubble-gradient;
}

.icon-bubble--variant-inverse {
  @extend .border-primary;

  background: $icon-bubble-inverse-background;
}

.icon-bubble--background-green {
  background: $icon-bubble-green-background;
}

.icon-bubble--background-orange {
  background: $icon-bubble-orange-background;
}

.icon-bubble--background-white {
  background-color: $icon-bubble-white-background !important;
}

.icon-bubble--background-orange-primary {
  background: $icon-bubble-orange-primary-background;
}

.icon-bubble--background-gray {
  background-color: $icon-bubble-gray-background !important;
}

.icon-bubble--background-dark {
  background: $icon-bubble-dark-background;
}

.icon-bubble--background-light {
  background-color: $color-gray-07 !important;
}

.icon-bubble--variant-square {
  background-image: $icon-bubble-gradient;
  border-radius: $border-radius-icon-bubble-square;
}

.icon-bubble--size-medium {
  height: $icon-bubble-size-medium;
  width: $icon-bubble-size-medium;
}

.icon-bubble--size-big {
  height: $icon-bubble-size-big;
  width: $icon-bubble-size-big;
}

.icon-bubble--size-l {
  height: $icon-bubble-size-l;
  width: $icon-bubble-size-l;
}

.icon-bubble--size-xl {
  height: $icon-bubble-size-xl;
  width: $icon-bubble-size-xl;
}

.icon-bubble--size-s {
  height: $icon-bubble-size-s;
  width: $icon-bubble-size-s;
}

.icon-bubble--size-xs {
  height: $icon-bubble-size-xs;
  width: $icon-bubble-size-xs;
}

.icon-bubble--size-small {
  height: $icon-bubble-size-small;
  width: $icon-bubble-size-small;
}
