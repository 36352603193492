$dialog-add-license-margin-button: $main-separator-components;
$dialog-add-license-content-bottom: $separator-xxxxl;
$dialog-add-license-course-info-margin: $separator-xxl;
$dialog-add-license-course-name-margin: $separator-s;
$dialog-add-license-max-width: $size-dialog-small-max-width;

.dialog-add-license__content {
  width: 80%;
  margin: auto;
  margin-top: $separator-xxxxl;
  margin-bottom: $separator-l;
}

.dialog-add-license__group-success {
  display: flex;
  align-items: center;
  padding: $dialog-add-license-course-info-margin 0;
}

.dialog-courses__info {
  width: 100%;
  margin-left: $dialog-add-license-course-info-margin;
}

.dialog-courses__name {
  @extend .title-h5;

  color: $color-black;
  margin-bottom: $dialog-add-license-course-name-margin;
  margin-left: $dialog-add-license-course-name-margin;
}

.dialog-courses__level {
  @extend .text-smallest;
}

.dialog-add-license {
  .MuiPaper-root {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
  .MuiDialogContent-root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .dialog-add-license__subtitle {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 20px;
  }
}

@media #{$breakpoints-tablet} {
  .dialog-add-license {
    .MuiPaper-root {
      max-width: $dialog-add-license-max-width;
      // min-height: 224px;
    }
    .MuiDialogContent-root {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
    }

    .dialog-add-license__subtitle {
      position: absolute;
      top: 0;
      width: 100%;
      padding: 0 20px;
    }
  }
}
