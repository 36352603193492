$grades-behavior-text-separator: $main-separator-items;
$grades-behavior-content-separator: $main-separator-components;
$grades-behavior-table-column-width: 50px;
$grades-behavior-table-cell-background: $color-white;
$grades-behavior-table-cell-header-month-color: $color-gray-03;
$grades-behavior-table-cell-header-day-number-color: $color-title;
$grades-behavior-table-cell-header-day-text-color: $color-gray-03;
$grades-behavior-table-cell-header-day-weekend-background: $color-gray-07;
$grades-behavior-table-cell-header-today-color: $color-first;
$grades-behavior-left-sticky: 220px;

.grades-behavior {
  display: flex;
  flex-direction: column;

  .table-react table tr th {
    min-width: $grades-behavior-table-column-width;
    max-width: $grades-behavior-table-column-width;
  }

  .table-react table tr:first-child td {
    background: $grades-behavior-table-cell-background;
  }
  .table-react table tr th:nth-child(2) {
    text-align: left;
  }

  .table-react table tr th:first-child,
  .table-react table tr td:first-child {
    border-right: $border-size-01 solid $color-borders;
  }

  .table-react table tr td:first-child,
  .table-react table tr th:first-child {
    z-index: $z-index-minor-elements;
  }

  .table-react table tr th {
    padding-top: $separator-xs;
    padding-bottom: $separator-xs;
  }

  .table-react table tr:first-child th {
    border-bottom: 0;

    padding-top: $separator-s;
    padding-bottom: $separator-s;
  }

  thead {
    tr:first-child {
      th {
        position: sticky;
        left: $grades-behavior-left-sticky;
      }
    }
  }
}

.grades-behavior__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 0 0 auto;
}

.grades-behavior__title {
  @extend .grades__title;
}

.grades-behavior__text {
  @extend .grades__text;

  margin-top: $grades-behavior-text-separator;
}

.grades-behavior__export-container {
  display: flex;
  align-items: center;
}

.grades-behavior__content {
  margin-top: $grades-behavior-content-separator;
}

.grades-behavior__image {
  img {
    max-width: 100%;
  }
}

.grades-behaviour__cell-header-month {
  @extend .text-small;

  color: $grades-behavior-table-cell-header-month-color;
}

.grades-behaviour__cell {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.grades-behaviour__cell-comment-icon {
  position: absolute;
  top: -8px;
  right: -6px;
}

.grades-behaviour__cell-header-day {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
}

.grades-behaviour__cell-header-day-number {
  @extend .text-small;

  color: $grades-behavior-table-cell-header-day-number-color;
}

.grades-behaviour__cell-header-day-text {
  @extend .text-xs;

  color: $grades-behavior-table-cell-header-day-text-color;
}

.grades-behaviour__cell-header-day-weekend {
  background: $grades-behavior-table-cell-header-day-weekend-background !important;
}

.grades-behaviour__cell-header-day--today {
  .grades-behaviour__cell-header-day-number {
    color: $grades-behavior-table-cell-header-today-color;
  }

  .grades-behaviour__cell-header-day-text {
    color: $grades-behavior-table-cell-header-today-color;

    &:after {
      width: 90%;
      content: '';
      height: 2px;
      border-radius: $border-radius-01;
      background: $grades-behavior-table-cell-header-today-color;
      position: absolute;

      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
