$button-fixed-dropdown-button-size: $size-icon-bubble-l;
$button-fixed-dropdown-button-size-s: $size-icon-bubble-small;
$button-fixed-dropdown-button-background-open: $color-gray-02;

.button-fixed-dropdown__button {
  position: fixed;
  bottom: 24px;
  // right: $grid-padding-left-mobile;

  height: $button-fixed-dropdown-button-size;
  width: $button-fixed-dropdown-button-size;

  .button {
    height: $button-fixed-dropdown-button-size;
    width: $button-fixed-dropdown-button-size;
    border-radius: 100%;

    padding: 0;

    svg {
      transition: transform 0.4s;
    }
  }
}

.button-fixed-dropdown--size-s {
  .button-fixed-dropdown__button {
    height: $button-fixed-dropdown-button-size-s;
    width: $button-fixed-dropdown-button-size-s;

    .button {
      height: $button-fixed-dropdown-button-size-s;
      width: $button-fixed-dropdown-button-size-s;

      min-height: $button-fixed-dropdown-button-size-s;
    }
  }
}

.button-fixed-dropdown__button--open-rotate {
  .button {
    background-image: linear-gradient(111.12deg, $button-fixed-dropdown-button-background-open 0.31%, $button-fixed-dropdown-button-background-open 100%);
  }

  svg {
    transform: rotate(45deg);
  }
}

.button-fixed-dropdown--not-fixed {
  .button-fixed-dropdown__button {
    position: static;
    display: inline;
  }
}
