$layout-with-image-colum1-separator: $separator-xl;

.layout-with-image {
  display: flex;
  height: 100vh;
}

.layout-with-image__column-1 {
  @include column--without-gutter(12);
  @extend .grid-lateral;

  padding-right: $layout-with-image-colum1-separator;
}

.layout-with-image__column-2 {
  display: none;
}

.layout-with-image__image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media #{$breakpoints-medium} {
  .layout-with-image__column-1 {
    @include column--without-gutter(6);
  }

  .layout-with-image__column-2 {
    @include column--without-gutter(6);

    display: block;
  }
}

@media #{$breakpoints-large} {
  .layout-with-image__column-1 {
    @include column--without-gutter(5);
  }

  .layout-with-image__column-2 {
    @include column--without-gutter(7);
  }
}

@media #{$breakpoints-xxxlarge} {
  .layout-with-image__column-1 {
    @include column--without-gutter(6);
  }

  .layout-with-image__column-2 {
    @include column--without-gutter(6);
  }
}
