.analytic-card-circles {
  .doughnut-chart__center {
    box-shadow: none;

    height: 50px;
    width: 50px;
  }
}

.analytic-card-circles__legend-color {
  border-radius: 4px;
  width: 32px;
  height: 3px;

  margin-top: 4px;
  margin-bottom: 8px;
}

.analytic-card-circles__info {
  margin-left: 22px;
}

.analytic-card-circles__info-container {
  display: flex;
  align-items: center;
}

.analytic-card-circles__info-title {
  color: $color-gray-03;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.analytic-card-circles__info-number {
  color: $color-gray-01;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.analytic-card-circles__info-total {
  color: $color-gray-03;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  display: block;
  margin-top: 8px;
}

.analytic-card-circles__graphic {
  .doughnut-rechart {
    width: 100px;
    height: 100px;
  }
}
