$kanban-header-title-margin: $separator-l;
$kanban-header-see-studen-margin: $separator-s;
$kanban-header-separator-margin: $separator-xl;
$kanban-header-title-color: $color-gray-01;
$kanban-header-see-student-color: $color-gray-02;
$kanban-header-separator-color: $color-gray-05;

.kanban-header__left {
  display: flex;
  align-items: center;
}

.kanban-header__see-as-student,
.kanban-header__close {
  @extend .hover-basic;
}

.kanban-header__title {
  @extend .title-h6;

  margin-left: $kanban-header-title-margin;
  color: $kanban-header-title-color;
}

.kanban-header__see-as-student,
.kanban-header__right {
  display: flex;
  align-items: flex-end;
}

.kanban-header__see-as-student-text {
  @extend .title-h7;

  margin-left: $kanban-header-see-studen-margin;
  color: $kanban-header-see-student-color;
}

.kanban-header__separator {
  height: 100%;
  border: 1px solid $kanban-header-separator-color;
  margin: 0 $kanban-header-separator-margin;
}
