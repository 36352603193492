.dialog-info {
  .dialog-info__text {
    line-height: 20px;
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    font-size: smaller;
  }
}

.dialog-info__container {
  margin-bottom: $separator-m;
}
