$link-content-image-max-height: 104px;
$link-content-image-max-width: 160px;
$link-content-separator: $main-separator-items;
$link-content-title-color: $color-first;
$link-content-description-color: $color-gray-04;

.link-content {
  @extend .card-default-small;

  display: flex;
}

.link-content__image-content {
  height: $link-content-image-max-height;
  width: $link-content-image-max-width;

  background-size: cover;
  margin-right: $link-content-separator;
}

.link-content__name {
  @extend .title-h6;

  color: $link-content-title-color;
  margin-top: $link-content-separator;
}

.link-content__description {
  @extend .text-smallest;

  color: $link-content-description-color;
  margin-top: $link-content-separator;
}

.link-content__text-info {
  display: flex;
  flex-direction: column;
}
