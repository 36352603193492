$title-generic-color: $color-title;

.title-generic {
  @extend .border-light;

  border-left: 0;
  border-right: 0;
  border-top: 0;

  padding-bottom: $separator-xs;
}

.title-generic__text {
  @extend .title-h5;

  color: $title-generic-color;
}
