$bar-menu-width: $size-bar-menu-width;
$bar-menu-logo-height: 23px;
$bar-menu-options-separator: $separator-xl;
$bar-menu-item-padding: 5.7px 9.6px;
$bar-menu-avatar: 35px;

.bar-menu {
  position: fixed;
  background-color: $color-background-menu;
  height: 100%;
  width: $bar-menu-width;
}

.bar-menu__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100vh;
  width: 100%;
  padding: $separator-xxl 0;
}

.bar-menu__logo {
  text-align: center;

  img {
    height: $bar-menu-logo-height;
  }
}

.bar-menu__sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $bar-menu-options-separator;
}

.bar-menu__item {
  @extend .hover-basic;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $separator-l;
  padding: $bar-menu-item-padding;
}

.bar-menu__item-selected {
  border-radius: 9px;
  background-color: #0003;
}

.bar-menu__setting {
  @extend .hover-basic;

  text-align: center;
}

.bar-menu__avatar {
  @extend .hover-basic;

  border-radius: 50%;
  height: $bar-menu-avatar;
  margin-top: 21px;
  width: $bar-menu-avatar;
}
