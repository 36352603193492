$action-dropdown-padding: $padding-dropdown;
$action-dropdown-separator-top: $separator-m;
$action-dropdown-separator-bottom: $separator-xs;

.action-dropdown {
  padding: $action-dropdown-padding;
}

.action-text__container {
  @extend .title-h6;
  @extend .text-link;

  width: 100%;
  text-align: center;
  margin: $action-dropdown-separator-top 0 $action-dropdown-separator-bottom 0;
}
