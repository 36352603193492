$new-unit-color: $color-gray-01;
$new-unit-width: 460px;

.new-unit {
  padding: $card-simple-padding;
  width: $new-unit-width;
}

.new-unit__title-container {
  display: flex;
  align-items: center;
}

.new-unit__back {
  @extend .hover-basic;
}

.new-unit__title {
  @extend .title-h6;

  color: $new-unit-color;
  margin-left: $main-separator-items;
}

.new-unit__form {
  margin-top: $main-separator-components;

  .input-text__icon {
    margin-left: $separator-xs;
  }

  .input-text__input-container {
    input {
      padding-left: calc(#{$size-icons-big} + #{$separator-l} + #{$separator-xs});
    }
  }
}

.new-unit__select {
  margin-top: $separator-s;

  .select-customizable .select-customizable__value-container {
    padding-left: 10px;
  }
}

.new-unit__select-time-text {
  margin-left: $separator-l;
}

.new-unit__actions {
  display: flex;
  justify-content: flex-end;

  margin-top: $main-separator-components;
}

.new-unit__select-time {
  display: flex;
  align-items: center;
}
