$scorm-results-activity-description-color: $color-title;
$scorm-results-activity-separator-bubble: $main-separator-components;
$scorm-results-activity-separator-title: $main-separator-items;
$scorm-results-activity-separator-items: $main-separator-items;
$scorm-results-activity-separator-viewer: $main-separator-components;

.scorm-results-activity {
  position: relative;
}

.scorm-results-activity__wrapper {
  @extend .card-secondary;
}

.scorm-results-activity__header {
  display: flex;
}

.scorm-results-activity__header-left {
}

.scorm-results-activity__header-right {
  flex: 1 1 auto;
  padding-left: $scorm-results-activity-separator-bubble;
}

.scorm-results-activity__header-pretitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scorm-results-activity__header-date {
  display: inline-flex;
  font-family: $font-second;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $color-gray-03;
  margin-left: 6px;
}

.scorm-results-activity__header-title {
  @extend .title-h4;

  display: block;
  margin-top: $scorm-results-activity-separator-title;
  padding-right: 66px;
}

.scorm-results-activity__header-description {
  @extend .text-small;

  color: $scorm-results-activity-description-color;
  display: block;
  line-height: 1.67;
  margin-top: $scorm-results-activity-separator-items;
  padding-right: 66px;
}

.scorm-results-activity__content {
  margin-top: $scorm-results-activity-separator-viewer;
  height: 70vh;
}
