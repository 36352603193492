$dialog-publish-results-max-width: $size-dialog-small-max-width;
$dialog-publish-results-container-height: 440px;
$dialog-publish-results-actions-background: $color-gray-07;

.dialog-publish-results {
  .MuiPaper-root {
    height: 100%;
    max-height: $dialog-publish-results-container-height;
    max-width: $dialog-publish-results-max-width;
  }
  .MuiDialogActions-root {
    background: $dialog-publish-results-actions-background;
  }
}
