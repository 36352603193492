$dialog-reassign-comment-margin: $separator-xxxl;
$dialog-reassign-input-margin: $separator-m;

.dialog-reassign {
  .MuiPaper-root {
    max-width: $size-dialog-s-max-width;
  }

  .input-text__input {
    border-radius: 0;
  }
}

.dialog-reassign__text {
  @extend .text-small;
}

.dialog-reassign__comment {
  display: flex;
  margin-top: $dialog-reassign-comment-margin;
}

.dialog-reassign__input {
  flex: auto;
  margin-left: $dialog-reassign-input-margin;
}
