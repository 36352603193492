$vanilla-course-banner-button-edit-size: 40px;

.vanilla-course-banner {
  .button-fixed-dropdown__button {
    position: static;
  }

  .button-fixed-dropdown__button--open-rotate {
    .button {
      background-color: $color-gray-03;
      background-image: none;
    }
  }
}

.vanilla-course-banner__title {
  @extend .title-h3;

  color: $color-white;
  position: relative;
}

.vanilla-course-banner__bottom-right {
  display: flex;
  align-items: center;
  .button--color-transparent {
    color: $color-white;
  }
}

.vanilla-course-banner__button-edit {
  .button {
    height: $vanilla-course-banner-button-edit-size;
    min-height: $vanilla-course-banner-button-edit-size;
    width: $vanilla-course-banner-button-edit-size;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 22px;
      min-height: 22px;
      width: 22px;
    }
  }
}

.vanilla-course__dropdown {
  .list-simple-dropdown__name {
    color: $color-first;
  }

  .list-simple-dropdown__li {
    flex-direction: row-reverse;
  }

  .list-simple-dropdown__icon {
    margin-right: 0;
    margin-left: $main-separator-items;
  }
}

.vanilla-course-banner__header-student {
  display: flex;
  align-items: center;
}

.vanilla-course-banner__mode-exit {
  @extend .hover-basic;
  @extend .text-button;

  color: $color-white;
  margin-right: $separator-xxxxl;
}
