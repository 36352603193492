.classroom-user-aside-info {
  .user-item__name {
    color: $color-gray-01;
    font-family: $font-second;
    font-size: $font-size-06;
  }
}

.classroom-user-aside-info__back {
  margin-bottom: $main-separator-components;
  .back-button {
    display: none;
  }
}

.classroom-user-aside-info__icon-title {
  display: none
}

.classroom-user-aside-info__title {
  @extend .text-small-bold;

  color: $color-label;
  display: block;
  margin-bottom: $main-separator-items;
  margin-top: $separator-xxxl;
}

.classroom-user-aside-info__users-container {
  padding-right: $separator-xxxxl;
}

.classroom-user-aside-info__code {
  display: inline-block;

  .classroom-code__number {
    font-size: $font-size-09;
  }
}

@media #{$breakpoints-xlarge} {
  .classroom-user-aside-info__back {
    .back-button {
      display: block;
    }
  }

  .classroom-user-aside-info__icon-title {
    display: block;
  }
}
