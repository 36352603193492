.ai-home-contaner__title {
  @extend .title-h4;

  margin-bottom: $separator-l;
}

.ai-home-container__entry {
  @extend .hover-basic;
  @extend .text-small-bold;

  margin-bottom: $separator-s;
  background-color: $color-gray-06;
  padding: $separator-m;
  border-radius: $border-radius-01;
}
