$z-index-notifications: 9500;
$z-index-popup: 8001;
$z-index-popup-overlay: 8000;
$z-index-aside: 5001;
$z-index-aside-overlay: 5000;
$z-index-header: 4000;
$z-index-header-elements: 4050;
$z-index-popup-pages: 1199;
$z-index-footer: 4051;
$z-index-toolbar: 3000;

$z-index-minor-elements: 10;
