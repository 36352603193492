$personalize-program-gray-color: $color-gray-02;

.personalize-program__wrapper {
  padding: $separator-m;
}

.personalize-program__head {
  display: flex;
}

.personalize-program__head-title {
  @extend .title-h5;

  color: $color-gray-01;
  margin-left: $separator-l;
}
.personalize-program__content {
  margin-left: $separator-xxxxl;
}

.personalize-program__subhead {
  @extend .text-p;

  margin-top: $separator-s;
  margin-bottom: $separator-l;
  color: $personalize-program-gray-color;
}

.personalize-program__entry {
  display: flex;
  align-items: center;
  margin-bottom: $separator-l;
}

.personalize-program__entry-icon {
  margin-right: $separator-m;
}

.personalize-program__entry-text {
  @extend .text-p;

  color: $personalize-program-gray-color;
}
