$icon-title-action-icon-separator: $separator-l;
$icon-title-action-title-color: $color-title;

.icon-title-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-title-action__title-container {
  display: flex;
  align-items: center;
}

.icon-title-action__title {
  @extend .title-h6;

  color: $icon-title-action-title-color;
}

.icon-title-action__icon {
  margin-right: $icon-title-action-icon-separator;
}

.icon-title-action__action {
  @extend .text-link;
  @extend .text-small-bold;
}
