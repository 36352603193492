$editby-color: $color-gray-03;
$editby-name-color: $color-gray-02;

.edit-by {
  display: flex;
  align-items: center;
}

.edit-by__editby,
.edit-by__editby-name {
  @extend .text-small;

  margin-left: $separator-s;
  color: $editby-color;
}

.edit-by__editby-name {
  color: $editby-name-color;
  margin-left: $separator-xs;
}
