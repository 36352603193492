$task-pending-item-padding: $separator-m;
$task-pending-item-section-color: $color-gray-03;
$task-pending-item-evaluative-margin: $separator-xs;
$task-pending-item-progress-margin: $separator-s;
$task-pending-item-progress-item-margin: $separator-m;
$task-pending-item-evaluative-color: $color-error;
$task-reviewed-item-progress-moon-color: $color-first;
$task-reviewed-item-no-progress-moon-color: $color-gray-04;

.task-pending-item {
  padding: $separator-s $task-pending-item-padding;
}

.task-pending-item__section {
  @extend .ellipsis-complex;
  @extend .title-h7;

  color: $task-pending-item-section-color;
}

.task-pending-item__evaluative {
  display: flex;
  align-items: center;
}

.task-pending-item__evaluative-text {
  @extend .text-extra;

  margin-left: $task-pending-item-evaluative-margin;
  color: $task-pending-item-evaluative-color;
}

.task-pending-item__title {
  @extend .text-smallest-bold;
  @extend .ellipsis-complex;
}

.task-pending-item__progress {
  display: flex;
  align-items: inherit;

  margin-top: $task-pending-item-progress-margin;
}

.task-pending-item__progress-item {
  min-width: 80px;
  margin-left: $task-pending-item-progress-item-margin;

  .progresss-moon__bar {
    border-color: $task-reviewed-item-no-progress-moon-color;
    border-bottom-color: $task-reviewed-item-progress-moon-color;
    border-right-color: $task-reviewed-item-progress-moon-color;
  }
}

.task-pending-item__count {
  @extend .title-h7;

  color: $task-pending-item-section-color;
}

.task-pending-item__progress-text {
  @extend .text-smallest;

  color: $task-pending-item-section-color;
}

.task-reviewed-item__score {
  @extend .text-smallest-bold;

  text-align: right;
}

.task-reviewed-item__average {
  @extend .text-xs;

  text-align: right;
  color: $task-pending-item-section-color;
}

.task-reviewed-item__progress--success {
  .progress-bar__progress {
    background: $color-success;
  }
}
.task-reviewed-item__progress--regular {
  .progress-bar__progress {
    background: $color-alert;
  }
}
.task-reviewed-item__progress--bad {
  .progress-bar__progress {
    background: $color-error;
  }
}
