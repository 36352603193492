$xapi-individual-results-view-text-color: $color-label;
$xapi-individual-results-view-button-arrow-separator: $main-separator-components;
$xapi-individual-results-view-button-action-separator: $separator-xxxxl;
$xapi-individual-results-view-left-separator: $main-separator-components;
$xapi-individual-results-view-name-separator: $main-separator-items;
$xapi-individual-results-view-questions-color: $color-label;
$xapi-individual-results-view-points-width: 110px;
$xapi-individual-results-view-list-separator: $main-separator-components;
$input-points-border-radius-big: $border-radius-02;

.xapi-individual-results-view__left {
  padding-right: $xapi-individual-results-view-left-separator;
}

.xapi-individual-results-view__avatar {
  position: relative;
  z-index: 1;

  .avatar {
    margin: auto;
  }
}

.xapi-individual-results-view__card {
  @extend .card-secondary;

  position: relative;
  top: -20px;
}

.xapi-individual-results-view__name {
  @extend .title-h4;

  display: block;
  margin-bottom: $xapi-individual-results-view-name-separator;
  text-align: center;
}

.xapi-individual-results-view__text-info {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  color: $xapi-individual-results-view-text-color;
  margin-bottom: $xapi-individual-results-view-name-separator;
}

.xapi-individual-results-view__text-date {
  @extend .text-smallest;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.xapi-individual-results-view__feedback {
  text-align: center;
}

.xapi-individual-results-view__right {
  padding-right: $grid-padding-left-xxlarge;
}

.xapi-individual-results-view__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.xapi-individual-results-view__title {
  @extend .title-h4;

  display: flex;
  margin-left: $separator-m;
}

.xapi-individual-results-view__questions {
  @extend .text-p;

  color: $xapi-individual-results-view-questions-color;
}

.xapi-individual-results-view__header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.xapi-individual-results-view__header-progress {
  margin-left: $separator-m;
}

.xapi-individual-results-view__header-left {
  display: flex;
}

.xapi-individual-results-view__header-points {
  width: $xapi-individual-results-view-points-width;
  margin-left: 14px;

  .input-points {
    .input-points__input-container {
      @extend .border-light-black;

      border-radius: $input-points-border-radius-big;
      padding-right: 8px !important;
    }
  }
}

.xapi-individual-results-view__content {
  margin-top: $xapi-individual-results-view-list-separator;
}

// Responsive

.xapi-individual-results-view {
  .layout-calendar__column-1 {
    width: 275px;
    padding-right: 0;
    transition: all 0.2s ease;
  }

  .layout-calendar__column-2 {
    padding-top: 20px;
    padding-left: calc(275px + 20px);
    padding-bottom: 20px;
    width: 100%;
    transition: all 0.2s ease;
  }
}

@media #{$breakpoints-xlarge} {
  .xapi-individual-results-view {
    .layout-calendar__column-1 {
      width: 450px;
      padding-right: 20px;
      transition: all 0.2s ease;
    }

    .layout-calendar__column-2 {
      padding-top: 20px;
      padding-left: calc(450px + 20px);
      padding-bottom: 20px;
      width: 100%;
      transition: all 0.2s ease;
    }
  }
}
