.activity-state-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon--done {
    circle {
      fill: #16DA1E
    }

    path {
      fill: #ffffff;
    }
  }

  .icon--incorrect-circle {

    circle {
      fill: #F66868
    }

    path {
      fill: #ffffff;
    }
  }

}
