$assessment-item-border-radius-big: $border-radius-big-size;
$assessment-item-title-color: $color-title;
$assessment-item-date-color: $color-label2;
$assessment-item-due-color-soon: $color-label;
$assessment-item-due-color-late: $color-first;
$assessment-item-questions-color: $color-title;
$assessment-item-separator: $separator-s;
$assessment-item-pending: $color-label;
$assessment-item-student-status-text-color: $color-first;
$assessment-item-student-status-font-size: $font-size-08;
$assessment-item-student-status-separator: $main-separator-items;
$assessment-item-student-late-icon-separator: $main-separator-items;
$assessment-item-section-color: $color-gray-03;

.assessment-item {
  @extend .card-default-small;
  box-shadow: 0px 2px 5px rgba(129, 102, 81, 0.15);
  border-radius: 16px;
  padding: 16px 16px 20px 16px;
}

// soon or late
.assessment-item--status-soon {
  .assessment-item__date {
    color: $assessment-item-due-color-soon;
  }
}

.assessment-item--status-late {
  .assessment-item__date {
    color: $assessment-item-due-color-late;
  }
}

// card header
.assessment-item__header {
  display: flex;
  flex-direction: row;
}

.assessment-item__course {
  @extend .text-small;

  color: $assessment-item-section-color;
}

.assessment-item__lesson {
  @extend .text-small-bold;
  line-height: 20px;

  color: $assessment-item-section-color;
  border-left: 1px solid #828282;
  padding-left: 16px;
  margin-left: 16px;
  min-height: 20px;
}

// card left content
.assessment-item__left {
  display: flex;
  flex-direction: row;
  margin-top: $separator-m;
}

.assessment-item__filter-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon--size-default {
    width: 26px;
    height: auto;
  }
}

.assessment-item__filter-icon--to-do {
  border: 2px solid #ff5322;
}

.assessment-item__info {
  display: flex;
  flex-direction: column;
  margin-left: $separator-xl;
}
.assessment-item__questions-length {
  @extend .text-smallest;

  color: $color-gray-03;
  margin-bottom: $assessment-item-separator;
}

.assessment-item__title {
  @extend .text-p;
  @extend .ellipsis-complex;

  color: $assessment-item-title-color;
  color: #333333;
  margin-bottom: $separator-m;
  font-family: $font-second-bold;
  font-weight: bold;
  line-height: 24px;
}

.assessment-item__date-icon {
  margin-right: $assessment-item-student-late-icon-separator;
  background: #faf7f5;
  border-radius: 6px;
  padding: 6px;
}

.assessment-item__date-container {
  display: flex;
  align-items: center;
}

.assessment-item__date {
  @extend .text-smallest;

  color: $assessment-item-date-color;
}

.assessment-item__right {
  display: flex;
  flex-direction: column;
}

.assessment-item .item-complex-list {
  align-items: flex-start;
}
