$footer-buttons: $main-separator-components;
.footer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .button {
    margin-left: $footer-buttons;
  }
}
