$close-mini-button-size: 20px;
$close-mini-button-size-m: 32px;
$close-mini-button-background: $color-gray-04;
$close-mini-button-border-color: $color-white;

.close-mini-button {
  @extend .hover-basic;
  @extend .border-default;

  display: flex;
  align-items: center;
  justify-content: center;

  background: $close-mini-button-background;
  border-color: $close-mini-button-border-color;
  border-radius: 100%;
  height: $close-mini-button-size;
  width: $close-mini-button-size;
}

.close-mini-button--m {
  height: $close-mini-button-size-m;
  width: $close-mini-button-size-m;
}
