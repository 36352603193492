.dialog-activity-preview {
  .dialog-activity-preview__content {
    .lemonade-exercises .lemo-question-header {
      margin-top: 0;
      color: $color-gray-02;
      font-family: $font-first;
      font-weight: 700;
      line-height: 25px; /* 156.25% */
    }

    .lemonade-exercises .lemo-question-controls {
      margin: 0 auto;
    }

    .dialog-activity-preview__controls {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
