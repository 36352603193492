$score-needs-reinforcement: $color-error;
$score-needs-ampliacion: $color-alert;
$score-good-progress: $color-success;

.score {
  @extend .text-small-bold;
}

.score--needs-reinforcement {
  color: $score-needs-reinforcement;
}

.score--needs-ampliacion {
  color: $score-needs-ampliacion;
}

.score--good-progress {
  color: $score-good-progress;
}
