$dropdown-next-events-width: $size-dropdown-next-events-width;
$dropdown-next-events-padding: $padding-card-default-small;
$dropdown-next-events-separator: $main-separator-items;
$dropdown-next-events-no-events-background: $color-gray-07;
$dropdown-next-events-no-events-padding: $padding-card-default-small;
$dropdown-next-events-no-events-color: $color-label;
$dropdown-next-events-top-separator: $separator-xl;

.dropdown-next-events__lib {
  margin-top: $dropdown-next-events-top-separator;

  .MuiPaper-root {
    width: $dropdown-next-events-width;
  }

  .next-event-item {
    border: 0;
    padding: 0;
  }

  .basic-list__item {
    margin-top: $dropdown-next-events-separator;
  }

  .no-items {
    padding: $padding-card-default;
  }

  .no-items__item--texts {
    margin-bottom: 0;
  }
}

.dropdown-next-events__container {
  padding: $dropdown-next-events-padding;
}

.dropdown-next-events__title {
  @extend .title-h6;
}

.dropdown-next-events__no-events-container {
  background: $dropdown-next-events-no-events-background;
  border-radius: $border-radius-01;
  text-align: center;
  padding: $dropdown-next-events-no-events-padding;
}

.dropdown-next-events__no-events-title {
  @extend.text-smallest;

  color: $dropdown-next-events-no-events-color;
}
