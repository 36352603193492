$progress-moon-width: 50px;
$progress-moon-image-width: 40px;
$progress-feedback-color: $color-label;
$progress-feedback-space: $separator-xs;
$progress-background: $color-first;
$progress-background-active: $color-gray-01;
$progress-bar-background: $color-second-blue-02;

.progress-moon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: $progress-moon-width;
}

.progress-moon__overflow {
  height: calc(#{$progress-moon-width} / 2);
  position: relative;
  overflow: hidden;
  width: $progress-moon-width;
}

.progresss-moon__bar {
  border-radius: 50%;
  box-sizing: border-box;
  border: calc(#{$progress-moon-width} / 2) solid $progress-moon-width; /* half gray, */
  border-bottom-color: $progress-bar-background; /* half azure */
  border-right-color: $progress-bar-background;
}

.progress-moon__image {
  position: absolute;
  bottom: -6px;
  left: 52%;
  transform: translateX(-51%);

  img {
    width: $progress-moon-image-width;
  }
}

.progress-moon__feedback {
  @extend .text-xs;

  color: $progress-feedback-color;
  margin-top: $progress-feedback-space;
}
