$assesment-student-result-avatar-size: 48px;
$assesment-student-result-score-height: 97px;
$assesment-student-result-score-width: 119px;
$assesment-student-result-comments-margin: 70px;
$assesment-student-result-attempt-arrow-width: 20px;
$assesment-student-result-sender-padding: $separator-l;
$assesment-student-result-name-color: $color-gray-01;
$assesment-student-result-date-color: $color-gray-02;
$assesment-student-result-date-attemtps-total: $color-gray-04;
$assesment-student-result-date-attemtps-text: $color-gray-03;

.custom-assessment-student-results {
  display: flex;
  flex-direction: column;
  height: 90vh;
  .lemonade-view__container {
    border: none;
  }

  .button-fixed-with-hover {
    bottom: 160px;
  }
}

.custom-assessment-student-results__header,
.custom-assessment-student-results__header-left,
.custom-assessment-student-results__header-right {
  display: flex;
  align-items: center;
}

.custom-assessment-student-results__header {
  justify-content: space-between;
}

.dialog-activity__body {
  .custom-assessment-student-results__header {
    margin-bottom: $separator-xl;
  }

  .custom-assessment-student-results__avatar {
    margin-left: 0;
  }
}

.custom-assessment-student-results__back,
.custom-assessment-student-results__attemps-left,
.custom-assessment-student-results__attemps-right {
  @extend .hover-basic;
}

.custom-assessment-student-results__avatar {
  margin-left: $separator-xl;

  .avatar {
    height: $assesment-student-result-avatar-size;
    width: $assesment-student-result-avatar-size;
  }
}

.custom-assessment-student-results__info {
  margin-left: $separator-l;
}

.custom-assessment-student-results__name {
  @extend .title-h5;

  color: $assesment-student-result-name-color;
}

.custom-assessment-student-results__date {
  @extend .text-small;

  display: flex;
  align-items: center;
  color: $assesment-student-result-date-color;

  svg {
    margin-right: $separator-s;
  }
}

.custom-assessment-student-results__attemps-top,
.custom-assessment-student-results__attemps-number,
.custom-assessment-student-results__attemps-bottom {
  display: flex;
}

.custom-assessment-student-results__attemps-top {
  align-items: center;
  justify-content: space-between;
}

.custom-assessment-student-results__attemps-bottom {
  justify-content: center;
}

.custom-assessment-student-results__attemps-number {
  align-items: baseline;
}

.custom-assessment-student-results__attemps-actual {
  @extend .title-h3;

  margin-left: $separator-xl;
  color: $assesment-student-result-date-color;
}

.custom-assessment-student-results__attemps-total {
  @extend .text-button;

  margin-right: $separator-xl;
  color: $assesment-student-result-date-attemtps-total;
}

.custom-assessment-student-results__attemps-text {
  @extend .text-small-bold;

  color: $assesment-student-result-date-attemtps-text;
}

.custom-assessment-student-results__attemps-left-container,
.custom-assessment-student-results__attemps-right-container {
  width: $assesment-student-result-attempt-arrow-width;
}

.custom-assessment-student-results__redo {
  margin-right: $separator-xxl;
}

.custom-assessment-student-results__score {
  margin-left: $separator-xxl;

  .note-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $assesment-student-result-score-width;
    height: $assesment-student-result-score-height;
    padding: 0;
  }
}

.customs-assessment-students-results__comments {
  margin-top: $separator-xxl;
  //margin-bottom: $assesment-student-result-comments-margin;
  margin-bottom: $separator-l;
}

.customs-assessment-students-results__comment-number {
  @extend .title-h6;

  color: $color-gray-01;
}

.customs-assessment-students-results__comment-number-list {
  margin-top: $separator-xl;
}

.customs-assessment-students-results__sender {
  justify-self: flex-end;
  margin-top: auto;
  z-index: 100;

  .message-sender-simple {
    padding: $assesment-student-result-sender-padding;
    border-radius: $border-size-active;
    border: none;
  }
}

.custom-assessment-student-results__exercise {
  margin-top: $gutter;
  .lemo-controls-wrapper {
    display: none !important;
  }
}

.custom-assessment-student-results__viewer {
  margin-top: $gutter;
  margin-bottom: $gutter;
  height: 70vh;
}

.custom-assessment-student-results__pdf-container {
  @extend .grid;
  position: absolute !important;
  margin-top: 240px !important;
  right: 0 !important;
  left: 0 !important;
  max-height: 50vh !important;
}

.custom-assessment-student-results__exercise--hide-lemo {
  .lemo-question-header {
    display: none;
  }
  .lemo-file-uploader__methods-add {
    display: none;
  }
}

@media #{$breakpoints-xlarge} {
  .custom-assessment-student-results__pdf-container {
    left: 400px !important;
    max-height: 60vh !important;
  }
}
