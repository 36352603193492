$classroom-users-dropdown-width: 273px;

.classroom-users {
  @extend .hover-basic;

  margin-right: $separator-l;
}

.classroom-users__dropdown {
  @extend .card-generic--default-small;

  margin-top: $separator-s;
  max-height: $dropdown-students-max-height;
  overflow: auto;
  .basic-list__item {
    border: 0;
  }

  .user-item__avatar-name {
    font-family: $font-second;
  }
}

.classroom-users__avatars {
  display: flex;
  margin-right: $main-separator-components;
  .avatars-list__count-bubble {
    border: 0;
  }

  .avatars-list__count-bubble-text {
    color: $color-gray-02;
    font-size: $font-size-06;
    font-family: $font-second-bold;
  }
}

.classroom-users__entry {
  margin-right: $separator-m;
}

.classroom-users--overlapping {
  margin-right: 0;
  .classroom-users__avatars {
    flex-direction: row-reverse;
  }
  .classroom-users__entry {
    margin-right: 0;
    &:nth-child(n+2) {
      margin-right: -20px;
    }
  }
}

.classroom-users__dropdown-lib {
  .MuiPaper-root {
    border: 0 !important;
    width: $classroom-users-dropdown-width;
  }
}

.classroom-users__teachers-text {
  @extend .text-extra-bold;

  color: $color-gray-03;
  display: block;
  margin-bottom: $main-separator-items;
}


.classroom-users__users {
  max-height: 40vh;
}

.classroom-users__students {
  @extend .hover-basic;

  display: flex;
  align-items: center;

  margin-top: $separator-l;
  margin-bottom: $separator-xs;
}

.classroom-users__students-text {
  @extend .text-p;

  color: $color-gray-03;
  display: block;
  margin-left: $separator-l;
}

