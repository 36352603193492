.mint-view-header-student {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .icon-background__info-description {
    text-transform: capitalize;
  }

  .tag--color-default {
    background-color: #f3ecfd;
  }
  .tag__text {
    color: #a472ed;
  }
}
