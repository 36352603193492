$calendar-view-separators: $main-separator-components;
$calendar-view-list-calendar-width: 265px;
$calendar-view-list-calendar-max-height: 48vh;

.calendar-view__item {
  margin-bottom: $calendar-view-separators;
  .back-button {
    display: none;
  }
}

.calendar-view__item--title {
  display: none;
  width: $calendar-view-list-calendar-width;
}

.calendar-view__item--list-calendars {
  width: $calendar-view-list-calendar-width;

  .card-generic {
    @extend .scroll-bar-horizontal;

    max-height: $calendar-view-list-calendar-max-height;
    overflow-y: auto;
  }
}

.calendar-view__right-google {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: $separator-xl;
}

.calendar-view__center-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calendar-container .toolbar {
  margin-left: 0;
}
.calendar-container .toolbar__left,
.calendar-container .toolbar__right {
  flex-direction: row;
}

.calendar-container .toolbar__settings {
  cursor: pointer;
}

@media #{$breakpoints-xlarge} {
  .calendar-container .toolbar {
    margin-left: 72px;
  }

  .toolbar__left,
  .toolbar__right {
    flex-direction: row;
  }
}

.calendar-big--view-month .rbc-calendar {
  height: 700px !important;
}

@media #{$breakpoints-xlarge} {
  .calendar-view__item--title {
    display: block;
  }
  .calendar-view__item {
    .back-button {
      display: block;
    }
  }
}
