$header-week-day-font-size: 24px;
$header-week-day-font-family: $font-second-bold;
$header-week-day-color: $color-label;
$header-week-day-color-active: $color-white;
$header-week-day-name-color: $color-calendar-day-title;
$header-week-day-name-color-active: $color-first;
$header-week-day-name-margin-top: $separator-m;
$-selected-background: $color-first;
$calendar-big-day-size: 46px;
$calendar-big-day-size-mobile: 32px;
$header-week-min-height: 70px;

.calendar-big {
  &.calendar-big--view-week {
    .rbc-day-bg {
      border: 0 !important;
    }
  }

  .rbc-header + .rbc-header {
    border: 0;
  }

  .rbc-time-header-content {
    border: 0 !important;
  }

  .rbc-row.rbc-time-header-cell {
    min-height: $header-week-min-height;
  }

  //   para que quepa bien
  .rbc-header {
    padding-top: 10px;
  }

  .header-week__header-week {
    display: flex;
    flex-direction: column;
  }

  .header-week__header-week-day-number {
    color: $header-week-day-color;
    font-family: $header-week-day-font-family;
    font-size: $header-week-day-font-size;

    position: relative;
    z-index: 0;
  }

  .header-week__header-week--selected {
    .header-week__header-week-day-number {
      color: $header-week-day-color-active;
    }

    .header-week__header-week-day-name {
      color: $header-week-day-name-color-active;
    }

    .header-week__day-background {
      background: $-selected-background;

      width: $calendar-big-day-size-mobile;
      height: $calendar-big-day-size-mobile;
      border-radius: 100%;

      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      z-index: -1;

      top: -4px;
    }
  }

  .header-week__header-week-day-name {
    @extend .text-small-bold;
    @extend .ellipsis;

    color: $header-week-day-name-color;
    margin-top: $header-week-day-name-margin-top;
  }
}

@media #{$breakpoints-tablet} {
  .header-week__day-background {
    width: $calendar-big-day-size !important;
    height: $calendar-big-day-size !important;
    position: absolute;
    top: -10px !important;
  }
}
