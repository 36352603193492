$login-form-item-spaces: $form-spacing-big;
$login-form-color-text: $color-label;
$login-form-color-title: $color-title;
$login-form-signup-height: 57px;
$login-form-signup-background: $color-background;
$login-form-form-max-width: $size-login-form-max-width;
$login-form-main-padding: $main-padding-page;
$login-form-logo-image-height: 96px;

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  width: 100%;
}

.login-form__logo {
  height: 10%;
  margin: auto;
  max-width: $login-form-form-max-width;
  padding: $login-form-main-padding;
  width: 100%;

  img {
    max-height: $login-form-logo-image-height;
  }
}

.login-form__form {
  margin: auto;
  max-width: $login-form-form-max-width;
  padding: $login-form-main-padding;
  height: 80%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form__item {
  margin-bottom: $login-form-item-spaces;
}

.login-form__title {
  @extend .title-h4;

  color: $login-form-color-title;
}

.login-form__text {
  @extend .text-small-bold;
}

.login-form__text--forget {
  @extend .text-link;

  margin-left: auto;
}

.login-form__link {
  @extend .text-link;
}

.login-form__item--subactions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-form__signup {
  background: $login-form-signup-background;
  height: $login-form-signup-height;
  // height: 10%;
  line-height: $login-form-signup-height;
  text-align: center;
}

.login-form__third-parties {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  width: 100%;

  .login-form__item {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-right: $separator-l;
    width: 100%;
    max-height: 40px;
    margin-bottom: $separator-m !important;
  }
  .button {
    box-shadow: none !important;
    border-radius: 4px !important;
    height: 40px !important;
    min-height: 40px !important;
    padding: 10px 16px !important;
  }
  .button__text {
    @extend .text-small;

    color: $color-black;
    // margin-left: $separator-xl;
  }
}

.login-form__politics {
  display: flex;
  justify-content: center;
  margin-top: $separator-m !important;
}

.login-form__system-requirements {
  width: 100%;
  text-align: center;
  margin-top: $separator-s;
}

.login-form__legal-advice,
.login-form__system-requirements,
.login-form__privacy,
.login-form__support {
  @extend .hover-basic;
  @extend .text-small;

  color: $color-first;
}

.login-form__privacy,
.login-form__legal-advice {
  margin-right: $separator-l;
}

.login-form__system-requirements-text {
  font-family: $font-second;
  font-weight: 400;
  margin-left: $separator-m;
  margin-bottom: $separator-l;
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: $separator-m auto;
  }
  strong {
    font-weight: 700;
  }
  ol {
    list-style: square;
  }
  li {
    margin: $separator-s;
  }
}

.login-form__desktop-versions {
  margin-left: $separator-s;
}
.login-form__desktop-versions {
  margin-left: $separator-s;
}
