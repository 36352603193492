$icons-select-title-color: $color-label;

.icons-select {
  display: flex;
  justify-content: space-between;
}

.icons-select__icon-card {
  text-align: center;
}

.icons-select__icon {
  @extend .hover-basic;
  @extend .card-default;
}

.icons-select__title {
  @extend .text-xs;

  color: $icons-select-title-color;
}
