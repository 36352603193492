$dialog-add-unit-actions-background: $color-gray-07;
$dialog-add-unit-number-results-color: $color-gray-03;
$dialog-add-unit-separator-color: #f6f6f6;
$dialog-add-unit-selector-color: $color-gray-04;

.dialog-add-unit {
  .select-customizable__single-value {
    color: $dialog-add-unit-selector-color;
  }

  .MuiDialogActions-root {
    background-color: $dialog-add-unit-actions-background;
  }

  .MuiDialog-paperScrollPaper {
    overflow-y: hidden;
  }

  .footer-buttons {
    flex: 0 0 auto;
  }

  .dialog__actions-left {
    width: 100%;
    max-width: 400px;
  }
}

.dialog-add-unit__select {
  display: flex;
  align-items: center; 

  .select-customizable {
    width: 100%;
  }

  .select-customizable__label {
    flex: 0 0 auto;
    margin-right: $main-separator-items;
  }
}

.dialog-add-unit__filters {
  display: flex;
  margin-bottom: $separator-m;
}

.dialog-add-unit__search {
  flex: 1;

  .input-text__input {
    border-radius: $border-radius-02;
  }
}

.dialog-add-unit__filter-level,
.dialog-add-unit__filter-discipline,
.dialog-add-unit__filter-book {
  flex: 1;
  margin-left: $separator-m;

  .select-customizable__control {
    border-radius: $border-radius-02;
  }
}

.dialog-add-unit__filter-book {
  .select-customizable__single-value {
    .program-image__picture-container {
      display: none;
    }

    .book-select__name {
      margin-left: 0;
    }
  }
}

.dialog-add-unit__number-results {
  @extend .text-small;

  color: $dialog-add-unit-number-results-color;
}

#dialog-add-unit__list {
  height: 50vh;
  overflow-y: auto;
  margin-top: $separator-m;
  padding-right: $separator-m;
  border-top: 1px solid $dialog-add-unit-separator-color;
}
