$avatar-with-delete-and-border-size: $size-avatar-default;

.avatar-with-delete-and-border {
  position: relative;

  height: $avatar-with-delete-and-border-size;
  width: $avatar-with-delete-and-border-size;
}

.avatar-with-delete-and-border__button {
  position: absolute;

  bottom: 0px;
  right: -4px;
}
