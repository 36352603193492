$count-color: $color-label;
$count-label-separator-items: $separator-xs;

.count {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.count--align-left {
  text-align: left;
}

.count__number {
  @extend .title-h6;
}

.count__text {
  @extend .text-smallest;

  color: $count-color;
  margin-top: $count-label-separator-items;
}
