$timeline-sessions-tablet-height: 202px;
$timeline-sessions-tablet-item-width: 90px;
$timeline-sessions-tablet-separator-width: 45px;
$timeline-sessions-font-family: inherit;
$timeline-sessions-color-dark: #4e4e4e;
$timeline-sessions-color-soft: #828282;
$timeline-sessions-color-primary: $color-first;

.timeline-sessions {
  font-family: $timeline-sessions-font-family !important;
  transform: rotate(-90deg);
  height: $timeline-sessions-tablet-height;
  margin-left: $separator-xl;
  margin-top: -$timeline-sessions-tablet-separator-width;
}

.timeline-sessions__item {
  cursor: pointer;
  flex-direction: row-reverse;
  min-height: $timeline-sessions-tablet-item-width !important;
}

.timeline-sessions__separator {
  width: $timeline-sessions-tablet-item-width;
}

.timeline-sessions__icon {
  transform: rotate(90deg);
}

.timeline-sessions__icon--active {
  transform: scale(2);
}

.timeline-sessions__content-container {
  display: inline-block;
  transform: rotate(90deg);
  text-align: left;
  margin-left: $separator-l;
  text-transform: uppercase;
  padding: $separator-xxl $separator-l !important;
}

.timeline-sessions__content-text {
  margin-left: -$separator-xxxxl;
  color: $timeline-sessions-color-soft;
  &--active {
    color: $timeline-sessions-color-primary;
  }
  &--done {
    color: $timeline-sessions-color-dark;
  }
}

.timeline-sessions__content-all {
  cursor: pointer;
  width: 130px;
  color: $timeline-sessions-color-soft;
  transform: rotate(90deg);
  text-align: center;
  margin-left: $separator-l;
  text-transform: uppercase;
}

@media #{$breakpoints-large} {
  .timeline-sessions {
    font-family: $timeline-sessions-font-family !important;
    transform: rotate(0deg);
    height: 100%;
    margin-top: $separator-xxxxl;
  }

  .timeline-sessions__item {
    cursor: pointer;
    flex-direction: row;
    min-height: $timeline-sessions-tablet-separator-width + $separator-s !important;
    margin-left: -$timeline-sessions-tablet-height;
  }

  .timeline-sessions_item--selected {
    z-index: -1;
    top: -15px;
    left: 165px;
    position: absolute;
    background-color: $color-first-alpha;
    width: 229px;
    height: 50px;
    border-right: solid 3px $timeline-sessions-color-primary;
  }

  .timeline-sessions__separator {
    width: $timeline-sessions-tablet-item-width;
  }

  .timeline-sessions__icon {
    transform: rotate(0deg);
  }

  .timeline-sessions__icon--active {
    transform: scale(2);
  }

  .timeline-sessions__content-container {
    display: inline-block;
    transform: rotate(0deg);
    text-align: left;
    margin-left: 0;
    text-transform: uppercase;
    padding: 0 !important;
  }

  .timeline-sessions__content-text {
    margin-left: $separator-l;
  }

  .timeline-sessions__content-all {
    cursor: pointer;
    width: 229px;
    min-height: 50px;
    color: $timeline-sessions-color-soft;
    transform: rotate(0deg);
    margin-left: -36px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    &--selected {
      background-color: $color-first-alpha;
      border-right: solid 3px $timeline-sessions-color-primary;
    }
  }
}
