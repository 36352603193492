$notification-item-subinfo-separator: $main-separator-items;
$notification-item-subinfo-colors: $color-label;
$notification-item-subinfo-colors-not-consumption: $color-black;
$notification-item-subinfo-background-not-view: $color-second-yellow-01;
$notification-item-subinfo-background-view: $color-white;
$notification-item-notification-view-background-delay: $transition-duration-03;

.notification-item__name {
  @extend .text-small-bold;

  color: $notification-item-subinfo-colors;
}

.notification-item__text {
  @extend .text-small;

  color: $notification-item-subinfo-colors;
}

.notification-item__subinfo {
  @extend .text-small;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: $notification-item-subinfo-colors;
  margin-top: $notification-item-subinfo-separator;
}

.notification-item__date {
  flex: 0 0 auto;
}

.notification-item__notification--not-consumption {
  .notification-item__name,
  .notification-item__text {
    color: $notification-item-subinfo-colors-not-consumption;
  }
}

.notification-item__notification--not-viewed {
  background: $notification-item-subinfo-background-not-view;
}
