$avatar-with-name-list-with-user: 80px;

.avatar-with-name-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.avatar-with-name-list__user {
  margin: $main-separator-items;
  width: $avatar-with-name-list-with-user;

  text-align: center;

  .avatar {
    margin: auto;
  }

  .avatar-with-name__name {
    @include ellipsis-line(2);
  }
}
