$icon-size-xs: $size-icons-xs;
$icon-size-s: $size-icons-s;
$icon-size-default: $size-icons-default;
$icon-size-big: $size-icons-big;
$icon-size-big-2: $size-icons-big-2;
$icon-size-xl: $size-icons-xl;
$icon-size-xxl: $size-icons-xxl;

$icon-color-primary: $color-first;
$icon-color-secondary: $color-gray-01;
$icon-color-default: $color-gray-04;
$icon-color-white: $color-white;
$icon-color-blue: $color-icon-blue;
$icon-color-blue2: $color-icon-blue2;
$icon-color-black: $color-title;
$icon-color-light: $color-gray-04;
$icon-color-success: $color-success;
$icon-color-red: $color-error;
$icon-color-yellow: $color-alert;
$icon-color-label: $color-label;
$icon-color-green: $color-success;
$icon-color-brown: $color-bg-02;

.icon {
}

// sizes
.icon--size-xs {
  height: $icon-size-xs;
  width: $icon-size-xs;
}

.icon--size-s {
  height: $icon-size-s;
  width: $icon-size-s;
}

.icon--size-default {
  height: $icon-size-default;
  width: $icon-size-default;
}

.icon--size-big {
  height: $icon-size-big;
  width: $icon-size-big;
}

.icon--size-big-2 {
  height: $icon-size-big-2;
  width: $icon-size-big-2;
}

.icon--size-xl {
  height: $icon-size-xl;
  width: $icon-size-xl;
}

.icon--size-xxl {
  height: $icon-size-xxl;
  width: $icon-size-xxl;
}

// colors
.icon--color-default {
  &.icon--lib-skin {
    path {
      fill: $icon-color-default;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-default;
  }
}

.icon--color-primary {
  &.icon--lib-skin {
    path {
      fill: $icon-color-primary;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-primary;
  }
}

.icon--color-secondary {
  &.icon--lib-skin {
    path {
      fill: $icon-color-secondary;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-secondary;
  }
}

.icon--color-white {
  &.icon--lib-skin {
    path {
      fill: $icon-color-white;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-white;
  }
}

.icon--color-blue {
  &.icon--lib-skin {
    path {
      fill: $icon-color-blue;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-blue;
  }
}

.icon--color-brown {
  &.icon--lib-skin {
    path {
      fill: $icon-color-brown;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-brown;
  }
}

.icon--color-blue2 {
  &.icon--lib-skin {
    path {
      fill: $icon-color-blue2;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-blue2;
  }
}

.icon--color-green {
  &.icon--lib-skin {
    path {
      fill: $icon-color-green;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-green;
  }
}

.icon--color-label {
  &.icon--lib-skin {
    path {
      fill: $icon-color-label;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-label;
  }
}

.icon--color-black {
  &.icon--lib-skin {
    path {
      fill: $icon-color-black;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-black;
  }
}

.icon--color-light {
  &.icon--lib-skin {
    path {
      fill: $icon-color-light;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-light;
  }
}

.icon--color-success {
  &.icon--lib-skin {
    path {
      fill: $icon-color-success;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-success;
  }
}

.icon--color-red {
  &.icon--lib-skin {
    path {
      fill: $icon-color-red;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-red;
  }
}

.icon--color-yellow {
  &.icon--lib-skin {
    path {
      fill: $icon-color-yellow;
    }
  }

  &.icon--lib-core {
    fill: $icon-color-yellow;
  }
}

.icon--color-red-stroke {
  &.icon--lib-skin {
    path {
      stroke: $icon-color-red;
    }
  }
}
