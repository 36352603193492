.dialog-new-classroom {
  z-index: $z-index-popup-pages !important;
}

.new-classroom-step-books__header-actions {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: $separator-s;
}

.new-classroom-step-books__header-action {
  margin-left: $separator-s;
}
