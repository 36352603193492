$header-close-height: $size-height-header;
$header-close-background: $color-header-course;
$header-close-border: $border-headers;

.header-close {
  background: $header-close-background;
  border-bottom: $header-close-border;
  height: $header-close-height;

  display: flex;
  align-items: center;
  position: relative;
}

.header-close__grid {
  @extend .grid;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-close__close {
  @extend .hover-basic;
}
