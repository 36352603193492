.dialog-user-courses {
  .dialog__hr {
    margin-top: $separator-l;
  }
}

.classroom-users-content__header {
  padding-bottom: $separator-xs;
}

.dialog-user-courses__title {
  color: $color-gray-03;
}

.dialog-user-courses__title-user {
  color: $color-gray-01;
}

.dialog-user-courses__course {
  display: flex;
  align-items: center;

  margin-bottom: $separator-m;
}

.dialog-user-courses__course-name {
  @extend .text-p;

  color: $color-gray-01;
  margin-left: $separator-m;
} 
