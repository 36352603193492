.grades-additio {
  height: 80vh;
}

.grades-additio__fullscreen {
  display: flex;
  flex: auto;
  margin-bottom: $separator-m;

  .icon--color-white.icon--lib-skin path {
    fill: transparent;
  }
}

.grades-additio__needs-login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grades-additio__needs-login {
  @extend .title-h4;

  text-align: center;
  color: $color-gray-01;

  a {
    color: $color-first;
  }
}
