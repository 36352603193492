$select-bubble-separator-item: $separator-l;
$select-bubble-item-opacity: 0.4;
$select-bubble-item-after-color: $color-first;
$select-bubble-item-after-border-radius: $border-radius-select-bubble;
$select-bubble-item-after-bottom: -12px;
$select-bubble-item-after-height: $size-select-bubble-after-height;

.select-bubble {
}

.select-bubble__label {
  @extend .label;

  margin-bottom: $label-input-separator;
}

.select-bubble__list {
  display: flex;
  justify-content: space-between;
}

.select-bubble__item {
  @extend .hover-basic;

  opacity: $select-bubble-item-opacity;
  position: relative;
}

.select-bubble__item--selected {
  opacity: 1;

  &:after {
    background: $select-bubble-item-after-color;
    border-radius: $select-bubble-item-after-border-radius;
    content: '';
    height: $select-bubble-item-after-height;
    width: 100%;

    position: absolute;
    bottom: $select-bubble-item-after-bottom;
  }
}
