$steps-item-background-color-active: #E8471D;
$steps-item-background-bubble-active: #DB5E00;
$steps-item-background-color: $color-white;
$steps-item-text-color-active: $color-white;
$steps-item-text-color: $color-gray-02;
$steps-item-height: 56px;

.steps__list {
  display: grid;
}

.steps__item {
  background-color: $steps-item-background-color;
  border-radius: 4px;
  height: $steps-item-height;
  display: inline-flex;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  margin-right: 16px;
  position: relative;

  &:last-child {
    margin-right: 0;
    .steps__arrow-right {
      display: none;
    }
  }

  &:first-child {
    .steps__arrow-left {
      border-left-color: transparent !important;
    }
  }
}

.steps__item--no-clickable {
  cursor: not-allowed;
}

.steps__item--clickable {
  @extend .hover-basic;
}

.steps__item--active {
  background: linear-gradient(302.52deg, $steps-item-background-color-active 0%, #F08119 100%);

  .steps__arrow-right {
    border-left-color: $steps-item-background-color-active !important;
  }

  .steps__item--text {
    color: $steps-item-text-color-active;
  }

  &:first-child {
    .steps__arrow-left {
      border-left-color: transparent !important;
    }
  }

  .steps__bubble {
    &.bubble-content {
      background: $steps-item-background-bubble-active !important;
      border-color: $steps-item-background-bubble-active !important;
    }

    .bubble-content__content {
      color: $color-white;
    }
  }
}

.steps__bubble {
  margin-left: $separator-s;
}

.steps__arrow-left, .steps__arrow-right {
  width: 0;
  height: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  position: absolute;
}

.steps__arrow-right {
  border-left: 18px solid $steps-item-background-color;
  right: -16px;
}

.steps__arrow-left {
  border-left: 16px solid $color-background;
  position: relative;
  left: -1px;
}

.steps__item--text {
  @extend .text-p;
  
  color: $steps-item-text-color;
  margin-left: $separator-s;
}
