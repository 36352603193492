.progress-with-label__title {
  @extend .title-h3;

  color: $color-gray-01;
}

.progress-with-label__title,
.progress-with-label__percent {
  margin-bottom: $separator-s;
}

.progress-with-label__description {
  @extend .text-small-bold;

  color: $color-gray-03;
}
