.projection-paginator {
  display: flex;
  justify-content: center;
}

.projection-paginator__step {
  width: 176px;
  margin: 0 $separator-l;
}

.projection-paginator--with-arrows {
  justify-content: space-around;

  .projection-paginator__step {
    margin: 0 $separator-m;
  }

  .paginator__arrow {
    display: flex;
    align-items: center;
  }
}
