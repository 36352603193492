$input-checkbox-color-disabled: $color-disabled;
$input-checkbox-color-checked: $color-black;
$input-checkbox-size: $size-input-checkbox;

.input-checkbox {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(.input-checkbox--disabled) {
    @extend .hover-basic;
  }
}

.input-checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 9999;
}

.input-checkbox__icon-container {
  height: $input-checkbox-size;
  .icon {
    height: $input-checkbox-size;
    width: $input-checkbox-size;
  }
}

.input-checkbox__label {
  @extend .label;

  margin-left: $label-input-separator;
}

.input-checkbox__label {
  @extend .label;

  margin-left: $label-input-separator;
}

.input-checkbox__icon-container--checked + .input-checkbox__label {
  color: $input-checkbox-color-checked;
}
