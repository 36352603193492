$hr-height: 2px;
$hr-height-small: 1px;
$hr-background: $color-borders;

.hr {
  height: 2px;
  background: $hr-background;
}

.hr--size-small {
  height: $hr-height-small;
}
