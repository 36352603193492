$dialog-skip-student-size: $size-dialog-small-max-width;
$dialog-skip-student-text-color: $color-label;

.dialog-skip-student {
  .MuiPaper-root {
    max-width: $dialog-skip-student-size;
  }
}

.dialog-skip-student__text {
  @extend .text-p;

  color: $dialog-skip-student-text-color;
}
