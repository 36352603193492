$dialog-new-test-max-width: $size-dialog-xl-max-width;

.dialog-new-test {
  .MuiPaper-root {
    max-width: $dialog-new-test-max-width;
    overflow-y: visible;
  }

  .MuiDialog-container {
    overflow: auto;
  }

  .MuiDialogContent-root {
    overflow-y: visible;
  }

  .MuiDialog-paperScrollPaper {
    max-height: none !important;
  }
}
