$calendar-small-color-day: $color-title;
$calendar-small-arrow-top: 4px;
$calendar-small-day-selected-background: $color-first;
$calendar-small-day-paddings: 8px 10px;
$calendar-small-day-size: 30px;
$calendar-small-event-point-size: 4px;
$calendar-small-event-point-background: $color-first;
$calendar-small-event-point-active-background: $color-white;

.calendar-small__calendar {
  @extend .card-simple;

  .DayPicker-wrapper {
    padding: 0;
  }

  .DayPicker-Caption {
    text-align: center;
    > div {
      @extend .text-small-bold;
    }
  }

  .DayPicker-Day,
  .DayPicker-Weekday {
    padding: $calendar-small-day-paddings;
  }

  .DayPicker-Weekday {
    @extend .text-extra-bold;

    padding-top: 0;
    padding-bottom: 0;
  }

  .DayPicker-Day {
    @extend .text-extra-bold;

    color: $calendar-small-color-day;
    margin-bottom: 10px;
  }

  .DayPicker-Day--selected {
    background-color: transparent !important;
  }

  .calendar-small__arrow {
    @extend .hover-basic;

    position: absolute;
    top: $calendar-small-arrow-top;
  }

  .DayPicker-Month {
    margin: 0;
  }

  .calendar-small__arrow--left {
  }

  .calendar-small__arrow--right {
    right: 0;
  }
}

.calendar-small__day {
  position: relative;
  z-index: 1;
}

.calendar-small__event-point {
  background: $calendar-small-event-point-background;
  border-radius: 100%;
  width: $calendar-small-event-point-size;
  height: $calendar-small-event-point-size;

  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -3px;
}

.calendar-small__day--active {
  .calendar-small__day-background {
    background: $calendar-small-day-selected-background !important;
    width: $calendar-small-day-size;
    height: $calendar-small-day-size;
    border-radius: 100%;

    position: absolute;
    top: -6px;
    transform: translateX(-50%);
    left: 50%;

    z-index: -1;
  }

  .calendar-small__event-point {
    background: $calendar-small-event-point-active-background;
  }
}
