$lesson-task-list-date-color: $color-gray-04;
$lesson-task-list-header-margin: $separator-xl;
$lesson-task-list-task-margin: $separator-m;
$lesson-task-list-margin: $separator-xxl;

.lesson-task-list {
  margin-bottom: $lesson-task-list-margin;
}

.lesson-task-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $lesson-task-list-header-margin;
}

.lesson-task-list__name {
  @extend .title-h5;
}

.lesson-task-list__date {
  @extend .text-small;

  color: $lesson-task-list-date-color;
}

.lesson-task-list__task {
  @extend .hover-basic;

  margin-bottom: $lesson-task-list-task-margin;

  .item-complex-list {
    flex-direction: column;
    align-items: flex-start;
  }
}

.lesson-task-list__task-skeleton {
  height: 95px;
  margin-bottom: $lesson-task-list-task-margin;
}

@media #{$breakpoints-tablet} {
  .lesson-task-list__task {
    .item-complex-list {
      flex-direction: row;
      align-items: center;
    }
  }
}


