.scorm-individual-results-table {


  .scorm-individual-results-table__header {
    display: flex;

    span {
      font-family: $font-second;
      font-weight: 700;
      font-size: $font-size-10;
      line-height: 15px;
      color: $color-gray-02;
      text-transform: uppercase;

      &:nth-child(1) {
        flex-grow: 1
      }

      &:nth-child(2) {
        width: 250px;
      }

      &:nth-child(3) {
        width: 120px;
        text-align: center;
      }

      &:nth-child(4) {
        width: 90px;
        text-align: center;
      }
    }
  }

  .scorm-individual-results-table__accordion {
    min-height: 40px;

    .MuiAccordionDetails-root {
      flex-direction: column;
    }

    .MuiIconButton-root {
      padding: 0;
      margin-right: 14px;
    }
  }

  .scorm-individual-results-table__accordion-title {
    font-family: $font-first;
    font-weight: 700;
    font-size: $font-size-10;
    line-height: 15px;
    color: $color-gray-02;
    text-transform: uppercase;
    min-height: 40px;
    border-bottom: 1px solid $color-gray-05;
    flex-direction: row-reverse;

  }

  .scorm-individual-results-table__category-title {
    font-family: $font-first;
    font-size: 16px;
    color: $color-gray-02;
    min-height: 40px;
    border-bottom: 1px solid $color-gray-05;
    display: flex;
    align-items: center;
  }

}
