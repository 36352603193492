$input-date-icon-width: 36px;
$input-date-background-active: $color-first;
$input-date-icon-position-top: 24px;
$input-date-padding-day: 10px 9px;

.input-date {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  width: 100%;

  input {
    @extend .input-text__input;

    padding-left: $input-date-icon-width !important;
    cursor: default;
  }

  .DayPicker-Day {
    padding: $input-date-padding-day;
    word-break: normal;
  }

  .DayPickerInput {
    width: 100%;
  }

  .DayPicker-Day--selected {
    background-color: $input-date-background-active !important;
  }
}

.input-date--error {
  .input-date__input {
    input {
      @extend .border-errors;
    }
  }
}

.input-date__input {
  position: relative;
}

.input-date__label {
  @extend .label;
}

.input-date__icon {
  position: absolute;

  transform: translateY(-50%);
  top: $input-date-icon-position-top;
  left: $label-input-separator;

  display: flex;
  align-items: center;
  justify-content: center;
}

.input-date__clear-icon {
  position: absolute;
  transform: translateY(-50%);
  top: $input-date-icon-position-top;
  display: flex;
  align-items: center;
  justify-content: center;
  right: $label-input-separator;

  .MuiIconButton-root {
    padding: 8px;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
  }
}

.input-date__hour-container {
  margin: 0 $card-simple-padding;
}

.input-date__custom {
  padding: $card-simple-padding;
}

.input-date--fixed {
  .DayPickerInput-OverlayWrapper {
    position: fixed;
  }
}
