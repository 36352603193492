$accordion-list-margin-botton: $separator-m;

.accordion-list {
  .accordion__title,
  .accordion__title-text {
    width: 100%;
  }

  .accordion__tab-container {
    @extend .card-secondary-small;

    margin-bottom: $accordion-list-margin-botton;
  }

  .accordion__tab {
    padding: 0;
    position: relative;
  }

  .accordion__arrow-icon {
    top: 16px;
    right: 8px;
  }
}
