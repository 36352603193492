$exercise-item-book-margin: 36px;
$exercise-item-border-color: $color-gray-05;
$exercise-item-name-color: $color-gray-02;
$exercise-item-background-image-default: $color-gray-07;
$exercise-item-height-image: 56px;
$exercise-item-width-image: 100px;

.exercise-item {
  width: 100%;
}

.exercise-item__container {
  padding-bottom: $separator-m;
  border-bottom: solid 1px $exercise-item-border-color;
  padding-left: $separator-m;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.exercise-item__left {
  width: 90%;
  display: flex;
}

.exercise-item__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
}

.exercise-item__image-container {
  margin: 0 $separator-m;
  background-size: cover;
  background-color: $exercise-item-background-image-default;
  height: $exercise-item-height-image;
  width: $exercise-item-width-image;
  position: relative;
}

.exercise-item__icon-autoevaluative {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 100%;
  border: 1px solid $color-first;
  background-color: $color-white;

  position: absolute;
  right: -5px;
  top: -5px;
  svg {
    path {
      stroke: $color-first;
    }
  }
}

.exercise-item__information {
  min-height: $exercise-item-height-image;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.exercise-item__author {
  margin: $separator-s;
}

.exercise-item__name {
  @extend .title-h6;
  margin-left: $separator-s;
  color: $exercise-item-name-color;
  max-width: 80%;

  .text-html {
    @include ellipsis-line(2);
  }
}

.exercise-item__extra {
  display: flex;
  align-items: center;
  margin-left: $separator-s;
}

.exercise-item__extra-title {
  @extend .text-smallest;
  margin-right: $separator-s;
  font-family: $font-second;
  font-weight: 600;
  color: $color-first;
}

.exercise-item__check {
  .input-checkbox--disabled {
    .input-checkbox__icon-container--checked {
      svg {
        path {
          fill: $color-gray-04 !important;
        }
      }
    }
  }
}
