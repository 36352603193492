$assessment-status-tags-assessment-assigned-background: $color-assesment-status-assessment-assigned;
$assessment-status-tags-assigned-color: $color-white;
$assessment-status-tags-assessment-reassigned-background: $color-assesment-status-assessment-reassigned;
$assessment-status-tagsassessment-evaluated-background: $color-assesment-status-assessment-evaluated;
$assessment-status-tags-late-background: $color-assesment-status-late;
$assessment-status-tags-oneday-background: $color-assesment-status-one-day;

$assessment-status-tags-assigned-background: $color-assesment-status-assigned;
$assessment-status-tags-soon-background: $color-assesment-status-soon;
$assessment-status-tags-turned-in-background: $color-assesment-status-turned-in;
$assessment-status-tags-turned-in-late-background: $color-assesment-status-turned-in-late;
$assessment-status-tags-timed-out-background: $color-assessment-status-tags-timed-out;
$assessment-status-tags-due-date-tomorrow-background: $color-second-orange-01;
$assessment-status-tags-due-date-today-background: $color-error;

.assessment-status-tags {
  .tag__text {
    @extend .text-extra-bold;
  }
}

.assessment-status-tags--clickable {
  @extend .hover-basic;
}

.assessment-status-tags--big {
  .tag {
    min-height: 40px;
  }
  .tag__text {
    font-family: $font-first-semi;
    font-weight: normal;
    font-size: $font-size-06;
  }

  .tag__icon .icon {
    height: 24px;
    width: 24px;
  }
}

.assessment-status-tags--assessment-assigned {
  .tag {
    background: $assessment-status-tags-assessment-assigned-background;
  }

  .tag__text {
    color: $color-white;
  }
}

.assessment-status-tags--assessment-reassigned {
  .tag {
    background: $assessment-status-tags-assessment-reassigned-background;
  }

  .tag__text {
    color: $color-error;
  }
}

.assessment-status-tags--assessment-evaluated {
  .tag {
    background: $assessment-status-tagsassessment-evaluated-background;
  }

  .tag__text {
    color: $color-title;
  }
}

.assessment-status-tags--one-day {
  .tag {
    background: $assessment-status-tags-oneday-background;
  }

  .tag__text {
    color: $color-white;
  }
}

.assessment-status-tags--late {
  .tag {
    background: $assessment-status-tags-late-background;
  }

  .tag__text {
    color: $color-white;
  }
}

.assessment-status-tags--assigned {
  .tag {
    background: $assessment-status-tags-assigned-background;
  }

  .tag__text {
    color: $color-error;
  }
}

.assessment-status-tags--soon {
  .tag {
    background: $assessment-status-tags-soon-background;
  }

  .tag__text {
    color: $color-title;
  }
}

.assessment-status-tags--turned-in {
  .tag {
    background: $assessment-status-tags-turned-in-background;
  }

  .tag__text {
    color: $color-title;
  }
}

.assessment-status-tags--turned-in-late {
  .tag {
    background: $assessment-status-tags-turned-in-late-background;
  }

  .tag__text {
    color: $color-title;
  }
}

.assessment-status-tags--timed-out {
  .tag {
    background: $assessment-status-tags-timed-out-background;
  }

  .tag__text {
    color: $color-white;
  }
}

.assessment-status-tags--due-date-tomorrow {
  .tag {
    background: $assessment-status-tags-due-date-tomorrow-background;
  }

  .tag__text {
    color: $color-white;
  }
}

.assessment-status-tags--due-date-today {
  .tag {
    background: $assessment-status-tags-due-date-today-background;
  }

  .tag__text {
    color: $color-white;
  }
}
