$kanban-empty-card-padding: $separator-l;
$kanban-empty-card-background: $color-white;
$kanban-empty-card-text-margin: $separator-m;
$kanban-empty-card-border-radius: 8px;
$kanban-empty-card-border-color: $color-gray-05;
$kanban-empty-card-text-color: $color-gray-02;

.kanban-empty-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $kanban-empty-card-padding;
  background-color: $kanban-empty-card-background;
  border: 1px dashed $kanban-empty-card-border-color;
  border-radius: $kanban-empty-card-border-radius;
}

.kanban-empty-card__icon {
  .icon--color-default.icon--lib-skin path {
    fill: $kanban-empty-card-border-color !important;
  }
}

.kanban-empty-card__text {
  @extend .text-small;

  margin-top: $kanban-empty-card-text-margin;
  color: $kanban-empty-card-text-color;
}
