$message-sender-background-focus: $color-white;
$message-sender-border-radius: $border-radius-01;
$message-sender-background-shadow: $box-shadow-02;
$message-sender-textarea-height: $size-height-input;
$message-sender-textarea-height-focus: $size-form-sender-textarea-focus-height;
$message-sender-separator: $main-separator-items;
$message-sender-textarea-color-border-focus: $color-first;
$message-sender-with-left: $size-avatar-default;
$message-sender-main-right-icons: $textarea-padding;
$message-sender-transition-duration: $transition-duration-01;

.message-sender {
  @extend .card-background;

  // border-radius: $message-sender-border-radius;
  transition-duration: $message-sender-transition-duration;

  .textarea__input {
    height: $message-sender-textarea-height;
    min-height: $message-sender-textarea-height !important;
    transition-duration: $message-sender-transition-duration;
    resize: none;
  }
}

.message-sender--focus {
  background: $message-sender-background-focus;
  box-shadow: $message-sender-background-shadow;

  .textarea__input {
    @extend .text-p;

    border-color: $message-sender-textarea-color-border-focus;
    height: $message-sender-textarea-height-focus;
    min-height: $message-sender-textarea-height-focus !important;
  }
}

.message-sender__main {
  display: flex;
}

.message-sender__main-left {
  width: $message-sender-with-left;
  text-align: center;
}

.message-sender__pin {
  @extend .hover-basic;
}

.message-sender__textarea {
  flex: 1 1 auto;
  margin: 0 $message-sender-separator;
  position: relative;

  .message-sender__attachment-containter--nofocus {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: $message-sender-main-right-icons;
  }
}

.message-sender__attached-files {
  padding-left: 48px;
  padding-right: $separator-s;
  margin-top: $separator-m;
}

.message-sender__actions {
  display: flex;
  justify-content: space-between;

  margin-top: $message-sender-separator;
  margin-right:  $message-sender-separator;
  width: 100%;
}

.message-sender__attachment-containter--focus {
  margin-left: $message-sender-with-left;
}

.message-sender__attachment {
  display: flex;
}

.message-sender__attachment {
  margin-left: $message-sender-with-left;
}

.message-sender__attachment-icon {
  @extend .hover-basic;

  margin-left: $message-sender-separator;
}

.message-sender__buttons {
  display: flex;

  .button {
    margin-left: $message-sender-separator;
  }
}
