$teacher-notes-padding: $separator-xxl;
$teacher-notes-add-note-text-padding: $separator-xs;
$teacher-notes-header-title-margin: $separator-l;
$teacher-notes-wrapper-view-max-height: 400px;
$teacher-notes-editor-margin: $separator-xxl;
$teacher-notes-border-radius: $border-radius-02;
$teacher-notes-border-color: $color-second-blue-02;
$teacher-notes-background: $color-second-blue-03;
$teacher-notes-text-color: $color-gray-02;
$teacher-notes-add-note-text-color: $color-link;
$teacher-notes-text-color: $color-gray-02;

.teacher-notes__wrapper {
  padding: $teacher-notes-padding;
  border-radius: $teacher-notes-border-radius;
  border: 1px solid $teacher-notes-border-color;
  background: $teacher-notes-background;

  .fr-toolbar {
    border: none !important;
    border-radius: 10px !important;

    .fr-newline {
      display: none;
    }
  }

  .fr-wrapper {
    background: transparent !important;
    border: none !important;
  }

  .fr-second-toolbar {
    display: none;
  }
}

.teacher-notes__add-note-container {
  display: flex;
  justify-content: center;
}

.teacher-notes__add-note-wrapper {
  @extend .hover-basic;

  display: flex;
  align-items: center;
}

.teacher-notes__add-note-text {
  @extend .title-h6;

  padding-left: $teacher-notes-add-note-text-padding;
  color: $teacher-notes-add-note-text-color;
}

.teacher-notes__editor-header {
  display: flex;
  justify-content: space-between;
}

.teacher-notes__editor-header-left {
  display: flex;
  align-items: center;
}

.teacher-notes__editor-header-title {
  @extend .title-h6;

  color: $teacher-notes-text-color;
  margin-left: $teacher-notes-header-title-margin;
}

.teacher-notes__editor-header-delete {
  @extend .hover-basic;
}

.teacher-notes__editor-wrapper {
  margin-top: $teacher-notes-editor-margin;

  .fr-toolbar.fr-toolbar-open {
    padding-bottom: 0;
  }
}

.teacher-notes__viewer {
  font-family: $font-second;
  line-height: 24px;

  /*& > :first-child {
    margin-bottom: 5px;
  }*/

  strong {
    font-family: $font-second-bold;
  }

  img {
    max-width: 100%;
  }

  td {
    border: 1px solid #ddd;
  }

  td.fr-highlighted {
    border: 1px double red;
  }
  ul,
  ol {
    list-style: initial;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    font-size: smaller;
  }
}

.teacher-notes__editor-wrapper--overflow {
  max-height: $teacher-notes-wrapper-view-max-height;
  overflow: auto;
  padding: 5px;
}
