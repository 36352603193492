.analytic-card-donuts {
  height: 150px;
  .doughnut-chart {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.analytic-card-donuts__legend {
  @extend .title-h7;
}

.analytic-card-donuts__legend-text {
  @extend .text-xs;
}

.analytic-card-circles__extra {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: $color-gray-03;
}

.analytic-card-circles__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: $color-gray-03;
}

.analytic-card-circles__info-number-circle {
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: $color-gray-01;
}
