.accountant {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.accountant--align-left {
  text-align: left;
}

.accountant__number {
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: $color-gray-01;
}

.accountant__text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: $color-gray-03;
}

.accountant--direction-column {
  flex-direction: column;
}

.accountant--direction-column-reverse {
  flex-direction: column-reverse;
}

.accountant__riseAndFall {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.accountant__riseAndFall-positive {
  color: #5fcb93;
}

.accountant__riseAndFall-negative {
  color: #d84843;
}
