.dialog-create-unit {
  .new-unit__title-container, 
  .new-unit__actions {
    display: none;
  }

  .new-unit {
    width: 100%;
  }

  .new-unit__form {
    margin-top: 0;
  }

  .new-unit {
    padding: 0;
  }
}
