$separator-calendar-toolbar: 40px !default;

$main-padding-page: $separator-xl !default;

$main-separator-items: $separator-s !default;
$main-separator-components: $separator-xxl !default;

$form-spacing-big: $separator-xl !default;
$form-spacing: $separator-m !default;

$label-input-separator: $separator-xs !default;
$input-padding: $separator-s !default;
$textarea-padding: $separator-s !default;

$padding-card-default-horizontal: $separator-m !default;
$padding-card-default-vertical: $separator-l !default;
$padding-card-default-big-horizontal: $separator-xxxl !default;
$padding-card-default-big-vertical: $separator-xl !default;
$padding-aside: $separator-xxl !default;

$padding-card-default-small: $separator-s !default;
$padding-card-default-big: $padding-card-default-big-vertical $padding-card-default-big-horizontal !default;
$padding-card-default: $padding-card-default-vertical $padding-card-default-horizontal !default;
$padding-card-background: $separator-s !default;
$padding-dialog: $separator-m $separator-xl !default;

$padding-dropdown: $separator-s $separator-m !default;

$separator-without-grid: 30px !default;
$separator-hr: $separator-l !default;
