.dialog-learning-objective__title-container {
  position: relative;
  padding: $separator-m $separator-xxl;
  border-radius: $border-size-active;
  background-color: $xapi-header-background-color;
}

.dialog-learning-objective__title-container-icon {
  position: absolute;
  top: -4px;
  left: -4px;
}

.dialog-learning-objective__title-container-title {
  @extend .text-small;
  span {
    @extend .text-small-bold;
  }
}

.dialog-learning-objective__activities {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $separator-m;
  padding: $separator-m;
  border: 1px solid #e0e0e0;
  border-radius: $border-size-active;
}

.dialog-learning-objective__activities-activity {
  width: 100%;
}

.dialog-learning-objective__activities-left,
.dialog-learning-objective__activities-right {
  @extend .hover-basic;
}

.dialog-learning-objective__activities-activity-title {
  @extend .text-small-bold;
}

.dialog-learning-objective__activities-activity-response {
  margin-top: $separator-l;
}

.dialog-learning-objective__activities-activity-students {
  margin-top: $separator-m;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-around;
}

.dialog-learning-objective__activities-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $separator-m;
}

.dialog-learning-objective__activities-students-amount {
  @extend .title-h5;
}

.dialog-learning-objective__activities-correct {
  .dialog-learning-objective__activities-students-amount {
    color: #40d14f;
  }
}
.dialog-learning-objective__activities-incorrect {
  .dialog-learning-objective__activities-students-amount {
    color: #f86871;
  }
}

.dialog-learning-objective__activities-students-progress {
  width: 180px;
  margin-top: $separator-m;
}

.dialog-learning-objective__activities-students-progress-label {
  @extend .text-xs;

  margin-top: $separator-xs;
  color: $color-gray-03;
}
