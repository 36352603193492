.dialog-google-clasroom-import-users {
  .MuiDialogActions-spacing {
    background-color: $color-gray-06;
  }
}

.dialog-google-clasroom-import-users__number {
  @extend .text-small;

  color: $color-gray-02;
}

.classroom-list__item-skeleton {
  height: 32px;
  margin-bottom: $separator-m;
}
