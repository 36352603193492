$progress-bar-height: $size-progressbar-height;
$progress-bar-height-medium: $size-progressbar-medium-height;
$progress-bar-background: $color-progress-bar-background;
$progress-bar-background-white: $color-white;
$progress-bar-background-active: $color-first;
$progress-bar-background-grey: $color-gray-03;
$progress-bar-border-radius: $border-radius-progress-bar;
$progress-bar-text-color: $color-label;
$progress-bar-text-reverse-color: $color-title;
$progress-bar-separator: $separator-xs;
$progress-bar-text-font-size: 10px;

.progress-bar {
  display: flex;
  flex-direction: column;
}

.progress-bar--reverse {
  flex-direction: column-reverse;

  .progress-bar__label {
    @extend .text-small-bold;

    color: $progress-bar-text-reverse-color;
    display: flex;
    justify-content: flex-end;
  }
}

.progress-bar__bar {
  background: $progress-bar-background;
  border-radius: $progress-bar-border-radius;
  height: $progress-bar-height;
  // margin-bottom: $progress-bar-separator;
  position: relative;
}

.progress-bar--size-medium {
  height: $progress-bar-height-medium;

  .progress-bar__bar {
    height: 100%;
  }
}

.progress-bar--background-white {
  .progress-bar__bar {
    background: $progress-bar-background-white;
  }
}

.progress-bar--background-grey {
  .progress-bar__bar {
    background: $progress-bar-background-grey;
  }
}

.progress-bar--background-gray {
  .progress-bar__bar {
    background: $progress-bar-background-active;
  }
}

.progress-bar__progress {
  background: $progress-bar-background-active;
  border-radius: $progress-bar-border-radius;

  position: absolute;
  top: 0;
  height: 100%;
}

.progress-bar__label {
  @extend .text-extra;

  color: $progress-bar-text-color;
}

.progress-bar__text {
  @extend .text-extra;

  color: $progress-bar-text-color;
  font-size: $progress-bar-text-font-size;
  text-align: right;
}
