* {
  box-sizing: border-box;
}

body {
  font-size: 100%; // = 10px, 2.4rem 14px, 2.4rem = 24px..
  font-weight: normal;
  min-height: 100%;

  word-break: break-word;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.direction--ar,
.direction--rtl {
  direction: rtl;
}
