$lesson-info-lesson-items-components-separator: $main-separator-components;
$lesson-info-lesson-items-move-icon-separator: $separator-l;
$lesson-info-lesson-items-title-separator: $main-separator-items;
$lesson-info-lesson-items-title-color: $color-label;
$lesson-info-lesson-items-section-empry-separator: $main-separator-items;
$lesson-info-lesson-items-section-background: $color-gray-06;
$lesson-info-lesson-items-create-section-size: 18px;

.unoi-lesson-items {
  .lesson-element-item__big-preview-content {
    min-height: 400px;
    max-height: 750px;
  }

  width: 100%;

  .lesson-items-guides-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: transparent;
    box-shadow: none;
    margin: $separator-xxl auto;
    .lesson-info-lesson-items__header {
      display: block !important;
      width: 100% !important;

      .MuiIconButton-root {
        display: block;
        position: absolute;
        right: 0;
        top: $separator-l;
        z-index: 100;
      }
    }

    .lesson-info-lesson-items__content {
      width: 100%;
      margin-top: $separator-xxxl;
      display: flex;
      justify-content: space-between;
    }

    .lesson-info-lesson-items__allGuides {
      display: block !important;
    }

    .MuiCollapse-root {
      width: 100% !important;
    }

    .card-generic {
      width: 48%;
      margin-top: $separator-s;

      .card-generic__children {
        display: flex !important;

        .lesson-items-guides-cover {
          height: 120px;
          width: 82px;
          img {
            height: 100%;
            width: 100%;
            border-radius: $border-radius-01;
          }
        }

        .lesson-items-guides-info {
          width: calc(100% - 120px);
          margin-left: $separator-s;
          .lesson-items-guides-info__type {
            color: #828282;
            text-transform: uppercase;
          }
          .lesson-items-guides-info__title {
            margin-top: $separator-s;
            color: #666666;
          }
        }
      }
    }
  }
  .lesson-info-lesson-items__header {
    display: block;
    margin-bottom: $lesson-info-lesson-items-title-separator;

    &:hover {
      .lesson-info-lesson-items__actions-move-icon {
        visibility: visible;
      }
    }
    .dropdown__lib {
      left: -$separator-l !important;
    }
  }
}

.lesson-info-lesson-items__list {
  margin-bottom: $lesson-info-lesson-items-components-separator;
}

.lesson-info-lesson-items__actions-move-icon {
  @extend .hover-basic;

  margin-left: $lesson-info-lesson-items-move-icon-separator;
  visibility: hidden;
}

.lesson-info-lesson-items__title-container {
  display: flex;
  align-items: center;

  .learning-objetives-tag-list {
    margin-bottom: 0;
  }

  .icon {
    margin-right: $lesson-info-lesson-items-title-separator;
  }
}

.lesson-info-lesson-items__title {
  @extend .title-h6;
  @extend .ellipsis;
  margin-top: $lesson-info-lesson-items-components-separator;
  display: block;
  color: $lesson-info-lesson-items-title-color !important;
}

.lesson-info-lesson-items__date {
  color: #4e4e4e;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: -$separator-xl;
}

.lesson-info-lesson-items__date-text {
  margin-right: $separator-s;
}

.lesson-info-lesson-items__actions {
  margin-right: $separator-l;
  display: flex;
  align-items: flex-start;
  margin-bottom: -$separator-m;
}

.lesson-info-lesson-items__create-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
}

.lesson-info-lesson-items__create-section-text {
  // margin-bottom: $separator-m;
  margin-right: $separator-s;
  font-size: $lesson-info-lesson-items-create-section-size;
}

.lesson-info-lesson-items__empty-section {
  margin-top: $lesson-info-lesson-items-section-empry-separator;

  .no-items-mode-edit {
    background: $lesson-info-lesson-items-section-background;
  }
}
