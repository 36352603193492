$dialog-add-license-margin-button: $main-separator-components;

.add-license-form {
  display: block;
  margin: auto;
}

.add-license-form__input {
  flex: 1 1 auto;
}

.add-license-form__button {
  margin-left: 0;
  margin-top: $dialog-add-license-margin-button;
}

.add-license-form__custom-error-container {
  background: $color-second-yellow-01;
  padding: $padding-card-default;
  margin-top: $main-separator-components;
}

.add-license-form__custom-error {
  @extend .text-p;

  color: $color-gray-01;
}

.add-license-form__error-byme {
  color: $color-first;
  text-decoration: underline;
}

@media #{$breakpoints-tablet} {
  .add-license-form {
    display: flex;
  }

  .add-license-form__button {
    margin-left: $dialog-add-license-margin-button;
    margin-top: 0;
  }
}
