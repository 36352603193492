.gamified-position {
  position: absolute;
  color: #4f4f4f;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  font-family: Dosis;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  margin-bottom: 2px;
}
