$assessment-result-student-test-margin: $separator-xxxxxl;

.assessment-result-student-view {
  .layout-header__children {
    max-width: $grid-max-width-content;
  }
}

.assessment-result-student-view__container {
  @extend .card-secondary;

  .lemonade-view__container {
    padding: 0;
  }

  .question-item__points {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
}

.assessment-result-student__test {
  margin-top: $assessment-result-student-test-margin;
}

.assessment-result-student__videolesson {
  margin-top: $separator-l;
  .vl-question-list {
    margin-top: $separator-l;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__label {
    font-size: 15px;
    font-weight: 700;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__label,
  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__number {
    color: $color-gray-03;

    svg {
      display: none;
    }
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts {
    justify-content: flex-end;
  }

  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__right,
  .vl-lesson .vl-lemonade-question__navigation-attempts .vl-navigation-attempts .vl-navigation-attempts__left {
    button {
      background: transparent;
    }
  }

  .vl-lesson .vl-button--arrow-attempts {
    border-radius: 50%;
    border: 1px solid $color-gray-03;
    height: 28px;
    width: 28px;
    margin: auto $separator-s;
    svg {
      border: none;
    }
    svg rect {
      fill: transparent;
    }
    svg path {
      fill: $color-gray-03;
    }
  }

  .vl-lesson .vl-navigation-attempts__right {
    .vl-navigation-attempts__number {
      font-size: 22px;
      color: $color-gray-02;

      span {
        font-size: 15px;
        color: $color-gray-04;
      }
    }
  }

  .vl-lesson .vl-lemonade-question .vl-lemonade-question__score-wrapper {
    .assessment-grade-tag {
      padding: $separator-m;

      .assessment-grade-tag__grade-container {
        margin-right: $separator-s;
      }
    }
  }
}
