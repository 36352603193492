$header-custom-height: $size-height-header;
$header-custom-background: $color-header;
$header-custom-border: $border-headers;

.header-custom {
  background: $header-custom-background;
  border-bottom: $header-custom-border;
  height: $header-custom-height;

  display: flex;
  align-items: center;
  position: relative;
}

.header-custom__grid {
  @extend .padding-default;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.header-custom__left,
.header-custom__right {
  width: 30%;
}

.header-custom__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-custom__center {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;

  width: 40%;
}
