$kanban-card-radius: $border-radius-04;
$kanban-card-border-width: 24px;
$kanban-card-border-color-editorial: $color-gray-01;
$kanban-card-minheight: 50px;
$kanban-card-background: $color-white;
$kanban-card-text-color: $color-gray-01;
$kanban-card-text-owner-color: $color-gray-02;

.kanban-card {
  position: relative;
}

.kanban-card__card--is-dragging {
  .kanban-card__card-content {
    transform: rotate(-5deg) !important;
  }

  .kanban-card__drag-icon {
    transform: rotate(-5deg) !important;
    top: $separator-l;
  }
}

.kanban-card__info-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kanban-card__card-meta-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kanban-card__card-content {
  background: $kanban-card-background;
  border-left: $kanban-card-border-width solid $kanban-card-border-color-editorial;
  border-radius: $kanban-card-radius;
  box-shadow: 0px 1px 2px rgba(112, 107, 101, 0.15);
  user-select: none;
  margin: 0 0 $separator-s 0;
  min-height: $kanban-card-minheight;
  padding: $padding-card-default-small;
  transform: rotate(0deg) !important;
  transition: all 0.2s ease;
}

.kanban-card__card-meta-info {
  margin-bottom: $main-separator-items;
}
.kanban-card__info-right {
  display: flex;
}

.kanban-card__card-text {
  @extend .text-small;

  color: $kanban-card-text-color;
}

.kanban-card__card-owner-text {
  @extend .text-smallest-bold;

  color: $kanban-card-text-owner-color;
}

.kanban-card__drag-icon {
  z-index: 10;
  position: absolute;
  left: $separator-xxs;
  top: $separator-s;
}
