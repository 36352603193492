$lesson-element-item-type-color: $color-type;
$lesson-element-item-name-color: $color-title;
$lesson-element-item-type-separator: $separator-xs;
$lesson-element-item-separator: $separator-m;
$lesson-element-item-separator-mobile: $separator-m;
$lesson-element-item-moon-active: $color-first;
$lesson-element-item-moon-background: $color-gray-06;
$lesson-element-item-text-color: $color-label2;
$lesson-element-item-right-info-margin-right: $separator-l;
$lesson-element-item-right-info-margin-right-student: $separator-l;
$lesson-element-item-right-date-color: $color-type;
$lesson-element-item-dropdown-min-width: 130px;
$lesson-element-item-date-text-separator: $separator-m;
$lesson-element-item-progress-bar-width: 100px;
$lesson-element-item-description-separator: $main-separator-items;
$lesson-element-item-description-color: $color-gray-04;
$lesson-element-item-children-content-separator: $main-separator-components;
$lesson-element-item-separator-metas: $main-separator-components;
$lesson-element-item-separator-icon-metas: $main-separator-items;
$lesson-element-item-meta-icon-padding: $separator-s;
$lesson-element-item-separator-text-color-meta: $color-label;
$lesson-element-item-icon-background: $color-gray-07;

.lesson-element-item {
  .progresss-moon__bar {
    border-color: $lesson-element-item-moon-background;
    border-bottom-color: $lesson-element-item-moon-active;
    border-right-color: $lesson-element-item-moon-active;
  }

  .progress-moon__feedback,
  .progress-bar__text {
    color: $lesson-element-item-text-color;
  }

  .hr {
    height: 1px;
  }
}

.lesson-element-item__hr {
  margin: $main-separator-items 0 $main-separator-components 0;
}

.lesson-element-item__center {
  display: flex;
  flex-direction: column;
}

.lesson-element-item__type {
  @extend .text-smallest;

  color: $lesson-element-item-type-color;
}

.lesson-element-item__name {
  @extend .text-p;
  @extend .ellipsis-complex;

  color: $lesson-element-item-name-color;
  margin-top: $lesson-element-item-type-separator;
}

.lesson-element-item__comments {
  @extend .text-smallest;

  color: $lesson-element-item-description-color;
}

.lesson-element-item__right {
  display: flex;
  align-items: center;
}

.lesson-element-item__right-deliver-at {
  margin-right: $gutter;
}

.lesson-element-item__only-teacher {
  margin-right: $lesson-element-item-right-info-margin-right;
}

.lesson-element-item__right-info {
  margin-right: $lesson-element-item-right-info-margin-right;
  flex: 0 0 auto;
}

.lesson-element-item__right-info--student {
  display: flex;
  margin-right: $lesson-element-item-right-info-margin-right-student;
}

.lesson-element-item__right-info--scheduled {
  text-align: right;
}

.lesson-element-item__right-item {
  margin-left: $lesson-element-item-separator-mobile;
  margin-right: $lesson-element-item-separator-mobile;

  .tag--progress-bar {
    min-height: 40px;
  }

  .progress-bar__label {
    .progress-bar__label--value {
      display: inline-block;
      color: $color-gray-01;
      font-size: $font-size-08;
      font-family: $font-first;
    }
    .progress-bar__label--percent {
      display: inline-block;
      color: $color-gray-03;
      font-size: $font-size-10;
      font-family: $font-first;
      margin-left: $separator-xxs;
    }
  }
}

.lesson-element-item__right-item--progress-bar {
  margin-right: $separator-l !important;
  width: $lesson-element-item-progress-bar-width;
}

.lesson-element-item__right-date-container {
  display: flex;
  align-items: center;

  margin-top: $lesson-element-item-type-separator;
}

.lesson-element-item__right-date {
  @extend .text-smallest;

  color: $lesson-element-item-right-date-color;
  font-style: italic;
  margin-left: $lesson-element-item-date-text-separator;
}

.lesson-element-item__dropdown-lib {
  min-width: $lesson-element-item-dropdown-min-width;
}

.assigments-list__children-content {
  display: flex;
  align-items: center;
  margin-top: $lesson-element-item-children-content-separator;
}

.assigments-list__children-description {
  @extend .text-smallest;

  color: $lesson-element-item-description-color;
  margin-left: $lesson-element-item-description-separator;
}

.lesson-element-item__meta {
  display: flex;
  align-items: center;

  margin-right: $lesson-element-item-separator-metas;
}

.lesson-element-item__meta-info {
  display: flex;
  flex-direction: column;
}

.lesson-element-item__meta-icon {
  background: $lesson-element-item-icon-background;
  border-radius: $border-radius-01;
  margin-right: $lesson-element-item-separator-icon-metas;
  padding: $lesson-element-item-meta-icon-padding;
}

.lesson-element-item__meta-info-text {
  @extend .text-smallest;

  color: $lesson-element-item-separator-text-color-meta;
  font-style: italic;
}

.lesson-element-item__meta-info-description {
  @extend .text-small-bold;

  color: $lesson-element-item-separator-text-color-meta;
}

.lesson-element-item__go-to-test {
  margin-left: $lesson-element-item-separator-metas;

  .button__text {
    top: 0;
    padding-left: 0;
  }
}

.lesson-element-item__big-preview-content {
  cursor: default;
  width: 100%;
  height: auto;
  max-height: auto;
  max-width: 100%;
  margin: $separator-m auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 100%;
  }
  .lesson-element-item__navigation {
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    background-color: #f1f3f5;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
}

@media #{$breakpoints-medium} {
  .lesson-element-item__right-item {
    margin-left: $lesson-element-item-separator;
  }
}
