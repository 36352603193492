$select-checkboxs-top-dropdown: 56px;
$select-checkboxs-option-height: 40px;
$select-checkboxs-text-color: $color-title;
$select-checkboxs-all-text-color: $color-first;
$select-checkboxs-item-complex-left-width: 46px;
$select-checkboxs-item-hover-color: $color-gray-07;
$select-checkboxs-item-border-radius: $border-radius-01;
$select-checkboxs-input-text-color: $color-placeholder;
$select-checkboxs-all-text-color: $color-label2;
$select-checkboxs-all-text-color-active: $color-first;
$select-checkboxs-all-text-separator: $main-separator-items;

.select-checkboxs {
  min-height: $select-customizable-min-heigt;

  .picky__input {
    @extend .transition-hover-border;
    @extend .border-default;
    @extend .text-small;

    border-radius: $select-customizable-border-radius;
    color: $select-checkboxs-input-text-color;
    min-height: $select-customizable-min-heigt;
    padding: $input-padding;
  }

  .picky__dropdown {
    top: $select-checkboxs-top-dropdown;
  }

  .item-complex-list {
    width: 100%;
  }

  .picky__dropdown {
    @extend .card-secondary;

    overflow: auto !important;
  }

  .select-checkboxs__all-container {
    border-bottom: 0 !important;
    text-align: center !important;
    &:focus {
      outline: 0 !important;
    }
  }
  .select-checkboxs__all {
    @extend .text-small-bold;

    color: $select-checkboxs-all-text-color;
  }

  .select-checkboxs__all--active {
    color: $select-checkboxs-all-text-color-active;
  }

  .select-checkboxs__placeholder-all {
    display: flex;
    align-items: center;
  }

  .select-checkboxs__placeholder-all-text {
    margin-left: $select-checkboxs-all-text-separator;
  }

  .select-autocomplete-option {
    @extend .text-small-bold;

    color: $select-checkboxs-all-text-color;
  }

  .item-complex-list__left-children {
    width: $select-checkboxs-item-complex-left-width;
  }

  .select-checkboxs__all-container,
  .select-checkboxs__option-container {
    background-color: transparent !important;

    &:hover {
      cursor: pointer;
      background-color: $select-checkboxs-item-hover-color !important;
      border-radius: $select-checkboxs-item-border-radius;
    }
  }

  .picky__filter {
    input {
      @extend .input-text__input;
    }
  }

  .picky__input::after {
    top: 22px;
  }

  .picky__placeholder {
    color: $select-checkboxs-input-text-color;
  }

  &__subtitle {
    color: $color-gray-01;
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    margin-right: 12px;
  }
}

.select-checkboxs__placeholder-all {
  color: $select-checkboxs-text-color;
}

.select-checkboxs__option {
  input {
    display: none;
  }
}

.select-checkboxs__option {
  display: flex;
  height: $select-checkboxs-option-height;
}

.select-checkboxs__option-text {
  @extend .text-smallest;

  color: $select-checkboxs-text-color;
}

.select-checkboxs__label {
  @extend .label;
}
