.table-pagination {
  margin: $separator-l auto;

  .MuiPaginationItem-page.Mui-selected {
    background-color: $color-first;
    color: $color-white;
  }

  .MuiButtonBase-root.Mui-disabled {
    border-radius: 4px;
    background: $color-gray-07;
    .MuiSvgIcon-root,
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
      color: $color-gray-04 !important;
    }
  }

  .MuiPaginationItem-outlined {
    border-radius: 4px;
    border: 1px solid #ebe0d7;
    background: var(--base-white, #fff);
  }

  .MuiPaginationItem-page.Mui-selected:hover,
  .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
    @extend .hover-basic;

    background-color: $color-first;
    color: $color-white;
  }
}
