$student-grades-tabs-height: 50px;
$student-grades-background: $color-white;
$student-grades-options-color: $color-label;
$student-grades-options-color-active: $color-first;
$student-grades-options-padding-left: $main-separator-items;
$student-grades-student-tasks-padding: $main-padding-page;
$student-grades-options-separator: $main-separator-items;
$student-grades-options-border-radius: $border-radius-01;
$student-grades-padding: $border-radius-01;
$student-grades-title-color: $color-title;
$student-grades-text-color: $color-label;

.student-grades {

  .layout-calendar,
  .layout-calendar__columns {
    min-height: calc(100vh - #{$size-height-header});
  }

  .list-simple-dropdown__li {
    align-items: center;
    height: $student-grades-tabs-height;
    border-top-left-radius: $student-grades-options-border-radius;
    border-bottom-left-radius: $student-grades-options-border-radius;
    margin-top: $student-grades-options-separator;

    &:hover {
      background: $student-grades-background;
    }
  }

  .list-simple-dropdown__name {
    @extend .title-h6;

    color: $student-grades-options-color !important;
  }

  .list-simple-dropdown__li--active {
    .list-simple-dropdown__name {
      color: $student-grades-options-color-active !important;
    }
  }

  .student-grades__options {
    margin-top: $student-grades-options-separator;
  }
}

// Responsive

.student-grades {
  .student-grades__mobile-menu-button {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 9999;

    .button {
      border-radius: 50%;
      padding: 12px 12px;
    }
  }

  .student-grades__mobile-menu-backdrop {
    position: fixed;
    display: none;
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0);
    top: 0;
    right: 0;
    opacity: 0;
    z-index: $z-index-aside-overlay;
  }

  .layout-calendar__column-1 {
    padding-top: 0;
    padding-right: 0;
    right: -450px;
    left: unset;
    top: 0;
    padding-top: 64px;
    z-index: $z-index-aside;
    transition: right 0.3s ease-in-out;
  }

  .layout-calendar__column-2 {
    background: $student-grades-background;
    padding-left: 28px;
  }

  &.student-grades__mobile-menu--open {
    .layout-calendar__column-1 {
      right: 0;
      transition: right 0.3s ease-in-out;
    }

    .student-grades__mobile-menu-button {
      right: 57px;
    }

    .student-grades__mobile-menu-backdrop {
      display: block;
      opacity: 0.25;
    }
  }
}

@media #{$breakpoints-xlarge} {
  .student-grades {
    .student-grades__mobile-menu-button {
      display: none;
    }

    .student-grades__mobile-menu-backdrop {
      display: none;
    }

    .layout-calendar__column-1 {
      padding-top: 0;
      padding-bottom: 20px;
      padding-right: 0;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100vh;
      position: fixed;
      left: 0;
      width: 450px;
      top: unset;
    }

    .layout-calendar__column-2 {
      padding-top: 20px;
      padding-left: calc(450px + 20px);
      padding-bottom: 20px;
      width: 100%;
    }
  }
}
