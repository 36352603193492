@import './Accountant/index';
@import './ListingAxisX/ListingAxisX.scss';
@import './ListingAxisY/ListingAxisY.scss';
@import './charts/index';

.analytics-text-subtitle-bold {
  color: var(--gray-03, #828282);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
