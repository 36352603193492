$button-height: $size-button-height;
$button-height-small: $size-button-height-small;
$button-height-xs: $size-button-height-xs;
$button-gradient: $gradient;
$button-border-radius: $border-radius-button;
$button-border-radius-big: $border-radius-big-button;
$button-border-radius-small: $border-radius-02;
$button-icon-padding-text: $separator-l;
$button-padding: $separator-m $separator-l;
$button-padding-small: $separator-s $separator-l;
$button-padding-xs: $separator-xs $separator-l;
$button-padding-l: $separator-s $separator-xxl;
$button-transparent-color: $color-label;
$button-white-background: $color-white;
$button-white-color: $color-label;
$button-white-shadow: $box-shadow-04;
$button-grey-background: $color-gray-07;
$button-grey-color: $color-first;
$button-black-background: $color-gray-07;
$button-black-color: $color-white;
$button-blue-background: $color-second-blue-03;
$button-blue-color: $color-title;
$button-blue-border-color: $color-second-blue-02;
$button-border-color: $color-borders-dark;
$button-border-text-color: $color-button-border-text;
$button-min-width: $size-button-min-width;
$button-orange-background: $color-alert;
$button-disabled-background: $color-gray-05;
$button-google-fontsize: 14px;
$button-google-color: #787979;
$button-google-icon-size: 18px;
$button-google-height: 42px;
$button-google-max-width: 200px;

.button {
  @extend .hover-basic;
  @extend .text-button;

  border-radius: $button-border-radius;
  color: $color-white;
  display: inline-block;
  min-height: $button-height;
  min-width: $button-min-width;
  padding: $button-padding;
  position: relative;

  &__spinner {
    margin: 0 auto;

    & > div {
      display: inline-block;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background: $button-disabled-background;
  }
}

.button--border-radius-big {
  border-radius: $button-border-radius-big;
}

.button--border-radius-small {
  border-radius: $border-radius-02;
}

.button--color-primary {
  background-image: $button-gradient;
}

.button--color-info {
  background: $color-info;
}

.button--color-grey {
  background: $button-grey-background;
  color: $button-grey-color;
  svg {
    fill: $button-grey-color;
  }
  &:disabled {
    color: $color-gray-04;
  }
}

.button--color-black {
  background: $button-grey-background;
  color: $button-black-color;
  svg {
    fill: $button-grey-color;
  }
  &:disabled {
    color: $color-gray-04;
  }
}

.button--color-transparent {
  background: transparent;
  color: $button-transparent-color;
}

.button--color-transparent-primary {
  background: transparent;
  color: $button-grey-color;
}

.button--color-white {
  @extend .border-light;

  background: $button-white-background;
  box-shadow: $button-white-shadow;
  color: $button-white-color;
}

.button--color-orange {
  background: $button-orange-background;
}

.button--color-border {
  @extend .border-default;

  border-color: $button-border-color;
  color: $button-border-color;

  .button__text {
    color: $button-border-text-color;
  }
}

.button--color-blue {
  @extend .border-default;

  border-color: $button-blue-border-color;
  background: $button-blue-background;

  .button__text {
    color: $button-blue-color;
  }
}

.button--color-primary-outlined {
  @extend .border-primary;

  color: $color-first;
  padding-top: inherit !important;
  padding-bottom: inherit !important;

  .icon {
    path {
      fill: $color-first !important;
    }
  }

  &:disabled {
    background: transparent;
    border-color: $color-borders-dark;
    color: $color-gray-04;

    .icon {
      path {
        fill: $color-borders-dark;
      }
    }
  }
}

.button--color-info-outlined {
  @extend .border-info;

  color: $color-info;
  padding-top: inherit !important;
  padding-bottom: inherit !important;

  .icon {
    path {
      fill: $color-info !important;
    }
  }

  &:disabled {
    background: transparent;
    border-color: $color-borders-dark;
    color: $color-gray-04;

    .icon {
      path {
        fill: $color-borders-dark;
      }
    }
  }
}

.button__content {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: $main-separator-items;
  }
}

.button__text {
}

.button--default {
  width: 100%;
}

.button--inline {
  display: inline-block;
}

.button--size-small {
  min-height: $button-height-small;
  padding: $button-padding-small;
}

.button--size-xs {
  min-height: $button-height-xs;
  padding: $button-padding-xs;
}

.button--size-large {
  padding: $button-padding-l;
}

.button--color-only-icon {
  min-width: initial;

  svg {
    margin-right: 0;
  }
}

.button--icon-position-left {
  .icon {
    position: absolute;
    left: $separator-m;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
  }
}

.button--icon-position-right {
  .button__text {
    padding-right: $separator-l;
    padding-left: 0;
    position: relative;
    margin-right: $separator-xs;
  }

  .icon {
    position: absolute;
    right: $separator-m;
    transform: translateY(-50%);
    top: 50%;
    margin-left: $separator-s;
    margin-right: 0;
  }
}

.button__spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  .sk-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button--theme-google {
  height: $button-google-height;
  max-width: $button-google-max-width;
  background-color: #ffff !important;
  background-image: none;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 0 8px;
  border: 0;

  .button__content {
    justify-content: flex-start;
  }

  .icon {
    margin-right: 24px;
    height: $button-google-icon-size;
    width: $button-google-icon-size;
  }
  .button__text {
    color: $button-google-color;
    font-size: $button-google-fontsize;
    letter-spacing: 0.2px;
    font-family: 'Roboto Medium';
  }

  &:hover {
    opacity: 1;
  }
}
