.chat-with-messages {
  margin: 10px;
  background-color: $color-white;

  .cc__contacts {
    height: 600px !important;
  }

  .cc-call-buttons__wrapper {
    display: none !important;
  }
}

.chat-back-to-recent {
  @extend .hover-basic;
}

.cc-incomingcall__wrapper {
  z-index: 10000 !important;
}

.chat-button__messages-close {
  z-index: 100;
}
