$behaviour-list-icon-width: 44px;

.behaviour-list {
  max-height: 70vh;
  overflow: auto;

  .behaviour-item {
    border-bottom: $border-size-01 solid $color-borders;
  }

  .basic-list__item {
    margin-bottom: 0;
  }

  .item-complex-list__left-children {
    width: $behaviour-list-icon-width;
  }
}
