$dropdown-border-radius: $border-radius-dropdown;
$dropdown-shadow: $box-shadow-02;
$dropdown-z-index: $box-shadow-02;

.dropdown__lib {
  .MuiPaper-root {
    border-radius: $dropdown-border-radius;
    box-shadow: $dropdown-shadow;
  }
}

.dropdown__lib {
  z-index: $z-index-popup;
}
