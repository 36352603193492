$gamified-medal-progress-svg: 32px;

.gamified-medal-progress {
  &__medal {
    z-index: 1;
    svg {
      height: $gamified-medal-progress-svg;
      width: $gamified-medal-progress-svg;
    }
  }

  &__value {
    position: relative;
    right: 11px;

    border-radius: 0px 8px 8px 0px;
    background: #eee4dc;
    height: 25px;
    padding: 4px 10px 4px 20px;

    color: $color-gray-01;
    font-family: Dosis;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: end;
  }
}
