$image-bubble-size: $size-icon-bubble;
$image-bubble-size-big: $size-icon-bubble-big;
$image-bubble-size-medium: $size-icon-bubble-medium;
$image-bubble-size-small: $size-icon-bubble-small;
$image-bubble-size-s: $size-icon-bubble-s;
$image-bubble-size-xs: $size-icon-bubble-xs;
$image-bubble-size-l: $size-icon-bubble-l;
$image-bubble-size-xl: $size-icon-bubble-xl;

.image-bubble {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-bubble__picture {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-bubble--size-medium {
  height: $image-bubble-size-medium;
  width: $image-bubble-size-medium;
}

.image-bubble--size-big {
  height: $image-bubble-size-big;
  width: $image-bubble-size-big;
}

.image-bubble--size-l {
  height: $image-bubble-size-l;
  width: $image-bubble-size-l;
}

.image-bubble--size-xl {
  height: $image-bubble-size-xl;
  width: $image-bubble-size-xl;
}

.image-bubble--size-s {
  height: $image-bubble-size-s;
  width: $image-bubble-size-s;
}

.image-bubble--size-xs {
  height: $image-bubble-size-xs;
  width: $image-bubble-size-xs;
}

.image-bubble--size-small {
  height: $image-bubble-size-small;
  width: $image-bubble-size-small;
}
