$tab-padding: 0 $separator-l;
$tab-padding-first: 0 $separator-l 0 $separator-xl;
$tab-padding-last: 0 $separator-xl 0 $separator-l;
$tab-active-background-color: $color-error;
$tab-active-color: $color-white;
$tab-inactive-color: $color-gray-03;
$tab-border-color: $color-gray-06;

.simple-tabs {
  display: flex;
}

.simple-tabs__option {
  @extend .title-h6;
  @extend .hover-basic;

  display: flex;
  align-items: center;
  justify-content: center;
  color: $tab-inactive-color !important;
  height: 40px;
  padding: $tab-padding;
  border: 1px solid $tab-border-color;

  &:first-child {
    border-radius: 24px 0 0 24px;
    padding: $tab-padding-first;
  }
  &:last-child {
    border-radius: 0 24px 24px 0;
    padding: $tab-padding-last;
  }
}

.simple-tabs__option--active {
  background-color: $tab-active-background-color;
  color: $tab-active-color !important;
}
