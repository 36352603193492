$user-item-name-separator: $main-separator-items;
$user-item-poinsts-width: 74px;
$user-item-name-color: $color-label;

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $separator-s;
  width: 100%;
  &.selected {
    background-color: rgba(237, 112, 17, 0.1);
  }
  .input-points__input-container {
    background: white;
  }
}

.user-item__main-info {
  display: flex;
  align-items: center;

  width: calc(91% - #{$user-item-poinsts-width});
}

.user-item__input-checkbox {
  margin-right: $user-item-name-separator;
}

.user-item__texts {
  max-width: 70%;
  @extend .ellipsis;
}

.user-item__name {
  @extend .text-small-bold;

  color: $user-item-name-color;
}

.user-item__email {
  margin-left: $user-item-name-separator;
}

.user-item__name,
.user-item__input-note-text {
  margin-left: $user-item-name-separator;
}

.user-item__input-note-text {
  display: block;
}
.user-item__input-points {
  flex: 0 0 auto;
  width: $user-item-poinsts-width;
}

.user-item__input-note {
  @extend .title-h6;
}

.user-item__avatar-name {
  display: flex;
  align-items: center;
  width: 100%;
}
.user-item--checkboxs-right {
  .user-item__main-info {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }
}

.user-item__action {
  @extend .hover-basic;
}
