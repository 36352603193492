$dialog-select-template-type-max-width: 200px;
$dialog-select-template-type-padding: $separator-s;
$dialog-select-template-type-border-radius: $border-radius-02;
$dialog-select-template-type-selected-color: $color-white;

.dialog-select-template {
  .MuiPaper-root {
    max-width: $size-dialog-xl-max-width;
  }
}

.dialog-select-template__columns {
  @extend .grid-column;

  min-height: 100px;
}

.dialog-select-template__left-column {
  @include column(4);
}
.dialog-select-template__right-column {
  @include column(8);
}

.dialog-select-template__list {
  overflow-y: auto;
  height: calc(70vh - 48px - 54px); //menos el footer menos el header
  padding-right: $dialog-select-template-type-padding;
  margin-bottom: $dialog-select-template-type-padding;
}

.dialog-select-template__type {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  max-width: $dialog-select-template-type-max-width;
  margin-bottom: $padding-card-default-vertical;
  padding: $dialog-select-template-type-padding;
  border-radius: $dialog-select-template-type-border-radius;
}

.dialog-select-template__type-icon {
  display: none;
}

.dialog-select-template__type-name {
  @extend .label;

  margin-left: $padding-card-default-vertical;
  font-size: 18px !important;
}

.dialog-select-template__type--selected {
  background: $gradient;
  width: 100%;

  .dialog-select-template__type-name {
    color: $dialog-select-template-type-selected-color;
  }
}

@media #{$breakpoints-medium} {
  .dialog-select-template__type-icon {
    display: block;
  }
}
/*@media #{$breakpoints-xlarge} {
  .dialog-select-template__left-column {
    @include column(3);
  }

  .dialog-select-template__right-column {
    @include column(9);
  }
}*/
