$error-button-width: 120px;

.error__container {
  background: $color-gray-07;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error__title {
  @extend .title-h2;

  color: $color-gray-03;
}

.error_retry {
  margin-top: $separator-xxl;
  width: $error-button-width;
}
