@keyframes loading-dots {
  to {
    opacity: 0.1;
    transform: scale(0.1);
  }
}

.loading-dots {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading-dots__img {
    width: 170px;
    margin: $separator-xxxl auto;
  }
  .loading-dots__text {
    font-size: 32px;
    color: #706b65;
    margin: $separator-l auto;
  }
  .loading-dots__loader {
    display: flex;
    margin: $separator-m auto;
    .loading-dots__dot-one,
    .loading-dots__dot-two,
    .loading-dots__dot-three {
      width: 12px;
      height: 12px;
      margin: 3px 6px;
      border-radius: 50%;
      background-color: #444444;
      opacity: 1;
      animation: loading-dots 1s infinite alternate;
    }

    .loading-dots__dot-one {
      animation-delay: 0.1s;
    }

    .loading-dots__dot-two {
      animation-delay: 0.2s;
    }

    .loading-dots__dot-three {
      animation-delay: 0.5s;
    }
  }
}
