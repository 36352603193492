$xapi-results-overview-separator: $main-separator-components;

.xapi-results-overview__title {
  @extend .grades__title;
}

.xapi-results-overview__counters {
  margin-top: $xapi-results-overview-separator;
  display: flex;
  flex-wrap: nowrap;

  > div {
    margin-right: $xapi-results-overview-separator;
  }
}

.xapi-results-overview__graph {
  margin-top: $xapi-results-overview-separator;
}
