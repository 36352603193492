$textarea-background: $color-white;
$textarea-border-radius: $border-radius-textarea;
$textarea-padding: $textarea-padding;
$textarea-color-placeholder: $color-placeholder;
$textarea-grey-background: $color-gray-07;

.textarea {
  width: 100%;
}

.textarea--background-grey {
  .textarea__input {
    background: $textarea-grey-background;
  }
}

.textarea__input {
  @extend .transition-hover-border;
  @extend .text-small;
  @extend .border-default;

  background: $textarea-background;
  border-radius: $textarea-border-radius;
  padding: $textarea-padding;

  width: 100%;

  &::placeholder {
    color: $textarea-color-placeholder;
  }
}

.textarea__label {
  @extend .input-text__label;
}
