.two-page-pdf-viewer {
  width: 80%;
  max-width: 80%;
  height: 100%;
  max-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.two-page-pdf-viewer__document {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 700px;
}

.two-page-pdf-viewer__page {
  margin: auto;
  max-height: 100%;
}

.two-page-pdf-viewer__page-one-page {
  max-width: 90% !important;
}

.two-page-pdf-viewer__page-two-pages {
  max-width: 45% !important;
}

.react-pdf__Page__canvas {
  margin: auto;
  max-height: 700px !important;
  width: auto !important;
  height: auto !important;
  max-width: 100%;
}

// .react-pdf__Page__textContent {
//   width: auto !important;
//   height: auto !important;
//   max-height: 100% !important;
// }
