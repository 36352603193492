// ----------------------------------------------------------------------------------
// ----------------------------------- USE-VARIABLES --------------------------------
// ----------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------

$color-disabled: $color-gray-04 !default;
$color-borders: $color-bg-01 !default;
$color-borders-dark: $color-gray-04 !default;
$color-borders-black: $color-gray-03 !default;
$color-button-border-text: $color-gray-03 !default;
$color-label: $color-gray-03 !default;
$color-label2: $color-gray-04 !default;
$color-placeholder: $color-gray-04 !default;
$color-title: $color-gray-02 !default;
$color-type: $color-gray-04 !default;

$color-background: var(--color-background) !default;
$color-card-background: var(--color-card-background) !default;

$color-header: $color-white !default;
$color-header-simple: $color-background !default;
$color-header-course: $color-white !default;
$color-header-save: $color-white !default;
$color-header-lesson: $color-white !default;
$color-header-width-tabs: $color-white !default;

$color-footer: $color-white !default;


$color-hover-dropdowns-items-hover: $color-card-background !default;
$color-layout-progress: $color-white !default;
$color-input-background: $color-white !default;

$color-calendar-day-title: $color-gray-04 !default;

$color-progress-bar-background: $color-gray-05 !default;

$color-background-tag: $color-gray-07 !default;
$color-tag: $color-gray-02 !default;
$color-tag-primary: $color-first !default;

$color-background-note: $color-second-blue-03 !default;
$color-border-note: $color-second-blue-03 !default;

$color-icon-blue: $color-second-blue-02 !default;
$color-icon-blue2: $color-link !default;

$color-background-dialog-footer: $color-gray-07 !default;

$color-toast-success-background: $color-white !default;
$color-toast-success-border-color: $color-success !default;
$color-toast-success-color-color: $color-success !default;
$color-toast-success-color-color: $color-success !default;

$color-assesment-status-assessment-assigned: $color-second-blue-02 !default;
$color-assesment-status-assessment-reassigned: rgba(255, 83, 34, 0.1) !default;
$color-assesment-status-assessment-evaluated: $color-gray-07 !default;
$color-assesment-status-one-day: $color-second-orange-01 !default;
$color-assesment-status-late: $color-error !default;
$color-assesment-status-assigned: rgba(255, 83, 34, 0.1) !default;
$color-assesment-status-soon: $color-gray-07 !default;
$color-assesment-status-turned-in: $color-gray-07 !default;
$color-assesment-status-turned-in-late: rgba(255, 83, 34, 0.1) !default;
$color-assessment-status-tags-timed-out: $color-gray-03 !default;
$color-background-test-bar-footer: $color-white !default;
$color-background-menu: $color-second-blue-01 !default;

$color-responsive-header: $gradient !default;
