$kanban-student-banner-border: 8px;
$kanban-student-banner-text-margin: $separator-l;
$kanban-student-banner-text-color: $color-gray-01;
$kanban-student-banner-image-height: 72px;

.kanban-student-banner {
  @extend .card-default;

  border-radius: $kanban-student-banner-border;
  border: none;
  padding: 0 $separator-xxl;
}

.kanban-student-banner__wrapper {
  display: flex;
  align-items: center;
}

.kanban-student-banner__image {
  height: $kanban-student-banner-image-height;
}

.kanban-student-banner__text {
  @extend .text-p;

  margin-left: $kanban-student-banner-text-margin;
  color: $kanban-student-banner-text-color;
}
