$dialog-search-questions-max-width: $size-dialog-xxl-max-width;
$dialog-search-questions-color-founds: $color-label;
$dialog-search-questions-search-width: 270px;
$dialog-search-questions-width-card: 201px;
$dialog-search-questions-margin-card: $separator-xxl;
$dialog-search-questions-selects-width: 200px;
$dialog-search-questions-menu-selects-width: 200px;
$dialog-search-questions-header-components-margin: $separator-xxl;

.dialog-search-questions {
  .MuiPaper-root {
    max-width: $dialog-search-questions-max-width;
  }

  .MuiDialogContent-root {
    height: 80vh;
  }

  .select-customizable__menu {
    width: $dialog-search-questions-menu-selects-width;
  }
}

#dialog-search-questions__content {
  height: 60vh;
  overflow-y: auto;
}

.dialog-search-questions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dialog-search-questions__header-left {
  display: flex;
  align-items: center;

  width: 100%;
  flex-wrap: wrap;

  @media #{$breakpoints-large} {
    width: auto;
  }
}
.dialog-search-questions__header-right {
  margin-top: $separator-m;
  margin-bottom: $separator-m;
  display: flex;

  justify-content: flex-end;
  width: 100%;

  @media #{$breakpoints-large} {
    justify-content: flex-start;
    width: auto;
  }
}

.dialog-search-questions__search {
  flex-grow: 1;
  width: 100%;

  @media #{$breakpoints-medium} {
    width: auto;
  }

  .input-text {
    width: 100%;

    @media #{$breakpoints-large} {
      width: $dialog-search-questions-search-width;
    }
  }
}

.dialog-search-questions__select {
  &:last-child {
    margin-left: $dialog-search-questions-header-components-margin;
  }
  margin-top: $separator-m;
  flex: 1;

  .select-customizable__control {
    width: 100%;

    @media #{$breakpoints-medium} {
      width: $dialog-search-questions-selects-width;
    }
  }

  @media #{$breakpoints-medium} {
    margin-left: $dialog-search-questions-header-components-margin;
    margin-top: 0;
  }
}

.dialog-search-questions__header-right-founds {
  @extend .text-small;

  color: $dialog-search-questions-color-founds;
}

.dialog-search-questions__exercise-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, $dialog-search-questions-width-card);
  justify-content: space-evenly;
  grid-gap: $dialog-search-questions-margin-card;
}

.dialog-search-questions__exercise-card {
  @extend .hover-basic;

  align-items: stretch;

  margin-bottom: $dialog-search-questions-margin-card;
}
