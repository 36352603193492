$scorm-results-users-search-separator: $separator-xxxl;
$scorm-results-users-list-title-border: $border-size-01 solid $color-borders;
$scorm-results-users-list-padding: $separator-s;
$scorm-results-users-no-users-width: 80%;

.scorm-results-users__accordions {

  .MuiAccordion-root {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    height: auto !important;

    background: transparent;
    box-shadow: none;

    border-top: $scorm-results-users-list-title-border;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    border-bottom: $scorm-results-users-list-title-border;
    margin: 0;
  }

  .MuiAccordionSummary-root {
    height: auto !important;
  }
}

.scorm-results-users__search {
  width: 100%;
  margin-bottom: $scorm-results-users-search-separator;
  padding-right: $scorm-results-users-list-padding;
}

.scorm-results-users__no-items {
  margin: auto;
  width: $scorm-results-users-no-users-width;
}
