.remote-mint-lesson-item {
  @extend .hover-basic;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top:$separator-m;
  padding-bottom:$separator-m;
    
  border-bottom: $border-size-01 solid $color-borders;
}

.remote-mint-lesson-item__name {
  @extend .title-h6;

  color: $color-gray-01;

}
