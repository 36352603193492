.layout-login {
  @extend .grid-column--without-gutter;

  min-height: 100vh;
}

.layout-login__column-1 {
  @include column--without-gutter(12);
}

.layout-login__column-2 {
  display: none;
}

@media #{$breakpoints-medium} {
  .layout-login__column-1 {
    @include column--without-gutter(7);
  }

  .layout-login__column-2 {
    @include column--without-gutter(5);

    display: block;
  }
}

@media #{$breakpoints-large} {
  .layout-login__column-1 {
    @include column--without-gutter(5);
  }

  .layout-login__column-2 {
    @include column--without-gutter(7);
  }
}
