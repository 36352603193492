$linear-graphic-title-color: $color-title;
$linear-graphic-text-color: $color-label;
$linear-graphic-note-tooltip-title-separator: $main-separator-items;
$linear-graphic-note-tooltip-title-color: $color-title;
$linear-graphic-note-tooltip-name-separator: $main-separator-components;
$linear-graphic-note-tooltip-color-separator: $main-separator-items;
$linear-graphic-note-tooltip-color-dashed-separator: $main-separator-items;
$linear-graphic-note-tooltip-color-height: 4px;
$linear-graphic-note-tooltip-color-width: 24px;
$linear-graphic-note-tooltip-name-color: $color-title;
$linear-graphic-dashed-item-width: 5px;
$linear-graphic-dashed-item-height: 4px;
$linear-graphic-dashed-item-separator: $separator-xs;
$linear-graphic-dashed-item-background: $color-first;

.linear-graphic {
  .recharts-surface {
    overflow: visible;
  }
}

.linear-graphic__tick-x-title {
  @extend .text-small-bold;

  fill: $linear-graphic-title-color;
}

.linear-graphic__tick-x-text {
  @extend .text-smallest;

  fill: $linear-graphic-text-color;
}

.linear-graphic__tick-y-title {
  @extend .text-smallest;

  fill: $linear-graphic-text-color;
  font-style: italic;
}

.linear-graphic__tooltip {
  @extend .card-default-small;
}

.linear-graphic__tooltip-title {
  @extend .text-small-bold;

  color: $linear-graphic-note-tooltip-title-color;
  display: block;
  margin-bottom: $linear-graphic-note-tooltip-title-separator;
}

.linear-graphic__tooltip-color {
  width: $linear-graphic-note-tooltip-color-width;
  height: $linear-graphic-note-tooltip-color-height;

  margin-right: $linear-graphic-note-tooltip-color-separator;
}

.linear-graphic__tooltip-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linear-graphic__tooltip-main-info {
  display: flex;
  align-items: center;

  margin-right: $linear-graphic-note-tooltip-name-separator;
}

.linear-graphic__tooltip-name {
  @extend .text-small;

  color: $linear-graphic-note-tooltip-name-color;
}

.linear-graphic__color-dashed {
  display: flex;
  align-items: center;

  margin-right: $linear-graphic-note-tooltip-color-dashed-separator;
}

.linear-graphic__color-dashed-item {
  height: $linear-graphic-dashed-item-height;
  width: $linear-graphic-dashed-item-width;
  background: $linear-graphic-dashed-item-background;
  margin-right: $linear-graphic-dashed-item-separator;

  &:last-child {
    margin-right: 0;
  }
}
