$kanban-column-background-dragging: $color-second-blue-03;
$kanban-column-background: $color-gray-06;
$kanban-column-header-height: 45px;
$kanban-column-header-student-height: 56px;
$kanban-column-header-text-font-size: 16px;
$kanban-column-header-item-length-student-size: 32px;
$kanban-column-header-item-length-student-background: $color-white;
$kanban-column-header-item-length-student-color: $color-gray-01;
$kanban-column-header-height-color: $color-white;
$kanban-column-header-background: $color-gray-01;
$kanban-column-header-radius: $border-radius-05;
$kanban-column-padding: $separator-l;

.kanban-column {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 $separator-s;
}

.kanban-column__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $kanban-column-header-background;
  border-radius: $kanban-column-header-radius $kanban-column-header-radius 0 0;
  height: $kanban-column-header-height;
  padding: $kanban-column-padding;
  width: 100%;
}

.kanban-column__header-text,
.kanban-column__header-items-length {
  @extend .title-h8;

  color: $kanban-column-header-height-color;
}

.kanban-column__header-items-length {
}

.kanban-column__wrapper {
  width: 100%;
}

.kanban-column__card-list {
  background: $kanban-column-background;
  padding: $kanban-column-padding;
}

.kanban-column__card-list--is-dragging-over {
  //background: $kanban-column-background-dragging;
}

.kanban-column__button {
  margin-top: $kanban-column-padding;
  display: flex;
  justify-content: center;

  .button {
    width: auto;
  }
}

.kanban-column__header--student {
  height: $kanban-column-header-student-height;

  .kanban-column__header-text {
    @extend .text-button;

    font-size: $kanban-column-header-text-font-size;
    text-transform: uppercase;
  }

  .kanban-column__header-items-length {
    @extend .text-button;

    display: flex;
    align-items: center;
    justify-content: center;
    height: $kanban-column-header-item-length-student-size;
    width: $kanban-column-header-item-length-student-size;
    border-radius: 20px;
    background-color: $kanban-column-header-item-length-student-background;
    color: $kanban-column-header-item-length-student-color;
  }
}
