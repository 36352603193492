$period-form-subtitle-color: $color-label;
$period-form-separator-background: $color-gray-06;
$period-form-separator-height: 1px;
$period-form-separator-width: 12px;
$period-form-input-time-range-max-width: 100px;
$period-form-select-time-range-max-width: 134px;
$period-form-error-time-margin-top: $main-separator-items;
$period-form-error-time-font-size: $font-size-08;
$period-form-error-date-text-size: $font-size-08;

.period-form {
  width: 100%;
}

.period-form__title {
  @extend .title-h5;

  display: block;
}

.period-form__form {
  @extend .grid-column;

  display: block;

  margin-top: $main-separator-components;
}

.period-form__subtitle {
  @extend .title-h6;

  color: $period-form-subtitle-color;
}

.period-form__dates {
  @include column(12);
}

.period-form__schedules {
  @include column(12);

  margin-top: $main-separator-components;
}

.period-form__schedules-header {
  display: flex;
  justify-content: space-between;
}

.period-form__range-date {
  display: flex;

  margin-top: $main-separator-components;
  position: relative;

  .input-date {
    z-index: auto;
  }
}

.period-form__date-error {
  font-size: $period-form-error-date-text-size;
}

.period-form__separator {
  background: $period-form-separator-background;
  height: $period-form-separator-height;
  margin: $separator-l $main-separator-items 0 $main-separator-items;
  width: $period-form-separator-width;
  position: relative;
  top: 30px;
}

.period-form__schedules-form {
  margin-top: $main-separator-components;
}

.period-form__schedules-form--hide-delete {
  .period-form__day-trash {
    visibility: hidden;
  }
}

.period-form__day {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
}

.period-form__day-week {
  margin-right: $main-separator-components;
  flex: 1 1 auto;

  width: $period-form-select-time-range-max-width;
}

.period-form__add-day-text {
  @extend .title-h6;
  @extend .text-link;

  display: block;
  margin-top: $main-separator-components;
}

.period-form__day-schedule {
  flex: 1 1 auto;
  width: auto;
}

.period-form__day-trash {
  @extend .hover-basic;

  margin-top: 38px;
  margin-left: $main-separator-components;
}

.period-form__day-schedule-error {
  font-size: $period-form-error-time-font-size;
  margin-top: $period-form-error-time-margin-top;
}

@media #{$breakpoints-large} {
  .period-form__form {
    display: flex;
  }

  .period-form__schedules {
    @include column(7);

    border-left: $border-size-01 solid $period-form-separator-background;
    margin-top: 0;
    padding-left: $main-separator-components;
  }

  .period-form__day-schedule {
    width: $period-form-input-time-range-max-width;
  }

  .period-form__dates {
    @include column(5);
  }
}
