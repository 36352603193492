$avatar-size-small: $size-avatar-small;
$avatar-size-xs: $size-avatar-xs;
$avatar-size-xl: $size-avatar-xl;
$avatar-size-xxl: $size-avatar-xxl;
$avatar-size-default: $size-avatar-default;
$avatar-size-big: $size-avatar-big;
$avatar-border-radius: $border-radius-avatar;
$avatar-background-color: $color-gray-05;
$avatar-border-size: 4px;

.avatar {
  background-size: cover !important;
  border-radius: $avatar-border-radius;
  background-color: $avatar-background-color;

  height: $avatar-size-default;
  width: $avatar-size-default;
}

.avatar--size-xl {
  height: $avatar-size-xl;
  width: $avatar-size-xl;
}

.avatar--size-xxl {
  height: $avatar-size-xxl;
  width: $avatar-size-xxl;

  .image {
    width: 100%;
  }
}

.avatar--size-big {
  height: $avatar-size-big;
  width: $avatar-size-big;
}

.avatar--size-small {
  height: $avatar-size-small;
  width: $avatar-size-small;
}

.avatar--size-xs {
  height: $avatar-size-xs;
  width: $avatar-size-xs;
}

.avatar--with-border {
  border-width: $avatar-border-size;
  border-style: solid;
}
