$layout-big-header-image-height: 108px;
$layout-big-header-icon-right-top: 30px;
$layout-big-header-icon-right-right: 30px;
$layout-big-header-background: $color-background;
$layout-big-header-background-header: $color-white;

.layout-big-header {
  background: $layout-big-header-background;
  min-height: 100vh;
  position: relative;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .progress-bar__bar {
    border-radius: 0;
  }
}

.layout-big-header__header {
  display: flex;
  flex-direction: column;

  padding-bottom: $separator-s;
}

.layout-big-header__header-top {
  @extend .padding-default;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: $header-simple-height;
}

.layout-big-header__icon-back {
  @extend .hover-basic;
}

.layout-big-header__tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .swiper {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .swiper-slide {
    display: block;
    width: auto !important;
  }

  .slidePrev-btn, .slideNext-btn {
    background-color: $color-white;
    border: 0;
    flex: 0 0 auto;
  }

  .slideNext-btn {
    &:disabled {
      opacity: 0;
      transition-duration: 0.6s;
    }
  }

  .slidePrev-btn{
    &:disabled {
      display: none;
    }
  }

  .tag {
    background-color: $color-gray-07;
    height: 31px;
    padding-left: $separator-m;
    padding-right: $separator-m;
    border-left: 4px solid transparent;
  }

  .tag__text {
    @extend .text-small-bold;

    text-transform: uppercase;
  }
}

.layout-big-header__header {
  background: $layout-big-header-background-header;
  height: $layout-big-header-image-height;
  width: 100%;
  position: relative;
}

.layout-big-header__texts-title {
  @extend .title-h4;

  color: $color-gray-01;
  display: block;
  text-transform: uppercase;
}

.layout-big-header__texts-subtitle {
  @extend .text-small-bold;

  color: $color-gray-03;
  display: block;
  margin-top: $main-separator-items;
}

.layout-big-header__children-container {
  @extend .grid;
  
  flex: 1 1 auto;
  overflow: hidden;
  overflow-x: hidden;
  
  padding-top: $main-padding-page;
  padding-bottom: $main-padding-page;
}

.layout-big-header__children {
  height: calc(100vh - #{$layout-big-header-image-height});
  overflow-y: auto
}

.layout-big-header__header-body {
  @extend .grid;

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

