.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 21474836473;

  .loading__backdrop {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: $color-gray-03;
    opacity: 0.2;
  }

  .loading__wrapper {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    z-index: 21474836474;

    width: 62px;
    height: 62px;

    padding: 15px;
    border-radius: 70px;
    box-shadow: 0 5px 20px 0 rgba(169, 155, 144, 0.2);
  }

  .loading__text {
    @extend .title-h4;
    text-align: center;

    margin-top: 20px;
  }
}

#loading__svg {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
