.answer-videolesson-view {
  .video-lesson,
  .video-lesson .video-lesson-content {
    background-color: transparent !important;
  }

  .answer-videolesson-view__title {
    display: flex;
    align-items: center;

    .answer-videolesson-view__title-text {
      @extend .title-h5;
      margin-left: $separator-m;
      text-transform: uppercase;
    }
  }

  .video-lesson {
    margin-top: $separator-l;
    .vl-lesson .vl-wrapper-visor-question .vl-wrapper-visor-question__visor,
    .vl-lesson .vl-wrapper-question .vl-lemonade-question__question {
      border-radius: 8px !important;
      border: 1px solid var(--stroke-border-card, #ebe0d7);
    }

    .vl-card-activity-list--active,
    .vl-card-activity-list {
      border-radius: 8px !important;
    }
  }

  .video-lesson .video-lesson-content {
    width: 100% !important;
  }
  .video-lesson,
  .video-lesson-content {
    background-color: transparent !important;
  }
  .video-lesson .video-lesson-content .vl-lesson .vl-button-add-activity .vl-button-add-activity__button .vl-button,
  .video-lesson .video-lesson-content .vl-lesson .vl-confirm-message .vl-controls .vl-button {
    background-color: $color-first !important;
  }

  .answer-videolesson-view__exit {
    cursor: pointer;
  }
}

.answer-videolesson-view__navegation {
  background-color: #faf7f5;
  padding: 8px 24px;
  border-radius: 36px;
  font-family: $font-first;
  font-size: 22px;
  color: $color-black;
  font-weight: 600;

  &-questions {
    color: #828282;
  }
}
