$calendar-item-separator: $separator-s;
$calendar-item-name-color: $color-title;
$calendar-item-level-color: $color-gray-03;
$calendar-item-icon-visible-width: 30px;

.calendar-item {
  .item-complex-list__left-children {
    width: $calendar-item-icon-visible-width;
  }
}

.calendar-item__visible-icon {
  @extend .hover-basic;
}

.calendar-item__info {
  display: flex;
  align-items: center;
}

.calendar-item__name {
  @extend .text-small-bold;
  @extend .ellipsis-complex;

  color: $calendar-item-name-color;
  margin-left: $calendar-item-separator;
}

.calendar-item__level {
  @extend .text-extra;
  @extend .ellipsis-complex;

  color: $calendar-item-level-color;
  margin-left: $calendar-item-separator;
}

.calendar-item__info--invisible {
  opacity: 0.5;
}

.calendar-item__options {
  @extend .hover-basic;
}
