$checks-lists-title-color: $color-label;
$checks-lists-margin-left-accordion-children: 20px;

.checklists__title {
  @extend .border-default;

  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.checklists__option {
  margin-top: $separator-m;
}
.checklists__title-text {
  @extend .text-small-bold;

  color: $checks-lists-title-color;
}

.checklists__option {
  list-style: none;
}

.checkslists__accordion {
  .accordion__title-text {
    margin-left: 0;
  }

  .accordion__children {
    margin-top: 0;
    margin-left: $checks-lists-margin-left-accordion-children;
  }
}
