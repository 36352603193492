$header-width-tabs-height: $size-height-header;
$header-width-tabs-background: $color-header-width-tabs;
$header-width-tabs-border: $border-headers;

.header-width-tabs {
  background: $header-width-tabs-background;
  border-bottom: $header-width-tabs-border;
  height: $header-width-tabs-height;

  display: flex;
  align-items: center;
}

.header-width-tabs__grid {
  @extend .padding-default;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-width-tabs__tabs {
  width: 80%;
}

.header-width-tabs__left {
  white-space: nowrap;
}

.header-width-tabs__left,
.header-width-tabs__right {
  width: 10%;
}

.header-width-tabs__right {
  @extend .hover-basic;

  display: flex;
  justify-content: flex-end;
}
