$aside-units-unit-margin: $separator-xxl;
$aside-units-lesson-margin-left: $separator-m;
$aside-units-lesson-margin-bottom: $separator-l;
$aside-units-unit-color: $color-gray-02;
$aside-units-lesson-color: $color-gray-03;
$aside-units-lesson-color-active: $color-first;

.aside-units__unit {
  @extend .title-h5;

  margin: $aside-units-unit-margin 0;
  color: $aside-units-unit-color;
}

.aside-units__lesson {
  @extend .hover-basic;
  @extend .title-h6;

  margin-left: $aside-units-lesson-margin-left;
  margin-bottom: $aside-units-lesson-margin-bottom;
  color: $aside-units-lesson-color;
}

.aside-units__lessons--active {
  color: $aside-units-lesson-color-active;
}
