@import "DialogActivityDetail/dialog-activity-detail.scss";

.dialog-activity {
  .layout-header {
    background: $color-white;
  }
  
  .assessment-instructions-view__date, 
  .assessment-instructions-view-header__header-right {
    visibility: hidden;
  }
}

.dialog-activity--student {
  .dialog-activity-detail__assesment  {
    box-shadow: none;
    border: 0;
    padding: 0
  }
 
}
 
.dialog-activity__group-name {
  @extend .text-p;

  color: $color-gray-02;
  text-transform: uppercase;
}
 