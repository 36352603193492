$cronometer-font-size: 174px;
$cronometer-font-size-mobile: 90px;
$cronometer-width-input: 250px;
$cronometer-width-input-mobile: 110px;
$cronometer-arrows-size: 48px;
$cronometer-arrows-size-mobile: 32px;
$cronometer-buttons-ten-font-size: $font-size-03;
$cronometer-buttons-ten-color: $color-label;
$cronometer-timeout-font-size: $font-size-01;
$cronometer-timeout-actions-separator: $main-separator-components;
$cronometer-timeout-actions-item-separator: $separator-xxxxxxl;
$cronometer-timeout-input-color: $color-gray-04;
$cronometer-timeout-input-color-start: $color-title;
$cronometer-timeout-input-color-timeout: $color-first;
$cronometer-timeout-ten-color: $color-label;

.cronometer {
  text-align: center;
}

.cronometer--timeout {
  .cronometer__points,
  .cronometer__input {
    background: linear-gradient(to right, #ff5029 0%, #f91b6b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.cronometer__timeout-text {
  @extend .title-h1;

  background: linear-gradient(to right, #ff5029 0%, #f91b6b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cronometer--start {
  .cronometer__points,
  .cronometer__input {
    color: $cronometer-timeout-input-color-start;
  }
}

.cronometer__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cronometer__arrows {
  visibility: hidden;
}

.cronometer__arrows--active {
  visibility: visible;
}

.cronometer__arrows-button {
  @extend .hover-basic;

  display: block;
  min-width: 0;
  outline: 0;
  padding: 0;

  .icon {
    width: $cronometer-arrows-size-mobile;
    height: $cronometer-arrows-size-mobile;
  }
}

.cronometer__input-container {
  display: flex;
  align-items: center;
}

.cronometer__input {
  color: $cronometer-timeout-input-color;
  // height: $cronometer-height-input;
  width: $cronometer-width-input-mobile;

  font-family: $font-first;
  font-size: $cronometer-font-size-mobile;
  outline: 0;
  text-align: center;
  -moz-appearance: textfield;

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.cronometer__points {
  color: $cronometer-timeout-input-color;

  // bottom: 8px;
  position: relative;

  font-size: $cronometer-font-size-mobile;
}

.cronometer__input--active {
  //   background-color: #fff;
}

.cronometer__input--pointer {
  @extend .hover-basic;
}

.cronometer__actions {
  @extend .card-secondary;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-top: $cronometer-timeout-actions-separator;
}

.cronometer__actions-button {
  @extend .hover-basic;

  // margin: 0 10px;
  outline: 0;

  &:last-child {
    margin-right: 0;
  }

  // .icon {
  //   height: $cronometer-buttons-size;
  //   width: $cronometer-buttons-size;
  // }
}

.cronometer__actions-button--play,
.cronometer__actions-button--pause {
  margin: 0 $cronometer-timeout-actions-item-separator;
}

.cronometer__actions-button--ten {
  @extend .title-h6;

  color: $cronometer-buttons-ten-color;
  font-size: $cronometer-buttons-ten-font-size;
}

.cronometer__timeout {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: $cronometer-timeout-font-size;
  margin: $separator-xl auto;
  visibility: hidden;
}

.cronometer--timeout {
  .cronometer__timeout {
    visibility: visible;
  }
}

@media #{$breakpoints-medium} {
  .cronometer__input {
    width: $cronometer-width-input;
    font-size: $cronometer-font-size !important;
  }

  .cronometer__points {
    // bottom: 29px;

    font-size: $cronometer-font-size !important;
  }

  .cronometer__arrows-button {
    .icon {
      width: $cronometer-arrows-size;
      height: $cronometer-arrows-size;
    }
  }
}
