$input-radio-separator-label: $separator-s;
$input-radio-label-checked-color: $color-first;

.input-radio {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.input-radio[animation='1'] {
  .input-radio__icon-container {
    animation: wobble 0.4s;
  }
}

.input-radio:not(.input-radio--disabled) {
  @extend .hover-basic;
}

.input-radio__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 9999;
}

.input-radio__label,
.input-radio__description {
  @extend .label;

  margin-left: $input-radio-separator-label;
}

.input-radio__description {
  @extend .text-smallest;

  margin-top: $separator-xs;
}

.input-radio--checked {
  .input-radio__label {
    color: $input-radio-label-checked-color;
  }
}

.input-radio__texts {
  display: flex;
  flex-direction: column;
}
