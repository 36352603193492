$card-resource-video-height: 202px;
$card-resource-video-image-height: 112px;

.card-resource-video {
  @extend .hover-basic;

  height: $card-resource-video-height;
  box-shadow: $box-shadow-06;   

  display: flex;
  flex-direction: column; 
  position: relative;

  .icon-bubble {
    background: rgba(0, 0, 0, 0.8);;
  }
}

.card-resource-video__image {
  background-size: cover;
  background-color: $color-gray-05;
  height: $card-resource-video-image-height;
  background-position: center;

  position: relative;
} 

.card-resource-video__icon-play {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  .icon {
    left: 1.5px;
    position: relative;
  }
}

.card-resource-video__texts {
  @extend .card-default;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1 1 auto;

  border-top: 0;

}

.card-resource-video__name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $color-gray-02;
  display: block;
  margin-top: $main-separator-items;
}

.card-resource-video__duration {
  @extend .text-smallest;

  color: $color-gray-03;
}

.card-resource-video__only-teacher {
  position: absolute;
  top: $separator-m;
  right: $separator-m;

  z-index: $z-index-minor-elements;

  .bubble-content {
    background-color: $color-gray-02;
    border: 0;
    margin-left: $main-separator-items;
  }
}
