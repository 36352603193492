$comments-list-and-create__separator: $separator-l;
$comments-list-and-create__view-all-color: $color-first;

.comments-list-and-create__sender {
  .message-sender-simple {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.comments-list-and-create__list {
  margin-bottom: $comments-list-and-create__separator;
}

.comments-list-and-create__view-all {
  @extend .title-h6;
  @extend .hover-basic;

  padding: $comments-list-and-create__separator;
  color: $comments-list-and-create__view-all-color;
}
