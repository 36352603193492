.rubrics-dialog-add-list {
  &__text {
    color: var(--gray-color-gray-02, $color-gray-02);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .card-generic__children {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
