$card-resourse-test-student-container-size: 58px;

.card-resourse-test-student {
  @extend .border-light;
  @extend .hover-basic;

  background: $color-white;
  padding: $padding-card-default-small $padding-card-default;
}

.card-resourse-test-student--disabled {
  cursor: not-allowed;
  opacity: 1 !important;
}

.card-resourse-test-student--late {
  .card-resourse-test-student__date-container {
    .card-resourse-test-student__date {
      color: $color-error;
    }

    .icon {
      path {
        fill: $color-error;
      }
    }
  }
}

.card-resourse-test-student--pending {
  .card-resourse-test-student__date-container {
    .card-resourse-test-student__date {
      color: $color-gray-01;
    }
  }
}

.card-resourse-test-student__top {
  display: flex;
}

.card-resourse-test-student__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;

  background: $color-gray-07;
  border-radius: $border-radius-02;
  height: $card-resourse-test-student-container-size;
  width: $card-resourse-test-student-container-size;
}

.card-resourse-test-student__info-container {
  margin-left: $separator-l;

  width: 100%;
}

.card-resourse-test-student__name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $color-gray-01;
}

.card-resourse-test-student__date-container {
  display: flex;
  align-items: center;

  margin-top: $main-separator-items;
}

.card-resourse-test-student__date {
  @extend .title-h7;

  color: $color-gray-03;
  margin-left: $separator-s;
}

.card-resourse-test-student__status {
  display: flex;
  justify-content: space-between;

  margin-top: $separator-l;

  .assessment-status-tags--late {
    .tag {
      background: rgba(255, 83, 34, 0.1);
    }

    .tag__text {
      color: $color-gray-01;
    }
  }
}
