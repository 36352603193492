$projection-mint-view-container-arrows-size: 46px;
$projection-mint-view-paginator-size: 70px;
$projection-mint-view-paginator-backgroud: $color-gray-01;
$proyection-view-page-color: $color-white;
$proyection-view-border: 1px solid $color-gray-05;

.projection-mint-view {
  .layout-header__children {
    padding-top: $separator-xxxxl;
    max-width: 100%;
    width: 100%;
  }

  .for-users,
  .header-custom__center,
  .lesson-header__unit-hidden,
  .lesson-header__project,
  .lesson-header__separator,
  .lesson-header__edit,
  .lesson-header__personalize,
  .lesson-header__see-as {
    display: none;
  }

  .header-custom__left {
    width: calc(100% - 50px);
  }

  .header-custom__right {
    width: 50px;
  }

  .paginator__arrow {
    .bubble-content {
      width: 40px;
      height: 40px;

      border-width: 2px
    }
  }
  
  .footer-projection__bottom {
    @extend .grid;
  }
  
  .footer-expandable__top {
    background: $proyection-view-page-color;
  }
  
  .layout-header {
    background-color: $color-white;
  }
  
  .bubble-content__content {
    color: $color-white;
    font-size: $font-size-07;
  }

  .projection-card {
    .bubble-content {
      border: 0;
    }
  }
  
  .projection-card__type {
    font-size: $font-size-08;
    color: $color-gray-03;
  }
  
  .projection-card__title {
    color: $color-gray-02;
  }
  
  .projection-paginator {
    align-items: stretch;
  
    .projection-card {
      height: 100%;
    }
  
    .projection-card__container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
  
      height: 100%;
    }
  }
  
  .projection-card__container {
    padding-left: $separator-s;
    padding-right: $separator-s;
  }
  
  
  .projection-card--active {
    .projection-card__title {
      color: $color-gray-01;
      font-family: $font-second-bold;
    }
  }
  
  .answer-test-view__footer-bar {
    position: relative;
    transform: none;
    left: auto;
    top: auto;
  
    display: flex;
    justify-content: flex-end;
    width: 100%;

    z-index: 200;
  }
  
  .answer-test-bar-footer__content {
    box-shadow: none;
    border: 0;
    padding: 0;
    background: transparent;
  }
  
  .answer-test-bar-footer__status-showed {
    display: none;
  }
  
  .footer-expandable__toggle-icon {
    border: $proyection-view-border;
    border-bottom: 0;
    border-radius: 8px 8px 0px 0px;
  }
  
  .footer-expandable__top:not(.footer-expandable__top--expanded) {
    &~.footer-expandable__bottom {
      border: $proyection-view-border;
      border-bottom: 0;
    }
  }
  
  .footer-expandable__top--expanded {
    border-top: $proyection-view-border;
  }

  .mint-post {
    scroll-margin-top: 60px;
  }
}

.projection-mint-view__container {
  position: relative;
  margin: 0 auto;
  padding-bottom: calc(#{$size-height-footer} + #{$main-padding-page});
}

.projection-mint-view__paginator-arrow-big {
  position: fixed;
  top: calc(50vh - #{$projection-mint-view-paginator-size/2});
  z-index: 998;
}

.projection-mint-view__paginator-arrow-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $projection-mint-view-paginator-size * 2;
  width: $projection-mint-view-paginator-size;
  background-color: $projection-mint-view-paginator-backgroud;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

.projection-mint-view__paginator-arrow-container-right {
  border-bottom-left-radius: $projection-mint-view-paginator-size * 2;
  border-top-left-radius: $projection-mint-view-paginator-size * 2;
  transform: translate($projection-mint-view-paginator-size/2);

  .icon {
    margin-left: $projection-mint-view-paginator-size / 4;
  }

  &:hover {
    transform: translate(0);
    opacity: 0.7;
  }
}

.projection-mint-view__paginator-arrow-container-left {
  border-bottom-right-radius: $projection-mint-view-paginator-size * 2;
  border-top-right-radius: $projection-mint-view-paginator-size * 2;
  transform: translate(-$projection-mint-view-paginator-size/2);

  .icon {
    margin-right: $projection-mint-view-paginator-size / 4;
  }

  &:hover {
    transform: translate(0);
    opacity: 0.7;
  }
}

.projection-mint-view__paginator-arrow-left {
  left: 0;
}

.projection-mint-view__paginator-arrow-right {
  right: 0;
}

.projection-mint-view__question {
  @extend .grid;

  .answer-test-view__footer-bar {
    margin-top: $main-separator-components;
  }
}

.projection-mint-view__aside {
  .asside-lessons__kanban,
  .custom-lesson-item__right {
    display: none;
  }
}
