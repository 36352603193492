.tree-view-to-select-label {
  margin: 9px;

  .tree-view-to-select-label__checked {
    display: flex;
    align-items: flex-start;
  }

  .tree-view-to-select-label__text {
    display: flex;

    .title {
      margin-right: 10px;
      color: $color-gray-02;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
    }

    .description {
      color: $color-gray-04;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
    }
  }
}
