@import './components/TableConsumptions/table-consumptions.scss';
$dialog-consumptions-title-type-size: 12px;
$dialog-consumptions-title-type-weigth: 700;
$dialog-consumptions-title-type-text-transform: uppercase;
$dialog-consumptions-title-color-gray: #828282;
$dialog-consumptions-title-line-height: 19px;
$dialog-consumptions-graphic-font: $font-second;
$dialog-consumptions-graphic-horizontal-lines-color: #0000000d;
$dialog-consumptions-graphic-font-weight: 400;
$dialog-consumptions-cards-width: 160px;
$dialog-consumptions-cards-height: 82px;
$dialog-consumptions-card-text-big-size: 22px;
$dialog-consumptions-card-font-family: $font-first;
$dialog-consumptions-card-text-color-strong: #333333;
$dialog-consumptions-card-text-color-light: #bdbdbd;
$dialog-consumptions-card-text-sm-size: 16px;
$dialog-consumptions-no-data-text-size: 18px;
$dialog-consumptions-no-data-font-weight: 600;

.dialog-consumptions {
  .dialog__close {
    position: absolute;
    top: $separator-m;
    right: $separator-m;
  }
}

.dialog-consumptions__title {
  display: flex;
  flex-direction: column;
}

.dialog-consumptions__title-type {
  display: flex;
  font-size: $dialog-consumptions-title-type-size;
  font-weight: $dialog-consumptions-title-type-weigth;
  text-transform: $dialog-consumptions-title-type-text-transform;
  color: $dialog-consumptions-title-color-gray;
  line-height: $dialog-consumptions-title-line-height;
  margin-top: $separator-s;
  &-text {
    margin-left: $separator-xs;
  }
  &-questions {
    text-transform: none;
  }
}

.dialog-consumptions__content {
  display: flex;
}

.dialog-consumptions__content-status {
  margin-left: $separator-m;
  .card-generic {
    padding: $separator-s;
    width: $dialog-consumptions-cards-width;
    height: $dialog-consumptions-cards-height;
    margin-bottom: $separator-m;
    &__title-text {
      color: $dialog-consumptions-title-color-gray;
      margin-bottom: $separator-xs;
    }
  }
  .dialog-consumptions__card-content {
    font-family: $dialog-consumptions-card-font-family;
    font-size: $dialog-consumptions-card-text-big-size;
    color: $dialog-consumptions-card-text-color-strong;
    margin-top: $separator-xs;
    .dialog-consumptions__card-content-sm {
      font-size: $dialog-consumptions-card-text-sm-size;
      color: $dialog-consumptions-card-text-color-light;
    }
  }
}

.dialog-consumptions__content-graphic {
  width: 674px;
  .recharts-cartesian-axis-tick {
    .recharts-cartesian-axis-tick-line {
      display: none;
    }
  }
  .recharts-wrapper {
    margin-left: -20px;
  }
  .recharts-cartesian-axis-tick-value {
    tspan {
      font-family: $dialog-consumptions-graphic-font;
      font-size: $dialog-consumptions-title-type-size;
      color: $dialog-consumptions-title-color-gray;
      font-weight: $dialog-consumptions-graphic-font-weight;
      text-transform: $dialog-consumptions-title-type-text-transform;
    }
  }
  .recharts-cartesian-grid {
    .recharts-cartesian-grid-horizontal {
      line {
        stroke: $dialog-consumptions-graphic-horizontal-lines-color;
      }
    }
  }
  .recharts-layer.recharts-line {
    path {
      stroke: $color-first;
    }
  }
}

.dialog-consumptions__no-data {
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-consumptions__no-data-text {
  color: $dialog-consumptions-title-color-gray;
  font-family: $font-first;
  font-size: $dialog-consumptions-no-data-text-size;
  font-weight: $dialog-consumptions-no-data-font-weight;
}

.dialog-consumptions__tables {
  padding: $separator-m;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
