.stars-treasure-hunt {
  display: flex;
  align-items: center;

  .stars-treasure-hunt__star-activity {
    display: flex;
    align-items: center;
    margin-left: $main-separator-components;

    .icon {
      margin-left: $main-separator-items;
    }
  }

  .stars-treasure-hunt__star-length {
    @extend .title-h6;

    color: $color-gray-02  !important;
  }
}

.stars-treasure-hunt__star-activity--middle {
  .icon {
    height: 30px;
    width: 30px;
    right: 3px;
    position: relative;
    margin-right: -5px;
  }
}
