$group-resource-card-height: 140px;

.group-resource-card {
  @extend .border-light;
  @extend .hover-basic;

  background: $color-white;
  height: $group-resource-card-height;
  padding: $padding-card-default-small;

  .bubble-content__content {
    color: $color-white;
  }
}

.group-resource-card__top {
  display: flex;
  justify-content: space-between;
  height: 30%
}

.group-resource-card__bottom {
  height: 70%
}

.group-resource-card__name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $color-gray-01;
  display: block;
  text-transform:uppercase;
}

.group-resource-card__count {
  @extend .text-smallest;

  color: $color-gray-03;
  display: block;
  margin: $separator-xs 0;
  
}

.group-resource-card__only-teacher {
  .bubble-content {
    background-color: $color-gray-02;
    border: 0;
  }
}
