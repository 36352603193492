$classroom-code-background: $color-gray-07;
$classroom-code-number-color: $color-title;
$classroom-code-padding-vertical: $separator-xs;

.classroom-code {
  @extend .hover-basic;

  padding-top: $classroom-code-padding-vertical;
  padding-bottom: $classroom-code-padding-vertical;
  .card-background__children {
    display: flex;
    align-items: center;
  }
}

.classroom-code__wrapper {
  display: flex;
  align-items: center;
}

.classroom-code__icon {
  margin-right: $main-separator-components;
}

.classroom-code__number {
  @extend .title-h6;

  color: $classroom-code-number-color;
}
