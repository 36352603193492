$arrows-control-button-background: $color-gray-02;
$arrows-control-button-border-radius: 70px;
$arrows-control-button-height: 60px;
$arrows-control-button-width: 60px;
$arrows-control-buttons-separator: $separator-xxs;

.arrows-control {
  display: flex;
  flex-direction: column;
}

.arrows-control_button {
  @extend .hover-basic;

  background: $arrows-control-button-background;
  height: $arrows-control-button-height;
  width: $arrows-control-button-width;
}

.arrows-control_button--up {
  border-top-left-radius: $arrows-control-button-border-radius;
  border-top-right-radius: $arrows-control-button-border-radius;
}

.arrows-control_button--down {
  border-bottom-left-radius: $arrows-control-button-border-radius;
  border-bottom-right-radius: $arrows-control-button-border-radius;

  margin-top: $arrows-control-buttons-separator;
}
