.gamified-classification-dialog-list-item {
  padding: 8px 4px;
  margin: 4px 0;

  &__avatar {
    background-color: $color-gray-05;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15) inset;
  }

  &__name {
    color: $color-gray-01;
    font-size: 16px;
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__level {
    margin-right: 30px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .gamified-medal-progress__medal {
    svg {
      height: 42px;
      width: 42px;
    }
  }

  .gamified-medal-progress__value {
    font-size: 16px;
    height: 30px;
    padding-left: 26px;
    right: 14px;
    margin-right: 10px;
  }
}
