$border_radius: 18px;

.rubrics-table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 8px 12px;
    text-align: left;
    border: 1px solid #ebe0d7;
    background: #fff;
  }

  th {
    background: #faf7f5;
    color: $color-gray-02;
    font-family: Dosis;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180px;
    min-width: 180px;
  }

  th:nth-child(2) {
    width: 70px;
    min-width: 70px;
  }

  td {
    color: $color-gray-02;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__sub-name {
    color: $color-gray-03;
    font-family: Dosis;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
  }

  &__is-selected {
    td {
      @extend .hover-basic;
      padding-top: 4px;
    }

    td:nth-child(2),
    td:first-child {
      cursor: inherit;
    }
  }

  &__is-not-selected-td {
    @extend .d-flex;
    @extend .justify-content-end;
    @extend .mb-1;

    opacity: 0.15;
  }
}

.rubrics-table-container {
  overflow: auto;
  max-height: 680px;
}

.rubrics-table-container > table > thead > tr > th:first-child,
.rubrics-table-container > table > * > tr > td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.rubrics-table-container > table > thead > tr > th:nth-child(2),
.rubrics-table-container > table > * > tr > td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 180px;
}
