$aside-profile-z-indez: $z-index-aside;
$aside-profile-link-color: $color-first;
$aside-profile-name-color:$color-gray-01;
$aside-profile-role-color: $color-gray-03;

.aside-profile__aside {
  z-index: $aside-profile-z-indez !important;

  .menu-aside__header {
    justify-content: flex-start;
  }

  .menu-aside__children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .list-simple-dropdown {
    width: calc(100% + #{$separator-m} * 2);
    left: -$separator-m;
    position: relative;
  }

  .list-simple-dropdown__li {
    align-items: center;
    padding-top: $main-separator-items;
    padding-bottom: $main-separator-items;
    margin-top: $main-separator-items;
  }

  .list-simple-dropdown__name {
    font-size: $font-size-06 !important;
  }
}

.aside-profile__main-info {
  flex: 1 1 auto;
}

.aside-profile__name {
  @extend .text-p;

  color: $aside-profile-name-color;
  display: block;
  margin-top: $main-separator-items;
}

.aside-profile__role {
  @extend .text-small;

  color: $aside-profile-role-color;
  display: block;
}

.aside-profile__list {
  margin-top: $main-separator-items;
}

.aside-profile__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  color: $aside-profile-link-color;
}

.aside-profile__link {
  @extend .text-link;

  font-size: $font-size-06;
}

.aside-profile__close-session-container {
  @extend .hover-basic;

  display: flex;
  align-items: center;

  border-top: 1px solid $color-gray-05;
  padding-top: $separator-m;
}


.aside-profile__close-session {
  @extend .text-small;

  color: $color-gray-02;
  margin-left: $main-separator-items;
}


.aside-profile__item {
  position: relative;
}

.aside-profile__item__bubble {
  position: absolute;
  top: -11px;
  right: -19px;
}
