.dialog-new-group {
  .tag {
    margin: 0 8px 8px 0;
  }

  &__box {
    height: 400px;
    overflow-y: scroll;
  }
}
