.card-resourse-test {
  @extend .border-light;
  @extend .hover-basic;

  background: $color-white;
  padding: $padding-card-default-small $padding-card-default;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  min-height: 140px;
}

.card-resourse-test__top {
  display: flex;
  justify-content: space-between;
}

.card-resourse-test__name-container {
  display: flex;
}

.card-resourse-test__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  background: $color-gray-07;
  border-radius: $border-radius-02;
  height: 51px;
  width: 54px;
}

.card-resourse-test__name {
  @extend .title-h6;
  @include ellipsis-line(2);

  color: $color-gray-01;
  margin-left: $separator-l;
}

.list-simple-dropdown__texts {
  width: 100%;
}

.card-resourse-test__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-resourse-test__option--disabled {
  cursor: not-allowed !important;

  .list-simple-dropdown__icon,
  .card-resourse-test__option span {
    opacity: 0.5;
  }
}

.card-resourse-test__option-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-resourse-test__center {
  margin-top: $main-separator-items;
  margin-bottom: $separator-l;
}

.card-resourse-test__center {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $main-separator-items;
}

.card-resourse-test__progress {
  .progress-bar__bar {
    background: $color-gray-07;
  }

  .progress-with-label__title {
    font-size: $font-size-05;
    margin-bottom: 0;
  }

  .progress-with-label__description {
    font-size: $font-size-10;
    text-transform: uppercase;
  }
}

.card-resourse-test__progress--empty {
  .progress-with-label__title {
    color: $color-gray-04;
  }
}

.card-resourse-test__input-date-container {
  position: relative;
  .MuiFormControl-root {
    border-radius: $border-radius-02;
    border-color: #dbd5ce;
  }

  // .input-date-v2 {
  //   z-index: 100 !important;
  // }
}

.card-resourse-test__date-warning {
  position: absolute;
  top: 6px;
  right: 13px;
  z-index: $z-index-minor-elements;
}
