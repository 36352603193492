.scorm-individual-results-view__footer {
  @extend .padding-default;

  height: 100%;
}

.scorm-individual-results-view__footer-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 100%;
}

.scorm-individual-results-view__footer-buttons-arrow {
  display: flex;
  align-items: center;
}

.scorm-individual-results-view__footer-button-action {
  margin-left: $scorm-individual-results-view-button-action-separator;
}

.scorm-individual-results-view__footer-buttons-arrow {
  .button {
    margin-left: $scorm-individual-results-view-button-arrow-separator;
  }
}
