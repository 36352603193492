$dialog-project-lesson-item-background: $color-background;
$dialog-project-lesson-item-projecting-color: $color-success;
$dialog-project-lesson-item-projecting-separator: $main-separator-items;
$dialog-project-lesson-item-name-separator: $main-separator-items;
$dialog-project-lesson-item-separator: $main-separator-items;
$dialog-project-lesson-item-description-color: $color-title;
$dialog-project-lesson-item-preview-name-link-color: $color-title;
$dialog-project-lesson-detail-color: $color-label;
$dialog-project-lesson-ite-preview-description-color: $color-label;
$dialog-project-lesson-item-link-background: $color-gray-01;
$dialog-project-lesson-item-link-header-height: 40px;
$dialog-project-lesson-item-link-icon-separator: $main-separator-components;
$dialog-project-lesson-item-link-color-message: $color-white;

.dialog-project-lesson-item {
  .MuiDialogContent-root {
    background: $dialog-project-lesson-item-background;
  }

  .dialog-project-lesson-item__content {
    height: calc(100vh - #{$size-footer-projection-height} - #{$size-height-header});
    overflow-y: auto;
  }

  .preview-content__audio,
  .preview-content__type-with-icon {
    width: 100%;
  }

  .preview-content__video {
    height: 300px;
    margin-top: $main-separator-components;

    video {
      height: 100%;
    }

    .preview-content__type-with-icon-text-container {
      display: none;
    }
  }

  .preview-content__type--link-content {
    .preview-content__name {
      color: $dialog-project-lesson-item-preview-name-link-color;
    }

    .preview-content__description {
    }
  }

  .preview-content__type--pdf {
    .preview-content__description {
      color: $dialog-project-lesson-ite-preview-description-color;
    }
  }
}

.dialog-project-lesson-item__notes {
  margin-top: $dialog-project-lesson-item-separator;
}

.dialog-project-lesson-item__content-detail {
  @extend .text-smallest;

  color: $dialog-project-lesson-detail-color;
  margin-top: $dialog-project-lesson-item-separator;
}

.dialog-project-lesson-item__header {
  display: flex;
  justify-content: space-between;
  padding: $padding-card-default;
}

.dialog-project-lesson-item__projecting {
  display: flex;
  align-items: center;
}

.dialog-project-lesson-item__projecting-text {
  @extend .text-small;

  color: $dialog-project-lesson-item-projecting-color;
  margin-left: $dialog-project-lesson-item-projecting-separator;
}

.dialog-project-lesson-item__content {
  padding: 0 $padding-card-default-horizontal;
}

.dialog-project-lesson-item__content-header {
  display: flex;
  align-items: center;
}

.dialog-project-lesson-item__content-name {
  @extend .title-h5;

  margin-left: $dialog-project-lesson-item-name-separator;
}

.dialog-project-lesson-item__content-description {
  @extend .text-small;

  color: $dialog-project-lesson-item-description-color;
  display: block;
  margin-top: $dialog-project-lesson-item-separator;
}

.dialog-project-lesson-item__link {
  z-index: $z-index-popup !important;

  .MuiDialogContent-root {
    background: $dialog-project-lesson-item-link-background;
    padding: 0 $padding-card-default-horizontal;
  }

  .dialog-project-lesson-item__arrow {
    @extend .hover-basic;

    display: flex;
    align-items: center;

    height: $dialog-project-lesson-item-link-header-height;
    padding: 0 $padding-card-default-horizontal;
  }

  .thumbnail-or-bubble-icon__controls-player-icon {
    margin-top: $dialog-project-lesson-item-link-icon-separator;
  }

  .dialog-project-lesson-item__message {
    @extend .text-p;

    color: $dialog-project-lesson-item-link-color-message;
    padding: $padding-card-default;
  }
}
