$button-fixed-with-hover-radius: $border-radius-06;
$button-fixed-with-hover-size: $size-icon-bubble-medium;
$button-fixed-with-hover-size-big: $size-icon-bubble-l;
$button-fixed-with-hover-box-shadow: $box-shadow-05;

.button-fixed-with-hover {
  position: fixed;
  bottom: 24px;
  right: 24px;

  .button {
    border-radius: $button-fixed-with-hover-radius;
    box-shadow: $button-fixed-with-hover-box-shadow;
    min-width: $button-fixed-with-hover-size;
    padding: 12px;
    width: $button-fixed-with-hover-size;
    height: $button-fixed-with-hover-size;
    min-height: $button-fixed-with-hover-size;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0;
    }

    .button__text {
      display: none;
    }
  }
}

.button-fixed-with-hover:not(.button-fixed-with-hover--hover-disabled) {
  .button {
    &:hover {
      opacity: 1;
      width: auto;
      padding: 12px 16px;
      svg {
        margin-right: 8px;
      }

      .button__text {
        display: inline-block;
      }
    }
  }
}

.button-fixed-with-hover--size-big {
  .button {
    min-width: $button-fixed-with-hover-size-big;
    width: $button-fixed-with-hover-size-big;
    height: $button-fixed-with-hover-size-big;
  }
}
