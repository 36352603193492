$unit-item-book-margin: 36px;
$unit-item-border-color: $color-gray-05;
$unit-item-name-color: $color-gray-02;

.unit-item__container {
  padding-bottom: $separator-m;
  border-bottom: solid 1px $unit-item-border-color;
  padding-left: $separator-m;
}

.unit-item__top,
.unit-item__bottom {
  display: flex;
  justify-content: space-between;
}

.unit-item__bottom {
  margin-top: $separator-s;
}

.unit-item__top-left {
  display: flex;
  align-items: center;
}

.unit-item__name {
  @extend .title-h6;

  margin-left: $separator-s;
  color: $unit-item-name-color;
}

.unit-item__editorial {
  @extend .text-p;

  margin-left: $separator-m;
  color: $unit-item-name-color;
}

.unit-item__info-unit {
  @extend .text-small;

  color: $unit-item-name-color;
}

.unit-item__book {
  @extend .text-smallest;

  margin-left: $unit-item-book-margin;
  color: $unit-item-name-color;
}

.unit-item__edited-by {
  display: flex;
  align-items: center;
}

.unit-item__edited-by-text {
  @extend .text-smallest;

  margin-right: $separator-xs;
  color: $unit-item-name-color;

  span {
    @extend .text-smallest-bold;
  }
}

.unit-item--disabled {
  .unit-item__name {
    color: $color-gray-03;
  }

  .input-checkbox {
    svg {
      path {
        fill: $color-gray-03;
      }
    }
  }
}
