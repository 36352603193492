$hint-title-separator: $main-separator-components;
$hint-title-color: $color-bg-02;
$hint-text-color: $color-bg-02;
$hint-padding: $padding-card-default;
$hint-separator: $main-separator-components;

.hint {
  @extend .default-left;

  position: fixed;
  bottom: 24px;

  .button-fixed-dropdown__button--close {
    .button {
      animation: pulse 5s infinite forwards;
      box-shadow: 0 0 0 20px rgba(#fff, 0);
    }
  }
}

.hint__dropdown {
  max-width: 60vw;
  max-height: 60vh;
  overflow: auto;
  padding: $hint-padding;

  img {
    max-width: 100%;
  }
}

.hint__dropdown-title {
  @extend .title-h3;

  color: $hint-title-color;
  margin-left: $hint-title-separator;
}

.hint__dropdown-title-content {
  display: flex;
  align-items: center;
}

.hint__text-content {
  margin-top: $hint-separator;
}

.hint__text {
  color: $hint-text-color;
}

.hint__dropdown-lib {
  @extend .default-left;

  top: -30px !important;
}

