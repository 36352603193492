.input-password {
  position: relative;
}

.input-password__icon {
  position: absolute;
  transform: translateY(-50%);
  top: 52px;
  right: -30px;
}
