.card-default {
  @extend .border-light;

  background: $card-simple-background;
  border-radius: $card-simple-border-radius;
  box-shadow: $card-simple-box-shadow;
  padding: $card-simple-padding;
}

.card-default-small {
  @extend .card-default;

  padding: $card-default-small-padding;
}

.card-default-big {
  @extend .card-default;

  padding: $card-default-big-padding;
}

.card-simple {
  @extend .card-default-small;
  @extend .border-light;
}

.card-secondary {
  @extend .card-default;

  box-shadow: $card-secondary-box-shadow;
}

.card-secondary-small {
  @extend .card-secondary;

  padding: $card-default-small-padding;
}

.card-background {
  @extend .card-default;

  background: $card-background-background;
  padding: $card-background-padding;
}
