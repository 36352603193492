$dialog-expired-licenses-subtitle-size: 14px;

.dialog-expired-licenses {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.dialog-expired-licenses__subtitle {
  margin-top: 0;
  margin-bottom: $separator-l;
  color: $color-gray-02;
  font-weight: 700;
  font-size: $dialog-expired-licenses-subtitle-size;
  text-transform: uppercase;
}
