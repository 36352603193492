.lesson-power-header {
  background: $color-header-lesson;
  border-bottom: $border-headers;
  padding: $gutter;
}

.lesson-power-header__top {
  display: flex;
  justify-content: space-between;
}

.lesson-power-header__top-right,
.lesson-power-header__top-left {
  display: flex;
  align-items: center;
}

.lesson-power-header__unit-name {
  @extend .title-h6;

  margin-left: 16px;
}
.lesson-power-header__lesson-name {
  @extend .text-p;

  margin-left: 16px;
}

.lesson-power-header__subinfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 16px;
}

.lesson-power-header__meta-info {
  display: flex;
  align-items: center;
  margin-bottom: $separator-s;
}

.lesson-power-header__meta-info-text {
  @extend .text-small;

  color: $color-label2;
  margin-left: 0;
  margin-right: $separator-s;
  text-transform: lowercase;
}

.lesson-power-header__meta-date {
  text-transform: capitalize;
}

.lesson-power-header--student {
  .back-button {
    svg {
      path {
        fill: $color-white;
      }
    }
  }
  .lesson-power-header__unit-name,
  .lesson-power-header__lesson-name {
    color: $color-white;
  }

  .header-tabs__tab {
    background: none !important;
  }

  .header-tabs__tab--active {
    .header-tabs__tab-text {
      color: $color-white;
      opacity: 1;
    }
  }
  .header-tabs__tab-text {
    color: $color-white;
    opacity: 0.5;
  }
  .header-tabs__tab--active:after {
    background-color: $color-white;
  }
}
