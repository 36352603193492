$content-not-found-view-title-font-size: 22px;
$content-not-found-view-subtitle-font-size: 16px;

.content-not-found-view {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-not-found-view__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-not-found-view__texts {
  display: flex;
  flex-direction: column;
  margin: $separator-xl auto;
  text-align: center;
}

.content-not-found-view__texts-title {
  @extend .title-h4;
  margin: $separator-s auto;
  font-size: $content-not-found-view-title-font-size;
}

.content-not-found-view__texts-subtitle {
  font-size: $content-not-found-view-subtitle-font-size;
}
