$student-grades-activity-item-progress-bar-width: 80px;
$student-grades-activity-item-center-width: 160px;

.student-grades-activity-item {
  display: flex;
  align-items: center;
  width: 100%;

  .student-grades-activity-item__left {
  }

  .student-grades-activity-item__center {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    flex-grow: 1;

    .tag--progress-bar {
      width: $student-grades-activity-item-center-width;
      min-height: 40px;
    }

    .progress-bar__label {
      display: flex;
      align-items: center;
      .progress-bar__label--value {
        display: inline-block;
        color: $color-gray-03;
        font-size: $font-size-05;
        font-family: $font-first-semi;
      }
      .progress-bar__label--percent {
        display: inline-block;
        color: $color-gray-03;
        font-size: $font-size-08;
        font-family: $font-first;
        margin-left: $separator-xxs;
      }
    }

    .student-grades-activity-item__center--progress-bar {
      margin-right: $separator-l !important;
      width: $student-grades-activity-item-progress-bar-width;
    }

    .student-grades-activity-item__center--text-completed {
      color: #6d6f7d;
      font-family: $font-first-semi;
      font-size: 18px;
    }
    .student-grades-activity-item__center--icon {
      svg circle {
        fill: #6d6f7d;
      }
    }
  }

  .student-grades-activity-item__right {
    display: flex;
    align-items: center;
    padding-right: 120px;
  }

  .student-grades-activity-item__icon {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    font-size: $font-size-11;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-first;

    svg path {
      fill: $color-first;
    }
  }

  .student-grades-activity-item__icon--completed {
    border: 2px solid $color-gray-04;

    svg path {
      fill: $color-gray-04;
    }
  }

  .student-grades-activity-item__count {
    font-family: $font-second;
    font-weight: 400;
    font-size: $font-size-08;
    line-height: 20px;
    color: $color-gray-03;
  }

  .student-grades-activity-item__title {
    font-family: $font-second;
    font-weight: 700;
    font-size: $font-size-07;
    line-height: 20px;
    color: $color-gray-02;
    @include ellipsis-line(2);
  }

  .student-grades-activity-item__progress {
    margin-right: 22px;
  }

  .student-grades-activity-item__status {
    min-width: 244px;
  }
}

.student-grades-activity-item--disabled-click {
  cursor: not-allowed;
}

.student-grades-activity-item-clickable {
  cursor: pointer;
}
