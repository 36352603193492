$class-room-card-max-width-course: $size-classroom-course-max-width;
$class-room-card-name-color: $color-title;
$class-room-card-metatag-color: $color-label;

.class-room-card {
  :hover {
    $shadow-card-secondary: $box-shadow-02;
  }

  // .mosaic-list__item {
  //   max-width: 100%;
  // }
}

.class-room-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.class-room-card__header-level {
  @extend .text-smallest;

  color: $class-room-card-metatag-color;
}

.class-room-card__name {
  @extend .title-h4;

  color: $class-room-card-name-color;
  display: block;
  margin-bottom: $main-separator-items;
}

.class-room-card__courses-length {
  @extend .text-smallest;

  color: $class-room-card-metatag-color;
  display: block;
  margin-bottom: $main-separator-components;
}

.class-room-card__header-actions {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.class-room-card__texts {
  display: flex;
  flex-direction: column;
}

.class-room-card__add-course-card {
  .no-items-mode-edit {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
  }

  .no-items-mode-edit__add-button {
    margin-top: 0;
  }
}

// @media #{$breakpoints-large} {
//   .class-room-card {
//     .mosaic-list__item {
//       max-width: $class-room-card-max-width-course;
//     }
//   }
// }
