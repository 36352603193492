$footer-expandable-top-padding: $separator-xl;
$footer-expandable-toggle-padding-vertical: $separator-xs;
$footer-expandable-toggle-padding-horizontal: $separator-s;
$footer-expandable-top-background: $color-gray-07;
$footer-expandable-bottom-color: $color-white;

.footer-expandable {
}

.footer-expandable__toggle {
  display: flex;
  justify-content: center;
}

.footer-expandable__toggle-icon {
  cursor: pointer;
  padding: 0 $footer-expandable-toggle-padding-horizontal;
  border-radius: $border-radius-01 $border-radius-01 0 0;
  background-color: $footer-expandable-bottom-color;
}

.footer-expandable__top {
  display: none;

  padding: $footer-expandable-top-padding;
  background: $footer-expandable-top-background;
}

.footer-expandable__bottom {
  background: $footer-expandable-bottom-color;
}

.footer-expandable__top--expanded {
  display: block;
}
