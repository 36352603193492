$list-counts-separator: $main-separator-items;

.list-counts {
  display: flex;

  .count {
    margin-right: $list-counts-separator;

    &:last-child {
      margin-right: 0;
    }
  }
}
